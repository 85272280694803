import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FiChevronDown } from "react-icons/fi";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoIosArrowDown } from "react-icons/io";
import ListItemIcon from "@mui/material/ListItemIcon";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { CiExport } from "react-icons/ci";
import { FaRegTrashCan } from "react-icons/fa6";
import { IoPhoneLandscapeOutline } from "react-icons/io5";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { FaRegSquare } from "react-icons/fa";
import { FiEye } from "react-icons/fi";
import { CiImport } from "react-icons/ci";
import { BsPeople } from "react-icons/bs";

export default function AccountMenu({ name }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const navigate = useNavigate();
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip>
          <div
            onClick={handleClick}
            aria-controls={open ? "account-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
          >
            <div className="filter-dd">
              <p>Orientation</p>
              <IoIosArrowDown />
            </div>
          </div>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            mt: 0.5,
            border: "1px solid rgba(0,0,0,0.2)",
            width: "180px",
            "& .MuiAvatar-root": {
              width: 40,
              border: "1px solid rgba(0,0,0,0.2)",
              height: 40,
              ml: -0.5,
              mr: 1,
              bgcolor: "#f2f9ff",
            },
            "&::hover": {
              bgcolor: "#f2f9ff",
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              border: "1px solid rgba(0,0,0,0.2)",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: -555,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <ListItemIcon>
            <IoPhoneLandscapeOutline
              style={{ fontSize: "18px", color: "black" }}
            />
          </ListItemIcon>
          Landscape
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <IoPhonePortraitOutline
              style={{ fontSize: "18px", color: "black" }}
            />
          </ListItemIcon>
          Portrait
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <FaRegSquare style={{ fontSize: "18px", color: "black" }} />
          </ListItemIcon>
          Square
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
