import React, { forwardRef } from "react";

export const Spinner = forwardRef((props, ref) => {
  const { className, ...rest } = props;

  const spinnerClass = `animate-spin rounded-full border-2 border-current border-t-transparent h-4 w-4 ${
    className || ""
  }`;

  return <div className={spinnerClass} ref={ref} {...rest} />;
});

Spinner.displayName = "Spinner";
