import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../styles/stories/stories.css";
import "react-tooltip/dist/react-tooltip.css";
import { extractTitle } from "../../utils/Util";
import { MdDone } from "react-icons/md";
import { Tooltip } from "react-tooltip";
import Tooltipmui from "@mui/material/Tooltip";
import AddMoreOption from "../../components/stories/addMoreOption";
import { IoIosArrowRoundBack } from "react-icons/io";
import { TfiClose } from "react-icons/tfi";
import Add from "../../components/stories/add/Add";
import Template from "../../components/stories/template/template";
import Settings from "../../components/stories/setting/Settings";
import Seo from "../../components/stories/seo/Seo";
import CustomToolbar from "../../components/stories/CustomToolbar";
import { createStories, editStories, updateStories } from "../../api/Stories";
import { RiArrowGoBackLine, RiArrowGoForwardFill } from "react-icons/ri";
import Category from "../../components/stories/setting/Categories";
import Tags from "../../components/stories/setting/Tags";
import { Const } from "../../utils/Constants";
import { getArticleCategoryList } from "../../api/Category";
import { getListWriter } from "../../api/Writers";
import { getListTag } from "../../api/Tags";
import { generateSlugStories } from "../../utils/Util";
import * as Yup from "yup";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import PublishPopUp from "../../components/common/PublishPopup";
import { yupResolver } from "@hookform/resolvers/yup";
import BlockEditor from "../../components/editor/BlockEditor";
import { useEditor } from "@tiptap/react";
import { extensions, menu } from "../../helpers/TipTapConfig";
import Flags from "../../components/stories/setting/Flags";
import { getArticleFlagList } from "../../api/Flags";
import EmbedPopup from "../../helpers/extension/Embed/components/EmbedPopup";
import ImageFocalPopup from "../../components/common/ImageFocalPopup";

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  category: Yup.array()
    .min(1, "At least one category is required")
    .required("Category is required"),
});

const AddEditStories = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const prevurl = location.state;
  const getId = useMemo(
    () => location.pathname.split("/")[3],
    [location.pathname]
  );

  const [writer, setWriter] = useState([]);
  const [category, setCategory] = useState(null);
  const [flags, setFlags] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedFlags, setSelectedFlags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [images, setImages] = useState(null);
  const [ogImage, setOgImage] = useState(null);
  const [twitterImage, setTwitterImage] = useState(null);
  const [input, setInput] = useState({});
  const [meta, setMeta] = useState({});
  const [editorContent, setEditorContent] = useState({});
  const [open, setOpen] = useState(null);
  const [highlighted, setHighlighted] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [embedOpen, setEmbedOpen] = useState(false);
  const [contributor, setContributor] = useState([]);
  const [isCanonical, setIsCanonical] = useState(false);
  const menuContainerRef = useRef(null);
  const [isPublish, setIsPublish] = useState(false);
  const [showLink, setShowLink] = useState(null);
  const isInitialRender = useRef(true);
  const [filterText, setFilterText] = useState("");
  const [offset, setOffset] = useState(0);
  const [tagCount, setTagCount] = useState(0);
  const [openFocalModal, setOpenFocalModal] = useState(false);
  const [focalPosition, setFocalPosition] = useState({ x: 50, y: 50 });

  const extractTitleFunc = () => {
    const title = extractTitle(input?.title ?? "");
    if (title) {
      setInput((prev) => ({
        ...prev,
        articleTitle: title,
      }));
    } else {
      setInput((prev) => ({
        ...prev,
        articleTitle: "",
      }));
    }
  };

  useEffect(() => {
    extractTitleFunc();
  }, [input?.title]);

  const editor = useEditor({
    immediatelyRender: true,
    autofocus: true,
    onUpdate: useCallback(
      ({ editor }) => {
        const getContent = editor.getJSON();
        const description = getContent?.content?.find(
          (element) => element.type === "paragraph"
        );

        if (description && description?.content?.length > 0) {
          const getDesc = description?.content
            .map((data) => data.text || "")
            .join("");
          setMeta((prev) => ({ ...prev, description: getDesc }));
        } else {
          setMeta((prev) => ({ ...prev, description: "" }));
        }

        if (JSON.stringify(getContent) !== JSON.stringify(editorContent)) {
          setEditorContent(getContent);
        }
      },
      [editorContent]
    ),
    extensions: extensions,
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  useEffect(() => {
    if (getId) {
      setIsEdit(true);
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (editor && editorContent) {
      const currentContent = editor.getJSON();
      if (JSON.stringify(currentContent) !== JSON.stringify(editorContent)) {
        editor.commands.setContent(editorContent, false, {
          preserveScrollPosition: true,
        });
      }
    }
  }, [editor, editorContent]);

  useEffect(() => {
    if (errors && errors.category) {
      setOpen(1);
    }
  }, [errors]);

  const fetchData = async () => {
    const payload = {
      search: "",
      limit: 200, // Static
      offset: 0,
    };

    try {
      if (getId) {
        const storiesRes = await editStories(getId);
        if (storiesRes && storiesRes.data) {
          const {
            title,
            excerpt,
            coverImg,
            caption,
            courtesy,
            altName,
            duplicationNote,
            contributor,
            publishDate,
            content,
            tag,
            writer,
            articleTitle,
            articleSubheading,
            platform,
            views,
            week,
            weekendBoxOffice,
            cumulativeBoxOffice,
            template,
            subcategory,
            section,
            focalPosition,
            slug,
          } = storiesRes.data;
          setInput({
            title,
            excerpt,
            caption,
            courtesy,
            altName,
            duplicationNote,
            contributor,
            publishDate,
            slug: slug?.split("/")[1],
            writer,
            articleTitle,
            articleSubheading,
            platform,
            views,
            week,
            weekendBoxOffice,
            cumulativeBoxOffice,
            template,
          });
          if (focalPosition) {
            setFocalPosition(focalPosition);
          }
          setEditorContent(JSON.parse(content));
          setOgImage(storiesRes?.data?.meta?.og?.image ?? null);
          setTwitterImage(storiesRes?.data?.meta?.twitter?.image ?? null);
          setImages(coverImg ?? null);
          setWriter(writer ?? []);
          setSelectedTags(tag ?? []);
          setSelectedSubCategory(subcategory ?? "");
          setSelectedFlags(section ?? []);
          setSelectedKeywords(storiesRes?.data?.meta?.keywords ?? []);
          setIsCanonical(storiesRes?.data?.meta?.canonical ? true : false);
          setMeta({
            title: storiesRes?.data?.meta?.title ?? "",
            description: storiesRes?.data?.meta?.description ?? "",
            robots: storiesRes?.data?.meta?.robots ?? "index,follow",
            ...(storiesRes?.data?.meta?.author
              ? { author: storiesRes?.data?.meta?.author }
              : {}),
            ...(storiesRes?.data?.meta?.canonical
              ? { canonical: storiesRes?.data?.meta?.canonical }
              : {}),
          });
        }
      }

      // Category API Trigger
      const categoryRes = await getArticleCategoryList({ isVideo: false });
      if (categoryRes) setCategory(categoryRes.data);

      // Flag API Trigger
      const flagRes = await getArticleFlagList();
      if (flagRes) setFlags(flagRes.data);

      // Writer API Trigger
      const writerRes = await getListWriter(payload);
      if (writerRes) setWriter(writerRes.data);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTagsData = useCallback(async () => {
    try {
      const payload = {
        search: JSON.stringify(filterText ?? ""),
        limit: Const.Limit,
        offset: offset,
      };
      const tagRes = await getListTag(payload);
      if (tagRes) {
        if (offset > 0) {
          setTags((prev) => [...prev, ...tagRes.data]);
          setTagCount(tagRes.count);
          return;
        }
        setTags(tagRes.data);
        setTagCount(tagRes.count);
      }
    } catch (err) {
      console.log(err);
    }
  }, [filterText, offset]);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      fetchTagsData();
    }, 300);

    return () => clearTimeout(debounceTimeout);
  }, [filterText, fetchTagsData]);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltipmui {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: "white",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "writer") {
      setInput((prev) => {
        const writers = prev[name] || [];

        if (writers.includes(value)) {
          return { ...prev, [name]: writers.filter((item) => item !== value) };
        } else {
          return { ...prev, [name]: [...writers, value] };
        }
      });
    } else if (name === "title") {
      setInput((prev) => ({ ...prev, slug: generateSlugStories(value) }));
      setMeta((prev) => ({ ...prev, title: value }));
    }
    setInput((prev) => ({ ...prev, [name]: value }));
  };

  const handleDate = (value, name) => {
    setInput((prev) => ({ ...prev, [name]: value }));
  };

  const handleMetaData = (e) => {
    const { name, value, checked } = e.target;
    if (name.startsWith("og.") || name.startsWith("twitter.")) {
      const [prefix, key] = name.split(".");
      setMeta((prev) => {
        const newMeta = { ...prev };
        if (!newMeta[prefix]) {
          newMeta[prefix] = {};
        }
        newMeta[prefix][key] = value || "";
        return newMeta;
      });
    } else {
      if (name === "slug") {
        setInput((prev) => ({ ...prev, [name]: generateSlugStories(value) }));
      } else {
        setMeta((prev) => {
          const newMeta = { ...prev };
          if (value === undefined) {
            delete newMeta[name];
            return newMeta;
          }
          if (name === "robots") {
            newMeta[name] = checked ? "index,follow" : "noindex,nofollow";
            return newMeta;
          }
          newMeta[name] = value;
          return newMeta;
        });
      }
    }
  };

  const handleSaveOrUpdate = async (status) => {
    setIsBtnLoading(true);
    const getAuthor = writer.find(
      (author) => author._id === input.writer
    )?.name;
    const payload = {
      ...input,
      focalPosition: focalPosition,
      contributor: contributor,
      isHighlighted: highlighted == null ? false : true,
      content: JSON.stringify(editorContent),
      tag: selectedTags,
      subcategory: selectedSubCategory,
      section: selectedFlags,
      status: status,
      slug: `/${input?.slug}` ?? "",
      meta: {
        title: meta?.title ? meta?.title : input?.title ?? "",
        description: meta?.description
          ? meta?.description
          : input?.excerpt ?? "",
        keywords: selectedKeywords,
        author: getAuthor ?? "",
        robots: meta?.robots ? meta?.robots : "index,follow",
        ...(meta?.canonical && { canonical: meta?.canonical }),
        og: {
          title: meta?.og?.title ? meta?.og?.title : input?.title ?? "",
          description: meta?.og?.description
            ? meta?.og?.description
            : input?.excerpt ?? "",
          ...(!meta?.og?.image && typeof images === "string"
            ? { image: images }
            : {}),
        },
        twitter: {
          card: meta?.twitter?.card
            ? meta?.twitter?.card
            : "summary_large_image",
          title: meta?.twitter?.title
            ? meta?.twitter?.title
            : input?.title ?? "",
          description: meta?.twitter?.description
            ? meta?.twitter?.description
            : input?.excerpt ?? "",
          ...(!meta?.twitter?.image && typeof images === "string"
            ? { image: images }
            : {}),
        },
      },
    };

    const formData = new FormData();
    formData.append("data", JSON.stringify(payload));
    if (typeof images !== "string") {
      formData.append("coverImg", images[0]);
    }
    if (
      (ogImage && typeof ogImage !== "string") ||
      typeof images !== "string"
    ) {
      formData.append("ogImg", ogImage ? ogImage[0] : images[0]);
    }
    if (
      (twitterImage && typeof twitterImage !== "string") ||
      typeof images !== "string"
    ) {
      formData.append("twitterImg", twitterImage ? twitterImage[0] : images[0]);
    }
    let response = {};
    try {
      if (isEdit) {
        response = await updateStories(getId, formData);
      } else {
        response = await createStories(formData);
      }
      if (response && response?.data && status === Const.Active)
        navigate("/stories");
    } catch (error) {
      console.error("Error while saving/updating story", error);
    } finally {
      setIsBtnLoading(false);
    }
  };

  const handlePreview = async (e) => {
    e.preventDefault();
    if (isEdit) {
      window.open(`${Const.ClientLink}/stories/${input?.slug ?? ""}`, "_blank");
    }
  };

  // Link Toggle
  const handleLinkMenu = (event) => {
    setShowLink(event.currentTarget);
  };

  if (isLoading) return "Loading...";
  return (
    <>
      <div className="editor-cont">
        <div className="editor-nav">
          <div
            className="pn-back t3 flex-all"
            onClick={() => navigate(prevurl?.prev ?? "/stories")}
          >
            <IoIosArrowRoundBack />
            Back
          </div>
          <div className="er-wrap-center">
            <CustomToolbar
              editor={editor}
              showLink={showLink}
              setShowLink={setShowLink}
              handleLinkMenu={handleLinkMenu}
            />
          </div>
          <div className="pn-nav-items flex-story-btn">
            <div className="er-wrap-items undo-div">
              <button
                className="er-wrap-icon"
                onClick={() => editor.chain().focus().undo().run()}
                disabled={!editor.can().undo()}
              >
                <RiArrowGoBackLine className="undo-logo" />
              </button>
              <button
                className="er-wrap-icon"
                onClick={() => editor.chain().focus().redo().run()}
                disabled={!editor.can().redo()}
              >
                <RiArrowGoForwardFill className="undo-logo" />
              </button>
            </div>

            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    Draft saved. Click 'Publish' to make changes live.
                  </Typography>
                </React.Fragment>
              }
            >
              {!isBtnLoading ? (
                <button
                  className="story-head-p"
                  onClick={() => handleSaveOrUpdate(Const.Inactive)}
                  disabled={
                    input?.title && editorContent?.content?.length > 0
                      ? false
                      : true
                  }
                >
                  Save
                </button>
              ) : (
                <MdDone style={{ color: "#306bcd" }} />
              )}
            </HtmlTooltip>
            <div className="verti-line"></div>
            {!input?.title && !editorContent?.content?.length > 0 && (
              <>
                <Tooltip id="btn-preview" style={{ maxWidth: "300px" }} />
              </>
            )}
            <button
              data-tooltip-id="btn-preview"
              data-tooltip-content="To preview your post, give it a title then add text or an image."
              className="story-head-p"
              onClick={(e) => handlePreview(e)}
              disabled={
                input?.title && editorContent?.content?.length > 0
                  ? false
                  : true
              }
            >
              Preview
            </button>
            {isBtnLoading ? (
              <button className="publish flex-all t3">Loading...</button>
            ) : (
              <>
                {!input?.title && !editorContent?.content?.length > 0 && (
                  <>
                    <Tooltip id="btn-publish" style={{ maxWidth: "300px" }} />
                  </>
                )}
                <button
                  data-tooltip-id="btn-publish"
                  data-tooltip-content="To publish your post, give it a title then add text or an image."
                  type="submit"
                  className="publish flex-all t3"
                  onClick={() => {
                    setIsPublish(true);
                  }}
                  disabled={
                    input?.title && editorContent?.content?.length > 0
                      ? false
                      : true
                  }
                >
                  Publish
                </button>
              </>
            )}
            {/* <AddMoreOption
              disabled={
                input?.title && editorContent?.content?.length > 0
                  ? false
                  : true
              }
            /> */}
          </div>
        </div>
        <div className="editor-body" ref={menuContainerRef}>
          <div className="eb-left">
            <Tooltip id="my-tooltip" />
            {menu.map((item, i) => (
              <div
                key={i}
                className={`eb-block flex-all ${open === i ? "active" : ""}`}
                onClick={() => setOpen(i)}
              >
                <div
                  className={`eb-circle flex-all ${open === i ? "active" : ""}`}
                >
                  {item.svg}
                </div>
                <div className={`eb-name ${open === i ? "active" : ""}`}>
                  {item.name}
                </div>
              </div>
            ))}
          </div>
          <div className={`eb-mid t3 ${open !== null ? "eb-mid-open" : ""}`}>
            <div className="em-top">
              <div className="em-name">{menu[open]?.title || "Add"}</div>
              <div
                className="em-close t3 flex-all"
                onClick={() => setOpen(null)}
              >
                <TfiClose />
              </div>
            </div>
            {open === 0 && <Add editor={editor} setEmbedOpen={setEmbedOpen} />}
            {open === 1 && (
              <Category
                data={category}
                setCategory={setCategory}
                highlighted={highlighted}
                setHighlighted={setHighlighted}
                register={register}
                errors={errors}
                selectedSubCategory={selectedSubCategory}
                setSelectedSubCategory={setSelectedSubCategory}
              />
            )}
            {open === 2 && (
              <Flags
                data={flags}
                selectedFlags={selectedFlags}
                setSelectedFlags={setSelectedFlags}
              />
            )}
            {open === 3 && (
              <Tags
                tags={tags}
                setTags={setTags}
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                filterText={filterText}
                setFilterText={setFilterText}
                setOffset={setOffset}
                tagCount={tagCount}
              />
            )}
            {open === 4 && (
              <Settings
                writer={writer}
                images={images}
                setImages={setImages}
                input={input}
                contributor={contributor}
                openFocalModal={openFocalModal}
                setContributor={setContributor}
                setOpenFocalModal={setOpenFocalModal}
                handleInputChange={handleInputChange}
                handleDate={handleDate}
                setWriter={setWriter}
              />
            )}
            {open === 5 && <Template setInput={setInput} />}
            {open === 6 && (
              <Seo
                title={input?.title ?? ""}
                excerpt={input?.excerpt ?? ""}
                meta={meta}
                images={images}
                ogImage={ogImage}
                setOgImage={setOgImage}
                twitterImage={twitterImage}
                setTwitterImage={setTwitterImage}
                isCanonical={isCanonical}
                setIsCanonical={setIsCanonical}
                slug={input?.slug ?? ""}
                selectedKeywords={selectedKeywords}
                setSelectedKeywords={setSelectedKeywords}
                handleMetaData={handleMetaData}
              />
            )}
          </div>
          <div
            className={`eb-right t3 ${open !== null ? "eb-right-open" : ""}`}
          >
            <div className="editor-js-cont tiptap-editor-container">
              <div className="editor-js-title">
                <textarea
                  {...register("title")}
                  className="editor-js-input"
                  placeholder="Add a Catchy Title"
                  name="title"
                  value={input.title || ""}
                  onChange={handleInputChange}
                />
                {errors && errors.title && (
                  <div className="error-div">
                    <div className="error">{errors.title.message}</div>
                  </div>
                )}
              </div>
              <BlockEditor
                editor={editor}
                menuContainerRef={menuContainerRef}
                setOpen={setOpen}
                showLink={showLink}
                setShowLink={setShowLink}
              />
            </div>
          </div>
        </div>
      </div>
      {isPublish && (
        <PublishPopUp
          isBtnLoading={isBtnLoading}
          errors={errors}
          setIsPublish={setIsPublish}
          handleSubmit={handleSubmit}
          handleSaveOrUpdate={handleSaveOrUpdate}
          handlePreview={handlePreview}
        />
      )}
      {embedOpen && <EmbedPopup setOpen={setEmbedOpen} editor={editor} />}
      {openFocalModal && (
        <ImageFocalPopup
          images={
            typeof images === "string"
              ? images
              : images && images.length === 1
              ? URL.createObjectURL(images[0])
              : ""
          }
          focalPosition={focalPosition}
          setFocalPosition={setFocalPosition}
          setOpenFocalModal={setOpenFocalModal}
        />
      )}
    </>
  );
};

export default AddEditStories;
