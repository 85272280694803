import { BubbleMenu as BaseBubbleMenu, useEditorState } from "@tiptap/react";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { getRenderContainer } from "../../../utils/getRenderContainer";
import { IconButton, MenuItem, Select, Box, Drawer } from "@mui/material";
import SizeSmall from "../../../../assets/svg/SizeSmall";
import SizeMedium from "../../../../assets/svg/SizeMedium";
import SizeFullWidth from "../../../../assets/svg/SizeFullWidth";
import AlignLeft from "../../../../assets/svg/AlignLeft";
import AlignCenter from "../../../../assets/svg/AlignCenter";
import AlignRight from "../../../../assets/svg/AlignRight";
import Setting from "../../../../assets/svg/Setting";
import { LiaAngleDownSolid } from "react-icons/lia";
import { FiX } from "react-icons/fi";
import { getEmbedTypeAndUrl } from "./hooks";

export const EmbedMenu = ({ editor, appendTo }) => {
  const menuRef = useRef(null);
  const tippyInstance = useRef(null);
  const [open, setOpen] = useState(false);

  const getReferenceClientRect = useCallback(() => {
    const renderContainer = getRenderContainer(editor, "node-embed");
    const rect =
      renderContainer?.getBoundingClientRect() ||
      new DOMRect(-1000, -1000, 0, 0);

    return rect;
  }, [editor]);

  const shouldShow = useCallback(() => {
    const isActive = editor.isActive("embed");
    return isActive;
  }, [editor]);

  const onAlignEmbed = useCallback(
    (alignment) => {
      editor
        .chain()
        .focus(undefined, { scrollIntoView: false })
        .setEmbedAlign(alignment)
        .run();
    },
    [editor]
  );

  const onWidthChange = useCallback(
    (value) => {
      editor
        .chain()
        .focus(undefined, { scrollIntoView: false })
        .setEmbedWidth(value)
        .run();
    },
    [editor]
  );

  const updateEmbedAttributes = useCallback(() => {
    const caption = document.getElementById("caption").value;
    const courtesy = document.getElementById("courtesy").value;
    const alt = document.getElementById("alt").value;

    editor
      .chain()
      .focus(undefined, { scrollIntoView: false })
      .setEmbedCaption(caption)
      .setEmbedCourtesy(courtesy)
      .setEmbedAlt(alt)
      .run();
  }, [editor]);

  const { isEmbedCenter, isEmbedLeft, isEmbedRight, width } = useEditorState({
    editor,
    selector: (ctx) => {
      return {
        isEmbedLeft: ctx.editor.isActive("embed", { align: "left" }),
        isEmbedCenter: ctx.editor.isActive("embed", { align: "center" }),
        isEmbedRight: ctx.editor.isActive("embed", { align: "right" }),
        width: parseInt(ctx.editor.getAttributes("embed")?.width || 0),
      };
    },
  });

  const embedFrame = getEmbedTypeAndUrl(
    editor.getAttributes("embed")?.embed || ""
  ).embedFrame;

  return (
    <>
      <BaseBubbleMenu
        editor={editor}
        pluginKey="embedMenu"
        shouldShow={shouldShow}
        updateDelay={0}
        tippyOptions={{
          interactive: true,
          offset: [0, 8],
          maxWidth: "100%",
          popperOptions: {
            modifiers: [{ name: "flip", enabled: false }],
          },
          getReferenceClientRect,
          onCreate: (instance) => {
            tippyInstance.current = instance;
          },
          appendTo: () => {
            return appendTo?.current;
          },
        }}
      >
        <div className="bubble-menu" ref={menuRef}>
          <button className="bubble-menu-icon">
            <Select
              labelId="size-label"
              id="size-select"
              value={width || 100}
              onChange={(e) => onWidthChange(parseInt(e.target.value))}
              displayEmpty
              sx={{
                padding: "0px",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                  padding: "0px",
                },
                "& .MuiSelect-icon": {
                  display: "flex",
                  alignItems: "center",
                  width: "12px",
                  height: "12px",
                  right: "0px",
                },
                "& .MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0px",
                  height: "unset",
                  paddingRight: "15px!important",
                  minHeight: "unset",
                },
              }}
              IconComponent={LiaAngleDownSolid}
              renderValue={() => (
                <IconButton sx={{ padding: "0px" }}>
                  {width === 50 ? (
                    <SizeSmall />
                  ) : width === 75 ? (
                    <SizeMedium />
                  ) : (
                    <SizeFullWidth />
                  )}
                </IconButton>
              )}
              MenuProps={{
                PaperProps: {
                  sx: {
                    mt: 2,
                  },
                },
              }}
            >
              <MenuItem sx={{ fontSize: "14px", gap: "10px" }} value={50}>
                <SizeSmall /> Small
              </MenuItem>
              <MenuItem sx={{ fontSize: "14px", gap: "10px" }} value={75}>
                <SizeMedium /> Medium
              </MenuItem>
              <MenuItem sx={{ fontSize: "14px", gap: "10px" }} value={100}>
                <SizeFullWidth /> Full Width
              </MenuItem>
            </Select>
          </button>
          <span className="bubble-menu-divider"></span>
          <button className="bubble-menu-icon">
            <Select
              labelId="alignment-label"
              id="alignment-select"
              value={isEmbedLeft ? "left" : isEmbedRight ? "right" : "center"}
              onChange={(e) => onAlignEmbed(e.target.value)}
              displayEmpty
              sx={{
                padding: "0px",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiSelect-icon": {
                  display: "flex",
                  alignItems: "center",
                  width: "12px",
                  height: "12px",
                  right: "0px",
                },
                "& .MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0px",
                  height: "unset",
                  paddingRight: "15px!important",
                  minHeight: "unset",
                },
              }}
              IconComponent={LiaAngleDownSolid}
              renderValue={() => (
                <IconButton sx={{ padding: "0px" }}>
                  {isEmbedLeft ? (
                    <AlignLeft />
                  ) : isEmbedRight ? (
                    <AlignRight />
                  ) : (
                    <AlignCenter />
                  )}
                </IconButton>
              )}
              MenuProps={{
                PaperProps: {
                  sx: {
                    mt: 2,
                  },
                },
              }}
            >
              <MenuItem sx={{ fontSize: "14px", gap: "10px" }} value="left">
                <AlignLeft /> Align left
              </MenuItem>
              <MenuItem sx={{ fontSize: "14px", gap: "10px" }} value="center">
                <AlignCenter /> Align center
              </MenuItem>
              <MenuItem sx={{ fontSize: "14px", gap: "10px" }} value="right">
                <AlignRight /> Align right
              </MenuItem>
            </Select>
          </button>
          <span className="bubble-menu-divider"></span>
          <button className="bubble-menu-icon" onClick={() => setOpen(true)}>
            <Setting />
          </button>
        </div>
      </BaseBubbleMenu>
      <Drawer anchor={"right"} open={open} onClose={() => setOpen(false)}>
        <Box sx={{ width: 450 }} role="presentation">
          <div className="menu-drawer">
            <div className="headsec">
              <h2 className="heading">Image</h2>
              <FiX className="close" onClick={() => setOpen(false)} />
            </div>
            <div className="mainsec">
              <div className="general-main-div">
                <div className="featured-img-div">
                  <div className="featured-img-top">
                    <div className="feature-logo">
                      <p className="general-p-small">Embed</p>
                    </div>
                  </div>
                  <div
                    className="bubblemenu-iframe"
                    style={{
                      width: "100%",
                      height: "300px",
                      display: "flex",
                      overflow: "hidden",
                    }}
                    dangerouslySetInnerHTML={{ __html: embedFrame }}
                  ></div>
                </div>
                <div className="publish-date-div">
                  <div className="featured-img-top">
                    <div className="feature-logo">
                      <p className="general-p-small">Caption</p>
                    </div>
                  </div>
                  <input
                    id="caption"
                    type="text"
                    className="general-input"
                    defaultValue={
                      editor.getAttributes("embed")?.caption || ""
                    }
                    placeholder="Add a caption here"
                  />
                </div>
                <div className="publish-date-div">
                  <div className="featured-img-top">
                    <div className="feature-logo">
                      <p className="general-p-small">Courtesy</p>
                    </div>
                  </div>
                  <input
                    id="courtesy"
                    type="text"
                    className="general-input"
                    defaultValue={
                      editor.getAttributes("embed")?.courtesy || ""
                    }
                    placeholder="Add a courtesy here"
                  />
                </div>
                <div className="publish-date-div">
                  <div className="featured-img-top">
                    <div className="feature-logo">
                      <p className="general-p-small">Alt name</p>
                    </div>
                  </div>
                  <input
                    id="alt"
                    type="text"
                    className="general-input"
                    defaultValue={editor.getAttributes("embed")?.alt || ""}
                    placeholder="e.g. A cat sleeping on a white blanket"
                  />
                </div>
              </div>
            </div>
            <div className="footersec">
              <button
                className="btn-primary-outline"
                onClick={() => setOpen(false)}
              >
                Cancel
              </button>
              <button
                className="btn-primary"
                onClick={() => {
                  updateEmbedAttributes();
                  setOpen(false);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </Box>
      </Drawer>
    </>
  );
};

export default EmbedMenu;
