import React, { useEffect, useState } from "react";
import { FloatingMenu } from "@tiptap/react";
import { HiPlus } from "react-icons/hi2";

const InlineToolbar = ({ setOpen, editor }) => {
  const [toolbarStyle, setToolbarStyle] = useState({});

  useEffect(() => {
    if (editor && editor.isEditable) {
      editor.commands.focus();
    }
    const updateToolbarPosition = () => {
      const ebMidOpenElement = document.querySelector(".eb-mid-open");
      if (ebMidOpenElement) {
        const width = ebMidOpenElement.offsetWidth || 0;
        setToolbarStyle({
          right: `calc(100% - ${width}px)`,
        });
      } else {
        setToolbarStyle({
          right: "10px",
        });
      }
    };

    updateToolbarPosition();
    window.addEventListener("resize", updateToolbarPosition);
    return () => window.removeEventListener("resize", updateToolbarPosition);
  }, [editor]);

  return (
    <>
      {editor && (
        <FloatingMenu
          className="inlinetool-bar"
          tippyOptions={{ duration: 100, placement: "left-start" }}
          editor={editor}
          style={toolbarStyle}
        >
          <button className="btn-add-inlinetool" onClick={() => setOpen(0)}>
            <HiPlus />
          </button>
        </FloatingMenu>
      )}
    </>
  );
};

export default InlineToolbar;
