import Headers from '../Headers';
import { ProcessAPI, Const } from '../../utils/Constants';

// Get Customers List
export const getCustomers = async (body) => {
    const res = await fetch(Const.Link + "api/user/getcustomers", new Headers("POST", body));
    return ProcessAPI(res);
}

// Edit Product API
export const editCustomer = async (id) => {
    const res = await fetch(Const.Link + "api/user/editOne/"+ id, new Headers("GET"));
    return ProcessAPI(res);
}

export const insertCustomer = async (body) => {
    const res = await fetch(Const.Link + "api/user/insert-one", new Headers("POST", body));
    return ProcessAPI(res);
}

export const updateCustomer = async (body) => {
    const res = await fetch(Const.Link + "api/user/update-editone", new Headers("PATCH", body));
    return ProcessAPI(res);
}

export const deleteCustomer = async (id) => {
    const res = await fetch(Const.Link + "api/user/delete-customer/"+id, new Headers("DELETE"));
    return ProcessAPI(res);
}

export const blockedCustomer = async (body) => {
    const res = await fetch(Const.Link + "api/user/blocked-customer", new Headers("PATCH", body));
    return ProcessAPI(res);
}

export const getData = async (body, signal) => {
    const res = await fetch(Const.Link + "api/user/get-data", new Headers("POST", body, signal));
    return ProcessAPI(res);
}