import React, { useState, useEffect, useCallback, useRef } from "react";
import { FaPlus } from "react-icons/fa6";
import { MdArrowForwardIos, MdDragIndicator } from "react-icons/md";
import "../../styles/writers/Writers.css";
import TableStories from "../../components/subcategories/TableStoriesCat";
import { Const } from "../../utils/Constants";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getListStories,
  DeleteStories,
  duplicateStories,
  exportStories,
} from "../../api/Stories";
import {
  getAllListStories,
  getNonSubcategoryStories,
  addSubcategoryStories,
  deleteSubcategoryStories,
} from "../../api/Stories";
import { getTag } from "../../api/Tags";
import { getListWriter } from "../../api/Writers";
import DeletePopup from "../../components/common/DeletePopup";
import AddStories from "../../components/categories/AddStories";

const StoriesSelection = () => {
  const columns = ["Story Details", "Type", "Category", "Tags", "Status"];
  const navigate = useNavigate();
  const location = useLocation();
  const [isbtnLoading, setIsbtnLoading] = useState(false);
  const [isbtnLoadingExport, setIsBtnLoadingExport] = useState(false);
  const pathParts = location.pathname.split("/");
  const slugStarting = pathParts[pathParts.length - 2];
  const [open, setOpen] = useState({ right: false });
  const [scrolled, setScrolled] = useState(false);
  const [deleteStories, setDeleteStories] = useState(null);
  const [type, setType] = useState(null);
  const [row, setRow] = useState([]);
  const [tag, setTag] = useState({});
  const [selection, setSelection] = useState({
    select_bulk: false,
    selected: [],
  });
  const [author, setAuthor] = useState({});
  const [count, setCount] = useState({
    allCount: 0,
    publishedCount: 0,
    unpublishedCount: 0,
    draftCount: 0,
    trashCount: 0,
    scheduledCount: 0,
  });
  const [nonSubcat, setNonSubcat] = useState([]);
  const [nonSubcatCount, setNonSubcatCount] = useState(0);
  const [filter, setFilter] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [offset, setOffset] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isBtnLoading, setIsBtnLoading] = useState({
    deleteStories: null,
    addStories: null,
  });
  const [rowLoading, setRowLoading] = useState(false);
  const [tabSelected, setTabSelected] = useState("all");
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedAuthors, setSelectedAuthors] = useState([]);
  const [storiesType, setStoriesType] = useState([]);
  const [level, setLevel] = useState(null);
  const [sectionId, setSectionId] = useState(null);
  const [searchStories, setSearchStories] = useState("");
  const isInitialRender = useRef(true);
  const [isLoadingNonSubCat, setIsLoadingNonSubCat] = useState(false)

  useEffect(() => {
    const data = location?.state?.data ?? [];
    const type = location?.state?.type ?? 0;
    const filterId = location?.state?.id ?? null;
    const stories_type = location?.state?.storiesType ?? null;
    setLevel(type);
    setStoriesType(stories_type);

    if (type === 0 && data && data.length > 0) {
      setBreadcrumbs(data);
      setFilter((prevFilter) => ({
        ...prevFilter,
        category: [...(prevFilter?.category || []), ...filterId],
      }));
    }
    if (type === 1 && data && data.length > 0) {
      setBreadcrumbs(data);
      setFilter((prevFilter) => ({
        ...prevFilter,
        tag: [...(prevFilter?.tag || []), filterId],
      }));
    }
    if (type === 2 && data && data.length > 0) {
      setSectionId(filterId);
      setBreadcrumbs(data);
      setFilter((prevFilter) => ({
        ...prevFilter,
        section: [...(prevFilter?.section || []), filterId],
      }));
    }
  }, []);

  useEffect(() => {
    document
      .getElementsByClassName("admin-right")[0]
      .addEventListener("scroll", () => {
        if (
          document.getElementsByClassName("main-div")[0] &&
          document.getElementsByClassName("admin-right")[0].scrollTop >
            document.getElementsByClassName("main-div")[0].offsetTop - 150
        ) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      });
    // const element = document.getElementsByClassName("table-of")[0];
    // if (orders.length > 0) {
    //   element.addEventListener("scroll", handleLoadMore);
    // }

    setRowLoading(true);
    const timeout = setTimeout(() => {
      fetchData();
    }, 500);
    return () => {
      // element.removeEventListener("scroll", handleLoadMore);
      clearTimeout(timeout);
    };
  }, [filter?.search, offset, isUpdated]);

  useEffect(() => {
    fetchTag();
    fetchAuthor();
  }, []);

  const fetchTag = async () => {
    try {
      const res = await getTag();
      if (res) {
        setTag(res.data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchAuthor = async () => {
    const payload = {
      search: "",
      limit: Const.Limit,
      offset: 0,
    };
    try {
      const res = await getListWriter(payload);
      if (res) {
        setAuthor(res.data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchData = async () => {
    const navigateData = location?.state?.data ?? [];
    const navigateType = location?.state?.type ?? 0;
    const navigateFilterId = location?.state?.id ?? null;
    let navigateFilter = {};
    if (navigateType === 0 && navigateData && navigateData.length > 0) {
      const index = navigateData.length - 1;
      navigateFilter.category = [...navigateFilterId];
    }
    if (navigateType === 1 && navigateData && navigateData.length > 0) {
      navigateFilter.tag = [navigateFilterId];
    }
    if (navigateType === 2 && navigateData && navigateData.length > 0) {
      navigateFilter.section = [navigateFilterId];
    }
    const payload = {
      filter:
        filter && Object.keys(filter).length > 0 ? filter : navigateFilter,
      limit: Const.Limit,
      offset: offset,
    };
    try {
      const res = await getAllListStories(payload);
      if (res) {
        const {
          allCount,
          publishedCount,
          unpublishedCount,
          draftCount,
          scheduledCount,
          trashCount,
        } = res;
        if (offset > 0) {
          setCount((prevCount) => ({
            ...prevCount,
            allCount: prevCount[allCount] + allCount,
            publishedCount: prevCount[publishedCount] + publishedCount,
            unpublishedCount: prevCount[unpublishedCount] + unpublishedCount,
            draftCount: prevCount[draftCount] + draftCount,
            trashCount: prevCount[trashCount] + trashCount,
            scheduledCount: prevCount[scheduledCount] + scheduledCount,
          }));

          setRow((prevData) => [...prevData, ...res.data]);
          return;
        }
        setCount({
          allCount: allCount,
          publishedCount: publishedCount,
          unpublishedCount: unpublishedCount,
          draftCount: draftCount,
          trashCount: trashCount,
          scheduledCount: scheduledCount,
        });
        setRow(res.data);
      }
    } catch (e) {
      console.error("Error while fatching stories list", e);
    } finally {
      setIsLoading(false);
      setRowLoading(false);
    }
  };

  const handleSearch = (event) => {
    const { name, value } = event.target;
    const updatedFilter = { ...filter, [name]: value };
    if (!value) {
      delete updatedFilter[name];
    }
    setOffset(0);
    setFilter(updatedFilter);
  };

  const handleFilter = (event) => {
    const { name, value, type, checked } = event.target;
    if (name === "status") {
      if (value === "all") {
        const { [name]: removedFilter, ...rest } = filter;
        setFilter(rest);
      } else {
        setFilter((prevFilter) => ({
          ...prevFilter,
          [name]: JSON.parse(value),
        }));
      }
    } else if (name === "category") {
      if (checked) {
        setFilter((prevFilter) => ({
          ...prevFilter,
          [name]: [...(prevFilter?.[name] || []), value],
        }));
      } else {
        setFilter((prevFilter) => ({
          ...prevFilter,
          [name]: prevFilter[name].filter((item) => item !== value),
        }));
      }
    } else {
      setFilter((prevFilter) => ({ ...prevFilter, [name]: value }));
    }
  };

  const sendDataToChild = (isEdit, id) => {
    const getLeftClass = document.querySelector(".admin-left");
    const getRightClass = document.querySelector(".admin-right");
    getLeftClass.classList.add("admin-nav-disable");
    getRightClass.classList.add("admin-right-w100");
    setTimeout(() => {
      getRightClass.classList.add("admin-right-op0");
    }, 350);

    const dataToSend = { prev: "/stories" };
    setTimeout(() => {
      if (isEdit) {
        navigate(`/stories/edit-stories/${id}`, { state: dataToSend });
        return;
      }
      navigate("/stories/add-stories", { state: dataToSend });
    }, 650);
  };

  const toggleDrawer = (anchor, newOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen({ ...open, [anchor]: newOpen });
    if (newOpen) {
      fetchNonSubcategoryStories();
    }
  };

  const fetchNonSubcategoryStories = useCallback(async () => {
    try {
      setIsLoadingNonSubCat(true)
      const payload = {
        filter: {
          search: searchStories,
          type: storiesType,
          level: level,
          id: location.state?.data[location.state?.data.length - 1]?.id,
        },
        limit: Const.Limit,
        offset: offset,
      };

      const res = await getNonSubcategoryStories(payload);
      if (res) {
        setNonSubcatCount(res.count);
        setNonSubcat(res.data);
      }
    } catch (error) {
      console.error("Error fetching non-subcategory stories:", error);
    }
    finally{
      setIsLoadingNonSubCat(false)
    }
  }, [searchStories, storiesType, level, offset, location.state]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    console.log(searchStories,"seardh")
    // if(searchStories.length==0){
    //   setOffset(0)
    // }
    // if (!searchStories.trim()) return;

    const debounceTimeout = setTimeout(() => {
      fetchNonSubcategoryStories();
    }, 300);

    return () => clearTimeout(debounceTimeout);
  }, [searchStories, fetchNonSubcategoryStories]);

  const handleDeleteSubCategoryStories = async () => {
    setIsBtnLoading((prev) => ({ ...prev, deleteStories: true }));
    try {
      const payload = {
        type: storiesType,
        level: level,
        id: location.state?.data[location.state?.data.length - 1]?.id,
      };
      const res = await deleteSubcategoryStories(deleteStories, payload);
      if (res.success) {
        const updatedArticles = row.filter(
          (item, i) => item._id !== deleteStories
        );
        setCount((prevCount) => ({
          ...prevCount,
          allCount: prevCount.allCount - res.count,
        }));
        setRow(updatedArticles);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDeleteStories(null);
      setType(null);
      setIsBtnLoading((prev) => ({ ...prev, deleteStories: false }));
    }
  };

  const handleAddSubCategoryStories = async (event, id, type, i) => {
    event.preventDefault();
    setIsBtnLoading((prev) => ({ ...prev, addStories: i }));
    try {
      const payload = {
        type: storiesType ?? type,
        level: level,
        id: location.state?.data[location.state?.data.length - 1]?.id,
      };
      const res = await addSubcategoryStories(id, payload);
      if (res) {
        const nonSubcategory = nonSubcat.filter((item, i) => item._id !== id);
        setNonSubcat(nonSubcategory);
        setNonSubcatCount((prevCount) => prevCount - res.count);
        setCount((prevCount) => ({
          ...prevCount,
          allCount: prevCount.allCount + res.count,
        }));
        setRow((prevData) => [...prevData, res.data]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsBtnLoading((prev) => ({ ...prev, addStories: null }));
    }
  };
  const handleSelection = (event) => {
    const { id, checked } = event.target;
    if (id === "selection" || id === "selection-toggle") {
      setSelection({ ...selection, select_bulk: checked, selected: [] });
    } else {
      if (selection.select_bulk) {
        if (checked) {
          const filterSelected = selection.selected.filter((select) => {
            return select !== id;
          });
          setSelection({ ...selection, selected: filterSelected });
        } else {
          setSelection({ ...selection, selected: [...selection.selected, id] });
        }
      } else {
        if (checked) {
          setSelection({ ...selection, selected: [...selection.selected, id] });
        } else {
          const filterSelected = selection.selected.filter((select) => {
            return select !== id;
          });
          setSelection({ ...selection, selected: filterSelected });
        }
      }
    }
  };
  const handleDublicate = async () => {
    setIsBtnLoading(true);
    try {
      const payload = {
        select_bulk: selection.select_bulk,
        selected: selection.selected,
      };
      await duplicateStories(payload);
      setIsUpdated((prev) => !prev);
    } catch (e) {
      console.error(e);
    } finally {
      setIsBtnLoading(false);
    }
  };

  const handleExport = async () => {
    setIsBtnLoadingExport(true);
    try {
      const payload = {
        select_bulk: selection.select_bulk,
        selected: selection.selected,
      };
      const res = await exportStories(payload);
      if (!res.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await res.blob();

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "products.csv");
      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link);
    } catch (e) {
      console.error(e);
    } finally {
      setIsBtnLoadingExport(false);
    }
  };
  if (isLoading) {
    return "Loading...";
  }
  return (
    <>
      <div className="head-of-head writer-top">
        <div className="table-name">
          {breadcrumbs.length > 0 ? (
            <>
              {slugStarting === "categories" ? (
                <span
                  className="menu-nav"
                  onClick={() => navigate("/categories")}
                >
                  Categories
                </span>
              ) : (
                <span className="menu-nav" onClick={() => navigate("/flag")}>
                  Flagged stories
                </span>
              )}
              {breadcrumbs.map((breadcrumb, index) => {
                if (breadcrumbs.length - 1 === 0 || index > 0) {
                  return (
                    <React.Fragment key={breadcrumb.id}>
                      <MdArrowForwardIos
                        className="menu-nav-arrow"
                        style={{ marginInline: "5px" }}
                      />
                      <span className="active-p-head">{`${breadcrumb.name} (${row.length})`}</span>
                    </React.Fragment>
                  );
                } else {
                  if (
                    breadcrumbs.length > 1 &&
                    breadcrumbs[0].id === breadcrumbs[1].id
                  ) {
                    return (
                      <React.Fragment key={breadcrumb.id}>
                        <MdArrowForwardIos
                          className="menu-nav-arrow"
                          style={{ marginInline: "5px" }}
                        />
                        <span className="active-p-head">{`${breadcrumb.name} (${row.length})`}</span>
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <React.Fragment key={breadcrumb.id}>
                        <MdArrowForwardIos
                          className="menu-nav-arrow"
                          style={{ marginInline: "4px" }}
                        />
                        <span
                          className="menu-nav"
                          onClick={() => {
                            location.pathname === "/flag/sections/stories"
                              ? navigate("/flag/sections", {
                                  state: {
                                    data: [breadcrumbs[0]],
                                    type: location?.state?.type ?? 0,
                                    id: breadcrumbs[0].id ?? null,
                                  },
                                })
                              : navigate("/categories/subcategories", {
                                  state: {
                                    data: [breadcrumbs[0]],
                                    type: location?.state?.type ?? 0,
                                    id: breadcrumbs[0].id ?? null,
                                  },
                                });
                          }}
                        >
                          {breadcrumb.name}
                        </span>
                      </React.Fragment>
                    );
                  }
                }
              })}
            </>
          ) : (
            <>Stories</>
          )}
        </div>
        <div className="fixed-right">
          <button className="btn-primary" onClick={toggleDrawer("right", true)}>
            <FaPlus className="plus-logo" /> Add Stories
          </button>
        </div>
      </div>
      <p className="p-top-writer">
        Create, customize and manage your stories.{" "}
        <span className="learn-more">Learn More</span>
      </p>

      <TableStories
        count={count}
        columns={columns}
        slugStarting={slugStarting}
        selection={selection}
        setFilter={setFilter}
        isbtnLoadingExport={isbtnLoadingExport}
        rows={row}
        tabSelected={tabSelected}
        setTabSelected={setTabSelected}
        tag={tag}
        author={author}
        filter={filter}
        level={level}
        sectionId={sectionId}
        rowLoading={rowLoading}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        setSelectedAuthors={setSelectedAuthors}
        selectedAuthors={selectedAuthors}
        isBtnLoading={isBtnLoading}
        handleEdit={sendDataToChild}
        setDeleteStories={setDeleteStories}
        setType={setType}
        setIsUpdated={setIsUpdated}
        handleSelection={handleSelection}
        handleSearch={handleSearch}
        handleFilter={handleFilter}
        handleExport={handleExport}
        handleDublicate={handleDublicate}
        isbtnLoading={isbtnLoading}
        setOffset={setOffset}
        offset={offset}
        setRowLoading={setRowLoading}
      />
      {deleteStories !== null && type !== null && (
        <DeletePopup
          setDelete={setDeleteStories}
          handleDelete={handleDeleteSubCategoryStories}
        />
      )}

      <AddStories
        filter={filter}
        handleFilter={handleFilter}
        toggleDrawer={toggleDrawer}
        open={open}
        storiesType={storiesType}
        level={level}
        id={location.state?.data[location.state?.data.length - 1]?.id}
        setOpen={setOpen}
        count={nonSubcatCount}
        data={nonSubcat}
        setData={setNonSubcat}
        setCount={setNonSubcatCount}
        searchStories={searchStories}
        isLoadingNonSubCat={isLoadingNonSubCat}      
        setStoriesType={setStoriesType}
        setSearchStories={setSearchStories}
        isBtnLoading={isBtnLoading}
        handleAddSubCategoryStories={handleAddSubCategoryStories}
      />
    </>
  );
};

export default StoriesSelection;
