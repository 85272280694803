import React from "react";

const AlignRight = () => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 0H16V16H15V0ZM0 10.5C0 9.7 0.7 9 1.5 9H11.5C12.3 9 13 9.7 13 10.5V13.5C13 14.3 12.3 15 11.5 15H1.5C0.7 15 0 14.3 0 13.5V10.5ZM1 13.5C1 13.8 1.2 14 1.5 14H11.5C11.8 14 12 13.8 12 13.5V10.5C12 10.2 11.8 10 11.5 10H1.5C1.2 10 1 10.2 1 10.5V13.5ZM6.5 7H11.5C12.3 7 13 6.3 13 5.5V2.5C13 1.7 12.3 1 11.5 1H6.5C5.7 1 5 1.7 5 2.5V5.5C5 6.3 5.7 7 6.5 7ZM11.5 2C11.8 2 12 2.2 12 2.5V5.5C12 5.8 11.8 6 11.5 6H6.5C6.2 6 6 5.8 6 5.5V2.5C6 2.2 6.2 2 6.5 2H11.5Z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

export default AlignRight;
