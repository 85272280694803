import { NodeViewWrapper } from "@tiptap/react";
import { useCallback, useRef } from "react";

export const EmbedBlockView = (props) => {
  const { editor, getPos, node } = props;
  const embedWrapperRef = useRef(null);
  const { embedFrame, aspectRatio } = node.attrs;

  let wrapperClassName = "";
  if (node.attrs.align === "left") {
    wrapperClassName = "ml-0";
  } else if (node.attrs.align === "right") {
    wrapperClassName = "mr-0";
  } else if (node.attrs.align === "center") {
    wrapperClassName = "mx-auto";
  } else {
    wrapperClassName = "ml-auto mr-auto";
  }

  const onClick = useCallback(
    (e) => {
      e.stopPropagation();
      editor.commands.setNodeSelection(getPos());
    },
    [getPos, editor.commands]
  );

  return (
    <NodeViewWrapper>
      <div className={wrapperClassName} style={{ width: node.attrs.width }}>
        <div
          className="embed-wrapper"
          contentEditable={false}
          style={{ paddingTop: aspectRatio }}
          ref={embedWrapperRef}
          onClick={(e) => onClick(e)}
          dangerouslySetInnerHTML={{ __html: embedFrame }}
        ></div>
      </div>
    </NodeViewWrapper>
  );
};

export default EmbedBlockView;
