import { Node, ReactNodeViewRenderer } from "@tiptap/react";
import VideoUploadView from "./components/VideoUploadView";

export const VideoUpload = Node.create({
  name: "videoUpload",

  isolating: true,

  defining: true,

  group: "block",

  draggable: true,

  selectable: true,

  inline: false,

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML() {
    return ["div", { "data-type": this.name }];
  },

  addCommands() {
    return {
      setVideoUpload:
        () =>
        ({ commands }) =>
          commands.insertContent(`<div data-type="${this.name}"></div>`),
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(VideoUploadView);
  },
});

export default VideoUpload;
