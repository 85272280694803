export const dateFormate = (date) => {
  const dateObj = new Date(date);
  const options = {
    month: "long",
    day: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    dateObj
  );
  const getDate = formattedDate.split(" at ")[0];
  return getDate;
};
export const timeFormate = (date) => {
  const dateObj = new Date(date);
  const options = {
    month: "long",
    day: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    dateObj
  );
  const getTime = formattedDate.split(" at ")[1];
  return getTime;
};
export const formatDateAndTime = (isoString) => {
  const date = new Date(isoString);
  const now = new Date();

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const formattedDate = `${
    months[date.getUTCMonth()]
  } ${date.getUTCDate()}, ${date.getUTCFullYear()}`;

  const diffInMilliseconds = now - date;
  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInDays >= 10) {
    return formattedDate;
  } else {
    let timeDifference;
    if (diffInDays >= 1) {
      timeDifference = `${diffInDays} days ago`;
    } else if (diffInHours >= 1) {
      timeDifference = `${diffInHours} hours ago`;
    } else {
      timeDifference = `${diffInMinutes} minutes ago`;
    }
    return timeDifference;
  }
};

export const checkPermission = (value, viewIndex) => {
  if (value && typeof value == "number" && value > 0) {
    const permissions = Number(value)
      .toString(2)
      .split("")
      .reverse()
      .map((item) => item === "1");
    Object.keys(viewIndex).forEach(function (key, value) {
      if (permissions.length > value) {
        viewIndex[key] = permissions[value];
      } else {
        viewIndex[key] = false;
      }
    });
    return viewIndex;
  } else {
    return false;
  }
};

export const binaryToNumber = (value) => {
  if (value) {
    const binaryToNumber = parseInt(value, 2);
    return binaryToNumber;
  } else {
    return 0;
  }
};

export const permissionCount = (value) => {
  if (value && typeof value == "number" && value > 0) {
    const permissions = Number(value).toString(2).split("");
    const total = permissions.length;
    const count = permissions.filter((item) => item === "1").length;
    return { count, total };
  }
  return { count: 0, total: 0 };
};

export const isValidColor = (input) => {
  try {
    const namedColors = [
      "black",
      "silver",
      "gray",
      "white",
      "maroon",
      "red",
      "purple",
      "fuchsia",
      "green",
      "lime",
      "olive",
      "yellow",
      "navy",
      "blue",
      "teal",
      "aqua",
      // Add more color names here
    ];

    // Case-insensitive match against the list of named colors
    const colorRegex = new RegExp(`^(${namedColors.join("|")})$`, "i");
    // let regex = new RegExp(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);
    let regex = new RegExp(
      /^(#?([a-f\d]{3,4}|[a-f\d]{6}|[a-f\d]{8})|rgb\((0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d),(0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d),(0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d)\)|rgba\((0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d),(0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d),(0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d),(0|0?\.\d|1(\.0)?)\)|hsl\((0|360|35\d|3[0-4]\d|[12]\d\d|0?\d?\d),(0|100|\d{1,2})%,(0|100|\d{1,2})%\)|hsla\((0|360|35\d|3[0-4]\d|[12]\d\d|0?\d?\d),(0|100|\d{1,2})%,(0|100|\d{1,2})%,(0?\.\d|1(\.0)?)\))$/
    );
    return regex.test(input) || colorRegex.test(input);
  } catch (error) {
    return false;
  }
};

export const ga4FormatDate = (dateString) => {
  // Extract year, month, and day from the string
  const dateStringParam = dateString.split("-");
  const year = dateStringParam[0];
  const month = parseInt(dateStringParam[1]);
  const day = dateStringParam[2];

  // Define an array for month names
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const date = new Date(year, month - 1, day);
  const monthName = monthNames[date.getMonth()];
  const formattedDay = day.padStart(2, "0");
  return monthName + " " + formattedDay;
};

export const generateSlug = (title) => {
  let slug = title
    .toString() // Convert to string
    .toLowerCase() // Convert to lowercase
    .trim() // Trim leading/trailing whitespace
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/[^\w-]+/g, "") // Remove all non-word characters
    .replace(/--+/g, "-"); // Replace multiple hyphens with a single hyphen
  // Ensure the slug starts with a slash
  if (!slug.startsWith("/")) {
    slug = `/${slug}`;
  }

  return slug;
};

export const generateSlugStories = (title) => {
  let slug = title
    .toString() // Convert to string
    .toLowerCase() // Convert to lowercase
    .trim() // Trim leading/trailing whitespace
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/[^\w-]+/g, "") // Remove all non-word characters
    .replace(/--+/g, "-"); // Replace multiple hyphens with a single hyphen

  return slug;
};


export function extractTitle(fullString) {
  const titleMatch = fullString.match(/'([^']*)'/);
  return titleMatch ? titleMatch[1] : null;
}