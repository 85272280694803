import React, { useState, useEffect } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import style from "../../styles/common/DeletePopup.module.css";
import { Const } from "../../utils/Constants";
import { FaArrowLeft } from "react-icons/fa6";
import { IoReload } from "react-icons/io5";
import dayjs from "dayjs";
import DateTimePicker from "../fields/DateTimePicker";

import moment from "moment-timezone";

const DeletePopup = ({
  setIsPublish,
  handleSaveOrUpdate,
  handleSubmit,
  handlePreview,
  errors,
  isBtnLoading,
}) => {
  const [schedule, setSchedule] = useState({
    date: null,
    time: null,
  });
  const [publishDate, setPublishDate] = useState(null);
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const formatDateIST = (dateTimeString) => {
    const utcDate = moment.utc(dateTimeString);

    // Format the UTC date-time
    const formattedDate = utcDate.format("DD-MM-YYYY h:mm A");

    return formattedDate;
  };

  const formatDateTime = (date, time) => {
    if (!date) return "None selected";
    if (!time) return dayjs(date).format("YYYY-MM-DD");
    const formattedDate = dayjs(date).format("DD-MM-YYYY");
    const formattedTime = dayjs(time).format("HH:mm");
    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <div className={style.modal}>
      {/* <Toaster/> */}
      <OutsideClickHandler
        onOutsideClick={() => {
          if(!openDatePicker){
            setIsPublish(null);
          }
        }}
      >
        <div className={style.cardSchedule}>
          <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
            {!openDatePicker && 
            <div>
              <div className={style.cardBody} style={{ alignItems: "center" }}>
                <div className={style.contentSec}>
                  <h4>Ready to Publish</h4>
                  <p className={style.subheading}>
                    Choose to publish now or schedule for later.
                  </p>
                  <div className="domain-with-preview">
                    <div className="domain-view">
                      <FaArrowLeft className="domain-svg" />
                      <IoReload className="domain-svg" />
                      <div className="domain-text">
                        https://hollywoodreporterindia.com/
                      </div>
                    </div>
                    <button
                      className="btn-primary-outline"
                      onClick={(e) => {
                        handlePreview(e);
                      }}
                    >
                      Preview
                    </button>
                  </div>
                </div>
              </div>
              <div>
                {errors && errors.category && (
                  <p className="error" style={{ marginLeft: "20px" }}>
                    {errors.category.message}
                  </p>
                )}
              </div>
            </div>
            }
              {openDatePicker && 
            <DateTimePicker
              schedule={schedule}
              setSchedule={setSchedule}
              setOpenDatePicker={setOpenDatePicker}
            />
              }
          </div>

          <div className={style.footer}>
            <button className="btn-primary-outline" onClick={() => {setOpenDatePicker(!openDatePicker)}}>
              {openDatePicker ? "Back":"Schedule for later"}
            </button>
            {isBtnLoading ? (
              <button className="publish flex-all t3">Loading...</button>
            ) : (
              <button
                className="btn-primary"
                onClick={handleSubmit(() => handleSaveOrUpdate(Const.Active))}
              >
                Publish Now
              </button>
            )}
          </div>
        </div>
        {/* <DateTimePopup schedule={schedule} setSchedule={setSchedule} setOpenDatePicker={setOpenDatePicker} handleCancle={handleCancle} /> */}
      </OutsideClickHandler>
    </div>
  );
};

export default DeletePopup;
