import React, { useState } from "react";
import { GoPlus } from "react-icons/go";
import "../../../styles/stories/settings.css";
import { IoInformationCircleOutline } from "react-icons/io5";
import { MdDone } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import Tooltip from "@mui/material/Tooltip";
import { createCategory } from "../../../api/Category";
import { IoFlagOutline, IoFlagSharp } from "react-icons/io5";
import { IoIosStarOutline, IoMdStar } from "react-icons/io";
import { checkHighlightedArticle } from "../../../api/Stories";
import FlagStoryPopUp from "../FlagStoryPopUp"

const Categories = ({
  data,
  setCategory,
  highlighted,
  setHighlighted,
  register,
  errors,
  selectedSubCategory,
  setSelectedSubCategory,
}) => {
  const maxLimit = 35;
  const [categoryTitle, setCategoryTitle] = useState("");
  const [createCat, setCreateCat] = useState(false);
  const [open, setOpen] = useState(null)
  const [textLimit, setTextLimit] = useState(maxLimit);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const handleChecked = (e, id) => {
    const { checked, value } = e.target;
    if (checked) {
      setSelectedSubCategory(value);
    } else {
      setSelectedSubCategory(null);
      setHighlighted(null);
    }
  };

  const handleText = (e) => {
    const { value } = e.target;
    const remainingChars = maxLimit - value.length;

    if (value.length <= maxLimit) {
      setCategoryTitle(value);
      setTextLimit(remainingChars);
    }
  };

  const handleSave = async () => {
    const formData = new FormData();
    const payload = {
      name: categoryTitle,
    };

    formData.append("data", JSON.stringify(payload));

    try {
      const res = await createCategory(formData);
      if (res && res.data) {
        setCategory((prev) => [...prev, res.data]);
      }
    } catch (error) {
      console.error("Error while saving writer", error);
    } finally {
      setIsBtnLoading(false);
    }
  };

  const handleHighlighted = async(id) => {
    if(highlighted==id){
      setHighlighted(null);
      return;
    }
    let body = {
      subcategoryId:id
    }
    let data = await checkHighlightedArticle(body);
    console.log(data,"data from back")
    if(data?.data?.isHighlighted ===true){
      // not exist
      setHighlighted(id);
      setSelectedSubCategory(id);
    }
    else{
      setOpen(id)
    }

  };

  return (
    <>
    <div className="general-main-div flex-cat">
      <div>
        <p className="categories-p">
          Assign story to one or more subcategories for readers to find them.
        </p>

        <div className="assign-cat">
          <div>
            {/* <div className="special-listcat">
              <label className="special-head-cat">Home-page categories :</label>
              {data.length > 0 &&
                data.map((item) => {
                  if (item.isLocked) {
                    return (
                      <>
                        {item.subcategory.length > 0 &&
                          item.subcategory.map((cat, i) => {
                            return (
                              <div
                                className="cat-list flex-cat-div"
                                key={`cat-${i}`}
                              >
                                <div
                                  className="flex-checkbox-p"
                                  style={{ margin: "0" }}
                                >
                                  <input
                                    {...register("category")}
                                    type="checkbox"
                                    className="checkbox-cat"
                                    name="category"
                                    value={cat._id}
                                    checked={selectedSubCategory.includes(
                                      cat._id
                                    )}
                                    onChange={(e) => handleChecked(e, cat._id)}
                                  />
                                  <p className="categories-p-check">
                                    {cat.name}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                      </>
                    );
                  }
                })}
              {errors && errors.category && (
                <div className="error-div">
                  <div className="error">{errors.category.message}</div>
                </div>
              )}
            </div> */}
            {data.length > 0 &&
              data.map((cat, i) => {
                const isSubcategoryPresent =
                  cat.subcategory && cat.subcategory.length > 0;

                return (
                  <div className="cat-list" key={`cat-${i}`}>
                    {isSubcategoryPresent && (
                      <>
                        <label className="cat-title">{cat.name}</label>
                        {cat.subcategory.map((subcat, subcatIndex) => (
                          <div
                            className="flex-cat-div"
                            key={`subcat-${subcatIndex}`}
                          >
                            <div className="flex-checkbox-p">
                              <input
                                {...register("category")}
                                type="checkbox"
                                className="checkbox-cat"
                                name="category"
                                value={subcat._id}
                                checked={selectedSubCategory === subcat._id}
                                onChange={(e) => handleChecked(e, subcat._id)}
                              />
                              <p className="categories-p-check">
                                {subcat.name}
                              </p>
                            </div>
                            <Tooltip title="Flag this story" placement="top">
                              {highlighted==subcat._id ? (
                                <IoMdStar
                                  className="flagsvg" style={{visibility:'visible'}}
                                  onClick={() => handleHighlighted(subcat._id)}
                                />
                              ) : (
                                <IoIosStarOutline
                                  className="flagsvg"
                                  onClick={() => handleHighlighted(subcat._id)}
                                />
                              )}
                            </Tooltip>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                );
              })}

            {errors && errors.category && (
              <div className="error-div">
                <div className="error">{errors.category.message}</div>
              </div>
            )}
          </div>
        </div>

        {createCat && (
          <>
            <div className="featured-img-top cat-top">
              <div className="feature-logo">
                <p className="general-p-small">Category name</p>
              </div>
              <div className="basic-flex">
                <p className="basic-p-small">{textLimit}</p>
              </div>
            </div>
            <div className="flex-cat-input">
              <div className="publish-date-div cat-add-cat">
                <input
                  className="general-input"
                  name="category"
                  value={categoryTitle}
                  onChange={handleText}
                />
              </div>
              <div className="right-cat-input">
                <button
                  className="cat-btns"
                  onClick={() => {
                    setCategoryTitle("");
                    setTextLimit(maxLimit);
                    setCreateCat(false);
                  }}
                >
                  <RxCross2 />
                </button>
                <button
                  className={
                    categoryTitle ? "cat-btns2 enablebtn" : "cat-btns2"
                  }
                  disabled={!categoryTitle}
                  onClick={() => {
                    setIsBtnLoading(true);
                    handleSave();
                  }}
                >
                  <MdDone />
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
    {open &&
    <FlagStoryPopUp setHighlighted={setHighlighted} setSelectedSubCategory={setSelectedSubCategory} open={open} setOpen={setOpen}/>
    }
    </>
  );
};

export default Categories;
