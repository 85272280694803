import Headers from './Headers';
import { ProcessAPI, Const } from '../utils/Constants';

// Get List
export const getListFlag = async (body) => {
    const res = await fetch(Const.Link + "api/page/list", new Headers("POST", body));
    return ProcessAPI(res);
}
//bulk-update
export const bulkUpdate = async (body) => {
    const res = await fetch(Const.Link + "api/article/bulk-update", new Headers("PATCH", body));
    return ProcessAPI(res);
}

// Get Flag List Articles
export const getArticleFlagList = async (body) => {
    const res = await fetch(Const.Link + "api/page/get-flags", new Headers("GET"));
    return ProcessAPI(res);
}