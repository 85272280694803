import React from "react";
import { IoIosArrowForward } from "react-icons/io";

const Advanced = () => {
  return (
    <div>
      <div className="adv-box">
        <div className="adv-left">
          <p className="adv-p">Structured data markup</p>
          <p className="general-p-small">
            Make your posts eligible for rich results in search engines.
          </p>
        </div>
        <div className="adv-arrow-div">
          <IoIosArrowForward className="adv-arrow" />
        </div>
      </div>

      <div className="adv-box">
        <div className="adv-left">
          <p className="adv-p">Robots meta tag</p>
          <p className="general-p-small">
            Select the relevant instructions, so bots know what info to display
            after crawling this page.
          </p>
        </div>
        <div className="adv-arrow-div">
          <IoIosArrowForward className="adv-arrow" />
        </div>
      </div>

      <div className="adv-box">
        <div className="adv-left">
          <p className="adv-p">Additional tags</p>
          <p className="general-p-small">
            Help search engines get important info about this page.
          </p>
        </div>
        <div className="adv-arrow-div">
          <IoIosArrowForward className="adv-arrow" />
        </div>
      </div>
    </div>
  );
};

export default Advanced;
