import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const StyledTextField = styled(TextField)(({ theme }) => ({
  padding: "0 !important",
  "& .MuiOutlinedInput-root": {
    padding: "0 !important",
  },
  "& .MuiOutlinedInput-input": {
    padding: "0px",
  },
}));

export default function Tags({ selectedOptions, setSelectedOptions }) {
  const handleOnChange = (event, value) => {
    setSelectedOptions(value);
  };

  return (
    <div>
      <Autocomplete
        multiple
        freeSolo
        id="tags-outlined"
        options={selectedOptions}
        value={selectedOptions}
        onChange={handleOnChange}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            variant="outlined"
            placeholder="Add a tag"
          />
        )}
      />
    </div>
  );
}
