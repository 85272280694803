import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import MultipleSelectDropdown from "../../fields/MultipleSelectDropdown";
import DropDown from "../../fields/DropDown";
import ChipDropdown from "../../fields/ChipDropdown";

const ProductInfo = ({
  input,
  name,
  info,
  handleInputChange,
  handleQuillChange,
  errorForms,
  writer,
  status,
  contributor,
  setContributor,
}) => {
  return (
    <div className="aps-div">
      <div className="aps-title">Video Info</div>
      <div className="aps-content-div">
        <div className="aps-inp-cont">
          <div className="aps-inp-div1" style={{ width: "100%" }}>
            <label className="aps-label">Story Title*</label>
            {errorForms.name && (
              <label className="aps-label error_label">(Required)</label>
            )}
            <input
              placeholder="Add a story title"
              id="#aps1"
              value={input?.title ?? ""}
              className={errorForms.title ? "error-field" : ""}
              name="title"
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="video-story-field">
          <label className="aps-label">Description</label>
        </div>
        <ReactQuill
          theme="snow"
          value={info || ""}
          style={{margin:'10px 0'}}
          onChange={(value) => handleQuillChange(value, "excerpt")}
        />
        <div className="aps-inp-div1">
          <label className="aps-label">Status</label>
          <DropDown
            data={[
              { status: "Published", code: 1 },
              { status: "Unpublished", code: 0 },
              { status: "Draft", code: 3 },
              { status: "Schedule", code: 4 },
            ]}
            name={"status"}
            selected={input?.status ?? ""}
            handleOnChange={handleInputChange}
          />
        </div>
        <div className="video-story-field">
          <label className="aps-label">Author</label>
        </div>
        <MultipleSelectDropdown
          data={writer}
          name={"writer"}
          placeholder="Select Author"
          selected={input?.writer ?? []}
          handleOnChange={handleInputChange}
        />
        <div className="video-story-field">
          <label className="aps-label">Contributor</label>
        </div>
        <ChipDropdown
          placeholder="Contributors are temporary authors for a limited number of stories, without Author’s profile page"
          selectedOptions={contributor}
          setSelectedOptions={setContributor}
        />
      </div>
    </div>
  );
};

export default ProductInfo;
