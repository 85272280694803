import React from "react";
import { Routes, Route } from "react-router-dom";
import ConstructPage from "../components/common/Construct";
// import Inbox from "../pages/customers/Inbox";

const InboxRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<ConstructPage />} />
    </Routes>
  );
};

export default InboxRoute;
