import React from "react";
import { Routes, Route } from "react-router-dom";
import ConstructPage from "../components/common/Construct";
import Stories from "../pages/stories/Stories";
import TrashStories from "../pages/stories/TrashStory";
import VideoTrashStories from "../pages/stories/VideoTrashStory"
import VideoStories from "../pages/stories/VideoStories"
import VideoShorts from "../pages/stories/VideoShorts"
import AddVideo from "../pages/stories/AddVideo"
import AddEditStories from "../pages/stories/AddEditStories";

const StoriesRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<Stories />} />
      <Route path="/video-stories" element={<VideoStories />} />
      <Route path="/shorts" element={<VideoShorts />} />
      <Route path="/video-stories/add-story" element={<AddVideo />} />
      <Route path="/video-stories/edit-story/*" element={<AddVideo />} />
      <Route path="/trash-stories" element={<TrashStories />} />
      <Route path="/video-trash-stories" element={<VideoTrashStories />} />
      <Route path="/*" element={<ConstructPage />} />
    </Routes>
  );
};

export default StoriesRoute;
