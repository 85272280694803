import React from "react";

const TextHighlight = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 16"
      color={color ?? "#fff"}
    >
      <path
        width="11"
        height="10"
        fill="#000"
        id="Icon"
        d="M10.616 3.366L6.5 7.482 4.207 5.189l4.116-4.116c.098-.098.256-.098.354 0l1.94 1.939c.097.098.097.256 0 .354zM5.293 8.689h-1.94L3 8.336v-1.94l.5-.5 2.293 2.293-.5.5zm6.03-6.384L9.384.366c-.487-.488-1.282-.488-1.768 0l-5.47 5.47c-.093.093-.146.22-.146.353v2.147L.823 9.512c-.06.062-.087.15-.066.235.02.084.082.152.164.18l1.5.5c.026.008.052.012.08.012.064 0 .128-.025.176-.073l.676-.677H5.5c.133 0 .26-.052.353-.146l5.47-5.47c.487-.487.487-1.28 0-1.768z"
        transform="translate(-210.000000, -80.000000) translate(206.000000, 77.000000) translate(4.000000, 3.310800)"
      ></path>
      <path
        width="12"
        height="3"
        fill="currentColor"
        id="Fill"
        fill-rule="nonzero"
        d="M0 15.689L12 15.689 12 12.689 0 12.689z"
      ></path>
    </svg>
  );
};

export default TextHighlight;
