import React, { useCallback, useRef } from "react";
import { BubbleMenu as BaseBubbleMenu, useEditorState } from "@tiptap/react";
import Edit from "../../../../assets/svg/Edit";
import Trash from "../../../../assets/svg/Trash";

export const LinkMenu = ({ editor, appendTo, showLink, setShowLink }) => {
  const menuRef = useRef(null);

  const { link, target, rel } = useEditorState({
    editor,
    selector: (ctx) => {
      const attrs = ctx.editor.getAttributes("link");
      return { link: attrs.href, target: attrs.target, rel: attrs.rel };
    },
  });

  const shouldShow = useCallback(() => {
    const isActive = editor.isActive("link");
    return isActive;
  }, [editor]);

  const handleEdit = useCallback(() => {
    setShowLink(true);
  }, [setShowLink]);

  const onUnsetLink = useCallback(() => {
    editor.chain().focus().extendMarkRange("link").unsetLink().run();
    setShowLink(false);
    return null;
  }, [editor, setShowLink]);

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey="linkMenu"
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        interactive: true,
        offset: [0, 8],
        maxWidth: "100%",
        popperOptions: {
          modifiers: [{ name: "flip", enabled: false }],
        },
        appendTo: () => {
          return appendTo?.current;
        },
        onHidden: () => {
          setShowLink(false);
        },
      }}
    >
      <div className="bubble-menu" ref={menuRef}>
        <a
          href={link}
          target={target ? target : "_blank"}
          rel={rel}
          className="link-preview"
        >
          {link}
        </a>
        <span className="bubble-menu-divider"></span>
        <button className="bubble-menu-icon" onClick={() => handleEdit()}>
          <Edit />
        </button>
        <span className="bubble-menu-divider"></span>
        <button className="bubble-menu-icon" onClick={() => onUnsetLink()}>
          <Trash />
        </button>
      </div>
    </BaseBubbleMenu>
  );
};

export default LinkMenu;
