import React,{useEffect} from "react";
import { RxCross2 } from "react-icons/rx";

const DropChips = ({ contact, removeContact,setOpenRecipient,openRecipient }) => {
  const handle = (el)=>{
    if(!openRecipient){
      setOpenRecipient(false)
    }
    removeContact(el)
  }
  return (
    <div className="reci-div-main">
      <div className="reci-div" style={{cursor:'initial'}}>
      <div className="chips">
        {contact &&
          contact.map((el, i) => (
            <div className="chip" key={i}>
              <div>{el}</div>
              <div className="chip-cross" onClick={()=>{handle(el)}}>
                <RxCross2 />
              </div>
            </div>
          ))}
      </div>
      </div>

    </div>
  );
};

export default DropChips;
