import React, { useState, useEffect } from "react";
import { HexColorPicker } from "react-colorful";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import { TfiPlus } from "react-icons/tfi";

const HighlightColorPicker = ({
  editor,
  open,
  type,
  setClose,
  selected,
  setSelected,
}) => {
  const [colorChange, setColorChange] = useState(null);
  const [textColor, setTextColor] = useState(["#000", "#ff0101"]);
  const [openSelectPicker, setOpenSelectPicker] = useState(null);

  useEffect(() => {
    setColorChange(selected);
  }, []);

  const handleOpenSelectPicker = (event) => {
    setOpenSelectPicker(event.currentTarget);
  };

  const handleCloseSelectPicker = (event) => {
    setOpenSelectPicker(null);
  };

  const handleSelectedColor = (e, color) => {
    e.preventDefault();
    editor.chain().focus().toggleHighlight({ color: color }).run();
    setSelected(color);
    setClose(null);
  };

  const handleResetColor = (e, color) => {
    e.preventDefault();
    editor.chain().focus().unsetHighlight().run();
    setSelected(color);
    setClose(null);
  };

  const handleSaveColor = (e) => {
    e.preventDefault();
    editor.chain().focus().toggleHighlight({ color: colorChange }).run();
    setSelected(colorChange);
    setTextColor((prev) => [...prev, colorChange]);
    handleCloseSelectPicker();
  };

  return (
    <>
      <Menu
        anchorEl={open}
        open={Boolean(open)}
        onClose={() => setClose(null)}
        anchorOrigin={{
          horizontal: "center",
        }}
        transformOrigin={{
          horizontal: "center",
        }}
        sx={{
          "& .MuiPaper-root": {
            boxShadow:
              "0 8px 8px 0 rgba(22, 45, 61, 0.12), 0 3px 24px 0 rgba(22, 45, 61, 0.18)",
            width: "200px",
            top: "55px!important",
          },
        }}
      >
        <div className="colot-btn-container">
          {textColor.map((color, i) => {
            return (
              <>
                <button
                  className={`color-btn-box ${
                    selected === color ? "selected" : ""
                  }`}
                  key={`color-${i}`}
                  onClick={(e) => handleSelectedColor(e, color)}
                  style={{ backgroundColor: color }}
                ></button>
              </>
            );
          })}
        </div>
        <Divider />
        <div className="color-ft-container">
          <button
            className="color-reset-btn"
            onClick={(e) => {
              type === "hiliteColor"
                ? handleResetColor(e, "#fff")
                : handleResetColor(e, "#000");
            }}
          >
            Reset to default
          </button>
          <TfiPlus
            className="color-add-btn"
            onClick={(e) => {
              e.preventDefault();
              setClose(null);
              handleOpenSelectPicker(e);
            }}
          />
        </div>
      </Menu>
      <Menu
        anchorEl={openSelectPicker}
        open={Boolean(openSelectPicker)}
        onClose={handleCloseSelectPicker}
        anchorOrigin={{
          horizontal: "center",
        }}
        transformOrigin={{
          horizontal: "center",
        }}
        sx={{
          "& .MuiPaper-root": {
            boxShadow:
              "0 8px 8px 0 rgba(22, 45, 61, 0.12), 0 3px 24px 0 rgba(22, 45, 61, 0.18)",
            width: "200px",
            top: "55px!important",
            left: "398px!important",
          },
        }}
      >
        <div className="colot-picker-container">
          <HexColorPicker color={colorChange} onChange={setColorChange} />
        </div>
        <div className="color-input-box">
          <label className="input-label">Hex</label>
          <input
            type="text"
            className="hex-input"
            placeholder="Color Selected"
            value={colorChange}
            onChange={(e) => setColorChange(e.target.value)}
          />
          <span
            className="color-span"
            style={{ backgroundColor: colorChange }}
          ></span>
        </div>
        <Divider />
        <div className="color-ft-container" style={{ gap: "10px" }}>
          <button
            className="btn btn-primary-outline"
            style={{ width: "50%" }}
            onClick={() => {
              setColorChange(selected);
              handleCloseSelectPicker();
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            style={{ width: "50%" }}
            onClick={handleSaveColor}
          >
            Save
          </button>
        </div>
      </Menu>
    </>
  );
};

export default HighlightColorPicker;
