import React from "react";
import { IoInformationCircleOutline } from "react-icons/io5";
import Toggle from "../../fields/Toggle";
import ChipDrop from "../seo/ChipDrop";

const Basics = ({
  meta,
  slug,
  isCanonical,
  setIsCanonical,
  selectedKeywords,
  setSelectedKeywords,
  handleMetaData,
}) => {
  return (
    <div className="general-main-div">
      <div className="basic-preview-main">
        <p className="general-p-small">Preview on Google</p>
        <p className="general-p-small color-primary">
          When will changes show live ?
        </p>
        <div className="basic-preview-div">
          <div className="company-logo">R</div>
          <div>
            <p className="company-name">RMS</p>
            <p className="company-url">{`https://www.rms.com > prefix`}</p>
          </div>
        </div>
      </div>

      <div className="publish-date-div">
        <div className="featured-img-top">
          <div className="feature-logo">
            <p className="general-p-small">Title tag</p>
            <IoInformationCircleOutline className="general-info-logo" />
          </div>
          <div className="basic-flex">
            <p className="basic-p-small">Create AI text</p>
            <div className="verti-line"></div>
            <p className="basic-p-small">{meta?.title?.length ?? 0}</p>
          </div>
        </div>
        <input
          className="general-input"
          placeholder="Search engines may show a different title"
          name="title"
          value={meta?.title ?? ""}
          onChange={handleMetaData}
        ></input>
      </div>
      <div className="publish-date-div">
        <div className="featured-img-top">
          <div className="feature-logo">
            <p className="general-p-small">Meta description</p>
            <IoInformationCircleOutline className="general-info-logo" />
          </div>
          <div className="basic-flex">
            <p className="basic-p-small">Create AI text</p>
            <div className="verti-line"></div>
            <p className="basic-p-small">{meta?.description?.length ?? 0}</p>
          </div>
        </div>
        <textarea
          className="general-input"
          placeholder="Search engines may show a different description"
          name="description"
          value={meta?.description ?? ""}
          onChange={handleMetaData}
        />
      </div>
      <div className="publish-date-div">
        <div className="featured-img-top">
          <div className="feature-logo">
            <p className="general-p-small">Keyword</p>
            <IoInformationCircleOutline className="general-info-logo" />
          </div>
          <div className="basic-flex">
            <p className="basic-p-small">Create AI text</p>
            <div className="verti-line"></div>
            <p className="basic-p-small">{selectedKeywords?.length ?? 0}</p>
          </div>
        </div>
        <ChipDrop
          selectedOptions={selectedKeywords}
          setSelectedOptions={setSelectedKeywords}
        />
      </div>

      <div className="publish-date-div">
        <div className="featured-img-top">
          <div className="feature-logo">
            <p className="general-p-small">URL slug</p>
            <IoInformationCircleOutline className="general-info-logo" />
          </div>
          <div className="basic-flex">
            <p className="basic-p-small">{slug?.length ?? 0}</p>
          </div>
        </div>
        <div className="slug-wrapper">
          <span className="slash">/</span>
          <input
            className="general-input slug-input"
            placeholder="Add the last part of the URL"
            onChange={handleMetaData}
            value={slug ?? ""}
            name="slug"
          />
        </div>
        <p className="general-p-small color-primary mb-2">Go to URL</p>
      </div>

      <div className="basic-bottom">
        <label className="aps-switch aps-switch-small">
          <input
            type="checkbox"
            checked={isCanonical}
            onChange={(e) => {
              const newIsCanonical = e.target.checked;
              setIsCanonical(newIsCanonical);
              if (!newIsCanonical) {
                handleMetaData({
                  target: { name: "canonical", value: undefined },
                });
              }
            }}
          />

          <span className="aps-slider aps-slider-small aps-round"></span>
        </label>
        <p className="general-p-small">Let enable the canonical link</p>
      </div>
      {isCanonical && (
        <div className="publish-date-div">
          <div className="featured-img-top">
            <div className="feature-logo">
              <p className="general-p-small">Canonical Link</p>
              <IoInformationCircleOutline className="general-info-logo" />
            </div>
            <div className="basic-flex">
              <p className="basic-p-small">0</p>
            </div>
          </div>
          <div className="slug-wrapper">
            <input
              type="text"
              className="general-input"
              placeholder="Eg. https://example.com/sample"
              name="canonical"
              value={meta?.canonical ?? ""}
              onChange={handleMetaData}
            />
          </div>
        </div>
      )}

      <div className="basic-bottom">
        <Toggle
          value={
            meta?.robots === "index,follow"
              ? true
              : meta?.robots === "noindex,nofollow"
              ? false
              : true
          }
          handleInputChange={handleMetaData}
        />
        <p className="general-p-small">Let search engines index this page</p>
      </div>
    </div>
  );
};

export default Basics;
