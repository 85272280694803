import React, { useEffect, useState, useMemo, useCallback } from "react";
import { IoCloseOutline } from "react-icons/io5";
import Popover from "@mui/material/Popover";

const LinkEditor = ({
  editor,
  initialUrl = "",
  initialOpen = false,
  initialNoReferrer = false,
  initialNoFollow = false,
  initialSponsored = false,
  linkMenuId,
  open,
  showLink,
  setShowLink,
}) => {
  const [url, setUrl] = useState("");
  const [openInNewTab, setOpenInNewTab] = useState(true);
  const [noreferrer, setNoReferrer] = useState(true);
  const [nofollow, setNoFollow] = useState(false);
  const [sponsored, setSponsored] = useState(false);

  const isValidUrl = useMemo(() => /^(\S+):(\/\/)?\S+$/.test(url), [url]);
  useEffect(() => {
    setUrl(initialUrl);
    setOpenInNewTab(initialOpen);
    setNoReferrer(initialNoReferrer);
    setNoFollow(initialNoFollow);
    setSponsored(initialSponsored);
  }, [
    initialUrl,
    initialOpen,
    initialNoReferrer,
    initialNoFollow,
    initialSponsored,
  ]);

  const onSetLink = useCallback(
    (href, target, noreferrer, nofollow, sponsored) => {
      let rel = [];
      if (noreferrer) rel.push("noreferrer");
      if (nofollow) rel.push("nofollow");
      if (sponsored) rel.push("sponsored");

      editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({
          href: href,
          target: target ? "_blank" : "",
          rel: rel.length > 0 ? rel.join(", ") : "",
        })
        .run();
      setShowLink(null);
    },
    [editor, setShowLink]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (isValidUrl) {
        onSetLink(url, openInNewTab, noreferrer, nofollow, sponsored);
        setUrl("");
        setOpenInNewTab(true);
        setNoReferrer(true);
        setNoFollow(false);
        setSponsored(false);
      }
    },
    [url, isValidUrl, openInNewTab, noreferrer, nofollow, sponsored, onSetLink]
  );

  return (
    <Popover
      id={linkMenuId}
      open={open}
      anchorEl={showLink}
      onClose={() => setShowLink(null)}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      PaperProps={{
        style: {
          width: "395px",
          height: "100%",
          maxHeight: "345px",
          marginTop: "50px",
        },
      }}
    >
      <div className="link-menu">
        <div className="link-menu-head">
          <div className="link-menu-title">What do you want to link to?</div>
          <button
            className="btn-menulink-close"
            onClick={() => setShowLink(null)}
          >
            <IoCloseOutline />
          </button>
        </div>
        <div className="link-menu-main">
          <div className="input-list">
            <label>Link</label>
            <input
              type="text"
              placeholder="Enter or paste a link"
              value={url ?? ""}
              onChange={(e) => setUrl(e.target.value)}
            />
          </div>
          <div className="input-list">
            <label>
              <input
                type="checkbox"
                name="type"
                value="_blank"
                checked={openInNewTab ?? true}
                onChange={(e) => setOpenInNewTab(e.target.checked)}
              />
              Link opens in a new tab
            </label>
            <label>
              <input
                type="checkbox"
                name="type"
                value="_blank"
                checked={noreferrer ?? true}
                onChange={(e) => setNoReferrer(e.target.checked)}
              />
              noreferrer (recommended)
            </label>
            <label>
              <input
                type="checkbox"
                name="type"
                value="_blank"
                checked={nofollow ?? false}
                onChange={(e) => setNoFollow(e.target.checked)}
              />
              nofollow
            </label>
            <label>
              <input
                type="checkbox"
                name="type"
                value="_blank"
                checked={sponsored ?? false}
                onChange={(e) => setSponsored(e.target.checked)}
              />
              sponsored
            </label>
          </div>
        </div>
        <div className="link-menu-footer">
          <button
            className="btn-sm btn-primary-outline"
            onClick={() => setShowLink(null)}
          >
            Cancel
          </button>
          <button
            className="btn-sm btn-primary"
            disabled={!isValidUrl}
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </div>
    </Popover>
  );
};

export default LinkEditor;
