import React, { useState, useRef } from 'react'
import { FaCheck } from "react-icons/fa6";
import { MdClose } from "react-icons/md";
import 'react-tooltip/dist/react-tooltip.css'
import OutsideClickHandler from 'react-outside-click-handler';
import '../../../styles/admin/addProduct/variant.css'
import { TagsInput } from "react-tag-input-component";
import { CiCircleInfo } from "react-icons/ci";
import toast, { Toaster } from "react-hot-toast";
import { Tooltip } from 'react-tooltip'


const Popup = ({ deleteProduct,setDeleteProduct,setProducts,products }) => {
    const del = async () => {
        console.log(deleteProduct, "op")
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/products/deleteone/${deleteProduct._id}`, {
                method: 'DELETE',
            });

            if (!response.ok) {
                throw new Error('Error deleting item');
            }

            const deletedProduct = await response.json();
            console.log(deletedProduct);

            toast.success("Successfully Deleted")
            let newArr = products.filter(obj => obj._id !== deletedProduct._id);
            console.log(newArr,"new array")
            setProducts(newArr)
            setDeleteProduct(null);
            return;
        } catch (error) {
            console.error("Error deleting item:", error);
            throw error;
        }
    };

    return (
        <div className='variant-bg flex-all' style={{ backgroundColor: '#000000ba' }}>
            {/* <Toaster/> */}
            <OutsideClickHandler
                onOutsideClick={() => {
                    setDeleteProduct(null);
                }}
            >
                <div className="variant2" style={{ overflowY: 'hidden' }}>
                    <h4>Delete Product</h4>
                    <div className=" mt10 w100 pop-div">
                        <img src={deleteProduct.images[0]} className='img-pop'></img>
                        <div style={{ width: '100%' }}>
                            <p className='pop-p'>Name : {deleteProduct.name}</p>
                            <p className='pop-p'>Rs. {deleteProduct.priceperunit}</p>
                        </div>
                    </div>
                    <div className="var-btns2">
                        <div className="var-btn1 t3" onClick={() => {  setDeleteProduct(null); }}>Cancel</div>

                        <div className="var-btn2 t3" onClick={del} >Delete pro</div>
                    </div>
                </div>
            </OutsideClickHandler>
        </div>
    )
}

export default Popup