import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import { useState, useEffect } from "react";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import toast, { Toaster } from "react-hot-toast";
import { styled } from "@mui/material/styles";
import LineChart from "./LineChart";
import BarChart from "./BarChart";
import TableAllReport from "./TableAllReport";

const AntTabs = styled(Tabs)({
  borderBottom:"1px solid rgb(220,220,220)",
  "& .MuiTabs-indicator": {
    backgroundColor: "#1890ff",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    padding:'21px',
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 4 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(() => {
    // Get the stored tab index from localStorage, defaulting to 0 if not present
    return parseInt(localStorage.getItem("selectedTab"), 10) || 0;
  });

  const [load, setLoad] = useState(false);
  const [dis, Setdis] = useState(false)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Toaster />
      <div className="div-tab">
        <Box sx={{ width: "100%" }}>
          <AntTabs
            value={value}
            onChange={handleChange}
            aria-label="ant example"
            sx={{ borderColor: "rgba(0,0,0,0.2)" }}
          >
            <AntTab label="Post views : 0" />
            <AntTab label="Visitors : 0" />
            <AntTab label="New subscribers : 182" />
            <AntTab label="Engagement : 0" />
          </AntTabs>
        </Box>
      </div>
    </>
  );
}
