import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Const } from "../utils/Constants";

export default function AdminProtectedRoute({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [protectedRoute, setProtectedRoute] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem(Const.Token);
    if (token) {
      setProtectedRoute(true);
      if (location.pathname === "/") {
        navigate("/home");
      }
    } else {
      navigate("/signin");
    }
  }, [navigate, location]);

  return <>{protectedRoute && children}</>;
}
