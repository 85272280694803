import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FiChevronDown } from "react-icons/fi";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoIosArrowDown } from "react-icons/io";
import ListItemIcon from "@mui/material/ListItemIcon";
import Tooltip from "@mui/material/Tooltip";
import { GoPlus } from "react-icons/go";
import { GrFormEdit } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";
import { CiExport } from "react-icons/ci";
import { FaRegTrashCan } from "react-icons/fa6";
import { VscEdit } from "react-icons/vsc";
import { FiEye } from "react-icons/fi";
import { CiImport } from "react-icons/ci";
import { IoLogOutOutline } from "react-icons/io5";
import { BsPeople } from "react-icons/bs";

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [fullName, setFullName] = useState("");
  const [session, setSession] = useState({});
  const open = Boolean(anchorEl);

  useEffect(() => {
    const getSession = localStorage.getItem("Session")
      ? JSON.parse(localStorage.getItem("Session"))
      : {};
    if (getSession) {
      const getFullName = `${getSession.firstname} ${getSession.lastname}`;
      setSession(getSession);
      setFullName(getFullName);
    }
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const navigate = useNavigate();
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center", width: "100%" }}>
        <Tooltip>
          <div
            className="name-user"
            onClick={handleClick}
            aria-controls={open ? "account-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
          >
            <div className="name-logo">{session?.firstname?.charAt(0)}</div>
            <div className="name-main">
              {`${fullName.substring(0, 10)}...`} <MdKeyboardArrowDown />
            </div>
          </div>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            mt: 0.5,
            border: "1px solid rgba(0,0,0,0.2)",
            width: "230px",
            "& .MuiAvatar-root": {
              width: 40,
              border: "1px solid rgba(0,0,0,0.2)",
              height: 40,
              ml: -0.5,
              mr: 1,
              bgcolor: "#f2f9ff",
            },
            "&::hover": {
              bgcolor: "#f2f9ff",
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              border: "1px solid rgba(0,0,0,0.2)",
              top: 0,
              left: 7,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: -555,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div className="drop-down-profile">
          <div className="name-logo">
            {session?.firstname?.charAt(0)}
            <div className="editlogo-drop">
              <GrFormEdit className="editsvg" />
            </div>
          </div>
          <div className="name-main-drop">{fullName}</div>
          <div className="email-drop">{session?.email}</div>
        </div>

        <MenuItem>
          <ListItemIcon>
            <GoPlus style={{ fontSize: "18px", color: "black" }} />
          </ListItemIcon>
          Add Account
        </MenuItem>
        <MenuItem className="menuitem" onClick={logout}>
          <ListItemIcon>
            <IoLogOutOutline style={{ fontSize: "18px", color: "black" }} />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
