import React from "react";
import { Routes, Route } from "react-router-dom";
import Settings from "../pages/settings/SettingMain"

const InboxRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<Settings />} />
    </Routes>
  );
};

export default InboxRoute;