import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const MultipleSelectDropdown = ({
  data,
  name,
  selected,
  handleOnChange,
  placeholder = "Select items...",
}) => {
  const renderSelectedValue = (selectedItems) => {
    if (selectedItems.length === 0) return placeholder;

    const selectedData = data.filter((item) =>
      selectedItems.includes(item._id)
    );

    if (selectedItems.length > 2) {
      const firstTwo = selectedData
        .slice(0, 2)
        .map((item) => item.name)
        .join(", ");
      return `${firstTwo} + ${selectedItems.length - 2} more`;
    }

    return selectedData.map((item) => item.name).join(", ");
  };

  return (
    <FormControl
      sx={{ width: "100%" }}
      size="small"
      className="general-dropdown"
    >
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        multiple
        name={name}
        value={selected ?? []}
        onChange={handleOnChange}
        className="general-dropdown2"
        renderValue={(selectedItems) => renderSelectedValue(selectedItems)}
        displayEmpty
      >
        <MenuItem disabled value="">
          <em>{placeholder}</em>
        </MenuItem>
        {data.map((item, i) => (
          <MenuItem
            value={item._id}
            className="menuitem-drop"
            key={`option-${i}`}
          >
            <div className="general-drop-img-name">
              <img
                src={item.imgsrc}
                alt={item.name}
                className="general-drop-img"
              />
              <p>{item.name}</p>
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultipleSelectDropdown;
