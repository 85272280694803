import React from "react";
import { Routes, Route } from "react-router-dom";
import ConstructPage from "../components/common/Construct";
import Comment from "../pages/comment/Comment";

const CommentRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<Comment />} />
      <Route path="/*" element={<ConstructPage />} />
    </Routes>
  );
};

export default CommentRoute;
