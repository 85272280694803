import Headers from '../Headers';
import { ProcessAPI, Const } from '../../utils/Constants';

// Get Product List
export const getProductList = async (body) => {
    const res = await fetch(Const.Link + "api/products/list", new Headers("POST", body));
    return ProcessAPI(res);
}
// Edit Product API
export const editProduct = async (id) => {
    const res = await fetch(Const.Link + "api/products/getOne/"+ id, new Headers("GET"));
    return ProcessAPI(res);
}
// GET Categories API
export const getCategory = async (body) => {
    const res = await fetch(Const.Link + "api/categories", new Headers("GET"));
    return ProcessAPI(res);
}

// Update Existing Product
export const updateSave = async (body) => {
    const res = await fetch(Const.Link + "api/products/editone", { method: "POST", body: body });
    return res.json();
}

// Create New Product
export const insertSave = async (body) => {
    const res = await fetch(Const.Link + "api/products/", { method: "POST", body: body });
    return res.json();
}

// Export Product
export const exportProducts = async (body) => {
    const res = await fetch(Const.Link + "api/products/export", new Headers("POST", body));
    return res;
}

// Update Products Visibility
export const updateVisibility = async (body) => {
    const res = await fetch(Const.Link + "api/products/update-visibility", new Headers("PATCH", body));
    return ProcessAPI(res);
}

// Get Category Products
export const getCategoryProducts = async (body) => {
    const res = await fetch(Const.Link + "api/products/getCategoryProducts", new Headers("POST", body));
    return ProcessAPI(res);
}

// Get Non Category Products
export const getNonCategoryProducts = async (id) => {
    const res = await fetch(Const.Link + "api/products/getNonCategoryProducts/?category=" + id, new Headers("GET"));
    return ProcessAPI(res);
}

// Update Products Categories or Collection Array
export const updateChecked = async (body) => {
    const res = await fetch(Const.Link + "api/updateChecked", new Headers("PUT", body));
    return ProcessAPI(res);
}

// Update Products Categories or Collection Array
export const deleteCollectionProduct = async (body) => {
    const res = await fetch(Const.Link + "api/products/delete-collection-product", new Headers("PATCH", body));
    return ProcessAPI(res);
}

// Delete Product
export const deleteProductOne = async (id) => {
    const res = await fetch(Const.Link + "api/products/deleteone/" + id, new Headers("DELETE"));
    return ProcessAPI(res);
}

// Create Duplicate Product
export const createDuplicate = async (body) => {
    const res = await fetch(Const.Link + "api/products/create-duplicate", new Headers("POST", body));
    return ProcessAPI(res);
}

// Create Duplicate Product
export const updatePrice = async (body) => {
    const res = await fetch(Const.Link + "api/products/update-price", new Headers("PATCH", body));
    return ProcessAPI(res);
}

// Create Duplicate Product
export const createBulkDelete = async (body) => {
    const res = await fetch(Const.Link + "api/products/bulk-delete", new Headers("POST", body));
    return ProcessAPI(res);
}

// GET Ribbons
export const getRibbon = async (body) => {
    const res = await fetch(Const.Link + "api/products/get-ribbon", new Headers("GET"));
    return ProcessAPI(res);
}

// Create Duplicate Product
export const updateRibbon = async (body) => {
    const res = await fetch(Const.Link + "api/products/update-ribbon", new Headers("PATCH", body));
    return ProcessAPI(res);
}