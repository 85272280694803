import React, { useState } from "react";
import "../../../styles/stories/settings.css";

const Flags = ({ data, selectedFlags, setSelectedFlags }) => {
  const handleChecked = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setSelectedFlags((prev) => [...prev, value]);
    } else {
      const filterFlags = selectedFlags.filter((id) => id !== value);
      setSelectedFlags(filterFlags);
    }
  };

  return (
    <div className="general-main-div flex-cat">
      <div>
        <p className="categories-p">
          Assign story to one or more flags for readers to find them.
        </p>

        <div className="assign-cat">
          <div>
            {data.length > 0 &&
              data.map((item, i) => {
                const hasStoriesTypeZero =
                  item.section &&
                  item.section.some((sec) => sec.storiesType === 0);

                return (
                  <div className="cat-list" key={`cat-${i}`}>
                    {hasStoriesTypeZero && (
                      <>
                        <label className="cat-title">{item.name}</label>
                        {item.section.map((sec, secIndex) => (
                          <>
                            {sec.storiesType === 0 && (
                              <div
                                className="flex-cat-div"
                                key={`section-${secIndex}`}
                              >
                                <div className="flex-checkbox-p">
                                  <input
                                    type="checkbox"
                                    className="checkbox-cat"
                                    name="section"
                                    value={sec._id}
                                    checked={selectedFlags.includes(sec._id)}
                                    onChange={(e) => handleChecked(e)}
                                  />
                                  <p className="categories-p-check">
                                    {sec.name}
                                  </p>
                                </div>
                              </div>
                            )}
                          </>
                        ))}
                      </>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Flags;
