import React from "react";
import { IoMdClose } from "react-icons/io";
const TagsChips = ({tag, showAll, setShowAll, selectedTags,handleTagClick}) => {
    const tagArray = Array.isArray(tag) ? tag : [];
    const toggleShowMore = () => setShowAll(!showAll);
    const tagsToShow = showAll ? tagArray : tagArray.slice(0, 10);
  return (
    <>
    {tag && 
      <div className="tag-filter-div">
        {tagsToShow.length > 0 ? (
          tagsToShow.map((el, i) => (
            <span
              key={el._id}
              className={`tag-filter-chip ${
                selectedTags.includes(el._id) ? "tag-filter-active" : ""
              }`}
              onClick={() => handleTagClick(el._id)}
            >
              {el.name}
              {selectedTags.includes(el._id) ? <IoMdClose /> : ""}
            </span>
          ))
        ) : (
          <p>No tags available</p>
        )}
      </div>
    }
      {tag && tag.length > 10 && (
        <div>
          <button className="btn-primary" onClick={toggleShowMore}>
            {showAll ? "Show Less" : "Show More"}
          </button>
        </div>
      )}
    
    </>
  );
};

export default TagsChips;