import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import style from "../../styles/common/ImageFocalPopup.module.css";
import "@lemoncode/react-image-focal-point/style.css";
import { ImageFocalPoint } from "@lemoncode/react-image-focal-point";

const ImageFocalPopup = ({
  images,
  focalPosition,
  setFocalPosition,
  setOpenFocalModal,
}) => {
  const [aspectRatio, setAspectRatio] = useState("square");

  const resetFocalPosition = () => {
    setAspectRatio("square");
    setFocalPosition({ x: 50, y: 50 });
  };

  const getAspectRatioStyle = () => {
    return aspectRatio === "square"
      ? { width: "300px", height: "380px" }
      : { width: "100%", height: "380px" };
  };

  return images ? (
    <div className={style.modal}>
      <OutsideClickHandler onOutsideClick={() => setOpenFocalModal(null)}>
        <div className={style.card}>
          <div className={style.cardHeader}>
            <h2>Adjust Focal Point</h2>
            <div className={style.aspectRatioSelector}>
              <label htmlFor="aspect-ratio">Select Aspect Ratio:</label>
              <select
                id="aspect-ratio"
                value={aspectRatio}
                onChange={(e) => setAspectRatio(e.target.value)}
              >
                <option value="square">Square</option>
                <option value="rectangle">Rectangle</option>
              </select>
            </div>
          </div>

          <div className={style.cardBody}>
            <div className={style.adjustDiv}>
              <ImageFocalPoint
                className={style.imageFocal}
                src={images}
                onChange={(focalPoint) => setFocalPosition(focalPoint)}
              />
            </div>
            <div className={style.changeDiv}>
              <div className={style.imageWrapper} style={getAspectRatioStyle()}>
                <img
                  src={images}
                  alt="Image preview with focal point"
                  style={{
                    objectFit: "cover",
                    objectPosition: `${focalPosition?.x ?? 50}% ${
                      focalPosition?.y ?? 50
                    }%`,
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
            </div>
          </div>

          <div className={style.footer}>
            <button
              className={style.btnDangerOutline}
              onClick={() => setOpenFocalModal(null)}
            >
              Cancel
            </button>
            <button
              className={style.btnDanger}
              onClick={resetFocalPosition}
              disabled={focalPosition.x === 50 && focalPosition.y === 50}
            >
              Reset
            </button>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  ) : (
    <p>No image provided.</p>
  );
};

export default ImageFocalPopup;
