// import Link from "next/link";
import { Link } from "react-router-dom";
import "../../styles/admin/admin.css";
import React, { useEffect, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { AiOutlineDashboard } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import Toaster from "../common/Toaster";
import { MdKeyboardArrowDown } from "react-icons/md";
import ProfileDropdown from "../../components/ProfileDropdown";
import { AiOutlineBell } from "react-icons/ai";
import { HiOutlinePlus } from "react-icons/hi";
import { ToastProvider } from "../common/ToastContext";
import { IoImages } from "react-icons/io5";
import { FaRegArrowAltCircleUp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Profilebtn from "../admin/Profilebtn";
import { FaTags } from "react-icons/fa";
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi";
import { LuUsers } from "react-icons/lu";
import { IoMdNotificationsOutline } from "react-icons/io";
import FieldSearchText from "../fields/FieldSearchText";
import LogoutPopup from "../common/LogoutPopup";
import { AiFillHome } from "react-icons/ai";
import { IoMdAnalytics } from "react-icons/io";
import { FaStore } from "react-icons/fa";
import { RiArticleFill } from "react-icons/ri";
import { IoBagCheck } from "react-icons/io5";
import { FaUserGroup } from "react-icons/fa6";
import { HiSpeakerphone } from "react-icons/hi";
import { BiSolidCategory } from "react-icons/bi";
import { TbAppsFilled } from "react-icons/tb";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { BiSolidMessage } from "react-icons/bi";
import { MdWeb } from "react-icons/md";
import { IoMdSettings } from "react-icons/io";
import { FaUserEdit } from "react-icons/fa";
import { SiGoogleforms } from "react-icons/si";
import { MdEditSquare } from "react-icons/md";
import { MdWebStories } from "react-icons/md";
import { MdEditDocument } from "react-icons/md";
import { FaRegMessage } from "react-icons/fa6";
import { BsFillRocketFill, BsFillFlagFill } from "react-icons/bs";
import Footer from "../../components/admin/Footer";
import { CiSearch } from "react-icons/ci";
const Admin = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentTabs = localStorage.getItem("tabs");
  const currentPath = location.pathname;
  const firstSegment = currentPath.split("/")[1];
  const [open, setOpen] = useState(true);
  const [isConfirm, setIsConfirm] = useState(false);
  const [loggedIn, setLoggedIn] = useState(!false);
  const [expanded, setExpanded] = useState(false);
  const [specialLink, setSpecialLink] = useState(null);
  const [selected, Setselected] = useState(0);
  const [clicktab, Setclicktab] = useState(0);
  const [toasteractive, Settoasteractive] = useState(false);
  const [toastersuccess, Settoastersuccess] = useState(true);

  useEffect(() => {
    setExpanded(currentTabs);
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    localStorage.setItem("tabs", panel);
    setExpanded(isExpanded ? panel : false);
    Setclicktab(clicktab + 1);
  };

  const handleClick = (event) => {
    setIsConfirm(true);
    event.preventDefault();
  };
  const logout = (link) => {
    if (link.title == "Logout") {
      localStorage.removeItem("token"); // Remove token from local storage
      window.location.reload(); // Reload the page
    }
  };

  const Dashboard = [
    {
      svg: <AiFillHome className="logos-dash" />,
      title: "Home",
      link: "home",
      active: "home",
    },
    {
      svg: <IoMdAnalytics className="logos-dash" />,
      title: "Analytics",
      link: "analytics/traffic-overview",
      // active: "analytics",
      submenu: [
        {
          svg: <AiOutlineDashboard />,
          title: "Traffic Overview",
          link: "analytics/traffic-overview",
          active: "analytics/traffic-overview",
          parenttitle: "Analytics",
        },
        {
          svg: <AiOutlineDashboard />,
          title: "Real-time Overview",
          link: "analytics/realtime-overview",
          active: "analytics/realtime-overview",
          parenttitle: "Analytics",
        },
        {
          svg: <AiOutlineDashboard />,
          title: "Behavior Overview",
          link: "analytics/behavior-overview",
          active: "analytics/behavior-overview",
          parenttitle: "Analytics",
        },
        {
          svg: <AiOutlineDashboard />,
          title: "Story Overview",
          link: "analytics/story-overview",
          active: "analytics/story-overview",
          parenttitle: "Analytics",
        },
        {
          svg: <AiOutlineDashboard />,
          title: "Marketing Overview",
          link: "analytics/marketing-overview",
          active: "analytics/marketing-overview",
          parenttitle: "Analytics",
        },
      ],
    },
    {
      svg: <MdEditDocument className="logos-dash" />,
      title: "Stories",
      link: "stories",
      // active: "stories",
      submenu: [
        {
          title: "Text Stories",
          link: "stories",
          active: "stories",
          parenttitle: "Stories",
        },
        {
          title: "Video Stories",
          link: "stories/video-stories",
          active: "stories/video-stories",
          parenttitle: "Stories",
        },
        {
          title: "Shorts Stories",
          link: "stories/shorts",
          active: "stories/shorts",
          parenttitle: "Stories",
        },
        {
          title: "Web Stories",
          link: "stories/web-stories",
          active: "stories/web-stories",
          parenttitle: "Stories",
        },
      ],
    },
    {
      svg: <BiSolidCategory className="logos-dash" />,
      title: "Categories",
      link: "categories",
      active: "categories",
    },
     {
       svg: <BsFillFlagFill className="logos-dash" />,
       title: "Flagged stories",
       link: "flag",
      active: "flag",
     },
    {
      svg: <FaUserEdit className="logos-dash" />,
      title: "Authors",
      link: "authors",
      active: "authors",
    },
    {
      svg: <FaTags className="logos-dash" />,
      title: "Tags",
      link: "tags",
      active: "tags",
    },
    {
      svg: <IoImages className="logos-dash" />,
      title: "Media Library",
      link: "media-library",
      active: "media-library",
    },

    {
      svg: <SiGoogleforms className="logos-dash" />,
      title: "Forms & Submissions",
      link: "forms-and-submissions/contacts",
      // active: "analytics",
      submenu: [
        {
          svg: <LuUsers />,
          title: "Contacts",
          link: "forms-and-submissions/contacts",
          active: "forms-and-submissions/contacts",
          parenttitle: "Customers",
        },
        {
          svg: <AiOutlineDashboard />,
          title: "Contact Form Submission",
          link: "forms-and-submissions/contact-form-submissions",
          active: "forms-and-submissions/contact-form-submissions",
          parenttitle: "Customers",
        },
      ],
    },

    {
      svg: <HiSpeakerphone className="logos-dash" />,
      title: "Marketing",
      link: "marketing/sms-marketing",
      // active: "analytics",
      submenu: [
        {
          svg: <AiOutlineDashboard />,
          title: "SMS Marketing",
          link: "marketing/sms-marketing",
          active: "marketing/sms-marketing",
          parenttitle: "Apps",
        },
        {
          svg: <AiOutlineDashboard />,
          title: "Whatsapp Marketing",
          link: "marketing/whatsapp-marketing",
          active: "marketing/whatsapp-marketing",
          parenttitle: "Apps",
        },
        {
          svg: <AiOutlineDashboard />,
          title: "Email Marketing",
          link: "marketing/email-marketing",
          active: "marketing/email-marketing",
          parenttitle: "Apps",
        },
      ],
    },
    {
      svg: <IoMdSettings className="logos-dash" />,
      title: "Settings",
      link: "settings",
      active: "settings",
    },
  ];

  const [temptoken, Settemptoken] = useState(false);
  const func = () => {
    const token = localStorage.getItem("token");
    if (token) {
      Settemptoken(true);
    }
  };
  useEffect(() => {
    func();
  }, []);

  const checktab = () => {
    const tabs = localStorage.getItem("tabs");
    const index = parseInt(tabs.split("-")[1]);
    Setselected(index);
  };

  useEffect(() => {
    checktab();
  }, [clicktab]);

  return (
    <>
      {loggedIn ? (
        <div>
          <ToastProvider>
            <div className="admin">
              <Toaster
                toasteractive={toasteractive}
                toastersuccess={toastersuccess}
                Settoastersuccess={Settoastersuccess}
                Settoasteractive={Settoasteractive}
              />
              <div
                className={`admin-left ${!open ? "admin-left-collapse" : null}`}
              >
                {open && (
                  <div className="header-left-nav">
                    <div className="user-details">
                      {/* <div className="main-logo">
                        <img src="./mainlogo1.png" className="main-logo-img"></img>
                      </div> */}
                      <div className="user-details-top">
                        <ProfileDropdown />
                        <div>
                          <AiOutlineBell className="bell-logo" />
                        </div>
                      </div>
                      <div className="input-left-nav">
                        <CiSearch className="search-logo" />
                        <input
                          className="input-left-nav-search"
                          placeholder="Search for anything"
                        ></input>
                      </div>
                    </div>
                  </div>
                )}
                {!open && (
                  <div className="collapse-logo-div">
                    <div className="name-logo collapse-logo-name">R</div>
                  </div>
                )}
                <div
                  className={
                    open ? "admin-nav-list" : "admin-nav-list collapse-nav-list"
                  }
                >
                  <div>
                    {open ? (
                      Dashboard.map((link, index) => {
                        if (link?.submenu?.length > 0) {
                          return (
                            <>
                              <Accordion
                                sx={{
                                  margin: "0px!important",
                                  borderRadius: "5px !important",
                                  backgroundColor: "black",
                                  paddingBlock: "1px",
                                  "& .MuiCollapse-root": {
                                    paddingInline: "7px",
                                  },
                                  "& .MuiCollapse-wrapper": {
                                    paddingBottom: "5px",
                                  },
                                }}
                                className={`dropdown-accordion ${
                                  expanded === `panel-${index}`
                                    ? `admin-expand-item`
                                    : ``
                                }`}
                                expanded={expanded === `panel-${index}`}
                                onChange={handleChange(`panel-${index}`)}
                                key={index}
                              >
                                <div
                                  className={`${
                                    index === selected
                                      ? "admin-nav-item color-it"
                                      : "admin-nav-item"
                                  } ${
                                    expanded === `panel-${index}`
                                      ? "admin-nav-item-active"
                                      : null
                                  }`}
                                >
                                  <AccordionSummary
                                    sx={{
                                      padding: "0px!important",
                                      display: "flex!important",
                                      justifyContent: "space-between!important",
                                      width: "100%!important",
                                      minWidth: "100%!important",
                                    }}
                                    expandIcon={
                                      <FiChevronDown
                                        className={`${
                                          index === selected
                                            ? "dropdown-icon color-svg"
                                            : "dropdown-icon"
                                        }`}
                                      />
                                    }
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    onChange={handleChange(`panel-${index}`)}
                                    onClick={() => {
                                      navigate(`/${link.link}`);
                                    }}
                                  >
                                    <div
                                      className={`${
                                        index === selected
                                          ? "nav-svg-cont color-svg"
                                          : "nav-svg-cont"
                                      }`}
                                    >
                                      {link.svg}
                                    </div>

                                    <p
                                      className={`${
                                        index === selected
                                          ? "title-dash color-svg"
                                          : "title-dash"
                                      }`}
                                    >
                                      {link.title}
                                    </p>
                                  </AccordionSummary>
                                </div>
                                {link?.submenu.map((item, i) => {
                                  return (
                                    <>
                                      <Link to={`/${item.link}`}>
                                        <AccordionDetails
                                          key={`submenus-${i}`}
                                          className={`w-100 admin-subnav-item pp ${
                                            currentPath === `/${item.active}`
                                              ? "admin-nav-active"
                                              : null
                                          }`}
                                        >
                                          <div className="sub-menu-dot">
                                            <div
                                              className={`dot2 ${
                                                currentPath ===
                                                `/${item.active}`
                                                  ? "dot-active"
                                                  : null
                                              }`}
                                            ></div>
                                            <p className="sub-title">
                                              {item.title}
                                            </p>
                                          </div>
                                        </AccordionDetails>
                                      </Link>
                                    </>
                                  );
                                })}
                              </Accordion>
                            </>
                          );
                        } else {
                          // if (link.link === "") {
                          //   return (
                          //     <>
                          //       <div className="logout">
                          //         <Link to={`/${link.link}`}>
                          //           <div
                          //             className={`admin-nav-item ${
                          //               currentPath === `/${link.active}`
                          //                 ? "admin-nav-active"
                          //                 : null
                          //             }`}
                          //             // onClick={handleClick}
                          //             onClick={() => {
                          //               navigate("/*");
                          //             }}
                          //           >
                          //             <div className="nav-svg-cont">{link.svg}</div>
                          //             {link.title}
                          //           </div>
                          //         </Link>
                          //       </div>
                          //     </>
                          //   );
                          // } else {
                          return (
                            <>
                              <Link to={`/${link.link}`} className="">
                                <div
                                  onClick={() => {
                                    handleChange(`panel-${index}`);
                                    localStorage.setItem(
                                      "tabs",
                                      `panel-${index}`
                                    );
                                    localStorage.setItem("special", null);
                                    setSpecialLink(null);
                                    setExpanded(`panel-${index}`);
                                    Setclicktab(clicktab + 1);
                                  }}
                                  // solution
                                  className={`admin-nav-item ${
                                    `/${firstSegment}` === `/${link.active}`
                                      ? "admin-nav-active"
                                      : null
                                  }`}
                                >
                                  <div className="nav-svg-cont">{link.svg}</div>
                                  <p className="title-dash">{link.title}</p>
                                </div>
                              </Link>
                            </>
                          );
                        }
                      })
                    ) : (
                      <>
                        {Dashboard.map((link, index) => {
                          if (link.link === "...") {
                            return (
                              // <div className="logout">
                              <div className="nav-collapse-item flex-all logout">
                                <div
                                  className={`collapse-icons-cont flex-all ${
                                    currentPath === `/${link.link}`
                                      ? "collapse-icon-active"
                                      : null
                                  }`}
                                >
                                  {link.svg}
                                </div>
                              </div>
                              // </div>
                            );
                          } else {
                            return (
                              <Link to={`/${link.link}`}>
                                <div
                                  className="nav-collapse-item flex-all"
                                  onClick={() => {
                                    handleChange(`panel-${index}`);
                                    localStorage.setItem(
                                      "tabs",
                                      `panel-${index}`
                                    );
                                    setExpanded(`panel-${index}`);
                                    Setclicktab(clicktab + 1);
                                  }}
                                >
                                  <div
                                    className={`collapse-icons-cont flex-all ${
                                      currentPath === `/${link.link}`
                                        ? "collapse-icon-active"
                                        : null
                                    }`}
                                  >
                                    {link.svg}
                                  </div>
                                </div>
                              </Link>
                            );
                          }
                        })}
                      </>
                    )}
                  </div>
                  <div>{open && <Footer />}</div>
                </div>
              </div>

              <div
                className={`admin-right ${!open ? "admin-right-full" : null}`}
              >
                <div className="admin-section-cont">
                  <div
                    className={
                      open ? "close-expand" : "close-expand trans_expand"
                    }
                    onClick={() => {
                      setOpen(!open);
                    }}
                  >
                    {open ? <FiChevronsLeft /> : <FiChevronsRight />}
                  </div>
                  <div
                    className={
                      open
                        ? "close-expand-div"
                        : "close-expand-div trans_expand-div"
                    }
                    onClick={() => {
                      setOpen(!open);
                    }}
                  >
                    {open ? (
                      <p className="collapse-p">Collapse</p>
                    ) : (
                      <p className="collapse-p">Expand</p>
                    )}
                  </div>
                  {children}
                </div>
              </div>
              {isConfirm && (
                <LogoutPopup
                  isConfirm={isConfirm}
                  setIsConfirm={setIsConfirm}
                />
              )}
              {/* <Logout isConfirm={isConfirm} setIsConfirm={setIsConfirm} /> */}
            </div>
          </ToastProvider>
        </div>
      ) : (
        // <Loader />
        <></>
      )}
    </>
  );
};

export default Admin;
