import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import style from "../../styles/common/Zoom.module.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { FiDownload } from "react-icons/fi";
import { FaRegStar } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";

const DeletePopup = ({ setZoom, imgs, zoom,handleDownload }) => {
  const flattenedImages = imgs.flatMap((group) => group.row);
  const [currentIndex, setCurrentIndex] = useState(zoom - 1);
  const [animationClass, setAnimationClass] = useState("");

  const goToNext = () => {
    setAnimationClass(style.slideOutLeft);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % flattenedImages.length);
      setAnimationClass(style.slideInRight);
      setTimeout(() => {
        setAnimationClass("");
      }, 300); // Match the transition duration
    }, 300); // Match the transition duration
  };

  const goToPrev = () => {
    setAnimationClass(style.slideOutRight);
    setTimeout(() => {
      setCurrentIndex(
        (prevIndex) =>
          (prevIndex - 1 + flattenedImages.length) % flattenedImages.length
      );
      setAnimationClass(style.slideInLeft);
      setTimeout(() => {
        setAnimationClass("");
      }, 300); // Match the transition duration
    }, 300); // Match the transition duration
  };

  return (
    <div className={style.modal}>
      <OutsideClickHandler
        onOutsideClick={() => {
          setZoom(null);
        }}
      >
        <div className={style.card}>
          <div className={style.zoomhead}>
            <p className={style.legend}>{flattenedImages[currentIndex].name}</p>
            <div className={style.downloadmediadiv}>
              <div className={style.mediacontrolbtn} onClick={(e)=>{e.stopPropagation();handleDownload(flattenedImages[currentIndex].img, flattenedImages[currentIndex].name) }}>
                <FiDownload />
              </div>
              <div className={style.mediacontrolbtn}>
                <FaRegStar />
              </div>
            </div>
          </div>

          <div className={style.carousel}>
            <div className={`${style.imageContainer} ${animationClass}`}>
              <img
                src={flattenedImages[currentIndex].img}
                alt={flattenedImages[currentIndex].name}
                className={style.image}
              />
            </div>
          </div>
          <div className={style.controlimgc}>
            <button className={style.leftArrow} onClick={goToPrev}>
              <IoIosArrowBack />
            </button>
            <div
              onClick={() => {
                setZoom(null);
              }}
              className={style.crossimgc}
            >
              <RxCross2 style={{ cursor: "pointer" }} />
            </div>
            <button className={style.rightArrow} onClick={goToNext}>
              <IoIosArrowForward />
            </button>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default DeletePopup;
