import React, { useState, useEffect } from "react";
import { GoPlus } from "react-icons/go";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Divider,
  LinearProgress,
  Tab,
  Tabs,
} from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { IoIosArrowDown } from "react-icons/io";
import { AiOutlinePlus } from "react-icons/ai";
import { Chart } from "react-google-charts";
import dayjs from "dayjs";
import DateRangePicker from "../../components/fields/DateRangePicker";
import "../../styles/analytics/MarketingOverview.css";
import "../../styles/analytics/StoryOverview.css";
import "../../styles/analytics/BehaviorOverview.css";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { FaArrowUpLong } from "react-icons/fa6";
import { FcGoogle } from "react-icons/fc";
import { CiGlobe } from "react-icons/ci";
import { FaArrowDownLong } from "react-icons/fa6";
import { IoEyeOutline } from "react-icons/io5";
import MoSearchTable from "../../components/analytics/MoSearchTable";
import Progress from "../../components/analytics/Progress";
import Tabstory from "../../components/analytics/Tabs";
import Tabstory2 from "../../components/analytics/Tabs2";
import HeatMap from "../../components/analytics/HeatMap";
import { useNavigate } from "react-router-dom";
import SoTable from "../../components/analytics/SoTable";
import SoTable2 from "../../components/analytics/SoTable2";
import { IoIosArrowForward } from "react-icons/io";
import { TiMediaRecord } from "react-icons/ti";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { getTrafficAnalytics } from "../../api/admin/Analytics";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { WiTime4 } from "react-icons/wi";

const BehaviorOverview = () => {
  const navigate = useNavigate();
  let countrySession = [];
  const [isLoading, setIsLoading] = useState(true);
  const [expanded, setExpanded] = useState('panel1');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const data = [
    ["From", "To", "Weight"],
    ["/Homepage", "/naruto", 5],
    ["/Homepage", "/one-piece", 2],
    ["/product-page/op", "/one-piece", 7],
    ["/product-page/naruto-sasuke", "/search", 6],
    ["/mouse-pad", "/demon-slayer", 7],
    ["/demon-slayer", "+34 pages", 9],
  ];
  const options = {};
  const [scrolled, setScrolled] = useState(false);
  const [siteSession, setSitSession] = useState(0);
  const [uniqueVisitor, setUniqueSession] = useState(0);
  const [newReturning, setNewReturning] = useState([]);
  const [DeviceSession, setDeviceSession] = useState([]);
  const [overTimeSession, setOverTimeSession] = useState([]);
  const [sourcesSession, setSourcesSession] = useState([]);
  const [averageSession, setAverageSession] = useState([]);
  const [averageSessionGraph, setAverageSessionGraph] = useState({});
  const [chartData, setChartData] = useState([["Country", "Count"]]);

  const [dateRange, setDateRange] = useState([
    {
      startDate: dayjs()
        .subtract(30, "days")
        .startOf("day")
        .format("YYYY-MM-DD"),
      endDate: dayjs().endOf("day").format("YYYY-MM-DD"),
    },
  ]);
  const handleDateRangePicker = (start, end) => {
    const date = [
      {
        startDate: start,
        endDate: end,
      },
    ];
    setDateRange(date);
  };
  useEffect(() => {
    document
      .getElementsByClassName("admin-right")[0]
      .addEventListener("scroll", () => {
        if (
          document.getElementsByClassName("ad-mp")[0] &&
          document.getElementsByClassName("admin-right")[0].scrollTop >
            document.getElementsByClassName("ad-mp")[0].offsetTop - 240
        ) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      });
    fetchDate();
  }, [dateRange]);

  const fetchDate = async () => {
    const payload = {
      timeFrame: dateRange,
    };
    try {
      const res = await getTrafficAnalytics(payload);
      if (res) {
        setSitSession(res.site_session);
        setUniqueSession(res.unique_visitor);
        setNewReturning(res.new_vs_returing_visitor);
        setDeviceSession(res.session_by_device);
        setOverTimeSession(res.session_over_time);
        setSourcesSession(res.session_sources);
        countrySession = res.session_by_country;
        setAverageSession(res.average_session_days);
        if (res.average_session_days.length > 0) {
          const avgSessionData = res.average_session_days.reduce(
            (acc, day) => {
              acc.labels.push(day.title.substring(0, 3));
              acc.data.push(day.count);
              return acc;
            },
            { labels: [], data: [] }
          );
          setAverageSessionGraph(avgSessionData);
        }
        if (res.session_by_country.length > 0) {
          const countryData = res.session_by_country.map((country) => [
            country.title,
            country.count,
          ]);
          setChartData([["Country", "Count"], ...countryData]);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const newReturningData = newReturning.map((item) => item.count);
  const newReturningLabel = newReturning.map(
    (item) => item.title.charAt(0).toUpperCase() + item.title.slice(1)
  );

  const ReturnVisitorGraph = {
    series: newReturningData,
    options: {
      labels: newReturningLabel,
      chart: { height: "100%", type: "donut" },
      stroke: { width: 0 },
      dataLabels: { enabled: false },
      colors: ["#1684ea", "#1abef3"],
      legend: {
        show: true,
        formatter: (value, { seriesIndex, dataPointIndex, w }) => {
          const totalCount = w.config.series.reduce((a, b) => a + b, 0);
          return (
            w.config.labels[seriesIndex] +
            "<br />" +
            parseFloat(w.config.series[seriesIndex]) +
            " - " +
            (
              parseFloat(w.config.series[seriesIndex] / totalCount) * 100
            ).toFixed(0) +
            "%" +
            "</br />"
          );
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: "80%",
            background: "transparent",
            labels: {
              show: true,
              showAlways: true,
              name: { show: true, formatter: () => "Unique Visitor" },
              total: {
                show: true,
                formatter: (val) =>
                  val.globals.seriesTotals.reduce((a, b) => a + b, 0),
              },
            },
          },
        },
      },
    },
  };

  const [chartDatas, setChartDatas] = useState({
    series: [
      {
        name: "sales",
        data: [
          {
            x: "",
            y: 400,
          },
          {
            x: "",
            y: 300,
          },
          {
            x: "",
            y: 227,
          },
          {
            x: "",
            y: 220,
          },
          {
            x: "",
            y: 1,
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 380,
        toolbar: {
          show: false, // Hides the toolbar
        },
      },
      xaxis: {
        type: "category",
        group: {
          style: {
            fontSize: "10px",
            fontWeight: 700,
          },
          groups: [
            { title: "47%", cols: 1 },
            { title: "22%", cols: 1 },
            { title: "70%", cols: 1 },
            { title: "7%", cols: 1 },
          ],
        },
      },
      yaxis: {
        show: false, // Hides the Y-axis
      },
      grid: {
        show: false, // Hides the grid
      },
    },
  });

  return (
    <>
      <div className="head-of-head writer-top">
        <div className="table-name">Behavior Overview</div>
        <div className="fixed-right">
          <Grid item xs={8}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <DateRangePicker
                  onChange={handleDateRangePicker}
                  sDate={dateRange[0].startDate}
                  eDate={dateRange[0].endDate}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      <p className="p-top-writer">
        Track and analyze the interaction of customers with the site.
      </p>

      <div className="story-top-main mb-30">
        <div className="left-behavior-top">
          <div className="visitor-eng">
            <div className="visitor-eng-single">
              <p className="visitor-p-main">Avg. pages per session : 2.4</p>
              <p className="visitor-p-small">
                <FaArrowDownLong />
                10%
              </p>
            </div>
            <div className="behavior-verti"></div>
            <div className="visitor-eng-single">
              <p className="visitor-p-main">Bounce rate : 41.1%</p>
              <p className="visitor-p-small">
                <FaArrowUpLong />
                13%
              </p>
            </div>
            <div className="behavior-verti"></div>
            <div className="visitor-eng-single">
              <p className="visitor-p-main">Avg. session duration : 3m 34s</p>
              <p className="visitor-p-small">
                <FaArrowDownLong />
                11%
              </p>
            </div>
          </div>
          <div className="top-navigation">
            <div className="top-nav-top">
              <p className="bo-head-p">Top navigation flows</p>
              <p className="bo-subhead-p">
                Explore the first three steps that people take to navigate your
                site.
              </p>
            </div>
            <div className="main-chart-top-beha">

            <Chart
              chartType="Sankey"
              width="80%"
              height="250px"
              data={data}
              options={options}
              />
              </div>
          </div>
        </div>
        <div className="right-behavior-top">
          <Accordion
            defaultExpanded
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            sx={{
              boxShadow: "none",
              margin: "0 !important",
              borderRadius: "5px !important",
              border:"1px solid rgb(193, 228, 254)",
              "&::before": {
                position: "relative",
              },
            }}
            className="right-beha-drop"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div className="most-visited-page">
                <div className="right-behavior-head">
                  <div className="left-head-behavior">
                    <IoEyeOutline className="beha-eye" />
                    <div>
                      <p className="right-beha-p-main">Most visited pages</p>
                      <p className="right-beha-p-small">/homepage</p>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="accordian-details">
                <p className="accordian-p-small">Top pages by sessions</p>
                <div className="progress-main-div">
                  <div className="accord-detail-flex">
                    <p className="progress-p-main">/insight.homepage</p>
                    <div className="right-flex-progress">
                      <div className="rise-progress">
                        <FaArrowUpLong />
                        28%
                      </div>
                      <div className="accor-verti"></div>
                      <p className="progress-main">384</p>
                    </div>
                  </div>
                  <Progress value={60} />
                </div>

                <div className="progress-main-div">
                  <div className="accord-detail-flex">
                    <p className="progress-p-main">/accessories</p>
                    <div className="right-flex-progress">
                      <div className="rise-progress">
                        <FaArrowUpLong />
                        44%
                      </div>
                      <div className="accor-verti"></div>
                      <p className="progress-main">72</p>
                    </div>
                  </div>
                  <Progress value={20} />
                </div>
                <div className="progress-main-div">
                  <div className="accord-detail-flex">
                    <p className="progress-p-main">/naruto</p>
                    <div className="right-flex-progress">
                      <div className="rise-progress">
                        <FaArrowUpLong />
                        37%
                      </div>
                      <div className="accor-verti"></div>
                      <p className="progress-main">63</p>
                    </div>
                  </div>
                  <Progress value={18} />
                </div>
                <div className="progress-main-div">
                  <div className="accord-detail-flex">
                    <p className="progress-p-main">/collectibles</p>
                    <div className="right-flex-progress">
                      <div className="rise-progress">
                        <FaArrowUpLong />
                        30%
                      </div>
                      <div className="accor-verti"></div>
                      <p className="progress-main">60</p>
                    </div>
                  </div>
                  <Progress value={60} />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            sx={{
              boxShadow: "none",
              margin: "0 !important",
              border:"1px solid rgb(193, 228, 254)",
              borderRadius: "5px !important",
              "&::before": {
                position: "relative",
              },
            }}
            className="right-beha-drop"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div className="most-visited-page">
                <div className="right-behavior-head">
                  <div className="left-head-behavior">
                    <WiTime4 className="beha-eye" />
                    <div>
                      <p className="right-beha-p-main">Most time spent pages</p>
                      <p className="right-beha-p-small">/checkout</p>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="accordian-details">
                <p className="accordian-p-small">
                  Top pages by avg. time on page
                </p>
                <div className="progress-main-div">
                  <div className="accord-detail-flex">
                    <p className="progress-p-main">/checkout</p>
                    <div className="right-flex-progress">
                      <div className="rise-progress">
                        <FaArrowUpLong />
                        360%
                      </div>
                      <div className="accor-verti"></div>
                      <p className="progress-main">2m 14s</p>
                    </div>
                  </div>
                  <Progress value={30} />
                </div>

                <div className="progress-main-div">
                  <div className="accord-detail-flex">
                    <p className="progress-p-main">/sippers</p>
                    <div className="right-flex-progress">
                      <div className="rise-progress">
                        <FaArrowUpLong />
                        88%
                      </div>
                      <div className="accor-verti"></div>
                      <p className="progress-main">2m 13s</p>
                    </div>
                  </div>
                  <Progress value={30} />
                </div>
                <div className="progress-main-div">
                  <div className="accord-detail-flex">
                    <p className="progress-p-main">/tshirts</p>
                    <div className="right-flex-progress">
                      <div className="rise-progress">
                        <FaArrowUpLong />
                        22%
                      </div>
                      <div className="accor-verti"></div>
                      <p className="progress-main">1m 35s</p>
                    </div>
                  </div>
                  <Progress value={25} />
                </div>
                <div className="progress-main-div">
                  <div className="accord-detail-flex">
                    <p className="progress-p-main">/one-piece</p>
                    <div className="right-flex-progress">
                      <div className="rise-progress">
                        <FaArrowUpLong />
                        22%
                      </div>
                      <div className="accor-verti"></div>
                      <p className="progress-main">59s</p>
                    </div>
                  </div>
                  <Progress value={20} />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            sx={{
              boxShadow: "none",
              margin: "0 !important",
              borderRadius: "5px !important",
              border:"1px solid rgb(193, 228, 254)",
              "&::before": {
                position: "relative",
              },
            }}
            className="right-beha-drop"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div className="most-visited-page">
                <div className="right-behavior-head">
                  <div className="left-head-behavior">
                    <WiTime4 className="beha-eye" />
                    <div>
                      <p className="right-beha-p-main">Most exited pages</p>
                      <p className="right-beha-p-small">
                        /product-page/naruto-sasuke
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="accordian-details">
                <p className="accordian-p-small">
                  Pages with the hightest exit rate
                </p>
                <div className="progress-main-div">
                  <div className="accord-detail-flex">
                    <p className="progress-p-main">
                      /product-page/naruto-sasuke
                    </p>
                    <div className="right-flex-progress">
                      <p className="progress-main">89%</p>
                    </div>
                  </div>
                  <Progress value={80} />
                </div>

                <div className="progress-main-div">
                  <div className="accord-detail-flex">
                    <p className="progress-p-main">/demon-slayer</p>
                    <div className="right-flex-progress">
                      <p className="progress-main">55%</p>
                    </div>
                  </div>
                  <Progress value={55} />
                </div>
                <div className="progress-main-div">
                  <div className="accord-detail-flex">
                    <p className="progress-p-main">/one-piece</p>
                    <div className="right-flex-progress">
                      <p className="progress-main">54%</p>
                    </div>
                  </div>
                  <Progress value={54} />
                </div>
                <div className="progress-main-div">
                  <div className="accord-detail-flex">
                    <p className="progress-p-main">/checkout</p>
                    <div className="right-flex-progress">
                      <p className="progress-main">51%</p>
                    </div>
                  </div>
                  <Progress value={51} />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
            sx={{
              boxShadow: "none",
              margin: "0 !important",
              border:"1px solid rgb(193, 228, 254)",
              borderRadius: "5px !important",
              "&::before": {
                position: "relative",
              },
            }}
            className="right-beha-drop"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div className="most-visited-page">
                <div className="right-behavior-head">
                  <div className="left-head-behavior">
                    <WiTime4 className="beha-eye" />
                    <div>
                      <p className="right-beha-p-main">Most searched terms</p>
                      <p className="right-beha-p-small">katana</p>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="accordian-details">
                <p className="accordian-p-small">Top searches on your site</p>
                <div className="progress-main-div">
                  <div className="accord-detail-flex">
                    <p className="progress-p-main">katana</p>
                    <div className="right-flex-progress">
                      <div className="rise-progress">
                        <FaArrowUpLong />
                        29%
                      </div>
                      <div className="accor-verti"></div>
                      <p className="progress-main">9</p>
                    </div>
                  </div>
                  <Progress value={30} />
                </div>

                <div className="progress-main-div">
                  <div className="accord-detail-flex">
                    <p className="progress-p-main">keychain</p>
                    <div className="right-flex-progress">
                      <div className="rise-progress">
                        <FaArrowUpLong />
                        500%
                      </div>
                      <div className="accor-verti"></div>
                      <p className="progress-main">6</p>
                    </div>
                  </div>
                  <Progress value={20} />
                </div>
                <div className="progress-main-div">
                  <div className="accord-detail-flex">
                    <p className="progress-p-main">makima</p>
                    <div className="right-flex-progress">
                      <div className="rise-progress">
                        <FaArrowUpLong />
                        300%
                      </div>
                      <div className="accor-verti"></div>
                      <p className="progress-main">4</p>
                    </div>
                  </div>
                  <Progress value={15} />
                </div>
                <div className="progress-main-div">
                  <div className="accord-detail-flex">
                    <p className="progress-p-main">spy</p>
                    <div className="right-flex-progress">
                      <p className="progress-main">4</p>
                    </div>
                  </div>
                  <Progress value={15} />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>

      <div className="story-top-main mb-30">
        <div className="left-behavior-middle">
          <div className="top-nav-top">
            <p className="bo-head-p">Conversion rate : 0.48%</p>
          </div>
          <div className="graph-middle">
            <ReactApexChart
              options={chartDatas.options}
              series={chartDatas.series}
              type="bar"
              height={260}
            />
            <div className="flex-middle-graph">
              <div>
                <p className="middle-graph-p-small">Site sessions</p>
                <p className="middle-graph-p-main">622</p>
              </div>
              <div>
                <p className="middle-graph-p-small">Viewed product</p>
                <p className="middle-graph-p-main">292</p>
              </div>
              <div>
                <p className="middle-graph-p-small">Added to cart</p>
                <p className="middle-graph-p-main">63</p>
              </div>
              <div>
                <p className="middle-graph-p-small">Reached checkout</p>
                <p className="middle-graph-p-main">44</p>
              </div>
              <div>
                <p className="middle-graph-p-small">Sessions converted</p>
                <p className="middle-graph-p-main">3</p>
              </div>
            </div>
          </div>
        </div>
        <div className="right-behavior-middle">
        <div className="post-views-so">Suggested for you</div>
            <div className="suggested-div">
              <div className="sugg" onClick={()=>{navigate("/analytics/traffic-overview")}}>
                <div className="sugg-head">
                  <p className="sugg-p">Traffic overview</p>
                  <IoIosArrowForward />
                </div>
                <p className="sugg-p-small">Find out where your visitors are based, and how many of them are returning.</p>
              </div>
              <div className="sugg" onClick={()=>{navigate("/analytics/realtime-overview")}}>
                <div className="sugg-head">
                  <p className="sugg-p">Realtime overview</p>
                  <IoIosArrowForward />
                </div>
                <p className="sugg-p-small">Find out where your visitors are based, and how many of them are returning.</p>
              </div>
              <div className="sugg" onClick={()=>{navigate("/analytics/story-overview")}}>
                <div className="sugg-head">
                  <p className="sugg-p">Story overview</p>
                  <IoIosArrowForward />
                </div>
                <p className="sugg-p-small">See how visitors interact with your site's pages.</p>
              </div>
              <div className="sugg" style={{borderBottom:"none"}} onClick={()=>{navigate("/analytics/marketing-overview")}}>
                <div className="sugg-head">
                  <p className="sugg-p">Marketing overview</p>
                  <IoIosArrowForward />
                </div>
                <p className="sugg-p-small">Track key sales metrics and get insights on your customers.</p>
              </div>
            </div>
        </div>
      </div>

      <div className="story-top-main mb-30" style={{width:'67%'}}>
        <div className="left-behavior-bottom-graphs">
            <div className="top-nav-top">
              <p className="bo-head-p">Clicks to contact : 2</p>
            </div>
            <div className="graph-bottom">
              <p className="graph-p-small">Clicks by contact channel</p>
              <ReactApexChart
                options={ReturnVisitorGraph.options}
                series={ReturnVisitorGraph.series}
                type="donut"
                width={"100%"}
                height={"200px"}
              />
            </div>
          </div>
          <div className="left-behavior-bottom-graphs">
            <div className="top-nav-top">
              <p className="bo-head-p">
                New Leads : 16{" "}
                <span className="rise-bo-bottom">
                  <FaArrowUpLong /> 100%
                </span>
              </p>
            </div>
            <div className="graph-bottom">
              <p className="graph-p-small">Top lead sources</p>
              <ReactApexChart
                options={ReturnVisitorGraph.options}
                series={ReturnVisitorGraph.series}
                type="donut"
                width={"100%"}
                height={"200px"}
              />
            </div>
          </div>
      </div>
    </>
  );
};

export default BehaviorOverview;
