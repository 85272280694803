import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import "../../styles/common/StatusDrop.css";
import {updateStatus} from "../../api/Category"

const options = [
  { value: 'visible', label: 'Visible', color: '#2684FF',id:1 },
  { value: 'hidden', label: 'Hidden', color: '#ff4d4f',id:0 }
];

const customStyles = (selectedOption) => ({
  option: (provided, state) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: state.isSelected ? 'white' : 'black',
    backgroundColor: state.isSelected ? '#2684FF' : 'white',
    borderBottom: '1px solid #ccc',
    zIndex: '8',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#f0f4f7',
      color: 'black',
    },
  }),
  control: (provided, state) => {
    let backgroundColor;
    switch (selectedOption?.value) {
      case 'hidden':
        backgroundColor = '#ffeff0';
        break;
      case 'visible':
      default:
        backgroundColor = '#e6f4ff';
        break;
    }

    return {
      ...provided,
      backgroundColor,
      border: `1px solid ${backgroundColor}`,
      borderRadius: '5px',
      color: '#3b82f6 !important',
      fontSize: '12px',
      minHeight: '28px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '92px',
      boxShadow: state.isFocused ? 'none' : provided.boxShadow,
      ':hover': {
        borderColor: backgroundColor,
      },
    };
  },
  indicatorSeparator: (provided) => ({
    ...provided,
    width: 0,
  }),
  indicatorContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
  ValueContainer: (provided) => ({
    ...provided,
    padding: '0 !important', // Set padding to 0
  }),
  singleValue: (provided) => {
    let color;
    switch (selectedOption?.value) {
      case 'hidden':
        color = '#ff4d4f';
        break;
      case 'visible':
      default:
        color = '#3784f6';
        break;
    }

    return {
      ...provided,
      color,
      marginRight: '0px',
      marginLeft: '0px',
      maxWidth: '71px',
    };
  },
});

const CustomOption = (props) => (
  <components.Option {...props}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        style={{
          width: 20,
          height: 10,
          backgroundColor: props.data.color,
          border: '1px solid white',
          marginRight: 10,
        }}
      ></div>
      {props.data.label}
    </div>
  </components.Option>
);

function App({currentStatus,rowId,setIsUpdated}) {
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const handleStatus = ()=>{
    if(currentStatus==1){
      setSelectedOption(options[0]);
    }
    else if(currentStatus==0){
      setSelectedOption(options[1]);
    }
  }
  const handlevisi = async(option)=>{
    setSelectedOption(option);
    try{
      const payload = {status:option.id}
      await updateStatus(rowId,payload);
      setIsUpdated((prev)=>!prev);
    }
    catch(e){
      console.error(e)
    }
  }
  
  useEffect(()=>{
    handleStatus();
  },[currentStatus])

  return (
    <div>
    <Select
      styles={{
        ...customStyles(selectedOption),
        dropdownIndicator: (base) => ({
          ...base,
          padding: 0,
          width: "20px",
          paddingRight: "5px",
          color: selectedOption.color, // Dynamic color based on selected option
          "&:hover": {
            color: selectedOption.color, // Replace with your desired hover color
          },
        }),
      }}
      options={options}
      value={selectedOption}
      className="react-select-container"
      classNamePrefix="react-select"
      isSearchable={false}
      onChange={handlevisi}
      components={{ Option: CustomOption }}
    />
    </div>
  );
}

export default App;
