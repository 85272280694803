import React, { useState } from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import { IoInformationCircleOutline } from "react-icons/io5";
import { IoMdArrowDropright, IoMdArrowDropdown } from "react-icons/io";
import ImageDrop from "../setting/ImageDrop";

const Social = ({
  title,
  excerpt,
  images,
  ogImage,
  setOgImage,
  twitterImage,
  setTwitterImage,
  meta,
  handleMetaData,
}) => {
  const [xSetting, setXSetting] = useState(false);

  return (
    <div className="general-main-div">
      <div className="social-top">
        <p className="social-p-main">Social share</p>
        <p className="general-p-small">
          Open graph (og) tags are used by social networks like Facebook &
          Pinterest to display text and an image when this page is shared.
        </p>
      </div>

      <div className="social-prev-div">
        <p className="general-p-small">Preview on social</p>
        <p className="general-p-small color-primary mb-5">
          When will changes show live?
        </p>
        <ImageDrop
          selectedFiles={ogImage ?? images}
          setSelectedFiles={setOgImage}
        />
        {/* <div className="social-upload-main">
          <div className="social-upload">
            <button className="social-upload-btn">
              <MdOutlineFileUpload className="upload-logo" />{" "}
              <p>Upload Image</p>
            </button>
          </div>
          <div className="social-url-div">
            <p className="social-url">RMS.COM</p>
          </div>
        </div> */}
      </div>

      <div className="publish-date-div">
        <div className="featured-img-top">
          <div className="feature-logo">
            <p className="general-p-small">og:title</p>
            <IoInformationCircleOutline className="general-info-logo" />
          </div>
          <div className="basic-flex">
            <p className="basic-p-small">{meta?.og?.title?.length ?? 0}</p>
          </div>
        </div>
        <input
          type="text"
          name="og.title"
          className="general-input"
          value={meta?.og?.title ? meta?.og?.title : title ?? ""}
          onChange={handleMetaData}
          placeholder="Add the page's title"
        />
      </div>

      <div className="publish-date-div">
        <div className="featured-img-top">
          <div className="feature-logo">
            <p className="general-p-small">og:description</p>
            <IoInformationCircleOutline className="general-info-logo" />
          </div>
          <div className="basic-flex">
            <p className="basic-p-small">{meta?.og?.description?.length ?? 0}</p>
          </div>
        </div>
        <textarea
          name="og.description"
          className="general-input"
          value={meta?.og?.description ? meta?.og?.description : excerpt ?? ""}
          onChange={handleMetaData}
          placeholder="Add a description about this page"
        />
      </div>

      <div
        className="x-setting"
        onClick={() => {
          setXSetting(!xSetting);
        }}
      >
        <div className="feature-logo" style={{ fontSize: "16px" }}>
          <p className="">X Settings</p>
          <IoInformationCircleOutline className="general-info-logo" />
        </div>
        {!xSetting ? <IoMdArrowDropright /> : <IoMdArrowDropdown />}
      </div>

      {xSetting && (
        <div className="x-setting-main">
          <div className="social-prev-div">
            <p className="general-p-small">Preview on X</p>
            <p className="general-p-small color-primary mb-5">
              When will changes show live?
            </p>
            <ImageDrop
              selectedFiles={twitterImage ?? images}
              setSelectedFiles={setTwitterImage}
            />
            {/* <div className="social-upload-main">
              <div className="social-upload">
                <button className="social-upload-btn">
                  <MdOutlineFileUpload className="upload-logo" />{" "}
                  <p>Upload Image</p>
                </button>
              </div>
              <div className="social-url-div">
                <p className="social-url">RMS.COM</p>
              </div>
            </div> */}
          </div>

          <div className="card-size-social">
            <p>Select card size:</p>
            <div className="checkbox-div">
              <input
                type="radio"
                className="socialradio"
                name="twitter.card"
                value="summary_large_image"
                checked={
                  meta?.twitter?.card === "summary_large_image" ? true : true
                }
                onChange={handleMetaData}
              />
              <p>Large</p>
            </div>
            <div className="checkbox-div">
              <input
                type="radio"
                className="socialradio"
                name="twitter.card"
                value="summary"
                checked={meta?.twitter?.card === "summary" ? true : false}
                onChange={handleMetaData}
              />
              <p>Small</p>
            </div>
          </div>

          <div className="publish-date-div">
            <div className="featured-img-top">
              <div className="feature-logo">
                <p className="general-p-small">x:title</p>
                <IoInformationCircleOutline className="general-info-logo" />
              </div>
              <div className="basic-flex">
                <p className="basic-p-small">{meta?.twitter?.title?.length ?? 0}</p>
              </div>
            </div>
            <input
              type="text"
              name="twitter.title"
              className="general-input"
              value={meta?.twitter?.title ? meta?.twitter?.title : title ?? ""}
              onChange={handleMetaData}
              placeholder="Add the page's title"
            />
          </div>

          <div className="publish-date-div">
            <div className="featured-img-top">
              <div className="feature-logo">
                <p className="general-p-small">x:description</p>
                <IoInformationCircleOutline className="general-info-logo" />
              </div>
              <div className="basic-flex">
                <p className="basic-p-small">{meta?.twitter?.description?.length ?? 0}</p>
              </div>
            </div>
            <textarea
              name="twitter.description"
              className="general-input"
              value={
                meta?.twitter?.description
                  ? meta?.twitter?.description
                  : excerpt ?? ""
              }
              onChange={handleMetaData}
              placeholder="Add a description about this page"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Social;
