import React, { useEffect, useState, useRef, useCallback } from "react";
import { IoIosSearch } from "react-icons/io";
import { FiEye, FiEdit, FiTrash2, FiDownload } from "react-icons/fi";
import { FaSort } from "react-icons/fa";
import MoreOption from "../../components/stories/MoreOption";
import { RiApps2Line } from "react-icons/ri";
import { MdContentCopy } from "react-icons/md";
import Tabs from "../../components/stories/Tabs";
import { IoMdClose } from "react-icons/io";
import { getArticleCategoryList } from "../../api/Category";
import { CiUndo } from "react-icons/ci";
import ChipDrop from "../../components/stories/ChipDropFilter";
import { CiImageOn } from "react-icons/ci";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Drawer,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Slider,
  Divider,
  Menu,
  MenuItem,
  Button,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { FiEyeOff, FiPlus, FiX, FiChevronDown, FiFilter } from "react-icons/fi";
import style from "../../styles/common/TableStories.module.css";
import { useNavigate } from "react-router-dom";
import { getData } from "../../api/admin/Customers";
import { IoIosArrowDown } from "react-icons/io";
import { RiArticleFill } from "react-icons/ri";
import { BsFileRichtextFill } from "react-icons/bs";
import { formatDateAndTime } from "../../utils/Util";
import { MdOutlineRestore } from "react-icons/md";
import Statusdrop from "./statusDrop";
import { Const } from "../../utils/Constants";
import TagsChips from "../tags/TagsChips";
import MultipleSelectDropdown from "../fields/MultipleSelectDropdown";
// import useDebounce from "../../hooks/useDebounce"
import debounce from "lodash.debounce";

const Table = ({
  count,
  columns,
  rows,
  filter,
  tag,
  selection,
  rowLoading,
  setDeleteStories,
  setRestoreStory,
  tabSelected,
  setIsBtnLoading,
  isbtnLoading,
  selectedAuthors,
  isbtnLoadingExport,
  handleDublicate,
  setSelectedTags,
  author,
  handleEdit,
  handleExport,
  setSelectedAuthors,
  setIsUpdated,
  selectedTags,
  setTabSelected,
  setFilter,
  handleSelection,
  handleSearch,
  handleFilter,
  isFetching,
  setOffset,
  offset,
  setRowLoading,
}) => {
  const tableRef = useRef(null);
  const [open, setOpen] = useState({ right: false });
  const [category, setCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [expanded, setExpanded] = useState("panel1");
  // const debounce = useDebounce();

  useEffect(() => {
    fetchData();
  }, []);

  const handleScroll = useCallback(
    debounce(() => {
      const table = tableRef.current;
      if (!table || rowLoading) return;
      const { scrollTop, scrollHeight, clientHeight } = table;
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        // console.log(rows.length, offset,"count")
        if (rows.length >= count.allCount) {
          // console.log("STOP")
          setIsEnd(true);
          return;
        }

        setRowLoading(true);
        setOffset((prevOffset) => prevOffset + Const.Limit);
      }
    }, 200),
    [rows, count, rowLoading, setOffset, setRowLoading, setIsEnd]
  );

  useEffect(() => {
    const table = tableRef.current;
    if (table) {
      table.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (table) {
        table.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  

  const fetchData = async () => {
    try {
      const categoryRes = await getArticleCategoryList();
      if (categoryRes) {
        setCategory(categoryRes.data);
      }
    } catch (e) {
      console.error(e);
    } finally {
    }
  };

  const toggleDrawer = (anchor, newOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen({ ...open, [anchor]: newOpen });
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  
  const handleChangeAuthor = (event, newValue) => {
    setSelectedAuthors((prev) => {
      const selectedId = newValue?.props?.value || newValue;
      const updatedWriter = prev.includes(selectedId)
        ? prev.filter((tagId) => tagId !== selectedId)
        : [...prev, selectedId];
  
      setFilter((prevFilter) => ({ ...prevFilter, writer: updatedWriter }));
  
      return updatedWriter;
    });
  };


  const handleSave = async (event) => {
    // if (event.key === "Enter" && event.target.value) {
    //   event.preventDefault();
    //   const payload = { name: event.target.value };
    //   try {
    //     const res = await createTag(payload);
    //     if (res && res.data) {
    //       setTags((prev) => [...prev, res.data]);
    //       setSelectedTags((prev) => [...prev, res.data._id]);
    //     }
    //   } catch (error) {
    //     console.error("Error while saving tags", error);
    //   }
    // }
  };

  const handleFilterSave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsUpdated((prev) => !prev);
    toggleDrawer("right", false)({ type: "click" });
  };

  const handleTagClick = (id) => {
    setSelectedTags((prev) => {
      const updatedTags = prev.includes(id)
        ? prev.filter((tagId) => tagId !== id)
        : [...prev, id];

      setFilter((prevFilter) => ({ ...prevFilter, tag: updatedTags }));

      return updatedTags;
    });
  };

  return (
    <>
      <div className={style.tableCard}>
        <div className={style.tableFilterSec}>
          {selection.select_bulk || selection.selected.length > 0 ? (
            <>
              <div className={style.bulkSec}>
                <div className="d-flex align-items-center gap-3">
                  <span className={style.bulkcount}>
                    {`${
                      selection.select_bulk
                        ? count[`${tabSelected}Count`] -
                          selection.selected.length
                        : selection.selected.length
                    } of ${count[`${tabSelected}Count`]} selected`}
                  </span>
                </div>
                <div className={style.verticalDivider}></div>
                <div
                  className="d-flex align-items-center gap-3"
                  style={{ display: "flex", alignItems: "centre", gap: "10px" }}
                >
                  <button
                    className="btn-primary-outline-table"
                    onClick={handleExport}
                  >
                    {isbtnLoadingExport ? (
                      <div className="loader"></div>
                    ) : (
                      <>
                        <FiDownload /> Export
                      </>
                    )}
                  </button>
                  <button
                    className="btn-primary-outline-table"
                    onClick={handleDublicate}
                  >
                    {isbtnLoading ? (
                      <div className="loader"></div>
                    ) : (
                      <>
                        <MdContentCopy /> Duplicate
                      </>
                    )}
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={style.leftSec}>
                <Tabs
                  count={count}
                  setFilter={setFilter}
                  setTabSelected={setTabSelected}
                  setIsUpdated={setIsUpdated}
                />
              </div>
              <div className={style.rightSec}>
                <div className={style.searchInv}>
                  <IoIosSearch />
                  <input
                    placeholder="Search"
                    className={style.inputInv}
                    name="search"
                    value={filter?.search ?? ""}
                    onChange={handleSearch}
                  />
                </div>
                <React.Fragment key={"right"}>
                  {/* <Exportbtn2/> */}
                  <button
                    className="btn-primary-outline-table"
                    onClick={toggleDrawer("right", true)}
                  >
                    <FiFilter /> Filter
                  </button>
                  {/* Filter Drawer */}
                  <Drawer
                    anchor={"right"}
                    open={open["right"]}
                    onClose={toggleDrawer("right", false)}
                  >
                    <Box sx={{ width: 400 }} role="presentation">
                      <div className={style.filterDrawer}>
                        <div className={style.headSec}>
                          <h2 className={style.heading}>Filter your stories</h2>
                          <FiX
                            className={style.close}
                            onClick={toggleDrawer("right", false)}
                          />
                        </div>
                        <div className={style.mainSec}>
                          <Accordion
                            className={style.accordion}
                            disableGutters
                            expanded={expanded === "panel1"}
                            onChange={handleChange("panel1")}
                          >
                            <AccordionSummary
                              expandIcon={<FiChevronDown />}
                              className={style.accordionBtn}
                              aria-controls="panel4-content"
                              id="panel4-header"
                            >
                              Published Time
                            </AccordionSummary>
                            <AccordionDetails>
                              <select
                                style={{
                                  border: "1px solid rgb(193, 228, 254)",
                                  height: "45px",
                                }}
                                name="publishDate"
                                value={filter?.publishDate ?? ""}
                                onChange={(e) => handleFilter(e)}
                              >
                                <option value={""}>Select</option>
                                <option value={"lastWeek"}> Last 1 Week</option>
                                <option value={"last3Months"}>
                                  {" "}
                                  Last 3 Months
                                </option>
                                <option value={"last6Months"}>
                                  {" "}
                                  Last 6 Months
                                </option>
                                <option value={"last12Months"}>
                                  {" "}
                                  Last 12 Months
                                </option>
                              </select>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            className={style.accordion}
                            expanded={expanded === "panel2"}
                            onChange={handleChange("panel2")}
                            disableGutters
                          >
                            <AccordionSummary
                              expandIcon={<FiChevronDown />}
                              aria-controls="panel4-content"
                              id="panel4-header"
                            >
                              Tags
                            </AccordionSummary>
                            <AccordionDetails>
                              <TagsChips
                                tag={tag}
                                showAll={showAll}
                                setShowAll={setShowAll}
                                selectedTags={selectedTags}
                                handleTagClick={handleTagClick}
                              />
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            expanded={expanded === "panel3"}
                            onChange={handleChange("panel3")}
                            className={style.accordion}
                            disableGutters
                          >
                            <AccordionSummary
                              expandIcon={<FiChevronDown />}
                              aria-controls="panel4-content"
                              id="panel4-header"
                            >
                              Authors
                            </AccordionSummary>
                            <AccordionDetails>
                              {/* <ChipDrop
                                option={author}
                                selectedOptions={selectedAuthors}
                                handleOnChange={handleChangeAuthor}
                                handleSave={handleSave}
                              /> */}
                              <MultipleSelectDropdown
                                data={author}
                                name={"writer"}
                                placeholder="Select Author"
                                selected={selectedAuthors ?? []}
                                handleOnChange={(event, newValue) => handleChangeAuthor(event, newValue)}
                              />
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            expanded={expanded === "panel4"}
                            onChange={handleChange("panel4")}
                            className={style.accordion}
                            disableGutters
                          >
                            <AccordionSummary
                              expandIcon={<FiChevronDown />}
                              className={style.accordionBtn}
                              aria-controls="panel4-content"
                              id="panel4-header"
                            >
                              Category
                            </AccordionSummary>
                            <AccordionDetails>
                              {category.map((el, i) => {
                                return (
                                  <div>
                                    <p className={style.catnamep}>{el.name}</p>
                                    <div className={style.catsubcatdiv}>
                                      {el.subcategory.map((oo, k) => (
                                        <div className={style.subcatdiv}>
                                          <input
                                            type="checkbox"
                                            name="category"
                                            value={oo._id}
                                            checked={
                                              filter?.category?.includes(
                                                oo._id
                                              ) ?? false
                                            }
                                            onChange={(e) => handleFilter(e)}
                                          />
                                          <p>{oo.name}</p>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                );
                              })}
                            </AccordionDetails>
                          </Accordion>
                        </div>
                        <div className={style.footerSec}>
                          <button
                            className="btn-primary-outline"
                            onClick={toggleDrawer("right", false)}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn-primary"
                            onClick={handleFilterSave}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </Box>
                  </Drawer>
                </React.Fragment>
              </div>
            </>
          )}
        </div>
        <div
          ref={tableRef} // Attach ref to track the table's scroll position
          style={{ height: "100%", overflowY: "auto" }}
        >
          <table className={`${style.table} ${style.stories}`}>
            <thead>
              <tr>
                <th className={style.checktr}>
                  <input
                    type="checkbox"
                    className={style.formCheckbox}
                    id="selection"
                    checked={selection.select_bulk}
                    onChange={handleSelection}
                  />
                </th>
                {columns?.map((items) => {
                  return (
                    <>
                      <th>
                        <span role="button">
                          {items} <FaSort className={style.sortIcon} />
                        </span>
                      </th>
                    </>
                  );
                })}
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="table-of">
              {rows && rows.length > 0 ? (
                <>
                  {rows.map((items, i) => {
                    if (items.status != 2) {
                      return (
                        <tr className={style.roleBtn}>
                          <td className={style.checktr}>
                            <input
                              type="checkbox"
                              className={style.formCheckbox}
                              id={items._id}
                              checked={
                                selection.select_bulk || selection.select_bulk
                                  ? !selection.selected.includes(items._id)
                                    ? true
                                    : false
                                  : selection.selected.includes(items._id)
                                  ? true
                                  : false
                              }
                              onChange={handleSelection}
                            />
                          </td>
                          <td>
                            <div className={style.storiesdiv}>
                              {items && items.coverImg ? (
                                <div className={style.imagestorycontainer}>
                                  <img
                                    className={style.storiesimg}
                                    src={items.coverImg}
                                    alt="Cover Image"
                                  />
                                  {/* <div className={style.playbtndiv}>
                                  <BsFileRichtextFill
                                    className={style.playbtnvideo}
                                  />
                                </div> */}
                                </div>
                              ) : (
                                <div className={style.storiesimg}>
                                  <div className={style.storiesimgcircle}>
                                    <CiImageOn
                                      className={style.storiesimglogo}
                                    />
                                  </div>
                                </div>
                              )}
                              <div>
                                <p className={style.storiesp}>
                                  {items && items.title ? items.title : ""}
                                </p>
                                <p className={style.storiespsmall}>{`${
                                  items && items.timestamp
                                    ? formatDateAndTime(items.timestamp)
                                    : ""
                                } . ${
                                  items && items.author ? items.author : ""
                                }`}</p>
                              </div>
                            </div>
                          </td>
                          <td className={style.catp}>
                            {items && items.category ? items.category : ""}
                          </td>
                          <td>
                            <p className={style.tagsClamp}>
                              {items && items.tag && items.tag.length > 0
                                ? items.tag.join(", ")
                                : ""}
                            </p>
                          </td>
                          <td>
                            {items.status == 2 ? (
                              <div className={style.trashdiv}>Trash</div>
                            ) : (
                              <Statusdrop
                                rowId={items._id}
                                pubDate={items.publishDate}
                                currentStatus={items.status}
                                setIsUpdated={setIsUpdated}
                              />
                            )}
                          </td>
                          <td>
                            <div className={style.actions}>
                              <button className={style.items}>
                                <a
                                  href={`${Const.ClientLink}${
                                    items && items.viewLink
                                      ? items.viewLink
                                      : ""
                                  }`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100%",
                                    width: "100%",
                                  }}
                                >
                                  <FiEye />
                                </a>
                              </button>
                              {items.status == 2 ? (
                                <></>
                              ) : (
                                <button
                                  className={style.items}
                                  onClick={() => handleEdit(true, items._id)}
                                >
                                  <FiEdit />
                                </button>
                              )}

                              {items.status == 2 ? (
                                <button
                                  className={style.items}
                                  onClick={() => {
                                    setRestoreStory(items._id);
                                  }}
                                >
                                  <MdOutlineRestore />
                                </button>
                              ) : (
                                <button
                                  className={style.items}
                                  onClick={() => {
                                    setDeleteStories(items._id);
                                  }}
                                >
                                  <FiTrash2 />
                                </button>
                              )}
                              {/* <MoreOption
                            setDeleteStories={setDeleteStories}
                            id={items._id}
                          /> */}
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  })}
                </>
              ) : (
                <>
                  {rowLoading ? (
                    <>
                      <div className="text-center">Loading...</div>
                    </>
                  ) : (
                    <>
                      <div className="text-center">Stories Not Found</div>
                    </>
                  )}
                </>
              )}
            </tbody>
          </table>
          {rowLoading && !isEnd && <p>Loading....</p>}
        </div>
      </div>
      {/* <button onClick={()=>{setOffset((prev)=>prev+Const.Limit)}}>Next</button> */}
    </>
  );
};

export default Table;
