import { Const } from "../utils/Constants";

export default function Headers(method, body, isFormData, signal) {
  const auth = localStorage.getItem(Const.Token);
  const session = localStorage.getItem(Const.Session);
  this.method = method;
  this.headers = {
    "Access-Control-Allow-Origin": "*",
    ...(isFormData ? {} : { "Content-Type": "application/json" }),
  };
  if (auth) {
    this.headers.authtoken = auth;
  }
  if (session) {
    this.headers.session = session;
  }
  if (body) {
    this.body = isFormData ? body : JSON.stringify(body);
  }
  if (signal) {
    this.signal = signal;
  }
}
