import React from "react";
import { IoIosRadioButtonOff } from "react-icons/io";
import { CiImageOn, CiVideoOn, CiPlay1, CiViewTable } from "react-icons/ci";
import { PiSparkleThin, PiUploadSimpleThin, PiCodeThin } from "react-icons/pi";
import { SlFrame } from "react-icons/sl";
import Divider from "../../../assets/svg/Divider";
import List from "../../../assets/svg/List";
import Poll from "../../../assets/svg/Poll";

const Add = ({ editor, setEmbedOpen }) => {
  const adds = [
    {
      name: "Media",
      elements: [
        {
          name: "Image",
          target: "image",
          svg: <CiImageOn />,
          function: () => editor.chain().focus().setImageUpload().run(),
        },
        {
          name: "Gallery",
          target: "",
          svg: <CiImageOn />,
          function: () => console.log("Gallery"),
        },
        {
          name: "Video",
          target: "video",
          svg: <CiVideoOn />,
          function: () => editor.chain().focus().setVideoUpload().run(),
        },
        {
          name: "GIF",
          target: "",
          svg: <CiPlay1 />,
          function: () => console.log("GIF"),
        },
        {
          name: "File",
          target: "",
          svg: <PiUploadSimpleThin />,
          function: () => console.log("File"),
        },
        {
          name: "Embed",
          target: "",
          svg: <SlFrame />,
          function: () => setEmbedOpen(true),
        },
      ],
    },
    {
      name: "Elements",
      elements: [
        {
          name: "Divider",
          target: "",
          svg: <Divider />,
          function: () => editor.chain().focus().setHorizontalRule().run(),
        },
        {
          name: "Button",
          target: "",
          svg: <IoIosRadioButtonOff />,
          function: () => console.log("Button"),
        },
        {
          name: "Table",
          target: "table",
          svg: <CiViewTable />,
          function: () => console.log("Table"),
        },
        {
          name: "Expandable List",
          target: "",
          svg: <List />,
          function: () => console.log("Expandable List"),
        },
        {
          name: "Poll",
          target: "",
          svg: <Poll />,
          function: () => console.log("Poll"),
        },
      ],
    },
    {
      name: "From the web",
      elements: [
        {
          name: "HTML code",
          target: "",
          svg: <PiCodeThin />,
        },
      ],
    },
  ];
  const handleSelectElement = (element) => {
    if (editor && editor.current && element) {
      editor.current.blocks.insert(element);
    }
  };

  return (
    <>
      <div className="ad-blocks-cont">
        {adds.map((add, i) => {
          return (
            <div className="adb-block" key={i}>
              <div className="adb-name">{add.name}</div>
              <div className="adb-elms">
                {add.elements?.map((elm, index) => {
                  return (
                    <div className="adb-elm" onClick={elm.function}>
                      <div className="adb-elm-svg">{elm.svg}</div>
                      <div className="adb-elm-name">{elm.name}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        <div className="add-blocks">
          <div className="add-blocks-title"></div>
          <div className="add-blocks-elms"></div>
        </div>
        <div className="add-blocks"></div>
        <div className="add-blocks"></div>
      </div>
    </>
  );
};

export default Add;
