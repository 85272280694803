import { ReactNodeViewRenderer } from "@tiptap/react";
import { mergeAttributes, Node } from "@tiptap/core";
import EmbedBlockView from "./components/EmbedBlockView";
import { getEmbedTypeAndUrl } from "./components/hooks";

export const Embed = Node.create({
  name: "embed",

  group: "block",

  draggable: true,

  selectable: true,

  addAttributes() {
    return {
      embed: {
        default: "",
        parseHTML: (element) =>
          element.querySelector("iframe")?.getAttribute("src") || "",
        renderHTML: (attributes) => ({
          "embed-url": attributes.embed,
        }),
      },
      embedFrame: {
        default: "",
        parseHTML: (element) =>
          element.querySelector("iframe")?.getAttribute("src") || "",
        renderHTML: (attributes) => {
          return {
            tag: "iframe",
            attrs: {
              src: attributes.embedFrame,
              frameborder: "0",
              allowfullscreen: true,
              style: `width:100%; height:100%;`,
            },
          };
        },
      },
      aspectRatio: {
        default: "",
        parseHTML: (element) => element.getAttribute("embed-ratio") || "",
        renderHTML: (attributes) => ({
          "embed-ratio": attributes.aspectRatio,
        }),
      },
      width: {
        default: "100%",
        parseHTML: (element) => element.getAttribute("data-width") || "100%",
        renderHTML: (attributes) => ({
          "data-width": attributes.width || "100%",
        }),
      },
      align: {
        default: "center",
        parseHTML: (element) => element.getAttribute("data-align") || "center",
        renderHTML: (attributes) => ({
          "data-align": attributes.align || "center",
        }),
      },
      caption: {
        default: "",
        parseHTML: (element) => element.getAttribute("data-caption"),
        renderHTML: (attributes) => ({
          "data-caption": attributes.caption,
        }),
      },
      courtesy: {
        default: "",
        parseHTML: (element) => element.getAttribute("data-courtesy"),
        renderHTML: (attributes) => ({
          "data-courtesy": attributes.courtesy,
        }),
      },
      alt: {
        default: "",
        parseHTML: (element) => element.getAttribute("data-alt"),
        renderHTML: (attributes) => ({
          "data-alt": attributes.alt,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-type="embed"]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "div",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      [
        "iframe",
        {
          src: HTMLAttributes.embed,
          style: `width: ${HTMLAttributes.width || "100%"}; height: 315px;`,
          allowfullscreen: "true",
          frameborder: "0",
        },
      ],
    ];
  },

  addCommands() {
    return {
      setEmbed:
        (attrs) =>
        ({ commands }) => {
          const { type, embedUrl, embedFrame, aspectRatio } =
            getEmbedTypeAndUrl(attrs.embed);
          if (type && embedUrl) {
            return commands.insertContent({
              type: "embed",
              attrs: {
                embed: embedUrl,
                embedFrame: embedFrame,
                aspectRatio: aspectRatio,
                width: attrs.width || "100%",
                align: attrs.align || "center",
              },
            });
          }
          return false; // Invalid URL
        },

      setEmbedAt:
        (attrs) =>
        ({ commands }) => {
          const { type, embedUrl, embedFrame, aspectRatio } =
            getEmbedTypeAndUrl(attrs.embed);
          if (type && embedUrl) {
            return commands.insertContentAt(attrs.pos, {
              type: "embed",
              attrs: {
                embed: embedUrl,
                embedFrame: embedFrame,
                aspectRatio: aspectRatio,
                width: attrs.width || "100%",
                align: attrs.align || "center",
              },
            });
          }
          return false; // Invalid URL
        },

      setEmbedAlign:
        (align) =>
        ({ commands }) =>
          commands.updateAttributes("embed", { align }),

      setEmbedWidth:
        (width) =>
        ({ commands }) =>
          commands.updateAttributes("embed", {
            width: `${Math.max(0, Math.min(100, width))}%`,
          }),
      setEmbedCaption:
        (caption) =>
        ({ commands }) =>
          commands.updateAttributes("embed", { caption }),

      setEmbedCourtesy:
        (courtesy) =>
        ({ commands }) =>
          commands.updateAttributes("embed", { courtesy }),
      setEmbedAlt:
        (alt) =>
        ({ commands }) =>
          commands.updateAttributes("embed", { alt }),
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(EmbedBlockView);
  },
});

export default Embed;
