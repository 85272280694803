import React, { useState } from "react";
import { IoInformationCircleOutline } from "react-icons/io5";
import ChipDrop from "./ChipDrop";
import { createTag } from "../../../api/Tags";
import TagsChips from "../../tags/TagsChipsFilter";
import { Const } from "../../../utils/Constants";

const Tags = ({ tags, setTags, selectedTags, setSelectedTags,filterText, setFilterText, setOffset, tagCount }) => {
  const maxLimit = 30;
  const [selectLimit, setSelectLimit] = useState(0);
  const [showAll, setShowAll] = useState(false);


  const handleOnChange = (event, value) => {
    if (value.length <= maxLimit) {
      const selectedIds = value.map((item) => item.id);
      setFilterText("")
      if (!selectedIds.includes(undefined)) {
        setSelectedTags(selectedIds);
        setSelectLimit(selectedIds.length);
      }
    }
  };

  const handleInputChange = (e) => {
    setFilterText(e.target.value);
    setOffset(0)
  };

  const handleTagClick = (id) => {
    setSelectedTags((prev) => {
      const updatedTags = prev.includes(id)
        ? prev.filter((tagId) => tagId !== id)
        : [...prev, id];
      return updatedTags;
    });
  };

  const loadMore = ()=>{
    setOffset((prev)=>prev+Const.Limit);
  }

  const handleSave = async (event) => {
    if (event.key === "Enter" && event.target.value) {
      event.preventDefault();
      const payload = { name: event.target.value };
      try {
        const res = await createTag(payload);
        if (res && res.data) {
          setTags((prev) => [...prev, res.data]);
          setSelectedTags((prev) => [...prev, res.data._id]);
        }
      } catch (error) {
        console.error("Error while saving tags", error);
      }
    }
  };

  return (
    <div className="general-main-div">
      <div>
        <p className="categories-p">
          Create and assign tags to help readers find the blog posts they’re
          looking for.
        </p>
      </div>

      <div className="publish-date-div">
        <div className="featured-img-top">
          <div className="feature-logo">
            <p className="general-p-small">Create a new tag</p>
            <IoInformationCircleOutline className="general-info-logo" />
          </div>
          {/* <p className="general-p-small">{`${selectLimit}/${maxLimit}`}</p> */}
        </div>
        <div className="chips-tags-addedit">
          <ChipDrop
            option={tags}
            selectedOptions={selectedTags}
            handleOnChange={handleOnChange}
            handleSave={handleSave}
            handleInputChange={handleInputChange}
          />
        </div>
        <p className="categories-p">
          Suggested Tags
        </p>
        <TagsChips
          tag={tags}
          showAll={showAll}
          setShowAll={setShowAll}
          selectedTags={selectedTags}
          handleTagClick={handleTagClick}
          filterText={filterText}
          loadMore={loadMore}
          tagCount={tagCount}
        />
      </div>
    </div>
  );
};

export default Tags;
