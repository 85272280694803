import React, { useEffect, useRef, useState } from 'react';
import { Draggable } from 'gsap/dist/Draggable';
import { gsap } from 'gsap';

// Default SortableJS
// import Sortable from 'sortablejs';

// Core SortableJS (without default plugins)
import Sortable from 'sortablejs/modular/sortable.core.esm.js';

// Complete SortableJS (with all plugins)
// import Sortable from 'sortablejs/modular/sortable.complete.esm.js';



const Dnd = () => {

useEffect(() => {
    
    var el = document.getElementById('ul');
    var sortable = Sortable.create(el);
}, [])

  
    let items = ['1', '2', '3', '4', '5']

    return (
        <ul style={{ listStyle: 'none', padding: 0 }} id='ul'>
            {items.map((item, index) => (
                <li key={item} data-id={item} data-index={index} style={{ padding: '10px', border: '1px solid #ccc', marginBottom: '5px', cursor: 'grab' }}>
                    {item}
                </li>
            ))}
        </ul>
    )
}

export default Dnd