import React from "react";

const Setting = () => {
  return (
    <svg
      width={19}
      height={19}
      viewBox="0 0 19 19"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Settings Icon"
      role="img"
    >
      <path d="M9.5 7A2.5 2.5 0 1 0 12 9.5 2.5 2.5 0 0 0 9.5 7zm0 4A1.5 1.5 0 1 1 11 9.5 1.5 1.5 0 0 1 9.5 11z" />
      <path d="M17 8.66a.49.49 0 0 0-.48-.37 1.66 1.66 0 0 1-1.13-2.88.41.41 0 0 0 0-.56 7.4 7.4 0 0 0-1.19-1.2.42.42 0 0 0-.57 0 1.72 1.72 0 0 1-1.87.42 1.66 1.66 0 0 1-1-1.63.42.42 0 0 0-.39-.44 7.55 7.55 0 0 0-1.68 0 .42.42 0 0 0-.37.43 1.67 1.67 0 0 1-1 1.6 1.73 1.73 0 0 1-1.86-.42.42.42 0 0 0-.56 0 7.45 7.45 0 0 0-1.21 1.2.41.41 0 0 0 0 .57 1.66 1.66 0 0 1 .42 1.87 1.73 1.73 0 0 1-1.63 1 .41.41 0 0 0-.48.38 7.61 7.61 0 0 0 0 1.71.5.5 0 0 0 .49.37 1.64 1.64 0 0 1 1.54 1 1.67 1.67 0 0 1-.42 1.85.42.42 0 0 0 0 .56 7.45 7.45 0 0 0 1.19 1.2.42.42 0 0 0 .57 0 1.72 1.72 0 0 1 1.87-.42 1.66 1.66 0 0 1 1 1.63.42.42 0 0 0 .37.44 7.79 7.79 0 0 0 .86 0 7.55 7.55 0 0 0 .82 0 .42.42 0 0 0 .38-.43 1.67 1.67 0 0 1 1-1.6 1.73 1.73 0 0 1 1.86.42.42.42 0 0 0 .56 0 7.39 7.39 0 0 0 1.21-1.2.42.42 0 0 0 0-.57 1.66 1.66 0 0 1 1.11-2.89h.09a.42.42 0 0 0 .44-.37A7.52 7.52 0 0 0 17 8.66zm-1 1.12a2.64 2.64 0 0 0-2.06 1.66 2.67 2.67 0 0 0 .32 2.5c-.12.13-.24.25-.37.37a2.72 2.72 0 0 0-2.53-.31 2.71 2.71 0 0 0-1.63 2h-.5a2.67 2.67 0 0 0-1.59-2 2.79 2.79 0 0 0-1-.19 2.74 2.74 0 0 0-1.56.49l-.35-.36A2.69 2.69 0 0 0 5 11.36a2.63 2.63 0 0 0-2-1.62v-.53a2.73 2.73 0 0 0 2-1.55 2.69 2.69 0 0 0-.28-2.6l.37-.37a2.74 2.74 0 0 0 1.56.49A2.61 2.61 0 0 0 9.27 3h.5a2.67 2.67 0 0 0 1.59 2 2.72 2.72 0 0 0 2.57-.29c.12.11.24.23.35.36A2.68 2.68 0 0 0 14 7.61a2.64 2.64 0 0 0 2 1.65v.52z" />
    </svg>
  );
};

export default Setting;
