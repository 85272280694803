import React from "react";

const Poll = () => {
  return (
    <svg
      width={36}
      height={36}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x={10} y={13} width="1.5" height={14} rx="0.5" fill="#162D3D" />
      <rect x="14.125" y={10} width="1.5" height={17} rx="0.5" fill="#162D3D" />
      <rect x="18.25" y={13} width="1.5" height={14} rx="0.5" fill="#162D3D" />
      <rect x="22.375" y={18} width="1.5" height={9} rx="0.5" fill="#162D3D" />
      <rect x="26.5" y={13} width="1.5" height={14} rx="0.5" fill="#162D3D" />
      <path
        d="M7.5 28.5306H29.5C29.7761 28.5306 30 28.7499 30 29.0204V29.5102C30 29.7807 29.7761 30 29.5 30H6.5C6.22386 30 6 29.7807 6 29.5102V6.4898C6 6.21929 6.22386 6 6.5 6H7C7.27614 6 7.5 6.21929 7.5 6.4898V28.5306Z"
        fill="#162D3D"
      />
    </svg>
  );
};

export default Poll;
