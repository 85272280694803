import Headers from '../Headers';
import { ProcessAPI, Const } from '../../utils/Constants';

// Get Product List
export const getTrafficAnalytics = async (body) => {
    const res = await fetch(Const.Link + "api/analytics/traffic", new Headers("POST", body));
    return ProcessAPI(res);
}

export const getSalesAnalytics = async (body) => {
    const res = await fetch(Const.Link + "api/analytics/sales", new Headers("POST", body));
    return ProcessAPI(res);
}

