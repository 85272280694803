import React, { useState } from "react";
import { GoSearch } from "react-icons/go";
import { PiMagicWandLight } from "react-icons/pi";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { HiOutlineLightBulb } from "react-icons/hi";
import { MdOutlineDone } from "react-icons/md";
import { AiOutlineExclamation } from "react-icons/ai";
import { FaGoogle } from "react-icons/fa";

const Assistant = () => {
  const [bestPractices, setBestPractices] = useState(false);
  return (
    <div className="general-main-div">
      <div className="assis-head-div">
        <div className="assis-head-logo-name">
          <GoSearch />
          <div className="assis-hori"></div>
          <p className="assis-p-main">SEO Assistant</p>
        </div>
        <p className="assis-p-head">
          Follow tasks to optimize this post for search engines and visitors
        </p>
      </div>

      <div className="assis-focus-div">
        <p className="assis-p-main">Focus keyword</p>
        <p className="assis-p-small">
          You'll get more tasks based on this keyword phrase.
        </p>
        <input
          placeholder="eg., carrot cake recipe, top tech trends"
          className="assis-focus-input"
        ></input>
        <div
          className={
            bestPractices
              ? "assis-best-practice assis-expand"
              : "assis-best-practice"
          }
          onClick={() => {
            setBestPractices(!bestPractices);
          }}
        >
          <div className="assis-best-top">
            <p className="assis-best-p">Best practices</p>
            {!bestPractices ? <MdKeyboardArrowDown /> : <MdKeyboardArrowUp />}
          </div>
          {bestPractices && (
            <>
              <div className="assis-best-logo-name">
                <HiOutlineLightBulb />
                <p className="assis-best-p-small">
                  Make it 2-5 words long, like “10 best fashion rain boots”
                </p>
              </div>
              <div className="assis-best-logo-name">
                <HiOutlineLightBulb />
                <p className="assis-best-p-small">
                  Choose a phrase that describes the topic of this post and can
                  be used in the title tag, meta description and content
                </p>
              </div>
            </>
          )}
        </div>
        <div className="assis-focus-btns">
          <button className="btn-xsm btn-primary-outline">Cancle</button>
          <button className="btn-xsm btn-primary">Save</button>
        </div>
      </div>

      <div className="assis-critical-main">
        <div className="assis-critical">
          <div className="assis-critical-logo-name">
            <div className="assis-tick">
              <MdOutlineDone className="assis-tick-logo" />
            </div>
            <div>
              <p className="assis-criticle-p">Allow this post to get indexed</p>
              <p className="assis-criticle-p">(may appear in search results)</p>
            </div>
          </div>

          <div className="critical-logo">
            <div className="assis-status">
              <p className="critical-p">CRITICAL</p>
            </div>
            <MdKeyboardArrowDown />
          </div>
        </div>

        <div className="assis-critical">
          <div className="assis-critical-logo-name">
            <div className="assis-tick2">
              <AiOutlineExclamation className="assis-tick-logo" />
            </div>
            <div>
              <p className="assis-criticle-p">
                Add an image or video to this post
              </p>
            </div>
          </div>

          <div className="critical-logo">
            <div className="assis-status">
              <p className="critical-p">HIGH</p>
            </div>
            <MdKeyboardArrowDown />
          </div>
        </div>

        <div className="assis-critical">
          <div className="assis-critical-logo-name">
            <div className="assis-tick2">
              <AiOutlineExclamation className="assis-tick-logo" />
            </div>
            <div>
              <p className="assis-criticle-p">Write meta description</p>
            </div>
          </div>

          <div className="critical-logo">
            <div className="assis-status-low">
              <p className="critical-p">LOW</p>
            </div>
            <MdKeyboardArrowDown />
          </div>
        </div>

        <div className="assis-critical closeit">
          <div className="assis-critical-logo-name">
            <div className="assis-tick">
              <MdOutlineDone className="assis-tick-logo" />
            </div>
            <div>
              <p className="assis-criticle-p">
                Include markup to be eligible for rich results
              </p>
            </div>
          </div>

          <div className="critical-logo">
            <div className="assis-status-low">
              <p className="critical-p">LOW</p>
            </div>
            <MdKeyboardArrowDown />
          </div>
        </div>
      </div>

      <p className="post-p">Posts Insights</p>

      <div className="assis-post">
        <div className="assis-critical-logo-name">
          <FaGoogle className="google" />
          <div>
            <p className="assis-criticle-p">
              Get the index status for this post
            </p>
            <p className="assis-criticle-p-small">
              Publish your post, then come back here to inspect your site and
              get the index status on Google.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Assistant;
