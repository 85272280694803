import React, { useState, useEffect } from "react";
import "../../styles/category/addCategory.css";
import { useNavigate, useLocation } from "react-router-dom";
import { GoPlus } from "react-icons/go";
import * as Yup from "yup";
import style from "../../styles/common/DeletePopup.module.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IoMdGlobe } from "react-icons/io";
import SEOCategories from "../../components/categories/SEOCategories";
import { MdArrowForwardIos } from "react-icons/md";
import { IoInformationCircleOutline } from "react-icons/io5";
import { generateSlug, generateSlugStories } from "../../utils/Util";
import {
  getListTag,
  createTag,
  deleteTags,
  updateTag,
  tagEdit,
} from "../../api/Tags";

const AddCategory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setLoading] = useState(true);
  const [input, setInput] = useState({});
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [nameLength, setNameLength] = useState(0);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [meta, setMeta] = useState({});
  const [isCanonical, setIsCanonical] = useState(false);
  const MAX_NAME_LENGTH = 25;

  const getId = location.pathname.split("/")[3];

  useEffect(() => {
    if (getId) {
      setIsEdit(true);
      fetchData();
    }
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Tag Name is required")
      .max(
        MAX_NAME_LENGTH,
        `Tag Name cannot exceed ${MAX_NAME_LENGTH} characters`
      )
      .matches(/^[a-zA-Z0-9 ]*$/, "Please enter a valid tag name"),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
    },
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm(formOptions);

  const fetchData = async () => {
    try {
      const res = await tagEdit(getId);
      if (res.data) {
        const { name, slug, meta } = res.data;
        setInput({
          name,
          slug: slug?.split("/")[1],
        });
        setSelectedKeywords(res.data.meta.keywords);
        setMeta({
          title: res.data.meta.title,
          description: res.data.meta.description,
          ...(res.data.meta.canonical
            ? { canonical: res.data.meta.canonical }
            : {}),
        });
      }
    } catch (e) {
      console.log("Error while fetching inputs", e);
    }
  };

  const handleMetaData = (e) => {
    const { name, value, checked } = e.target;
    if (name === "slug") {
      setInput((prev) => ({ ...prev, [name]: generateSlugStories(value) }));
    } else {
      setMeta((prev) => {
        const newMeta = { ...prev };
        if (value === undefined) {
          delete newMeta[name];
          return newMeta;
        }
        if (name === "robots") {
          newMeta[name] = checked ? "index,follow" : "noindex,nofollow";
          return newMeta;
        }
        newMeta[name] = value;
        return newMeta;
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      setInput((prev) => ({ ...prev, slug: generateSlugStories(value) }));
    }
    setInput((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    setIsBtnLoading(true);
    try {
      const payload = {
        ...input,
        slug: `/${input?.slug}` ?? "",
        meta: { ...meta, keywords: selectedKeywords },
      };
      await createTag(payload);
    } catch (e) {
      console.error("Error while saving tag", e);
    } finally {
      setIsBtnLoading(false);
      navigate("/tags");
    }
  };

  const handleUpdate = async () => {
    setIsBtnLoading(true);
    try {
      const payload = {
        ...input,
        slug: `/${input?.slug}` ?? "",
        meta: { ...meta, keywords: selectedKeywords },
      };
      await updateTag(getId, payload);
    } catch (e) {
      console.error("Error while updating tag", e);
    } finally {
      setIsBtnLoading(false);
      navigate("/tags");
    }
  };

  return (
    <>
      <div className="head-of-head writer-top">
        <div className="table-name">
          <span className="menu-nav" onClick={() => navigate("/tags")}>
            Tags
          </span>{" "}
          <MdArrowForwardIos className="menu-nav-arrow" style={{marginInline:'5px'}} />{" "}
          <span className="active-p-menu">
            {input.name ? input.name : "Untitled Tag"}
          </span>
        </div>
        <div className="fixed-right">
          <button
            className="btn-primary-outline"
            onClick={() => navigate("/tags")}
          >
            Cancel
          </button>
          <button
            className="btn-primary"
            style={{ width: "80px" }}
            onClick={handleSubmit(isEdit ? handleUpdate : handleSave)}
          >
            {isBtnLoading ? <div className="loader"></div> : "Save"}
          </button>
        </div>
      </div>
      <p className="p-top-writer">
        Set the SEO and social sharing images and help readers navigate your
        story. <span className="primarycolor">Learn More</span>
      </p>

      <div className="cat-main-div">
        <div className="cat-left-div">
          <div className="profile-top-div">
            <p className="profile-main-head">{isEdit ? "Edit" : "Add"} tag</p>
          </div>

          <div
            className="profile-top-div flex-cut"
            style={{ borderBottom: "none" }}
          >
            <div className="left-addcat">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <p className="small-p-pro">Tag Name* </p>
                <p className="small-p-pro">
                  {nameLength} / {MAX_NAME_LENGTH}
                </p>
              </div>
              <input
                {...register("name")}
                placeholder="e.g., Bruch-spots, How-tos, Technology"
                className={`cat-input ${
                  errors.name ? "error-validation-input" : "mb-25"
                }`}
                value={input?.name || ""}
                name="name"
                maxLength={MAX_NAME_LENGTH}
                onChange={(e) => {
                  handleInputChange(e);
                }}
              />
              {errors.name && (
                <p className="small-p-pro error-validation mb-25">
                  {errors.name.message}
                </p>
              )}
              <p className="small-p-pro">
                <span>Description </span>
                <IoInformationCircleOutline className="primarycolor" />
              </p>
              {/* <p className={style.slug}>/slug{input?.slug || ""}</p> */}
              <textarea className={`cat-input`}></textarea>
            </div>
          </div>
        </div>

        <div className="cat-right-div">
          <SEOCategories
            handleMetaData={handleMetaData}
            meta={meta}
            input={input}
            isCanonical={isCanonical}
            setIsCanonical={setIsCanonical}
            selectedKeywords={selectedKeywords}
            setSelectedKeywords={setSelectedKeywords}
          />
        </div>
      </div>
    </>
  );
};

export default AddCategory;
