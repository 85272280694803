import React, { useState, useEffect } from "react";
import MoreActionbtn from "../../components/admin/customers/MoreActionbtn";
import { FaPlus } from "react-icons/fa6";
import "../../styles/writers/Writers.css";
import TableTrashStories from "../../components/stories/TableTrashStories";
import { Const } from "../../utils/Constants";
import { MdArrowForwardIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  getTrashListStories,
  DeleteStories,
  duplicateStories,
  exportStories,
} from "../../api/Stories";
import { getTag } from "../../api/Tags";
import { getWriter } from "../../api/Writers";
import Tabs from "../../components/stories/Tabs";
import DeletePopup from "../../components/common/DeletePopup";
import RestorePopup from "../../components/common/RestorePopUp";
import { updateStatus } from "../../api/Stories";
const Stories = () => {
  const columns = ["Text Story Details", "Category", "Tags","Days Remaining"];
  const navigate = useNavigate();

  const [scrolled, setScrolled] = useState(false);
  const [deleteStories, setDeleteStories] = useState(null);
  const [row, setRow] = useState([]);
  const [tag, setTag] = useState({});
  const [author, setAuthor] = useState({});
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState({ status: Const.Trash });
  const [offset, setOffset] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);
  const [selection, setSelection] = useState({
    select_bulk: false,
    selected: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isbtnLoading, setIsBtnLoading] = useState(false);
  const [isbtnLoadingExport, setIsBtnLoadingExport] = useState(false);
  const [rowLoading, setRowLoading] = useState(false);
  const [tabSelected, setTabSelected] = useState("all");
  const [selectedTags, setSelectedTags] = useState([]);
  const [restoreStory, setRestoreStory] = useState(null);
  const [selectedAuthors, setSelectedAuthors] = useState([]);

  useEffect(() => {
    // document
    //   .getElementsByClassName("admin-right")[0]
    //   .addEventListener("scroll", () => {
    //     if (
    //       document.getElementsByClassName("main-div")[0] &&
    //       document.getElementsByClassName("admin-right")[0].scrollTop >
    //         document.getElementsByClassName("main-div")[0].offsetTop - 150
    //     ) {
    //       setScrolled(true);
    //     } else {
    //       setScrolled(false);
    //     }
    //   });
    // const element = document.getElementsByClassName("table-of")[0];
    // if (orders.length > 0) {
    //   element.addEventListener("scroll", handleLoadMore);
    // }

    setRowLoading(true);
    const timeout = setTimeout(() => {
      fetchAuthor();
    }, 500);
    return () => {
      // element.removeEventListener("scroll", handleLoadMore);
      clearTimeout(timeout);
    };
  }, [filter?.search, offset, isUpdated]);

  useEffect(() => {
    fetchData();
    fetchTag();
  }, []);

  const fetchTag = async () => {
    try {
      const res = await getTag();
      if (res) {
        setTag(res.data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchAuthor = async () => {
    try {
      const res = await getWriter();
      if (res) {
        setAuthor(res.data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchData = async () => {
    const payload = {
      filter: filter,
      limit: Const.Limit,
      offset: offset,
    };
    try {
      const res = await getTrashListStories(payload);
      if (res) {
        if (offset > 0) {
          setCount((prevCount) => prevCount + res.count);

          setRow((prevData) => [...prevData, ...res.data]);
          return;
        }
        setCount(res.count);
        setRow(res.data);
      }
    } catch (e) {
      console.error("Error while fatching stories list", e);
    } finally {
      setIsLoading(false);
      setRowLoading(false);
    }
  };

  const handleSearch = (event) => {
    const { name, value } = event.target;
    const updatedFilter = { ...filter, [name]: value };
    if (!value) {
      delete updatedFilter[name];
    }
    setOffset(0);
    setFilter(updatedFilter);
  };

  // const editItem = (id) => {
  //   navigate(`/admin/edit-product/?id=${id}`);
  // };

  const handleSelection = (event) => {
    const { id, checked } = event.target;
    if (id === "selection" || id === "selection-toggle") {
      setSelection({ ...selection, select_bulk: checked, selected: [] });
    } else {
      if (selection.select_bulk) {
        if (checked) {
          const filterSelected = selection.selected.filter((select) => {
            return select !== id;
          });
          setSelection({ ...selection, selected: filterSelected });
        } else {
          setSelection({ ...selection, selected: [...selection.selected, id] });
        }
      } else {
        if (checked) {
          setSelection({ ...selection, selected: [...selection.selected, id] });
        } else {
          const filterSelected = selection.selected.filter((select) => {
            return select !== id;
          });
          setSelection({ ...selection, selected: filterSelected });
        }
      }
    }
  };

  const handleFilter = (event) => {
    const { name, value, type, checked } = event.target;
    if (name === "status") {
      if (value === "all") {
        const { [name]: removedFilter, ...rest } = filter;
        setFilter(rest);
      } else {
        setFilter((prevFilter) => ({
          ...prevFilter,
          [name]: JSON.parse(value),
        }));
      }
    } else if (name === "category") {
      if (checked) {
        setFilter((prevFilter) => ({
          ...prevFilter,
          [name]: [...(prevFilter?.[name] || []), value],
        }));
      } else {
        setFilter((prevFilter) => ({
          ...prevFilter,
          [name]: prevFilter[name].filter((item) => item !== value),
        }));
      }
    } else {
      setFilter((prevFilter) => ({ ...prevFilter, [name]: value }));
    }
  };

  const handleDelete = async () => {
    try {
      const res = await DeleteStories(deleteStories);
      if (res) {
        setIsUpdated((prev) => !prev);
      }
      if (tabSelected != "all") {
        if (res) {
          const writer = row.filter((tag) => tag._id !== deleteStories);
          setRow(writer);
        }
      }
    } catch (e) {
      console.error("Error while deleting writer", e);
    } finally {
      setDeleteStories(null);
    }
  };

  const sendDataToChild = () => {
    const dataToSend = { prev: "/stories" };
    navigate("add-stories", { state: dataToSend });
  };

  const handleDublicate = async () => {
    setIsBtnLoading(true);
    try {
      const payload = {
        select_bulk: selection.select_bulk,
        selected: selection.selected,
      };
      await duplicateStories(payload);
      setIsUpdated((prev) => !prev);
    } catch (e) {
      console.error(e);
    } finally {
      setIsBtnLoading(false);
    }
  };

  const handleExport = async () => {
    setIsBtnLoadingExport(true);
    try {
      const payload = {
        select_bulk: selection.select_bulk,
        selected: selection.selected,
      };
      const res = await exportStories(payload);
      if (!res.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await res.blob();

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "products.csv");
      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link);
    } catch (e) {
      console.error(e);
    } finally {
      setIsBtnLoadingExport(false);
    }
  };

  const handleRestore = async () => {
    try {
      const payload = { status: 0 };
      await updateStatus(restoreStory, payload);
      setIsUpdated((prev) => !prev);
    } catch (e) {
      console.error(e);
    } finally {
      setRestoreStory(null);
    }
  };

  return (
    <>
      <div className="head-of-head writer-top">
        <div className="table-name">
          <span className="menu-nav" onClick={() => navigate("/stories")}>
            Text Stories
          </span>{" "}
          <MdArrowForwardIos className="menu-nav-arrow" />{" "}
          <span className="active-p-head">{`Trash (${count})`}</span>{" "}
        </div>
        <div className="fixed-right">
        </div>
      </div>
      <p className="p-top-writer">
        Create, customize and manage your stories.{" "}
        <span className="learn-more">Learn More</span>
      </p>

      <TableTrashStories
        count={count}
        columns={columns}
        setFilter={setFilter}
        setTabSelected={setTabSelected}
        rows={row}
        selection={selection}
        tag={tag}
        author={author}
        filter={filter}
        setRestoreStory={setRestoreStory}
        rowLoading={rowLoading}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        setSelectedAuthors={setSelectedAuthors}
        selectedAuthors={selectedAuthors}
        tabSelected={tabSelected}
        setIsBtnLoading={setIsBtnLoading}
        isbtnLoading={isbtnLoading}
        isbtnLoadingExport={isbtnLoadingExport}
        handleDublicate={handleDublicate}
        handleExport={handleExport}
        setDeleteStories={setDeleteStories}
        setIsUpdated={setIsUpdated}
        handleSelection={handleSelection}
        handleSearch={handleSearch}
        handleFilter={handleFilter}
      />
      {deleteStories && (
        <DeletePopup setDelete={setDeleteStories} handleDelete={handleDelete} />
      )}
      {restoreStory && (
        <RestorePopup
          setDelete={setRestoreStory}
          handleDelete={handleRestore}
        />
      )}
    </>
  );
};

export default Stories;
