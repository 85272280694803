import React, { useState } from "react";
import style from "../../styles/categories/addCategory.module.css";
import { FiX } from "react-icons/fi";
import { GoPlus } from "react-icons/go";
import { Box, Drawer } from "@mui/material";
import SEOTabs from "./SEOTabs";
import { CiImageOn } from "react-icons/ci";
import { formatDateAndTime, generateSlugStories } from "../../utils/Util";

const AddCategories = ({
  title,
  excerpt,
  images,
  ogImage,
  twitterImage,
  setOgImage,
  setTwitterImage,
  meta,
  input,
  isCanonical,
  setIsCanonical,
  handleMetaData,
  setSelectedKeywords,
  selectedKeywords,
}) => {
  return (
    <>
      <div className="seo-settings-head">
        <p>SEO Settings</p>
      </div>
      <SEOTabs
        title={title}
        excerpt={excerpt}
        images={images}
        ogImage={ogImage}
        twitterImage={twitterImage}
        setOgImage={setOgImage}
        setTwitterImage={setTwitterImage}
        selectedKeywords={selectedKeywords}
        setSelectedKeywords={setSelectedKeywords}
        handleMetaData={handleMetaData}
        meta={meta}
        isCanonical={isCanonical}
        setIsCanonical={setIsCanonical}
        slug={generateSlugStories(input?.slug ?? "")}
      />
    </>
  );
};

export default AddCategories;
