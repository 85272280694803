import Headers from './Headers';
import { ProcessAPI, Const } from '../utils/Constants';

// Create 
export const uploadImage = async (body) => {
    const res = await fetch(Const.Link + "api/editor/image", new Headers("POST", body, true));
    return res.json();
}

export const uploadVideo = async (body) => {
    const res = await fetch(Const.Link + "api/editor/video", new Headers("POST", body, true));
    return res.json();
}