import { useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { uploadImage } from "../../../../api/Editor";

export const useUploader = ({ onUpload }) => {
  const [loading, setLoading] = useState(false);

  const uploadFile = useCallback(
    async (file) => {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      try {
        const response = await uploadImage(formData);

        if (response.success && response.file && response.file.url) {
          onUpload(response.file.url);
        } else {
          toast.error(response.message || "Error uploading Image");
        }
      } catch (errPayload) {
        const error =
          errPayload?.response?.data?.error ||
          errPayload.message ||
          "Something went wrong";
        console.error(error);
        toast.error(error);
      }

      setLoading(false);
    },
    [onUpload]
  );

  return { loading, uploadFile };
};

export const useFileUpload = () => {
  const fileInput = useRef(null);

  const handleUploadClick = useCallback(() => {
    fileInput.current?.click();
  }, []);

  return { ref: fileInput, handleUploadClick };
};

export const useDropZone = ({ uploader }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [draggedInside, setDraggedInside] = useState(false);

  useEffect(() => {
    const dragStartHandler = () => {
      setIsDragging(true);
    };

    const dragEndHandler = () => {
      setIsDragging(false);
    };

    document.body.addEventListener("dragstart", dragStartHandler);
    document.body.addEventListener("dragend", dragEndHandler);

    return () => {
      document.body.removeEventListener("dragstart", dragStartHandler);
      document.body.removeEventListener("dragend", dragEndHandler);
    };
  }, []);

  const onDrop = useCallback(
    (e) => {
      setDraggedInside(false);
      if (e.dataTransfer.files.length === 0) {
        return;
      }

      const fileList = e.dataTransfer.files;

      const files = [];

      for (let i = 0; i < fileList.length; i += 1) {
        const item = fileList.item(i);
        if (item) {
          files.push(item);
        }
      }

      if (files.some((file) => file.type.indexOf("image") === -1)) {
        return;
      }

      e.preventDefault();

      const filteredFiles = files.filter((f) => f.type.indexOf("image") !== -1);

      const file = filteredFiles.length > 0 ? filteredFiles[0] : undefined;

      if (file) {
        uploader(file);
      }
    },
    [uploader]
  );

  const onDragEnter = () => {
    setDraggedInside(true);
  };

  const onDragLeave = () => {
    setDraggedInside(false);
  };

  return { isDragging, draggedInside, onDragEnter, onDragLeave, onDrop };
};
