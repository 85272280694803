import React from "react";
import Settings from "../../components/admin/settings/Settings";
import "../../styles/admin/setting/settings.css";

const SettingMain = () => {
  return (
    <>
        <Settings />
    </>
  )
}

export default SettingMain