import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/home/Home";
import ConstructPage from "../components/common/Construct";

const HomeRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/*" element={<ConstructPage />} />
    </Routes>
  );
};

export default HomeRoute;
