import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import style from "../../../styles/admin/customers/DeletePopup.module.css";
import { blockedCustomer, deleteCustomer } from "../../../api/admin/Customers";

const DeletePopup = ({
  customerId,
  setModal,
  setCount,
  setData,
  data,
  setId,
}) => {
  const [isBlockedBtnLoading, setIsBlockedBtnLoading] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const handleDelete = async () => {
    try {
      const res = await deleteCustomer(customerId);
      if (res) {
        const newArr = data.filter((obj) => obj._id !== customerId);
        setCount((prevCount) => prevCount - 1);
        setData(newArr);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setId(null);
      setIsBtnLoading(false);
      setModal(false);
    }
  };

  const handleBlocked = async () => {
    try {
      await blockedCustomer({ id: customerId });
    } catch (error) {
      console.error(error);
    } finally {
      setId(null);
      setIsBlockedBtnLoading(false);
      setModal(false);
    }
  };
  return (
    <div className={style.modal}>
      {/* <Toaster/> */}
      <OutsideClickHandler
        onOutsideClick={() => {
          setModal(false);
        }}
      >
        <div className={style.card}>
          <div className={style.cardBody}>
            <div className={style.imageSec}>
              <img
                src="https://img.freepik.com/free-vector/recycling-concept-illustration_114360-5872.jpg"
                className="img-pop"
              />
            </div>
            <div className={style.contentSec}>
              <h4>Delete this item</h4>
              <p className={style.subheading}>
                Once deleted, you won’t be able to recover these items.
              </p>
              <div className={style.box}>
                <span className={style.heading}>
                  Do you want to hide item without deleting them?
                </span>
                <p>
                  You can hide item from your online store by turning off their
                  visibility in the actions menu.
                </p>
              </div>
            </div>
          </div>
          <div className={style.footer}>
            <button
              className={style.btnDangerOutline}
              onClick={() => {
                setModal(false);
              }}
            >
              Cancel
            </button>
            <button
              className={`${style.btnDangerOutline}`}
              disabled={isBlockedBtnLoading ? true : false}
              onClick={() => {
                setIsBlockedBtnLoading(true);
                handleBlocked();
              }}
            >
              {isBlockedBtnLoading ? (
                <span className="spin-loader"></span>
              ) : (
                "Block"
              )}
            </button>
            <button
              className={`${style.btnDanger}`}
              disabled={isBtnLoading ? true : false}
              onClick={() => {
                setIsBtnLoading(true);
                handleDelete();
              }}
            >
              {isBtnLoading ? <span className="spin-loader"></span> : "Delete"}
            </button>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default DeletePopup;
