import React, { useState, useEffect } from "react";
import "../../styles/writers/Writers.css";
import TableFlag from "../../components/flags/TableFlag";
import { Const } from "../../utils/Constants";
import { useNavigate, useLocation } from "react-router-dom";
import { getListFlag } from "../../api/Flags";
import { MdArrowForwardIos } from "react-icons/md";

const Flag = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);
  const [row, setRow] = useState([]);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState({});
  const [offset, setOffset] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);
  const [selection, setSelection] = useState({
    select_bulk: false,
    selected: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [rowLoading, setRowLoading] = useState(false);

  useEffect(() => {
    document
      .getElementsByClassName("admin-right")[0]
      .addEventListener("scroll", () => {
        if (
          document.getElementsByClassName("main-div")[0] &&
          document.getElementsByClassName("admin-right")[0].scrollTop >
            document.getElementsByClassName("main-div")[0].offsetTop - 150
        ) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      });
    // const element = document.getElementsByClassName("table-of")[0];
    // if (orders.length > 0) {
    //   element.addEventListener("scroll", handleLoadMore);
    // }

    setRowLoading(true);
    const timeout = setTimeout(() => {
      fetchData();
    }, 500);
    return () => {
      // element.removeEventListener("scroll", handleLoadMore);
      clearTimeout(timeout);
    };
  }, [filter.search, offset, isUpdated]);

  const fetchData = async () => {
    const payload = {
      filter: filter,
      limit: Const.Limit,
      offset: offset,
    };
    try {
      const res = await getListFlag(payload);
      if (res) {
        if (offset > 0) {
          setCount(res.count);
          setRow((prevData) => [...prevData, ...res.data]);
          return;
        }
        setCount(res.count);
        setRow(res.data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
      setRowLoading(false);
    }
  };

  const handleLoadMore = () => {
    const element = document.getElementsByClassName("table-of")[0];
    if (
      element.scrollHeight - element.scrollTop === element.clientHeight &&
      row.length < count
    ) {
      setRowLoading(true);
      setOffset((prevOffset) => prevOffset + Const.Limit);
      return;
    }
  };

  const handleSearch = (event) => {
    const { name, value } = event.target;
    const updatedFilter = { ...filter, [name]: value };
    if (!value) {
      delete updatedFilter[name];
    }
    setOffset(0);
    setFilter(updatedFilter);
  };

  const handleSelection = (event) => {
    const { id, checked } = event.target;
    if (id === "selection" || id === "selection-toggle") {
      setSelection({ ...selection, select_bulk: checked, selected: [] });
    } else {
      if (selection.select_bulk) {
        if (checked) {
          const filterSelected = selection.selected.filter((select) => {
            return select !== id;
          });
          setSelection({ ...selection, selected: filterSelected });
        } else {
          setSelection({ ...selection, selected: [...selection.selected, id] });
        }
      } else {
        if (checked) {
          setSelection({ ...selection, selected: [...selection.selected, id] });
        } else {
          const filterSelected = selection.selected.filter((select) => {
            return select !== id;
          });
          setSelection({ ...selection, selected: filterSelected });
        }
      }
    }
  };

  const handleFilter = (event) => {
    const { name, value, type, checked } = event.target;
    if (name === "status" || name === "inventory") {
      if (value === "all") {
        const { [name]: removedFilter, ...rest } = filter;
        setFilter(rest);
      } else {
        setFilter((prevFilter) => ({
          ...prevFilter,
          [name]: JSON.parse(value),
        }));
      }
    } else {
      setFilter((prevFilter) => ({ ...prevFilter, [name]: value }));
    }
  };

  const columns = ["Flag name", "Section", "Stories"];
  return (
    <>
      <div className="head-of-head writer-top">
        <div className="table-name">Flagged stories ({count})</div>
        <div className="fixed-right">{/* <MoreActionbtn/> */}</div>
      </div>
      <p className="p-top-writer">
        Flag and prioritize stories for your page, reorder and customize their
        visibility.
      </p>
      <TableFlag
        count={count}
        columns={columns}
        rows={row}
        selection={selection}
        filter={filter}
        rowLoading={rowLoading}
        handleSelection={handleSelection}
        handleSearch={handleSearch}
        handleFilter={handleFilter}
      />
    </>
  );
};

export default Flag;
