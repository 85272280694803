import React, { useState } from "react";
import "../../styles/media-library/media-library.css";
import { IoIosArrowDown } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";
import { CiSaveDown2 } from "react-icons/ci";
import { BsArrowsAngleExpand } from "react-icons/bs";
import { IoCloudUploadOutline } from "react-icons/io5";
import { FaRegStar } from "react-icons/fa";
import Zoom from "../../components/mediaLibrary/zoomed";
import Filter from "../../components/mediaLibrary/filters";
import Filter2 from "../../components/mediaLibrary/filters2";
import { FiDownload } from "react-icons/fi";

const MediaLibrary = () => {
  const [images, setImages] = useState(null);
  const [activeBtn, setActiveBtn] = useState(false);
  const [zoom, setZoom] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [imgs, setImgs] = useState([
    {
      row: [
        {
          img: "./gal1.jpg",
          name: "Img1",
        },
        {
          img: "./gal2.jpg",
          name: "Img2",
        },
        {
          img: "./gal3.jpg",
          name: "Img3",
        },
        {
          img: "./gal4.jpg",
          name: "Img4",
        },
        {
          img: "./gal5.jpg",
          name: "Img5",
        },
        {
          img: "./gal6.jpg",
          name: "Img6",
        },
      ],
    },
    {
      row: [
        {
          img: "./gal7.jpg",
          name: "Img7",
        },
        {
          img: "./gal8.jpg",
          name: "Img8",
        },
        {
          img: "./gal9.jpg",
          name: "Img9",
        },
        {
          img: "./gal10.jpg",
          name: "Img10",
        },
        {
          img: "./gal11.jpg",
          name: "Img11",
        },
      ],
    },
    {
      row: [
        {
          img: "./gal12.jpg",
          name: "Img12",
        },
        {
          img: "./gal13.jpg",
          name: "Img13",
        },
        {
          img: "./gal14.jpg",
          name: "Img14",
        },
        {
          img: "./gal15.jpg",
          name: "Img15",
        },
        {
          img: "./gal16.jpg",
          name: "Img16",
        },
      ],
    },
    {
      row: [
        {
          img: "./gal8.jpg",
          name: "Img17",
        },
        {
          img: "./gal18.jpg",
          name: "Img18",
        },
        {
          img: "./gal19.jpg",
          name: "Img19",
        },
        {
          img: "./gal20.jpg",
          name: "Img20",
        },
        {
          img: "./gal21.jpg",
          name: "Img21",
        },
      ],
    },
  ]);
  const imagesFilter = [{ name: "Images" }, { name: "Videos" }, { name: "Gifs" }, { name: "Favorites" }];
  const orientationFilter = [{ name: "Landscape" }, { name: "Portrait" }, { name: "Square" }];

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      // Check file size (in bytes)
      const fileSize = file.size;
      const maxSize = 5 * 1024 * 1024; // 5 MB limit

      if (fileSize <= maxSize) {
        // Update the state with the selected file
        setImages(file);
        setImageUrl(URL.createObjectURL(file));
        setImgs((prev) => [...prev, { row: [{ img: URL.createObjectURL(file), name: file.name }] }]);
      } else {
        alert("File size exceeds 5 MB");
      }
    }
  };

  const handleDownload = (imgSrc, imgName) => {
    const link = document.createElement("a");
    link.href = imgSrc;
    link.download = imgName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const calculateZoomIndex = (outerIndex, innerIndex) => {
    let index = 0;
    for (let i = 0; i < outerIndex; i++) {
      index += imgs[i].row.length;
    }
    index += innerIndex;
    return index;
  };

  return (
    <>
      <div className="head-of-head writer-top">
        <div className="table-name">Media Library</div>
        <div className="fixed-right">
          <div className="upload-input-div">
            <button className="btn-primary">
              <IoCloudUploadOutline /> Upload
            </button>
            <input
              className="file-input-upload-btn"
              accept="image/*"
              onChange={handleFileChange}
              name="imgsrc"
              type="file"
            ></input>
          </div>
        </div>
      </div>
      <p className="p-top-writer">
        Choose from images, illustrations, and patterns for your background.
      </p>
      <div className="media-nav">
        <div className="filters-media">
          <p>Filter by:</p>
          <Filter name={"Images"} filter={imagesFilter} />
          <Filter2 name={"Orientation"} filter={orientationFilter} />
          <div className="verti-line-media"></div>
          <button
            className={activeBtn ? "fav-btn-media fav-btn-active" : "fav-btn-media"}
            onClick={() => {
              setActiveBtn(!activeBtn);
            }}
          >
            <FaRegStar /> Favorites
          </button>
        </div>
        <div className="media-search-div">
          <IoSearchOutline />
          <input className="media-search" placeholder="Search..."></input>
        </div>
      </div>

      <div className="main-gal-div">
        <ul className="top-ul-imgs">
          {imgs.map((el, i) => (
            <li key={i} className="li-imgs">
              <ul className="inner-ul-img">
                {el.row.map((oo, k) => (
                  <li
                    className="main-li"
                    key={k}
                    onClick={() => {
                      setZoom(1 + calculateZoomIndex(i, k));
                    }}
                  >
                    <div className="img-div">
                      <div className="img-controls">
                        <div className="control-btn-img">
                          <div
                            className="control-btn-logo-img"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDownload(oo.img, oo.name);
                            }}
                          >
                            <FiDownload />
                          </div>
                          <div className="control-btn-logo-img">
                            <FaRegStar />
                          </div>
                        </div>
                        <p className="img-name">{oo.name}</p>
                      </div>
                      <img className="img-gal" src={oo.img} alt={oo.name}></img>
                    </div>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
      {zoom && <Zoom setZoom={setZoom} imgs={imgs} zoom={zoom} handleDownload={handleDownload} />}
    </>
  );
};

export default MediaLibrary;
