import React, { useEffect, useState, useRef, useCallback } from "react";
import style from "../../styles/categories/addCategory.module.css";
import { IoIosSearch } from "react-icons/io";
import { FiX } from "react-icons/fi";
import { GoPlus } from "react-icons/go";
import { Box, Drawer } from "@mui/material";
import { PiVideoThin } from "react-icons/pi";
import { PiNewspaperThin } from "react-icons/pi";
import { CiImageOn } from "react-icons/ci";
import { formatDateAndTime } from "../../utils/Util";
import {
  getNonSubcategoryStories,
} from "../../api/Stories";
import debounce from "lodash.debounce";
import { Const } from "../../utils/Constants";

const AddCategories = ({
  filter,
  handleFilter,
  toggleDrawer,
  open,
  setOpen,
  count,
  data,
  setData,
  setCount,
  setStoriesType,
  offset,
  setOffset,
  searchStories,
  setSearchStories,
  isBtnLoading,
  handleAddSubCategoryStories,
  isLoadingNonSubCat,
  storiesType,
  level,
  id
}) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [offsetSubCat, setOffsetSubCat] = useState(0);
  const listRef = useRef(null);

  const fetchData = async () => {
    console.log("Fetching")
    try {
      const payload = {
        filter: {
          search: searchStories,
          type: storiesType,
          level: level,
          id: id,
        },
        limit: Const.Limit,
        offset: offsetSubCat,
      };

      const res = await getNonSubcategoryStories(payload);
      if (res) {
        setData((prev) => [...prev, ...res.data]);
      }
    } catch (error) {
      console.error("Error fetching non-subcategory stories:", error);
    }
  };
  useEffect(()=>{
    if(offsetSubCat!=0){
      fetchData();
    }
  },[offsetSubCat])
  useEffect(()=>{
    setOffsetSubCat(0)
  },[searchStories])

  const handleScroll = useCallback(
    debounce(() => {
      const table = listRef.current;
      if (!table) return;
      const { scrollTop, scrollHeight, clientHeight } = table;
      // console.log(scrollTop, scrollHeight, clientHeight);
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        // Check if all data is already loaded
        // console.log(data.length, offsetSubCat, count, "count");
        if (data.length >= count) {
          // console.log("END");
          setIsEnd(true);
          return;
        }

        // Fetch more data
        setIsLoadingMore(true);
        setOffsetSubCat((prevOffset) => prevOffset + Const.Limit);
      }
    }, 200),
    [data, count, isLoadingMore, isEnd]
  );

  useEffect(() => {
    const table = listRef.current;
    if (table) {
      table.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (table) {
        table.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  return (
    <Drawer
      anchor={"right"}
      open={open["right"]}
      onClose={toggleDrawer("right", false)}
    >
      <Box sx={{ width: "55vw" }} role="presentation">
        <div className={style.filterDrawer}>
          <div className={style.headSec}>
            <div className={style.leftSec}>
              <h2 className={style.heading}>Add Story ({count})</h2>
              <div className={style.searchInv}>
                <IoIosSearch />
                <input
                  className={style.inputInv}
                  type="text"
                  value={searchStories ?? ""}
                  placeholder="Search"
                  onChange={(e) => {
                    setSearchStories(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className={style.rightSec}>
              <FiX
                className={style.close}
                onClick={toggleDrawer("right", false)}
              />
            </div>
          </div>
          <div className={style.mainSec} ref={listRef}>
            {data &&
              data.map((items, i) => (
                <div key={i} className={style.storiesdiv}>
                  <div className={style.storyphotoname}>
                    <div>
                      {items.type === 0 ? (
                        <PiNewspaperThin className={style.articleType} />
                      ) : (
                        <PiVideoThin className={style.articleType} />
                      )}
                    </div>
                    {items && items.coverImg ? (
                      <img
                        className={style.storiesimg}
                        src={items.coverImg}
                        alt="Cover"
                      />
                    ) : (
                      <div className={style.storiesimg}>
                        <div className={style.storiesimgcircle}>
                          <CiImageOn className={style.storiesimglogo} />
                        </div>
                      </div>
                    )}
                    <div>
                      <p className={style.storiesp}>{items.title ?? ""}</p>
                      <p className={style.storiespsmall}>
                        {`${formatDateAndTime(items.timestamp ?? "")} . ${
                          items.author ?? ""
                        }`}
                      </p>
                    </div>
                  </div>
                  <button
                    className="btn-primary-addstory"
                    onClick={(event) => {
                      setStoriesType(items.type);
                      handleAddSubCategoryStories(
                        event,
                        items._id,
                        items.type,
                        i
                      );
                    }}
                  >
                    {isBtnLoading.addStories === i ? (
                      <div className="loader"></div>
                    ) : (
                      <>
                        <GoPlus /> Add
                      </>
                    )}
                  </button>
                </div>
              ))}
            {isLoadingMore && !isEnd && (
              <div className={style.loadingMore}>Loading more...</div>
            )}
          </div>
        </div>
      </Box>
    </Drawer>
  );
};

export default AddCategories;
