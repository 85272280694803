import React from "react";
import { Routes, Route } from "react-router-dom";
import ConstructPage from "../components/common/Construct";
import Tag from "../pages/tags/Tag";
import AddTag from "../pages/tags/addTags";
import StoriesSelection from "../pages/stories/StoriesSelection";

const TagRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<Tag />} />
      <Route path="/stories" element={<StoriesSelection />} />
      <Route path="/add-tag" element={<AddTag />} />
      <Route path="/edit-tag/*" element={<AddTag />} />
      <Route path="/*" element={<ConstructPage />} />
    </Routes>
  );
};

export default TagRoute;
