import React, { useState } from "react";
import MultipleSelectDropdown from "../../fields/MultipleSelectDropdown";
import ChipDropdown from "../../fields/ChipDropdown";
import ImageDrop from "./ImageDrop";

const General = ({
  writer,
  input,
  images,
  setImages,
  contributor,
  setContributor,
  handleInputChange,
}) => {
  const [status, setStatus] = useState(false);
  const handlestatus = () => {
    setStatus(!status);
  };
  return (
    <div className="general-main-div">
      
      <div className="publish-date-div">
        <div className="featured-img-top">
          <div className="feature-logo">
            <p className="general-p-small">Movie/Series Title</p>
          </div>
        </div>
        <input
          type="text"
          className="general-input"
          name="articleTitle"
          onChange={handleInputChange}
          value={input && input.articleTitle ? input.articleTitle : ""}
          placeholder="Add a story title here"
        />
      </div>
      <div className="publish-date-div">
        <div className="featured-img-top">
          <div className="feature-logo">
            <p className="general-p-small">Subheading</p>
          </div>
        </div>
        <input
          type="text"
          className="general-input"
          name="articleSubheading"
          onChange={handleInputChange}
          value={input && input.articleSubheading ? input.articleSubheading : ""}
          placeholder="Add a story subheading here"
        />
      </div>
      <div className="publish-date-div">
        <div className="featured-img-top">
          <div className="feature-logo">
            <p className="general-p-small">Platform</p>
          </div>
        </div>
        <input
          type="text"
          className="general-input"
          name="platform"
          onChange={handleInputChange}
          value={input && input.platform ? input.platform : ""}
          placeholder="Add a platform where to watch the show"
        />
      </div>
      <div className="publish-date-div">
        <div className="featured-img-top">
          <div className="feature-logo">
            <p className="general-p-small">Views</p>
          </div>
        </div>
        <input
          type="text"
          className="general-input"
          name="views"
          onChange={handleInputChange}
          value={input && input.views ? input.views : ""}
          placeholder="Add a views of the show"
        />
      </div>
      <div className="publish-date-div">
        <div className="featured-img-top">
          <div className="feature-logo">
            <p className="general-p-small">Week</p>
          </div>
        </div>
        <input
          type="number"
          className="general-input"
          name="week"
          onChange={handleInputChange}
          value={input && input.week ? input.week : ""}
          placeholder="Add a week for the show"
        />
      </div>

      <div className="publish-date-div">
        <div className="featured-img-top">
          <div className="feature-logo">
            <p className="general-p-small">Weekend Box Office Collection</p>
          </div>
        </div>
        <input
          type="text"
          className="general-input"
          name="weekendBoxOffice"
          onChange={handleInputChange}
          value={input && input.weekendBoxOffice ? input.weekendBoxOffice : ""}
          placeholder="Add a Weekend Box Office Collection"
        />
      </div>
      <div className="publish-date-div">
        <div className="featured-img-top">
          <div className="feature-logo">
            <p className="general-p-small">Cumulative Box Office Collection</p>
          </div>
        </div>
        <input
          type="text"
          className="general-input"
          name="cumulativeBoxOffice"
          onChange={handleInputChange}
          value={input && input.cumulativeBoxOffice ? input.cumulativeBoxOffice : ""}
          placeholder="Add a Cumulative Box Office Collection"
        />
      </div>

      {/* <div className="publish-date-div">
        <div className="featured-img-top">
          <div className="feature-logo">
            <p className="general-p-small">Related posts</p>
          </div>
          <p className="general-p-small">0/3</p>
        </div>
        <div className="general-related">
          <div className="flex-general">
            <GoPlus className="general-plus-logo2" />
            <p className="choposts">Choose Posts</p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default General;
