import React, { useState, useEffect } from "react";
import MoreActionbtn from "../../components/admin/customers/MoreActionbtn";
import { FaPlus } from "react-icons/fa6";
import "../../styles/writers/Writers.css";
import TableWriters from "../../components/writers/TableWriters";
import { Const } from "../../utils/Constants";
import { useNavigate } from "react-router-dom";
import DeletePopup from "../../components/common/DeletePopup";
import {
  createWriter,
  writerDelete,
  editWriter,
  getListWriter,
  updateWriter,
  exportWriter,
  duplicateWriter,
} from "../../api/Writers";

const Writer = () => {
  const columns = ["Author Name", "Story Count"];
  const navigate = useNavigate();

  const [scrolled, setScrolled] = useState(false);
  const [deleteWriter, setDeleteWriter] = useState(null);
  const [row, setRow] = useState([]);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState({});
  const [offset, setOffset] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);
  const [selection, setSelection] = useState({
    select_bulk: false,
    selected: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [rowLoading, setRowLoading] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState({
    export: false,
    duplicate: false,
  });

  useEffect(() => {
    document
      .getElementsByClassName("admin-right")[0]
      .addEventListener("scroll", () => {
        if (
          document.getElementsByClassName("main-div")[0] &&
          document.getElementsByClassName("admin-right")[0].scrollTop >
            document.getElementsByClassName("main-div")[0].offsetTop - 150
        ) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      });
    // const element = document.getElementsByClassName("table-of")[0];
    // if (row.length > 0) {
    //   element.addEventListener("scroll", handleLoadMore);
    // }

    setRowLoading(true);
    const timeout = setTimeout(() => {
      fetchData();
    }, 500);
    return () => {
      // element.removeEventListener("scroll", handleLoadMore);
      clearTimeout(timeout);
    };
  }, [filter, offset, isUpdated]);

  const fetchData = async () => {
    const payload = {
      search: JSON.stringify(filter?.search) || "",
      limit: Const.Limit,
      offset: offset,
    };
    try {
      const res = await getListWriter(payload);
      if (res) {
        if (offset > 0) {
          setCount(res.count);
          setRow((prevProduct) => [...prevProduct, ...res.data]);
          return;
        }
        setCount(res.count);
        setRow(res.data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
      setRowLoading(false);
    }
  };

  const handleLoadMore = () => {
    const element = document.getElementsByClassName("table-of")[0];
    if (
      element.scrollHeight - element.scrollTop === element.clientHeight &&
      row.length < count
    ) {
      setRowLoading(true);
      setOffset((prevOffset) => prevOffset + Const.Limit);
      return;
    }
  };

  const handleSearch = (event) => {
    const { name, value } = event.target;
    const updatedFilter = { ...filter, [name]: value };
    if (!value) {
      delete updatedFilter[name];
    }
    setOffset(0);
    setFilter(updatedFilter);
  };

  const handleSelection = (event) => {
    const { id, checked } = event.target;
    if (id === "selection" || id === "selection-toggle") {
      setSelection({ ...selection, select_bulk: checked, selected: [] });
    } else {
      if (selection.select_bulk) {
        if (checked) {
          const filterSelected = selection.selected.filter((select) => {
            return select !== id;
          });
          setSelection({ ...selection, selected: filterSelected });
        } else {
          setSelection({ ...selection, selected: [...selection.selected, id] });
        }
      } else {
        if (checked) {
          setSelection({ ...selection, selected: [...selection.selected, id] });
        } else {
          const filterSelected = selection.selected.filter((select) => {
            return select !== id;
          });
          setSelection({ ...selection, selected: filterSelected });
        }
      }
    }
  };

  const handleFilter = (event) => {
    const { name, value, type, checked } = event.target;
    if (name === "status" || name === "inventory") {
      if (value === "all") {
        const { [name]: removedFilter, ...rest } = filter;
        setFilter(rest);
      } else {
        setFilter((prevFilter) => ({
          ...prevFilter,
          [name]: JSON.parse(value),
        }));
      }
    } else {
      setFilter((prevFilter) => ({ ...prevFilter, [name]: value }));
    }
  };

  const handleEdit = (id) => {
    navigate(`/authors/edit-author/${id}`);
  };

  const handleDuplicate = async () => {
    setIsBtnLoading((prevBtn) => ({ ...prevBtn, duplicate: true }));
    try {
      const payload = {
        select_bulk: selection.select_bulk,
        selected: selection.selected,
      };
      await duplicateWriter(payload);
      setIsUpdated((prev) => !prev);
    } catch (e) {
      console.error(e);
    } finally {
      setIsBtnLoading((prevBtn) => ({ ...prevBtn, duplicate: false }));
    }
  };

  const handleExport = async () => {
    setIsBtnLoading((prevBtn) => ({ ...prevBtn, export: true }));
    try {
      const payload = {
        select_bulk: selection.select_bulk,
        selected: selection.selected,
      };
      const res = await exportWriter(payload);
      if (!res.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await res.blob();

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "writer.csv");
      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link);
    } catch (e) {
      console.error(e);
    } finally {
      setIsBtnLoading((prevBtn) => ({ ...prevBtn, export: false }));
    }
  };

  const handleDelete = async () => {
    try {
      const res = await writerDelete(deleteWriter);
      if (res) {
        const writer = row.filter((tag) => tag._id !== deleteWriter);
        setRow(writer);
      }
    } catch (e) {
      console.error("Error while deleting writer", e);
    } finally {
      setDeleteWriter(null);
    }
  };

  return (
    <>
      <div className="head-of-head writer-top">
        <div className="table-name">Authors</div>
        <div className="fixed-right">
          <button
            className="btn-primary"
            onClick={() => {
              navigate("/authors/add-author");
            }}
          >
            <FaPlus className="plus-logo" /> Create Author
          </button>
        </div>
      </div>
      <p className="p-top-writer">
        Manage authors for your story, create and customize their public
        profiles. <span className="learn-more">Learn More</span>
      </p>
      <TableWriters
        count={count}
        columns={columns}
        rows={row}
        selection={selection}
        filter={filter}
        rowLoading={rowLoading}
        isBtnLoading={isBtnLoading}
        handleDuplicate={handleDuplicate}
        handleExport={handleExport}
        handleSelection={handleSelection}
        handleSearch={handleSearch}
        handleFilter={handleFilter}
        setDeleteWriter={setDeleteWriter}
        handleEdit={handleEdit}
        setOffset={setOffset}
        offset={offset}
        setRowLoading={setRowLoading}
      />

      {deleteWriter && (
        <DeletePopup setDelete={setDeleteWriter} handleDelete={handleDelete} />
      )}
    </>
  );
};

export default Writer;
