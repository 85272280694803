import React, { useState, useEffect } from "react";
import "../../styles/writers/Writers.css";
import "../../styles/writers/AddWriters.css";
import { useNavigate, useLocation } from "react-router-dom";
import ImageDrop from "../../components/common/ImageDrop";
import { MdArrowForwardIos } from "react-icons/md";
import { createWriter, editWriter, updateWriter } from "../../api/Writers";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { generateSlugStories } from "../../utils/Util";
import SEOCategories from "../../components/categories/SEOCategories";

const Writer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setLoading] = useState(true);
  const [input, setInput] = useState({});
  const [images, setImages] = useState(null);
  const [ogImage, setOgImage] = useState(null);
  const [twitterImage, setTwitterImage] = useState(null);
  const [isBtnLoading, setIsBtnLoading] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [meta, setMeta] = useState({});
  const [social, setSocial] = useState({});
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [isCanonical, setIsCanonical] = useState(false);

  const getId = location.pathname.split("/")[3];

  useEffect(() => {
    if (getId) {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    try {
      setIsEdit(true);
      const res = await editWriter(getId);
      if (res.data) {
        const { firstname, lastname, subheading, aboutus, slug } = res.data;
        setInput({
          firstname,
          lastname,
          subheading,
          aboutus,
          slug: slug?.split("/")[1],
        });
        setMeta(res?.data?.meta ?? {});
        setSocial(res?.data?.social ?? {});
        setSelectedKeywords(res?.data?.meta?.keywords ?? []);
        setImages(res?.data?.imgsrc ?? null);
        setOgImage(res?.data?.meta?.og?.image ?? null);
        setTwitterImage(res?.data?.meta?.twitter?.image ?? null);
        setValue("firstname", res?.data?.firstname ?? "");
        setValue("lastname", res?.data?.lastname ?? "");
      }
    } catch (e) {
      console.error("Error while fetching data", e);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "firstname" || name === "lastname") {
      setInput((prev) => ({
        ...prev,
        slug: generateSlugStories(
          `${name === "firstname" ? value ?? "" : prev?.firstname ?? ""} ${
            name === "lastname" ? value ?? "" : prev?.lastname ?? ""
          }`
        ),
      }));
    }
    setInput((prev) => ({ ...prev, [name]: value }));
    setValue(name, value);
  };

  const handleSocialChnage = (e) => {
    const { name, value } = e.target;
    setSocial((prev) => ({ ...prev, [name]: value }));
    setValue(name, value);
  };

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required("First name is required"),
    lastname: Yup.string().required("Last name is required"),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstname: "",
      lastname: "",
    },
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm(formOptions);

  const handleMetaData = (e) => {
    const { name, value, checked } = e.target;
    if (name.startsWith("og.") || name.startsWith("twitter.")) {
      const [prefix, key] = name.split(".");
      setMeta((prev) => {
        const newMeta = { ...prev };
        if (!newMeta[prefix]) {
          newMeta[prefix] = {};
        }
        newMeta[prefix][key] = value || "";
        return newMeta;
      });
    } else {
      if (name === "slug") {
        setInput((prev) => ({ ...prev, [name]: generateSlugStories(value) }));
      } else {
        setMeta((prev) => {
          const newMeta = { ...prev };
          if (value === undefined) {
            delete newMeta[name];
            return newMeta;
          }
          if (name === "robots") {
            newMeta[name] = checked ? "index,follow" : "noindex,nofollow";
            return newMeta;
          }
          newMeta[name] = value;
          return newMeta;
        });
      }
    }
  };

  const handleSave = async () => {
    setIsBtnLoading(true);
    const payload = {
      ...input,
      slug: `/${input?.slug}` ?? "",
      social: { ...social },
      meta: {
        ...meta,
        author: `${input?.firstname} ${input?.lastname}`,
        keywords: selectedKeywords,
      },
    };
    const formData = new FormData();
    formData.append("data", JSON.stringify(payload));

    if (images && images.length !== 0) {
      formData.append("imgsrc", images[0]);
    }
    if (ogImage && ogImage.length !== 0) {
      formData.append("ogImg", ogImage[0]);
    }
    if (twitterImage && twitterImage.length !== 0) {
      formData.append("twitterImg", twitterImage[0]);
    }
    try {
      await createWriter(formData);
    } catch (error) {
      console.error("Error while saving writer", error);
    } finally {
      setIsBtnLoading(false);
      navigate("/authors");
    }
  };

  const handleUpdate = async () => {
    setIsBtnLoading(true);
    const updatedInput = { ...input };

    if (images && images.length === 0) {
      updatedInput.imgsrc = "";
    }
    const payload = {
      ...updatedInput,
      slug: `/${updatedInput?.slug}` ?? "",
      social: { ...social },
      meta: {
        ...meta,
        author: `${updatedInput?.firstname} ${updatedInput?.lastname}`,
        keywords: selectedKeywords,
      },
    };

    const formData = new FormData();
    formData.append("data", JSON.stringify(payload));

    if (images && images.length !== 0) {
      formData.append("imgsrc", images[0]);
    }

    if (ogImage && ogImage.length !== 0) {
      formData.append("ogImg", ogImage[0]);
    }
    if (twitterImage && twitterImage.length !== 0) {
      formData.append("twitterImg", twitterImage[0]);
    }

    try {
      await updateWriter(getId, formData);
    } catch (error) {
      console.error("Error while updating writer", error);
    } finally {
      setIsBtnLoading(false);
      navigate("/authors");
    }
  };

  const handleDelete = () => {
    setImages([]);
  };

  return (
    <>
      <div className="head-of-head writer-top">
        <div className="table-name">
          <span className="menu-nav" onClick={() => navigate("/authors")}>
            Authors
          </span>{" "}
          <MdArrowForwardIos className="menu-nav-arrow" style={{marginInline:'5px'}} />{" "}
          <span className="">
            {input?.firstname || input?.lastname
              ? `${input?.firstname ?? ""} ${input?.lastname ?? ""}`
              : "Untitled Author"}
          </span>{" "}
        </div>
        <div className="fixed-right">
          <button
            className="btn-primary-outline"
            onClick={() => navigate("/authors")}
          >
            Cancel
          </button>
          <button
            className="btn-primary"
            style={{ width: "80px" }}
            onClick={handleSubmit(isEdit ? handleUpdate : handleSave)}
          >
            {isBtnLoading ? <div className="loader"></div> : "Save"}
          </button>
        </div>
      </div>
      <p className="p-top-writer">
        Manage what info is shown on your authors' public profile.
      </p>

      <div className="cat-main-div">
        <div
          className="cat-left-div"
          style={{ height: "100%", backgroundColor: "unset" }}
        >
          <div className="writer-profile-info-div">
            <div className="profile-top-div">
              <p className="profile-main-head">Profile Info</p>
              <p className="profile-sub-head">
                Give your author a name and a profile picture
              </p>
            </div>
            <div className="profile-top-div flex-pro">
              <div className="pro-pic-div">
                <p className="small-p-pro">Profile picture</p>
                <ImageDrop
                  selectedFiles={images}
                  setSelectedFiles={setImages}
                  setImageUrl={setImageUrl}
                  handleDelete={handleDelete}
                />
              </div>

              <div className="details-pro-main-div">
                <div className="details-pro-div gap-detail-pro">
                  <div className="detail-input">
                    <p className="small-p-pro">First Name*</p>
                    <div>
                      <input
                        {...register("firstname")}
                        className={`addi-input2 ${
                          errors.firstname ? "error-validation-input" : ""
                        }`}
                        type="text"
                        name="firstname"
                        onChange={handleInputChange}
                        value={input?.firstname || input?.name || ""}
                      />
                      {errors.firstname && (
                        <p className="small-p-pro error-validation mb-25">
                          {errors.firstname.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="detail-input">
                    <p className="small-p-pro">Last Name*</p>
                    <div>
                      <input
                        {...register("lastname")}
                        className={`addi-input2 ${
                          errors.lastname ? "error-validation-input" : ""
                        }`}
                        type="text"
                        name="lastname"
                        onChange={handleInputChange}
                        value={input?.lastname || ""}
                      />
                      {errors.lastname && (
                        <p className="small-p-pro error-validation mb-25">
                          {errors.lastname.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="details-pro-div gap-detail-pro">
                  <div style={{ width: "100%" }}>
                    <p className="small-p-pro">Subheading*</p>
                    <div>
                      <input
                        style={{ padding: "7.5px 10px" }}
                        className="addi-input2"
                        type="text"
                        name="subheading"
                        onChange={handleInputChange}
                        value={input?.subheading || ""}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="writer-profile-info-div">
            <div className="profile-top-div flex-pro-manage">
              <p className="profile-main-head">Additional Info</p>
            </div>
            <div className="profile-top-div">
              <div className="details-pro-div">
                <div className="detail-input2">
                  <p className="small-p-pro">Facebook</p>
                  <div>
                    <input
                      className="addi-input"
                      type="text"
                      name="facebook"
                      placeholder="E.g. username"
                      onChange={handleSocialChnage}
                      value={social?.facebook || ""}
                    />
                  </div>
                </div>
                <div className="detail-input2">
                  <p className="small-p-pro">Instagram</p>
                  <div>
                    <input
                      className="addi-input"
                      type="text"
                      name="instagram"
                      placeholder="E.g. username"
                      onChange={handleSocialChnage}
                      value={social?.instagram || ""}
                    />
                  </div>
                </div>
              </div>
              <div className="details-pro-div">
                <div className="detail-input2">
                  <p className="small-p-pro">Linkedin</p>
                  <div>
                    <input
                      className="addi-input"
                      type="text"
                      name="linkedIn"
                      placeholder="E.g. username"
                      onChange={handleSocialChnage}
                      value={social?.linkedIn || ""}
                    />
                  </div>
                </div>
                <div className="detail-input2">
                  <p className="small-p-pro">X</p>
                  <div>
                    <input
                      className="addi-input"
                      type="text"
                      name="twitterX"
                      placeholder="E.g. username"
                      onChange={handleSocialChnage}
                      value={social?.twitterX || ""}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="writer-profile-info-div">
            <div className="profile-top-div flex-pro-manage">
              <p className="profile-main-head">About</p>
            </div>
            <div className="profile-top-div h-25">
              <input
                placeholder="Share something about your author"
                className="big-input-pro"
                name="aboutus"
                onChange={handleInputChange}
                value={input?.aboutus || ""}
              />
            </div>
          </div>
        </div>
        <div className="cat-right-div">
          <SEOCategories
            handleMetaData={handleMetaData}
            meta={meta}
            input={input}
            title={`${input?.firstname ?? ""} ${input?.lastname ?? ""}`}
            excerpt={input?.aboutus ?? ""}
            images={images}
            ogImage={ogImage}
            twitterImage={twitterImage}
            setOgImage={setOgImage}
            setTwitterImage={setTwitterImage}
            isCanonical={isCanonical}
            setIsCanonical={setIsCanonical}
            selectedKeywords={selectedKeywords}
            setSelectedKeywords={setSelectedKeywords}
          />
        </div>
      </div>
    </>
  );
};

export default Writer;
