import React from "react";
import { Routes, Route } from "react-router-dom";
import ConstructPage from "../components/common/Construct";
import Flag from "../pages/flags/Flag";
// import SectionRoute from "./SectionRoute";
import StoriesSelection from "../pages/stories/StoriesSelection";

const FlagRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<Flag />} />
      <Route path="/stories" element={<StoriesSelection />} />
      {/* <Route path="/sections/*" element={<SectionRoute />} /> */}
      <Route path="/*" element={<ConstructPage />} />
    </Routes>
  );
};

export default FlagRoute;
