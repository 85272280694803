import React from "react";
import { Routes, Route } from "react-router-dom";
import ConstructPage from "../components/common/Construct";
import Writer from "../pages/writers/Writer";
import AddWriters from "../pages/writers/AddWriters"

const WriterRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<Writer />} />
      <Route path="/add-author" element={<AddWriters />} />
      <Route path="/edit-author/:id" element={<AddWriters />} />
      <Route path="/*" element={<ConstructPage />} />
    </Routes>
  );
};

export default WriterRoute;
