import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import style from "../../styles/common/DeletePopup.module.css";

const DeletePopup = ({
  open,
  setOpen,
  setSelectedSubCategory,
  setHighlighted
}) => {
  return (
    <div className={style.modal}>
      {/* <Toaster/> */}
      <OutsideClickHandler
        onOutsideClick={() => {
          setOpen(null);
        }}
      >
        <div className={style.card}>
          <div className={style.cardBody} style={{alignItems:'center'}}>
            
            <div className={style.contentSec}>
              <h4>Story already flagged</h4>
              <p className={style.subheading}>
                If you want to replace the existing story with this one, then proceed else cancle.
              </p>
              
            </div>
          </div>
          <div className={style.footer}>
            <button
              className="btn-primary-outline"
              onClick={() => {
                setOpen(null);
              }}
            >
              Cancel
            </button>
            <button className="btn-primary" onClick={() => {setHighlighted(open); setSelectedSubCategory(open); setOpen(null)}}>Proceed</button>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default DeletePopup;
