import React, { useState, useCallback } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import style from "../../../../styles/common/EmbedPopup.module.css";

const EmbedPopup = ({ setOpen, editor, getPos }) => {
  const [value, setValue] = useState("");

  const handleSave = useCallback(() => {
    if (value) {
      editor.chain().setEmbed({ embed: value }).focus().run();
    }
  }, [value, editor]);

  const isValidUrl = (url) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    return !!urlPattern.test(url);
  };

  return (
    <div className={style.modal}>
      <OutsideClickHandler
        onOutsideClick={() => {
          setOpen(false);
        }}
      >
        <div className={style.card}>
          <div className={style.cardBody}>
            <div className={style.contentSec}>
              <h4>Embed</h4>
              <p className={style.subheading}>
                Paste a URL from YouTube, Instagram, Twitter, or Facebook
              </p>
              <input
                type="text"
                className="general-input"
                placeholder="e.g. www.youtube.com/example"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                aria-label="Embed URL"
              />
              {!isValidUrl(value) && value.length > 0 && (
                <span className={style.errorMessage}>
                  Please enter a valid URL
                </span>
              )}
            </div>
          </div>
          <div className={style.footer}>
            <button
              className={style.btnPrimaryOutline}
              onClick={() => {
                setValue("");
                setOpen(false);
              }}
            >
              Cancel
            </button>
            <button
              className={style.btnPrimary}
              onClick={() => {
                if (isValidUrl(value)) {
                  handleSave();
                  setOpen(false);
                }
              }}
              disabled={!isValidUrl(value)}
            >
              Save
            </button>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default EmbedPopup;
