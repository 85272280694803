import React from "react";

const SizeSmall = () => {
  return (
    <svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 0H0V1H14V0ZM14 13H0V14H14V13ZM12 9.5C12 10.3 11.3 11 10.5 11H3.5C2.7 11 2 10.3 2 9.5V4.5C2 3.7 2.7 3 3.5 3H10.5C11.3 3 12 3.7 12 4.5V9.5ZM3 4.5V9.5C3 9.8 3.2 10 3.5 10H10.5C10.8 10 11 9.8 11 9.5V4.5C11 4.2 10.8 4 10.5 4H3.5C3.2 4 3 4.2 3 4.5Z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

export default SizeSmall;
