import { Spinner } from "./Spinner";
import { useDropZone, useFileUpload, useUploader } from "./hooks";
import { FiUpload } from "react-icons/fi";
import { CiVideoOn } from "react-icons/ci";
import { useCallback } from "react";

export const VideoUploader = ({ onUpload }) => {
  const { loading, uploadFile } = useUploader({ onUpload });
  const { handleUploadClick, ref } = useFileUpload();
  const { draggedInside, onDrop, onDragEnter, onDragLeave } = useDropZone({
    uploader: uploadFile,
  });

  const onFileChange = useCallback(
    (e) => (e.target.files ? uploadFile(e.target.files[0]) : null),
    [uploadFile]
  );

  if (loading) {
    return (
      <div className="flex items-center justify-center p-8 rounded-lg min-h-[10rem] bg-opacity-80">
        <Spinner className="text-neutral-500" size={1.5} />
      </div>
    );
  }

  const wrapperClass = [
    "flex flex-col items-center justify-center px-8 py-10 rounded-lg bg-opacity-80",
    draggedInside ? "bg-neutral-100" : "",
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <div
      className={wrapperClass}
      onDrop={onDrop}
      onDragOver={onDragEnter}
      onDragLeave={onDragLeave}
      contentEditable={false}
    >
      <CiVideoOn
        name="Image"
        className="w-12 h-12 mb-4 text-black dark:text-white opacity-20"
      />
      <div className="flex flex-col items-center justify-center gap-2">
        <div className="text-sm font-medium text-center text-neutral-400 dark:text-neutral-500">
          {draggedInside ? "Drop video here" : "Drag and drop or"}
        </div>
        <div>
          <button
            className="btn-primary"
            disabled={draggedInside}
            onClick={handleUploadClick}
          >
            <FiUpload />
            Upload an video
          </button>
        </div>
      </div>
      <input
        className="w-0 h-0 overflow-hidden opacity-0"
        ref={ref}
        type="file"
        accept="video/*"
        onChange={onFileChange}
      />
    </div>
  );
};

export default VideoUploader;
