import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Divider,
  FormControl,
  Select,
  MenuItem,
  LinearProgress,
  Box,
  Tabs,
  Tab,
  getAccordionSummaryUtilityClass,
} from "@mui/material";
import {
  FiClock,
  FiMapPin,
  FiSmartphone,
  FiEye,
  FiMonitor,
  FiShoppingCart,
  FiCreditCard,
  FiCheck,
} from "react-icons/fi";
import { Chart } from "react-google-charts";
import ReactApexChart from "react-apexcharts";
import io from "socket.io-client";
import { Const } from "../../utils/Constants";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import style from "../../components/analytics/Analytics.module.css";

const socket = io.connect(Const.Link, { transports: ["websocket"] });

const RealTime = () => {
  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("1");
  const [visitorCount, setVisitorCount] = useState(0);
  const [liveVisitorCount, setLiveVisitorCount] = useState(0);
  const [chartData, setChartData] = useState([["Country", "Count"]]);
  const [region, setRegion] = useState("world");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document
      .getElementsByClassName("admin-right")[0]
      .addEventListener("scroll", () => {
        if (
          document.getElementsByClassName("ad-mp")[0] &&
          document.getElementsByClassName("admin-right")[0].scrollTop >
            document.getElementsByClassName("ad-mp")[0].offsetTop - 240
        ) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      });
  }, []);

  const { data: visitorCountData } = useQuery("visitorCount", async () => {
    return new Promise((resolve, reject) => {
      socket.emit("visitors");
      socket.on("visitorCount", ({ count }) => {
        setVisitorCount(count);
        resolve(count);
      });
      return () => {
        socket.off("visitorCount");
      };
    });
  });

  const { data: liveVisitorCountData } = useQuery(
    "liveVisitorCount",
    async () => {
      return new Promise((resolve, reject) => {
        socket.emit("liveVisitors");
        socket.on("liveVisitorCount", ({ count }) => {
          setLiveVisitorCount(count);
          resolve(count);
        });
        return () => {
          socket.off("liveVisitorCount");
        };
      });
    }
  );

  const { data: summaryData } = useQuery("summaryData", async () => {
    return new Promise((resolve, reject) => {
      socket.emit("summary");
      socket.on("summaryData", (data) => {
        const countryData = data.map.map((country) => [
          country.title,
          country.count,
        ]);
        setChartData([["Country", "Count"], ...countryData]);
        resolve(data);
        setIsLoading(false);
      });
      return () => {
        socket.off("summaryData");
      };
    });
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const sessionSeviceData = summaryData?.devices.map((item, i) => {
    return item.count;
  });

  const sessionSeviceLabel = summaryData?.devices.map((item, i) => {
    return item.title;
  });

  const SessionDeviceGraph = {
    series: sessionSeviceData,
    options: {
      labels: sessionSeviceLabel,
      chart: {
        height: "100%",
        type: "donut",
      },
      stroke: {
        width: 0,
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#1684ea", "#1abef3", "#6d6bbd"],
      legend: {
        fontSize: "12px",
        markers: {
          width: 8,
          height: 8,
        },
        show: true,
        formatter: function (value, { seriesIndex, dataPointIndex, w }) {
          const totalCount = w.config.series.reduce((a, b) => {
            return a + b;
          }, 0);
          return (
            w.config.labels[seriesIndex] +
            "<br />" +
            (
              parseFloat(w.config.series[seriesIndex] / totalCount) * 100
            ).toFixed(0) +
            "%" +
            " - " +
            parseFloat(w.config.series[seriesIndex]) +
            "</br />"
          );
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: "80%",
            background: "transparent",
            labels: {
              show: false,
              showAlways: false,
              name: {
                show: false,
                formatter: function () {
                  return "Site Sessions";
                },
              },
            },
          },
        },
      },
    },
  };

  const handleCountryGraph = ({ chartWrapper }) => {
    const chart = chartWrapper.getChart();
    const selection = chart.getSelection();
    if (selection.length === 0) return;
    if (summaryData.map.length > 0) {
      const cityData = summaryData.map[selection[0].row].city.map((city) => [
        city.title,
        city.count,
      ]);
      setRegion(summaryData.map[selection[0].row].short_title);
      setChartData([["City", "Count"], ...cityData]);
    }
  };

  const countryGraph = {
    data: chartData,
    options: {
      sizeAxis: { minValue: 0, maxValue: 100 },
      region: region,
      // displayMode: "markers",
      datalessRegionColor: "#e7f0ff",
      colorAxis: { colors: ["#e7f0ff", "#1976d2"] },
      legend: false,

      // defaultColor: "#dddddd",
    },
  };
  const cardStyle = {boxShadow:'none', border:'1px solid rgb(193, 228, 254)'}
  return (
    <>
      <section id={style.analytics}>
      <div className="head-of-head writer-top">
        <div className="table-name">Real-Time Overview</div>
      </div>
      <p className="real-time-p-main">
      See how visitors interact with your site in real time. {" "}
        <span className="learn-more">Learn More</span>
      </p>
        <Container id={style.analytics}>
          <Grid container spacing={4}>
            <Grid item xs={12}></Grid>
            <Grid item xs={8}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Card sx={cardStyle}>
                    <CardContent sx={{ padding: "0px" }}>
                      <Box sx={{ width: "100%" }}>
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          TabIndicatorProps={{
                            style: {
                              height: "4px",
                            },
                          }}
                        >
                          <Tab
                            value="1"
                            className={style.realtimeTabs}
                            label={
                              <>
                                <label className={style.title}>
                                  Visitors in the last 30 minutes: {visitorCount}
                                </label>
                                {/* <h5 className={style.count}>{visitorCount}</h5> */}
                              </>
                            }
                          />
                          <Tab
                            value="2"
                            className={style.realtimeTabs}
                            label={
                              <>
                                <div className={style.livevisitordiv}>
                                <span className="live-indicator"></span>
                                <label className={style.title}>
                                  Live Visitors: {liveVisitorCount}
                                </label>
                                </div>
                                
                              </>
                            }
                          />
                        </Tabs>
                      </Box>
                    </CardContent>
                    <CardContent className="px-4">
                      <div
                        className={style.graphCardAuto}
                        style={{
                          display: "flex",
                          alignItems: `${!visitorCount ? "center" : "start"}`,
                          gap: "1.5rem",
                          marginBottom: "1rem",
                        }}
                      >
                        <div style={{ width: "70%" }}>
                          <Chart
                            chartType="GeoChart"
                            width="100%"
                            height="300px"
                            data={countryGraph.data}
                            options={countryGraph.options}
                            chartEvents={[
                              {
                                eventName: "select",
                                callback: handleCountryGraph,
                              },
                            ]}
                          />
                        </div>
                        <div style={{ width: "30%" }}>
                          {!visitorCount ? (
                            <>
                              <div className={style.noVisitorContent}>
                                <label className={style.title}>
                                  No visitors at the moment
                                </label>
                                <p>
                                  Once you have real-time visitors, you’ll see
                                  what pages they're browsing, how they came to
                                  your site, and more.
                                </p>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                className={`${style.graphCardAuto} ${style.smallBar}`}
                              >
                                <div className={style.progressbarItems}>
                                  {chartData.length > 1 &&
                                    chartData.map((item, i) => {
                                      if (i > 0) {
                                        return (
                                          <div className={style.items}>
                                            <div className={style.content}>
                                              <label className={style.title}>
                                                {item[0]}
                                              </label>
                                              <div className={style.count}>
                                                <span className={style.values}>
                                                  {item[1]}
                                                </span>
                                              </div>
                                            </div>
                                            <LinearProgress
                                              className={
                                                style.linearProgressBar
                                              }
                                              value={100}
                                              color="primary"
                                              variant="determinate"
                                            />
                                          </div>
                                        );
                                      }
                                    })}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className={style.divider}></div>
                    </CardContent>
                    <CardContent className="px-4 pt-0">
                      {!visitorCount ? (
                        <>
                          <div className={style.threeGroup}>
                            <div className={`${style.items} ${style.active}`}>
                              <label className={style.title}>Page Views</label>
                              <div className={style.graphSec}>
                                <span className={style.mutedLine}></span>
                                <span className={style.mutedLine}></span>
                                <span className={style.mutedLine}></span>
                                <span className={style.mutedLine}></span>
                                <span className={style.mutedLine}></span>
                              </div>
                            </div>
                            <div className={`${style.items} ${style.active}`}>
                              <label className={style.title}>
                                Traffic source
                              </label>
                              <div className={style.graphSec}>
                                <span className={style.mutedLine}></span>
                                <span className={style.mutedLine}></span>
                                <span className={style.mutedLine}></span>
                                <span className={style.mutedLine}></span>
                                <span className={style.mutedLine}></span>
                              </div>
                            </div>
                            <div
                              className={`${style.items} ${style.active}`}
                              style={{ width: "45%" }}
                            >
                              <label className={style.title}>Device</label>
                              <div className={style.graphSec}>
                                <span className={style.mutedCircle}></span>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={style.threeGroup}>
                            <div className={`${style.items} ${style.active}`}>
                              <label className={style.title}>Page Views</label>
                              <div className={style.graphSec}>
                                <span className={style.mutedLine}></span>
                                <span className={style.mutedLine}></span>
                                <span className={style.mutedLine}></span>
                                <span className={style.mutedLine}></span>
                                <span className={style.mutedLine}></span>
                              </div>
                            </div>
                            <div className={`${style.items} ${style.active}`}>
                              <label className={style.title}>
                                Traffic source
                              </label>
                              <div className={style.graphSec}>
                                <span className={style.mutedLine}></span>
                                <span className={style.mutedLine}></span>
                                <span className={style.mutedLine}></span>
                                <span className={style.mutedLine}></span>
                                <span className={style.mutedLine}></span>
                              </div>
                            </div>
                            <div
                              className={`${style.items}`}
                              style={{ width: "45%" }}
                            >
                              <label className={style.title}>Device</label>
                              <div
                                className={style.graphCardAuto}
                                style={{ right: "12%" }}
                              >
                                <ReactApexChart
                                  options={SessionDeviceGraph.options}
                                  series={SessionDeviceGraph.series}
                                  type="donut"
                                  width={"100%"}
                                  height={"500px"}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      <div style={{ width: "100%" }}></div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card sx={cardStyle}>
                  <CardContent>
                    <h5 className={style.customerViewsHeadTitle}>
                      Visitor Snapshot
                    </h5>
                  </CardContent>
                  <Divider />
                    <CardContent>
                      <span className={style.title}>
                        Track the current number of visitors at each stage of
                        the shopping process
                      </span>
                    </CardContent>
                    <div className={style.snapshotDiv}>
                      <div className={style.snapshotItem}>
                        <div className={style.iconDiv}>
                          <FiMonitor />
                        </div>
                        <label className={style.title}>Browsing Site: 0</label>
                        {/* <h5 className={style.count}>0</h5> */}
                      </div>
                      <div className={style.snapshotItem}>
                        <div className={style.iconDiv}>
                          <FiShoppingCart />
                        </div>
                        <label className={style.title}>Adding to cart: 0</label>
                        {/* <h5 className={style.count}>0</h5> */}
                      </div>
                      <div className={style.snapshotItem}>
                        <div className={style.iconDiv}>
                          <FiCreditCard />
                        </div>
                        <label className={style.title}>Checking out: 0</label>
                        {/* <h5 className={style.count}>0</h5> */}
                      </div>
                      <div className={style.snapshotItem}>
                        <div className={`${style.iconDiv} ${style.purchesed}`}>
                          <FiCheck />
                        </div>
                        <label className={style.title}>Purchashed: 0</label>
                        {/* <h5 className={style.count}>0</h5> */}
                      </div>
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card sx={cardStyle}>
                  <CardContent>
                    <h5 className={style.customerViewsHeadTitle}>
                      Live Activity
                    </h5>
                  </CardContent>
                  <Divider />
                    <CardContent className={style.recentVisitors}>
                      <div className={style.activityIconDiv}>
                        <FiEye />
                      </div>
                      <div className={style.visitorContentSec}>
                        <label className={style.title}>
                          <strong>Mikey & Darken Tee</strong> was Viewed
                        </label>
                        <span className={style.timeline}>Mar 14, 7:58 PM</span>
                      </div>
                    </CardContent>
                    <Divider />
                    <CardContent className={style.recentVisitors}>
                      <div className={style.activityIconDiv}>
                        <FiEye />
                      </div>
                      <div className={style.visitorContentSec}>
                        <label className={style.title}>
                          <strong>Mikey & Darken Tee</strong> was Viewed
                        </label>
                        <span className={style.timeline}>Mar 14, 7:58 PM</span>
                      </div>
                    </CardContent>
                    <Divider />
                    <CardContent className={style.recentVisitors}>
                      <div className={style.activityIconDiv}>
                        <FiEye />
                      </div>
                      <div className={style.visitorContentSec}>
                        <label className={style.title}>
                          <strong>Mikey & Darken Tee</strong> was Viewed
                        </label>
                        <span className={style.timeline}>Mar 14, 7:58 PM</span>
                      </div>
                    </CardContent>
                    <Divider />
                    <CardContent className={style.recentVisitors}>
                      <div className={style.activityIconDiv}>
                        <FiEye />
                      </div>
                      <div className={style.visitorContentSec}>
                        <label className={style.title}>
                          <strong>Mikey & Darken Tee</strong> was Viewed
                        </label>
                        <span className={style.timeline}>Mar 14, 7:58 PM</span>
                      </div>
                    </CardContent>
                    <Divider />
                    <CardContent className={style.recentVisitors}>
                      <div className={style.activityIconDiv}>
                        <FiEye />
                      </div>
                      <div className={style.visitorContentSec}>
                        <label className={style.title}>
                          <strong>Mikey & Darken Tee</strong> was Viewed
                        </label>
                        <span className={style.timeline}>Mar 14, 7:58 PM</span>
                      </div>
                    </CardContent>
                    <CardContent className={style.recentVisitors}>
                      <div className={style.activityIconDiv}>
                        <FiEye />
                      </div>
                      <div className={style.visitorContentSec}>
                        <label className={style.title}>
                          <strong>Mikey & Darken Tee</strong> was Viewed
                        </label>
                        <span className={style.timeline}>Mar 14, 7:58 PM</span>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Card sx={cardStyle}>
                  <CardContent>
                    <h5 className={style.customerViewsHeadTitle}>
                      Today's Sales
                    </h5>
                  </CardContent>
                  <Divider />
                    <CardContent className={style.todaySalesDiv}>
                      <div className="">
                        <label className={style.title}>Total Sales: ₹ 0.00</label>
                        
                      </div>
                      <Divider
                        orientation="vertical"
                        variant="large"
                        flexItem
                      />
                      <div className="">
                        <label className={style.title}>Orders: 0</label>
                        
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card sx={cardStyle}>
                  <CardContent>
                    <h5 className={style.customerViewsHeadTitle}>
                      Recent Visitors
                    </h5>
                  </CardContent>
                  <Divider />
                    <CardContent className={style.recentVisitors}>
                      <div className={style.visitorsIconDiv}>
                        <FiSmartphone />
                      </div>
                      <div className={style.visitorContentSec}>
                        <label className={style.title}>Visitor #1</label>
                        <div className={style.recentVisitors}>
                          <div className={style.recentVisitorsItem}>
                            <FiMapPin />
                            <span className={style.timeline}>Mumbai</span>
                          </div>
                          <div className={style.recentVisitorsItem}>
                            <FiSmartphone />
                            <span className={style.timeline}>Mobile</span>
                          </div>
                          <div className={style.recentVisitorsItem}>
                            <FiClock />
                            <span className={style.timeline}>7:58 PM</span>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                    <Divider />
                    <CardContent className={style.recentVisitors}>
                      <div className={style.visitorsIconDiv}>
                        <FiSmartphone />
                      </div>
                      <div className={style.visitorContentSec}>
                        <label className={style.title}>Visitor #1</label>
                        <div className={style.recentVisitors}>
                          <div className={style.recentVisitorsItem}>
                            <FiMapPin />
                            <span className={style.timeline}>Mumbai</span>
                          </div>
                          <div className={style.recentVisitorsItem}>
                            <FiSmartphone />
                            <span className={style.timeline}>Mobile</span>
                          </div>
                          <div className={style.recentVisitorsItem}>
                            <FiClock />
                            <span className={style.timeline}>7:58 PM</span>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                    <Divider />
                    <CardContent className={style.recentVisitors}>
                      <div className={style.visitorsIconDiv}>
                        <FiSmartphone />
                      </div>
                      <div className={style.visitorContentSec}>
                        <label className={style.title}>Visitor #1</label>
                        <div className={style.recentVisitors}>
                          <div className={style.recentVisitorsItem}>
                            <FiMapPin />
                            <span className={style.timeline}>Mumbai</span>
                          </div>
                          <div className={style.recentVisitorsItem}>
                            <FiSmartphone />
                            <span className={style.timeline}>Mobile</span>
                          </div>
                          <div className={style.recentVisitorsItem}>
                            <FiClock />
                            <span className={style.timeline}>7:58 PM</span>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                    <Divider />
                    <CardContent className={style.recentVisitors}>
                      <div className={style.visitorsIconDiv}>
                        <FiSmartphone />
                      </div>
                      <div className={style.visitorContentSec}>
                        <label className={style.title}>Visitor #1</label>
                        <div className={style.recentVisitors}>
                          <div className={style.recentVisitorsItem}>
                            <FiMapPin />
                            <span className={style.timeline}>Mumbai</span>
                          </div>
                          <div className={style.recentVisitorsItem}>
                            <FiSmartphone />
                            <span className={style.timeline}>Mobile</span>
                          </div>
                          <div className={style.recentVisitorsItem}>
                            <FiClock />
                            <span className={style.timeline}>7:58 PM</span>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                <Card sx={cardStyle}>
                <CardContent
                    sx={{ display: "flex", alignItems: "center", gap: "1rem" }}
                  >
                    <h5 className={style.customerViewsHeadTitle}>
                      Suggested for you
                    </h5>
                  </CardContent>
                  <Divider />
                  <CardContent sx={{padding:'0 !important'}}>
                    <div className="mo-sales-div" style={{marginBottom:'0',border:'none'}}>
                      <div className="suggested-div">
                        <div
                          className="sugg"
                          onClick={() => {
                            navigate("/analytics/traffic-overview");
                          }}
                        >
                          <div className="sugg-head">
                            <p className="sugg-p">Traffic overview</p>
                            <IoIosArrowForward />
                          </div>
                          <p className="sugg-p-small">
                            Find out where your visitors are based, and how many
                            of them are returning.
                          </p>
                        </div>
                        
                        <div
                          className="sugg"
                          onClick={() => {
                            navigate("/analytics/behavior-overview");
                          }}
                        >
                          <div className="sugg-head">
                            <p className="sugg-p">Behavior overview</p>
                            <IoIosArrowForward />
                          </div>
                          <p className="sugg-p-small">
                            See how visitors interact with your site's pages.
                          </p>
                        </div>
                        <div
                          className="sugg"
                          onClick={() => {
                            navigate("/analytics/story-overview");
                          }}
                        >
                          <div className="sugg-head">
                            <p className="sugg-p">Story overview</p>
                            <IoIosArrowForward />
                          </div>
                          <p className="sugg-p-small">
                            Find out where your visitors are based, and how many
                            of them are returning.
                          </p>
                        </div>
                        <div
                          className="sugg"
                          style={{ borderBottom: "none" }}
                          onClick={() => {
                            navigate("/analytics/marketing-overview");
                          }}
                        >
                          <div className="sugg-head">
                            <p className="sugg-p">Marketing overview</p>
                            <IoIosArrowForward />
                          </div>
                          <p className="sugg-p-small">
                            Track key sales metrics and get insights on your
                            customers.
                          </p>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  );
};

export default RealTime;
