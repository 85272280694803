import React from "react";
import { Routes, Route } from "react-router-dom";
import SubCategoryRoute from "./SubCategoryRoute";
import TagRoute from "./TagRoute";
import SectionRoute from "./SectionRoute";
import ConstructPage from "../components/common/Construct";
import Category from "../pages/category/Category";
import AddCategory from "../pages/category/AddCategory";
import StoriesSelection from "../pages/stories/StoriesSelection";

const CategoryRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<Category />} />
      <Route path="/stories" element={<StoriesSelection />} />
      {/* <Route path="/subcategories/*" element={<SubCategoryRoute />} /> */}
      {/* <Route path="/tags/*" element={<TagRoute />} /> */}
      {/* <Route path="/sections/*" element={<SectionRoute />} /> */}
      {/* <Route path="/add-category" element={<AddCategory />} /> */}
      {/* <Route path="/edit-category/:id" element={<AddCategory />} /> */}
      <Route path="/*" element={<ConstructPage />} />
    </Routes>
  );
};

export default CategoryRoute;
