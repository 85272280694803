import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Table from "../../components/forms-and-submission/ContactFormsTable";
import { exportProducts } from "../../api/admin/Product";
import { Const } from "../../utils/Constants";
import "../../styles/admin/products/products.css";
import { FiPlus } from "react-icons/fi";
// import Profilebtn2 from "../../../components/admin/customers/MoreActionbtn";
import "../../styles/address.css";
import { getCustomers } from "../../api/admin/Customers";
import CustomerPopup from "../../components/admin/customers/CustomerPopup";
import DeletePopup from "../../components/admin/customers/DeletePopup";
import { BsPeople } from "react-icons/bs";
const Customer = () => {
  const columns = ["Customers", "Email", "Phone", "Message", "Timestamp"];

  const rowdata = [
    {
      name: "Anubhav Gupta",
      profileImg:"https://mantisdashboard.io/assets/avatar-6-Cv0iCdgR.png",
      images:["https://mamosh.s3.amazonaws.com/products/v-pic-2024-05-06T11%3A32%3A40.435Z-00775406712-e1.jpg"],
      variantsCount: 0,
      collections:["Trouser","Shorts"],
      productname:"LINEN OVER T-SHIRT",
      email:"anubhav@gmail.com",
      phoneno: 9874562566,
      message:"I live in Uttar Pradesh, India",
      noofproducts:50,
      sku:100,
      discountperunit: 45,
      discountTypeRs:true,
      priceperunit:5000,
      createdAt:"2024-04-19T14:12:38.348Z",
      status: 1,
      emailstatus: 1,
    },
    {
      name: "Anubhav Gupta",
      profileImg:"https://mantisdashboard.io/assets/avatar-6-Cv0iCdgR.png",
      images:["https://mamosh.s3.amazonaws.com/products/v-pic-2024-05-06T11%3A32%3A40.435Z-00775406712-e1.jpg"],
      variantsCount: 0,
      collections:["Trouser","Shorts"],
      productname:"LINEN OVER T-SHIRT",
      email:"anubhav@gmail.com",
      phoneno: 9874562566,
      message:"I live in Uttar Pradesh, India",
      noofproducts:50,
      sku:100,
      discountperunit: 45,
      discountTypeRs:true,
      priceperunit:5000,
      createdAt:"2024-04-19T14:12:38.348Z",
      status: 2,
      emailstatus: 1,
    },
    {
      name: "Anubhav Gupta",
      profileImg:"https://mantisdashboard.io/assets/avatar-6-Cv0iCdgR.png",
      images:["https://mamosh.s3.amazonaws.com/products/v-pic-2024-05-06T11%3A32%3A40.435Z-00775406712-e1.jpg"],
      variantsCount: 0,
      collections:["Trouser","Shorts"],
      productname:"LINEN OVER T-SHIRT",
      email:"anubhav@gmail.com",
      phoneno: 9874562566,
      message:"I live in Uttar Pradesh, India",
      noofproducts:50,
      sku:100,
      discountperunit: 45,
      discountTypeRs:true,
      priceperunit:5000,
      createdAt:"2024-04-19T14:12:38.348Z",
      status: 1,
      emailstatus: 1,
    },
    {
      name: "Anubhav Gupta",
      profileImg:"https://mantisdashboard.io/assets/avatar-6-Cv0iCdgR.png",
      images:["https://mamosh.s3.amazonaws.com/products/v-pic-2024-05-06T11%3A32%3A40.435Z-00775406712-e1.jpg"],
      variantsCount: 0,
      collections:["Trouser","Shorts"],
      productname:"LINEN OVER T-SHIRT",
      email:"anubhav@gmail.com",
      phoneno: 9874562566,
      message:"I live in Uttar Pradesh, India",
      noofproducts:50,
      sku:100,
      discountperunit: 45,
      discountTypeRs:true,
      priceperunit:5000,
      createdAt:"2024-04-19T14:12:38.348Z",
      status: 2,
      emailstatus: 1,
    },
    {
      name: "Anubhav Gupta",
      profileImg:"https://mantisdashboard.io/assets/avatar-6-Cv0iCdgR.png",
      images:["https://mamosh.s3.amazonaws.com/products/v-pic-2024-05-06T11%3A32%3A40.435Z-00775406712-e1.jpg"],
      variantsCount: 0,
      collections:["Trouser","Shorts"],
      productname:"LINEN OVER T-SHIRT",
      email:"anubhav@gmail.com",
      phoneno: 9874562566,
      message:"I live in Uttar Pradesh, India",
      noofproducts:50,
      sku:100,
      discountperunit: 45,
      discountTypeRs:true,
      priceperunit:5000,
      createdAt:"2024-04-19T14:12:38.348Z",
      status: 1,
      emailstatus: 1,
    },
    {
      name: "Anubhav Gupta",
      profileImg:"https://mantisdashboard.io/assets/avatar-6-Cv0iCdgR.png",
      images:["https://mamosh.s3.amazonaws.com/products/v-pic-2024-05-06T11%3A32%3A40.435Z-00775406712-e1.jpg"],
      variantsCount: 0,
      collections:["Trouser","Shorts"],
      productname:"LINEN OVER T-SHIRT",
      email:"anubhav@gmail.com",
      phoneno: 9874562566,
      message:"I live in Uttar Pradesh, India",
      noofproducts:50,
      sku:100,
      discountperunit: 45,
      discountTypeRs:true,
      priceperunit:5000,
      createdAt:"2024-04-19T14:12:38.348Z",
      status: 2,
      emailstatus: 1,
    },
    {
      name: "Anubhav Gupta",
      profileImg:"https://mantisdashboard.io/assets/avatar-6-Cv0iCdgR.png",
      images:["https://mamosh.s3.amazonaws.com/products/v-pic-2024-05-06T11%3A32%3A40.435Z-00775406712-e1.jpg"],
      variantsCount: 0,
      collections:["Trouser","Shorts"],
      productname:"LINEN OVER T-SHIRT",
      email:"anubhav@gmail.com",
      phoneno: 9874562566,
      message:"I live in Uttar Pradesh, India",
      noofproducts:50,
      sku:100,
      discountperunit: 45,
      discountTypeRs:true,
      priceperunit:5000,
      createdAt:"2024-04-19T14:12:38.348Z",
      status: 2,
      emailstatus: 1,
    },
    {
      name: "Anubhav Gupta",
      profileImg:"https://mantisdashboard.io/assets/avatar-6-Cv0iCdgR.png",
      images:["https://mamosh.s3.amazonaws.com/products/v-pic-2024-05-06T11%3A32%3A40.435Z-00775406712-e1.jpg"],
      variantsCount: 0,
      collections:["Trouser","Shorts"],
      productname:"LINEN OVER T-SHIRT",
      email:"anubhav@gmail.com",
      phoneno: 9874562566,
      message:"I live in Uttar Pradesh, India",
      noofproducts:50,
      sku:100,
      discountperunit: 45,
      discountTypeRs:true,
      priceperunit:5000,
      createdAt:"2024-04-19T14:12:38.348Z",
      status: 2,
      emailstatus: 1,
    },
    {
      name: "Anubhav Gupta",
      profileImg:"https://mantisdashboard.io/assets/avatar-6-Cv0iCdgR.png",
      images:["https://mamosh.s3.amazonaws.com/products/v-pic-2024-05-06T11%3A32%3A40.435Z-00775406712-e1.jpg"],
      variantsCount: 0,
      collections:["Trouser","Shorts"],
      productname:"LINEN OVER T-SHIRT",
      email:"anubhav@gmail.com",
      phoneno: 9874562566,
      message:"I live in Uttar Pradesh, India",
      noofproducts:50,
      sku:100,
      discountperunit: 45,
      discountTypeRs:true,
      priceperunit:5000,
      createdAt:"2024-04-19T14:12:38.348Z",
      status: 2,
      emailstatus: 1,
    },
    {
      name: "Anubhav Gupta",
      profileImg:"https://mantisdashboard.io/assets/avatar-6-Cv0iCdgR.png",
      images:["https://mamosh.s3.amazonaws.com/products/v-pic-2024-05-06T11%3A32%3A40.435Z-00775406712-e1.jpg"],
      variantsCount: 0,
      collections:["Trouser","Shorts"],
      productname:"LINEN OVER T-SHIRT",
      email:"anubhav@gmail.com",
      phoneno: 9874562566,
      message:"I live in Uttar Pradesh, India",
      noofproducts:50,
      sku:100,
      discountperunit: 45,
      discountTypeRs:true,
      priceperunit:5000,
      createdAt:"2024-04-19T14:12:38.348Z",
      status: 2,
      emailstatus: 1,
    },
    {
      name: "Anubhav Gupta",
      profileImg:"https://mantisdashboard.io/assets/avatar-6-Cv0iCdgR.png",
      images:["https://mamosh.s3.amazonaws.com/products/v-pic-2024-05-06T11%3A32%3A40.435Z-00775406712-e1.jpg"],
      variantsCount: 0,
      collections:["Trouser","Shorts"],
      productname:"LINEN OVER T-SHIRT",
      email:"anubhav@gmail.com",
      phoneno: 9874562566,
      message:"I live in Uttar Pradesh, India",
      noofproducts:50,
      sku:100,
      discountperunit: 45,
      discountTypeRs:true,
      priceperunit:5000,
      createdAt:"2024-04-19T14:12:38.348Z",
      status: 2,
      emailstatus: 1,
    },
  ]

  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [id, setId] = useState(null);
  const [isEdit, setEdit] = useState(null);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState({});
  const [offset, setOffset] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);
  const [selection, setSelection] = useState({
    select_bulk: false,
    selected: [],
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rowLoading, setRowLoading] = useState(false);

  useEffect(() => {
    document
      .getElementsByClassName("admin-right")[0]
      .addEventListener("scroll", () => {
        if (
          document.getElementsByClassName("main-div")[0] &&
          document.getElementsByClassName("admin-right")[0].scrollTop >
            document.getElementsByClassName("main-div")[0].offsetTop - 150
        ) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      });

    const element = document.getElementsByClassName("table-of")[0];
    if (data.length > 0) {
      element.addEventListener("scroll", handleLoadMore);
    }

    setRowLoading(true);
    const timeout = setTimeout(() => {
      fetchData();
    }, 500);

    return () => {
      element.removeEventListener("scroll", handleLoadMore);
      clearTimeout(timeout);
    };
  }, [offset, isUpdated, filter]);

  const fetchData = async () => {
    const payload = {
      filter: filter,
      limit: Const.Limit,
      offset: offset,
    };
    try {
      const res = await getCustomers(payload);
      if (res) {
        if (offset > 0) {
          setCount(res.count);
          setData((prev) => [...prev, ...res.data]);
          return;
        }
        setCount(res.count);
        setData(res.data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
      setRowLoading(false);
    }
  };

  const handleLoadMore = () => {
    const element = document.getElementsByClassName("table-of")[0];
    if (
      element.scrollHeight - element.scrollTop === element.clientHeight &&
      data.length < count
    ) {
      setRowLoading(true);
      setOffset((prevOffset) => prevOffset + Const.Limit);
      return;
    }
  };

  const handleSearch = (event) => {
    const { name, value } = event.target;
    const updatedFilter = { ...filter, [name]: value };
    if (!value) {
      delete updatedFilter[name];
    }
    setOffset(0);
    setFilter(updatedFilter);
  };

  const handleSelection = (event) => {
    const { id, checked } = event.target;
    if (id === "selection" || id === "selection-toggle") {
      setSelection({ ...selection, select_bulk: checked, selected: [] });
    } else {
      if (selection.select_bulk) {
        if (checked) {
          const filterSelected = selection.selected.filter((select) => {
            return select !== id;
          });
          setSelection({ ...selection, selected: filterSelected });
        } else {
          setSelection({ ...selection, selected: [...selection.selected, id] });
        }
      } else {
        if (checked) {
          setSelection({ ...selection, selected: [...selection.selected, id] });
        } else {
          const filterSelected = selection.selected.filter((select) => {
            return select !== id;
          });
          setSelection({ ...selection, selected: filterSelected });
        }
      }
    }
  };

  const handleFilter = (event) => {
    const { name, value } = event.target;
    if (name === "age") {
      setFilter((prevFilter) => ({
        ...prevFilter,
        [name]: { ...prevFilter[name], min: value[0], max: value[1] },
      }));
    } else if (name === "country" || name === "city" || name === "state") {
      const updatedFilter = { ...filter, [name]: value };
      if (!value) {
        delete updatedFilter[name];
      }
      setFilter(updatedFilter);
    } else {
      const updatedFilter = { ...filter, [name]: Number(value) };
      if (!value) {
        delete updatedFilter[name];
      }
      setFilter(updatedFilter);
    }
  };

  const handleExport = async () => {
    try {
      const res = await exportProducts(selection);
      if (!res.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await res.blob();

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };
  // if (isLoading) return <ProductListLoader />;
  return (
    <>
      
      <div className="head-of-head writer-top">
        <div className="table-name">Contact Form Submission ({count})</div>
        <div className="fixed-right">
          <button
            className="btn-primary"
            onClick={() => {
              setEdit(null);
              setIsOpen(true);
            }}
          >
            <FiPlus /> Add Contacts
          </button>
        </div>
      </div>
      <p className="p-top-writer">
      Manage and track your contact form submissions and get the info you need. <span className="learn-more">Learn More</span>
      </p>
      <div className="main-div">
        <Table
          count={count}
          columns={columns}
          rows={rowdata}
          selection={selection}
          filter={filter}
          rowLoading={rowLoading}
          setId={setId}
          setEdit={setEdit}
          setModal={setIsOpen}
          setIsUpdated={setIsUpdated}
          handleExport={handleExport}
          handleSelection={handleSelection}
          handleSearch={handleSearch}
          handleFilter={handleFilter}
          setIsDelete={setIsDelete}
        />
      </div>

      {isOpen && (
        <CustomerPopup
          customerId={data?.[id]?._id ?? null}
          setId={setId}
          setModal={setIsOpen}
          setIsUpdated={setIsUpdated}
        />
      )}

      {isDelete && (
        <DeletePopup
          customerId={data?.[id]?._id ?? null}
          setId={setId}
          setModal={setIsDelete}
          data={data}
          setCount={setCount}
          setData={setData}
        />
      )}
    </>
  );
};

export default Customer;
