import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import "../../styles/common/StatusDrop.css";
import {Const} from "../../utils/Constants"
import { updateStatus, updateSchedule } from "../../api/Stories";
import DateTimePopup from "../../components/fields/DateTimePopUp"
import dayjs from 'dayjs';
import moment from "moment-timezone";

const options = [
  { value: Const.Active, label: "Published", color: "#2684FF" },
  { value: Const.Inactive, label: "Unpublished", color: "#ff4d4f" },
  { value: Const.Scheduled, label: "Scheduled", color: "rgb(195 111 72)" },
  { value: Const.Draft, label: "Draft", color: "rgb(100, 100, 100)" },
  
];

const customStyles = (selectedOption) => ({
  option: (provided, state) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    color: state.isSelected ? "white" : "black",
    backgroundColor: state.isSelected ? "#2684FF" : "white",
    borderBottom: "1px solid #ccc",
    zIndex: "8",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "#f0f4f7",
      color: "black",
    },
  }),
  control: (provided, state) => {
    let backgroundColor;
    switch (selectedOption?.value) {
      case 0:
        backgroundColor = "#ffeff0";
        break;
      case 3:
        backgroundColor = "rgb(220, 220, 220)";
        break;
      case 4:
        backgroundColor = "rgb(251 216 186)";
        break;
      case 1:
      default:
        backgroundColor = "#e6f4ff";
        break;
    }

    return {
      ...provided,
      backgroundColor,
      border: `1px solid ${backgroundColor}`,
      borderRadius: "5px",
      color: "#3b82f6 !important",
      fontSize: "12px",
      minHeight: "28px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "110px",
      boxShadow: state.isFocused ? "none" : provided.boxShadow,
      ":hover": {
        borderColor: backgroundColor,
      },
    };
  },
  indicatorSeparator: (provided) => ({
    ...provided,
    width: 0,
  }),
  indicatorContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
  ValueContainer: (provided) => ({
    ...provided,
    padding: "0 !important", // Set padding to 0
  }),
  singleValue: (provided) => {
    let color;
    switch (selectedOption?.value) {
      case 0:
        color = "#ff4d4f";
        break;
      case 3:
        color = "rgb(100, 100, 100)";
        break;
      case 4:
        color = "rgb(195 111 72)";
        break;
      case 1:
      default:
        color = "#3784f6";
        break;
    }

    return {
      ...provided,
      color,
      marginRight: "0px",
      marginLeft: "0px",
      maxWidth: "71px",
    };
  },
});

const CustomOption = (props) => (
  <components.Option {...props}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: 20,
          height: 10,
          backgroundColor: props.data.color,
          border: "1px solid white",
          marginRight: 10,
        }}
      ></div>
      {props.data.label}
    </div>
  </components.Option>
);

function Statusdrop({rowId, currentStatus,setIsUpdated,pubDate}) {
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [isSchedule, setIsSchedule] = useState(false);
  const [schedule, setSchedule] = React.useState({
    date: null,
    time: null,
  });
  const [publishDate, setPublishDate] = useState(null);
  const [openDatePicker, setOpenDatePicker] = useState(false)
  const handleStatus = ()=>{
    if(currentStatus==1){
      setSelectedOption(options[0]);
    }
    else if(currentStatus==0){
      setSelectedOption(options[1]);
    }
    else if(currentStatus==3){
      setSelectedOption(options[3]);
    }
    else if(currentStatus==4){
      setSelectedOption(options[2]);
      setIsSchedule(true)
    }
  }
  const formatDateResponse = (dateTimeString)=> {
    const date = new Date(dateTimeString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    const strTime = `${String(hours).padStart(2, '0')}:${minutes} ${ampm}`;

    return `${day}-${month}-${year} ${strTime}`;
}
const formatDateTimeIST=(dateTimeString)=> {
  const date = new Date(dateTimeString);

  // IST offset in milliseconds
  const ISTOffset = 5.5 * 60 * 60 * 1000;

  // Converting the date to IST
  const ISTDate = new Date(date.getTime() + ISTOffset);

  const day = String(ISTDate.getDate()).padStart(2, '0');
  const month = String(ISTDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = ISTDate.getFullYear();

  let hours = ISTDate.getHours();
  const minutes = String(ISTDate.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // The hour '0' should be '12'
  const strTime = `${String(hours).padStart(2, '0')}:${minutes} ${ampm}`;

  return `${day}-${month}-${year} ${strTime}`;
}

const formatDateIST = (dateTimeString) => {
  const utcDate = moment.utc(dateTimeString);

    // Format the UTC date-time
    const formattedDate = utcDate.format('DD-MM-YYYY h:mm A');

    return formattedDate;
}
  useEffect(()=>{
    if(pubDate){
      setPublishDate(formatDateIST(pubDate))
    }
    handleStatus();
  },[currentStatus])

  const handleCancle = ()=>{
    setOpenDatePicker(false)
    const row = options.find((row)=> row.value===currentStatus)
    setSelectedOption(row)
  }

  const handleOnlyScheduled = async()=>{
    try{
      const payloadstatus = {status:selectedOption.value}
      await updateStatus(rowId,payloadstatus);
      const payload = {scheduled:formatDateTime(schedule.date, schedule.time)}
      await updateSchedule(rowId,payload)
      setIsUpdated((prev)=>!prev);
    }
    catch(err){
      console.error(err)
    }
  }


  const handleSchedule = async (option) => {
    // setIsSchedule(option.value === 4);
    if(option.value==4){
      setOpenDatePicker(true)
    }
    setSelectedOption(option);
    try{
      const payload = {status:option.value}
      if(option.value!==4){
        await updateStatus(rowId,payload);
        setIsUpdated((prev)=>!prev);
      }
    }
    catch(e){
      console.error(e)
    }
  };


  const formatDateTime = (date, time) => {
    if (!date) return "None selected";
    if (!time) return dayjs(date).format("YYYY-MM-DD");
    const formattedDate = dayjs(date).format("DD-MM-YYYY");
    const formattedTime = dayjs(time).format("HH:mm");
    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <div>
      <Select
        styles={{
          ...customStyles(selectedOption),
          dropdownIndicator: (base) => ({
            ...base,
            padding: 0,
            width: "20px",
            paddingRight: "5px",
            color: selectedOption.color, // Dynamic color based on selected option
            "&:hover": {
              color: selectedOption.color, // Replace with your desired hover color
            },
          }),
        }}
        options={options}
        value={selectedOption}
        className="react-select-container"
        classNamePrefix="react-select"
        isSearchable={false}
        onChange={handleSchedule}
        components={{ Option: CustomOption }}
      />
      {openDatePicker && <DateTimePopup schedule={schedule} setSchedule={setSchedule} setOpenDatePicker={setOpenDatePicker} handleOnlyScheduled={handleOnlyScheduled} handleCancle={handleCancle} />}
      {currentStatus==4 && <p className="storiespsmall">{publishDate ? publishDate : formatDateTime(schedule.date, schedule.time)}</p>}
      {/* {currentStatus==4 && <p className="storiespsmall">7th July 12PM</p>} */}
    </div>
  );
}

export default Statusdrop;
