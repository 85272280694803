import React, { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import style from "../../../styles/admin/customers/CustomerPopup.module.css";
import { Divider } from "@mui/material";
import { countries } from "../../../utils/Constants";
import {
  editCustomer,
  insertCustomer,
  updateCustomer,
} from "../../../api/admin/Customers";

const CustomerPopup = ({ setModal, customerId, setIsUpdated, setId }) => {
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [input, setInput] = useState({});

  useEffect(() => {
    if (customerId) {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    try {
      const res = await editCustomer(customerId);
      if (res) {
        setInput(res);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const handleAddress = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, address: { ...input.address, [name]: value } });
  };

  const handleUpdate = async () => {
    const payload = {
      id: customerId,
      ...input,
    };
    try {
      const res = await updateCustomer(payload);
      if (res) {
        setIsUpdated((prevState) => !prevState);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setId(null);
      setIsBtnLoading(false);
      setModal(false);
    }
  };

  const handleSumbit = async () => {
    try {
      const res = await insertCustomer(input);
      if (res) {
        setIsUpdated((prevState) => !prevState);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setId(null);
      setIsBtnLoading(false);
      setModal(false);
    }
  };
  return (
    <div className={style.modal}>
      <OutsideClickHandler
        onOutsideClick={() => {
          setModal(false);
        }}
      >
        <div className={style.card}>
          <div className={style.header}>
            <h4>Create a new contact</h4>
          </div>
          <Divider />
          <div className={style.body}>
            <div className={style.box}>
              <div className={style.formControl}>
                <label>Name</label>
                <div className={style.formGroup}>
                  <input
                    type="text"
                    name="firstname"
                    placeholder="First Name"
                    value={input?.firstname ?? ""}
                    onChange={handleOnChange}
                  />
                  <input
                    type="text"
                    name="lastname"
                    placeholder="Last Name"
                    value={input?.lastname ?? ""}
                    onChange={handleOnChange}
                  />
                </div>
              </div>
              <div className={style.formControl}>
                <label>Phone No</label>
                <div className={style.formGroup}>
                  <input
                    type="text"
                    name="phoneno"
                    placeholder="Phone Number"
                    value={input?.phoneno ?? ""}
                    onChange={handleOnChange}
                  />
                </div>
              </div>
              <div className={style.formControl}>
                <label>Email</label>
                <div className={style.formGroup}>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    value={input?.email ?? ""}
                    onChange={handleOnChange}
                  />
                </div>
              </div>
              <div className={style.formControl}>
                <label>Address</label>
                <div className={style.formGroup}>
                  <input
                    type="number"
                    name="flat"
                    placeholder="Flat No"
                    value={input?.address?.flat ?? ""}
                    onChange={handleAddress}
                    style={{ width: "100px" }}
                  />
                  <input
                    type="text"
                    name="addressline1"
                    placeholder="Address Line 1"
                    value={input?.address?.addressline1 ?? ""}
                    onChange={handleAddress}
                  />
                  <input
                    type="text"
                    name="addressline2"
                    placeholder="Address Line 2 (Optional)"
                    value={input?.address?.addressline2 ?? ""}
                    onChange={handleAddress}
                  />
                  <input
                    type="number"
                    name="pincode"
                    placeholder="Zip/ Postal Code"
                    value={input?.address?.pincode ?? ""}
                    onChange={handleAddress}
                    style={{ width: "calc(50% - 5px)", marginRight: "5px" }}
                  />
                  <select
                    name="country"
                    value={input?.address?.country ?? ""}
                    onChange={handleAddress}
                    style={{ width: "calc(50% - 5px)", marginLeft: "5px" }}
                    required
                  >
                    <option value={""} disabled selected>
                      Country
                    </option>
                    {countries.map((country) => {
                      return (
                        <option value={country.name}>{country.name}</option>
                      );
                    })}
                  </select>
                  <input
                    type="text"
                    name="city"
                    placeholder="City"
                    value={input?.address?.city ?? ""}
                    onChange={handleAddress}
                    style={{ width: "calc(50% - 5px)", marginRight: "5px" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={style.footer}>
            <button
              className={style.btnPrimaryOutline}
              onClick={() => {
                setModal(false);
              }}
            >
              Cancel
            </button>
            <button
              className={`${style.btnPrimary}`}
              disabled={isBtnLoading ? true : false}
              onClick={() => {
                setIsBtnLoading(true);
                customerId ? handleUpdate() : handleSumbit();
              }}
            >
              {isBtnLoading ? <span className="spin-loader"></span> : "Save"}
            </button>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default CustomerPopup;
