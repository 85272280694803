import React, { useEffect, useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
import { MdOutlineDone } from 'react-icons/md';
import { useToast } from './ToastContext';

const Toaster = () => {
  const { toasts, removeToast } = useToast();
  const [visibleToasts, setVisibleToasts] = useState([]);

  useEffect(() => {
    setVisibleToasts(toasts);
  }, [toasts]);

  const handleRemoveToast = (id) => {
    // Add exit class
    setVisibleToasts((prevToasts) =>
      prevToasts.map((toast) =>
        toast.id === id ? { ...toast, exiting: true } : toast
      )
    );
    // Remove toast after exit animation
    setTimeout(() => {
      removeToast(id);
    }, 500); // Match the duration of the slide-out animation
  };

  return (
    <div className="toast-container">
      {visibleToasts.map((toast) => (
        <div
          key={toast.id}
          className={`tooster ${toast.type === 'success' ? 'tooster-success' : 'tooster-fail'} ${toast.exiting ? 'tooster-exit' : ''}`}
        >
          <div className="tooster">
            <div className="tick-toaster-span">
              {toast.type === 'success' ? <MdOutlineDone className="tick-toaster" /> : <RxCross2 className="tick-toaster" />}
            </div>
            <p className="tooster-p">{toast.message}</p>
          </div>
          <div className="cross-toaster-div">
            <RxCross2 className="cross-toaster" onClick={() => handleRemoveToast(toast.id)} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Toaster;
