import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import style from "../../styles/common/LogoutPopup.module.css";
import Cookies from "js-cookie";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
const LogoutPopup = (props) => {
  const navigate = useNavigate();

  const handleCancel = (event) => {
    props.setIsConfirm(false);
  };
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    Cookies.remove("token");
    Cookies.remove("role");
    toast.success("Logout Successfully");
    navigate("/login");
    // router.push("/home")
  };
  return (
    <>
      {/* <div className={`log-out ${!props.isConfirm ? "log-out-inactive" : ""}`}>
        <div className="log-out-background-blur" onClick={handleCancel}></div>
        <div className="log-out-main">
          <div className="log-out-del">
            <AiFillDelete />
          </div>
          <h2>Are you sure you want to Log out?</h2>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quos,
            tempore. Lorem ipsum dolor sit amet.
          </p>
          <div className="log-out-btn">
            <button className="lo-btn lo-btn-1" onClick={handleCancel}>
              Cancel
            </button>
            <button className="lo-btn lo-btn-2" onClick={logout}>
              Logout
            </button>
          </div>
        </div>
      </div> */}

      <div className={style.modal}>
        {/* <Toaster/> */}
        <OutsideClickHandler
          onOutsideClick={handleCancel}
        >
          <div className={style.card}>
            <div className={style.cardBody}>
              <div className={style.imageSec}>
                <img
                  src="https://img.freepik.com/free-vector/key-concept-illustration_114360-6005.jpg"
                  className="img-pop"
                />
              </div>
              <div className={style.contentSec}>
                <h4>Are you sure you want to Log out?</h4>
                <p className={style.subheading}>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quos, tempore. Lorem ipsum dolor sit amet.
                </p>
              </div>
            </div>
            <div className={style.footer}>
              <button
                className={style.btnDangerOutline}
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                className={style.btnDanger}
                onClick={logout}
              >
                Logout
              </button>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    </>
  );
}

export default LogoutPopup;
