import Headers from './Headers';
import { ProcessAPI, Const } from '../utils/Constants';

// Get List
export const getArticleCategoryList = async (body) => {
    const res = await fetch(Const.Link + "api/categories/get-category", new Headers("POST", body));
    return ProcessAPI(res);
}

export const getListCategory = async (body) => {
    const res = await fetch(Const.Link + `api/categories/list?search=${body.search}&limit=${body.limit}&offset=${body.offset}`, new Headers("GET"));
    return ProcessAPI(res);
}

// Create 
export const createCategory = async (body) => {
    const res = await fetch(Const.Link + "api/categories/save", new Headers("POST", body, true));
    return res.json();
}

export const updateSequenceCategory = async (body) => {
    const res = await fetch(Const.Link + "api/categories/update-sequence", new Headers("PUT",body));
    return ProcessAPI(res);
}

// UpdateStatus
export const updateStatus = async (id,body) => {
    const res = await fetch(Const.Link + "api/categories/update-status/"+ id, new Headers("PUT",body));
    return ProcessAPI(res);
}

// Update 
export const updateCategory = async (id, body) => {
    const res = await fetch(Const.Link + "api/categories/update/" + id, new Headers("PATCH", body, true));
    return res.json();
}

// Edit
export const editCategory = async (id) => {
    const res = await fetch(Const.Link + "api/categories/edit/"+ id, new Headers("GET"));
    return ProcessAPI(res);
}

// Duplicate
export const duplicateCategory = async (body) => {
    const res = await fetch(Const.Link + "api/categories/create-duplicate", new Headers("POST",body));
    return ProcessAPI(res);
}

// Export
export const exportCategory = async (body) => {
    const res = await fetch(Const.Link + "api/categories/export", new Headers("POST",body));
    return res;
}

// Delete 
export const categoryDelete = async (id) => {
    const res = await fetch(Const.Link + "api/categories/delete/" + id, new Headers("PATCH"));
    return ProcessAPI(res);
}

// Parmanent Delete 
export const parmanentDeleteCategory = async (id) => {
    const res = await fetch(Const.Link + "api/categories/parmanent-delete/" + id, new Headers("DELETE"));
    return ProcessAPI(res);
}