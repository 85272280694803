import Headers from './Headers';
import { ProcessAPI, Const } from '../utils/Constants';

// Get List
export const getListWriter = async (body) => {
    const res = await fetch(Const.Link + `api/writer/list?search=${body.search}&limit=${body.limit}&offset=${body.offset}`, new Headers("GET"));
    return ProcessAPI(res);
}

// Create 
export const createWriter = async (body) => {
    const res = await fetch(Const.Link + "api/writer/save", new Headers("POST", body, true));
    return res.json();
}

// Update 
export const updateWriter = async (id, body) => {
    const res = await fetch(Const.Link + "api/writer/update/" + id, new Headers("PATCH", body, true));
    return res.json();
}

// Edit
export const editWriter = async (id) => {
    const res = await fetch(Const.Link + "api/writer/edit/"+ id, new Headers("GET"));
    return ProcessAPI(res);
}

// Duplicate
export const duplicateWriter = async (body) => {
    const res = await fetch(Const.Link + "api/writer/create-duplicate", new Headers("POST", body));
    return ProcessAPI(res);
}

// Export
export const exportWriter = async (body) => {
    const res = await fetch(Const.Link + "api/writer/export", new Headers("POST", body));
    return res;
}

// Delete 
export const writerDelete = async (id) => {
    const res = await fetch(Const.Link + "api/writer/delete/" + id, new Headers("PATCH"));
    return ProcessAPI(res);
}

// Parmanent Delete 
export const parmanentDeleteWriter = async (id) => {
    const res = await fetch(Const.Link + "api/writer/parmanent-delete/" + id, new Headers("DELETE"));
    return ProcessAPI(res);
}

export const getWriter = async () => {
    const res = await fetch(Const.Link + "api/writer/get-writer", new Headers("GET"));
    return ProcessAPI(res);
}