import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import { useState, useEffect } from "react";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Assistant from "./Assistant";
import Basics from "./Basics";
import Advanced from "./Advanced";
import Social from "./Social";
import "../../../styles/stories/seo.css";

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#1890ff",
  },
  "& .MuiTabs-flexContainer": {
    justifyContent: "space-between",
    overflowX: "scroll",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",

    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Seo = ({
  title,
  excerpt,
  meta,
  images,
  ogImage,
  setOgImage,
  twitterImage,
  setTwitterImage,
  slug,
  isCanonical,
  setIsCanonical,
  selectedKeywords,
  setSelectedKeywords,
  handleMetaData,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="div-tab">
        <Box sx={{ width: "100%" }}>
          <AntTabs
            value={value}
            onChange={handleChange}
            aria-label="ant example"
            sx={{ borderColor: "rgba(0,0,0,0.2)" }}
          >
            <AntTab label="Assistant" />
            <AntTab label="Basics" />
            <AntTab label="Advanced" />
            <AntTab label="Social share" />
          </AntTabs>
          <CustomTabPanel value={value} index={0}>
            <Assistant />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Basics
              meta={meta}
              slug={slug}
              isCanonical={isCanonical}
              setIsCanonical={setIsCanonical}
              selectedKeywords={selectedKeywords}
              setSelectedKeywords={setSelectedKeywords}
              handleMetaData={handleMetaData}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <Advanced />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <Social
              title={title}
              excerpt={excerpt}
              images={images}
              ogImage={ogImage}
              setOgImage={setOgImage}
              twitterImage={twitterImage}
              setTwitterImage={setTwitterImage}
              meta={meta}
              handleMetaData={handleMetaData}
            />
          </CustomTabPanel>
        </Box>
      </div>
    </>
  );
};

export default Seo;
