import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import style from "../../styles/common/DeletePopup.module.css";

const DeletePopup = ({ handleDelete, setDelete }) => {
  return (
    <div className={style.modal}>
      {/* <Toaster/> */}
      <OutsideClickHandler
        onOutsideClick={() => {
          setDelete(null);
        }}
      >
        <div className={style.card}>
          <div className={style.cardBody} style={{ alignItems: "center" }}>
            <div className={style.imageSec}>
              <img
                src="https://img.freepik.com/free-vector/recycling-concept-illustration_114360-5872.jpg"
                className="img-pop"
              />
            </div>
            <div className={style.contentSec}>
              <h4>Delete this story</h4>
              <p className={style.subheading}>
                The story will be deleted, but you can restore it within 90 days
                from the Trash tab. After 90 days, it will be permanently
                deleted.
              </p>
              <div className={style.box}>
                <span>Note:</span>
                <p>
                  The Trash tab is located at the top right corner of the
                  Stories tab.
                </p>
              </div>
            </div>
          </div>
          <div className={style.footer}>
            <button
              className={style.btnDangerOutline}
              onClick={() => {
                setDelete(null);
              }}
            >
              Cancel
            </button>
            <button
              className={style.btnDanger}
              onClick={() => {
                handleDelete();
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default DeletePopup;
