import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Divider,
  LinearProgress,
  Tab,
  Tabs,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { Chart } from "react-google-charts";
import style from "../../components/analytics/Analytics.module.css";
import { getTrafficAnalytics } from "../../api/admin/Analytics";
import { ga4FormatDate } from "../../utils/Util";
import dayjs from "dayjs";
import DateRangePicker from "../../components/fields/DateRangePicker";
import { BorderAll } from "@mui/icons-material";
import { IoIosArrowForward } from "react-icons/io";

const Traffic = () => {
  let countrySession = [];
  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);
  const [siteSession, setSitSession] = useState(0);
  const [uniqueVisitor, setUniqueSession] = useState(0);
  const [newReturning, setNewReturning] = useState([]);
  const [DeviceSession, setDeviceSession] = useState([]);
  const [overTimeSession, setOverTimeSession] = useState([]);
  const [sourcesSession, setSourcesSession] = useState([]);
  const [averageSession, setAverageSession] = useState([]);
  const [averageSessionGraph, setAverageSessionGraph] = useState({});
  const [chartData, setChartData] = useState([["Country", "Count"]]);
  const [dateRange, setDateRange] = useState([
    {
      startDate: dayjs()
        .subtract(30, "days")
        .startOf("day")
        .format("YYYY-MM-DD"),
      endDate: dayjs().endOf("day").format("YYYY-MM-DD"),
    },
  ]);
  const [avgToggleVal, setAvgToggleVal] = useState(0);
  const [region, setRegion] = useState("world");
  const [avgSessionToggleValue, setAvgSessionToggleValue] = useState("days");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document
      .getElementsByClassName("admin-right")[0]
      .addEventListener("scroll", () => {
        if (
          document.getElementsByClassName("ad-mp")[0] &&
          document.getElementsByClassName("admin-right")[0].scrollTop >
            document.getElementsByClassName("ad-mp")[0].offsetTop - 240
        ) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      });
    fetchDate();
  }, [dateRange]);
  const PaidChart = {
    series: [
      {
        name: "Paid",
        data: [200, 600, 100, 400, 300, 400, 50],
      },
    ],
    options: {
      chart: {
        type: "area",
        width: "100%",
        height: "100%",
        toolbar: {
          show: false,
        },
      },
      colors: ["#65B741"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 1,
        curve: "smooth",
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "vertical",
          shadeIntensity: 0,
          opacityFrom: 0.7,
          opacityTo: 0.2,
        },
      },
      legend: {
        show: false,
      },
      xaxis: {
        show: false,
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        show: false,
      },
      grid: {
        show: false,
      },
    },
  };

  const fetchDate = async () => {
    const payload = {
      timeFrame: dateRange,
    };
    try {
      const res = await getTrafficAnalytics(payload);
      if (res) {
        setSitSession(res.site_session);
        setUniqueSession(res.unique_visitor);
        setNewReturning(res.new_vs_returing_visitor);
        setDeviceSession(res.session_by_device);
        setOverTimeSession(res.session_over_time);
        setSourcesSession(res.session_sources);
        countrySession = res.session_by_country;
        setAverageSession(res.average_session_days);
        
        if (res.average_session_days.length > 0) {
          const avgSessionData = res.average_session_days.reduce(
            (acc, day) => {
              acc.labels.push(day.title.substring(0, 3));
              acc.data.push(day.count);
              return acc;
            },
            { labels: [], data: [] }
          );
          console.log(averageSession,"abeb")
          setAverageSessionGraph(avgSessionData);
        }
      
        if (res.session_by_country.length > 0) {
          const countryData = res.session_by_country.map((country) => [
            country.title,
            country.count,
          ]);
          setChartData([["Country", "Count"], ...countryData]);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCountryGraph = useCallback(
    ({ chartWrapper }) => {
      const chart = chartWrapper.getChart();
      const selection = chart.getSelection();
      if (selection.length === 0) return;
      if (countrySession.length > 0) {
        const getCountryIndex = selection[0].row;
        const cityData = countrySession[getCountryIndex].city.map((city) => [
          city.title,
          city.count,
        ]);
        setRegion(countrySession[getCountryIndex].countryId);
        setChartData([["City", "Count"], ...cityData]);
      }
    },
    [countrySession]
  );

  const handleDaysHours = (event, newValue) => {
    const avgSessionData = averageSession[newValue].hours.reduce(
      (acc, day) => {
        acc.labels.push(day.title);
        acc.data.push(day.count);
        return acc;
      },
      { labels: [], data: [] }
    );
    setAverageSessionGraph(avgSessionData);
    setAvgToggleVal(newValue);
  };

  const handleAvgSessionToggle = (event) => {
    const { value } = event.target;
    if (value === "hours") {
      setAvgSessionToggleValue(value);
      const avgSessionData = averageSession[avgToggleVal].hours.reduce(
        (acc, day) => {
          acc.labels.push(day.title);
          acc.data.push(day.count);
          return acc;
        },
        { labels: [], data: [] }
      );
      setAverageSessionGraph(avgSessionData);
      return;
    }
    setAvgSessionToggleValue(value);
    const avgSessionData = averageSession.reduce(
      (acc, day) => {
        acc.labels.push(day.title.substring(0, 3));
        acc.data.push(day.count);
        return acc;
      },
      { labels: [], data: [] }
    );
    setAverageSessionGraph(avgSessionData);
  };

  const handleDateRangePicker = (start, end) => {
    const date = [
      {
        startDate: start,
        endDate: end,
      },
    ];
    setDateRange(date);
  };

  const overTimeSessionLabel = overTimeSession.map((item) =>
    ga4FormatDate(item.date)
  );
  const overTimeSessionData = overTimeSession.map((item) => item.count);

  const SessionOverTimeGraph = {
    series: [{ name: "Session Over Time", data: overTimeSessionData }],
    options: {
      chart: {
        type: "area",
        width: "100%",
        height: "100%",
        toolbar: { show: false },
      },
      colors: ["#1684ea"],
      dataLabels: { enabled: false },
      stroke: { show: true, width: 2, curve: "smooth" },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "vertical",
          shadeIntensity: 0,
          opacityFrom: 0.7,
          opacityTo: 0.2,
        },
      },
      legend: { position: "top", horizontalAlign: "left" },
      xaxis: {
        type: "date",
        categories: overTimeSessionLabel,
        tickAmount: 10,
        labels: { rotate: 0 },
      },
      yaxis: { min: 0, max: 60, tickAmount: 6 },
    },
  };

  const newReturningData = newReturning.map((item) => item.count);
  const newReturningLabel = newReturning.map(
    (item) => item.title.charAt(0).toUpperCase() + item.title.slice(1)
  );

  const ReturnVisitorGraph = {
    series: newReturningData,
    options: {
      labels: newReturningLabel,
      chart: { height: "100%", type: "donut" },
      stroke: { width: 0 },
      dataLabels: { enabled: false },
      colors: ["#1684ea", "#1abef3"],
      legend: {
        show: true,
        formatter: (value, { seriesIndex, dataPointIndex, w }) => {
          const totalCount = w.config.series.reduce((a, b) => a + b, 0);
          return (
            w.config.labels[seriesIndex] +
            "<br />" +
            parseFloat(w.config.series[seriesIndex]) +
            " - " +
            (
              parseFloat(w.config.series[seriesIndex] / totalCount) * 100
            ).toFixed(0) +
            "%" +
            "</br />"
          );
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: "80%",
            background: "transparent",
            labels: {
              show: true,
              showAlways: true,
              name: { show: true, formatter: () => "Unique Visitor" },
              total: {
                show: true,
                formatter: (val) =>
                  val.globals.seriesTotals.reduce((a, b) => a + b, 0),
              },
            },
          },
        },
      },
    },
  };

  const sessionSeviceData = DeviceSession.map((item) => item.count);
  const sessionSeviceLabel = DeviceSession.map((item) => item.title);

  const SessionDeviceGraph = {
    series: sessionSeviceData,
    options: {
      labels: sessionSeviceLabel,
      chart: { height: "100%", type: "donut" },
      stroke: { width: 0 },
      dataLabels: { enabled: false },
      colors: ["#1684ea", "#1abef3", "#6d6bbd"],
      legend: {
        show: true,
        formatter: (value, { seriesIndex, dataPointIndex, w }) => {
          const totalCount = w.config.series.reduce((a, b) => a + b, 0);
          return (
            w.config.labels[seriesIndex] +
            "<br />" +
            parseFloat(w.config.series[seriesIndex]) +
            " - " +
            (
              parseFloat(w.config.series[seriesIndex] / totalCount) * 100
            ).toFixed(0) +
            "%" +
            "</br />"
          );
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: "80%",
            background: "transparent",
            labels: {
              show: true,
              showAlways: true,
              name: { show: true, formatter: () => "Site Sessions" },
              total: {
                show: true,
                formatter: (val) =>
                  val.globals.seriesTotals.reduce((a, b) => a + b, 0),
              },
            },
          },
        },
      },
    },
  };
  console.log(averageSessionGraph,"ave")
  const AvgSessionGraph = {
    series: [{ name: "Avg Session", data: averageSessionGraph.data }],
    options: {
      chart: {
        type: "bar",
        width: "100%",
        height: "100%",
        toolbar: { show: false },
        // events: {
        //   dataPointSelection: (event, chartContext, config) => {
        //     const key = config.dataPointIndex;
        //     setAvgToggleVal(key);
        //     setAvgSessionToggleValue("hours");
        //     const avgSessionData = averageSession[key].hours.reduce(
        //       (acc, day) => {
        //         acc.labels.push(day.title);
        //         acc.data.push(day.count);
        //         return acc;
        //       },
        //       { labels: [], data: [] }
        //     );
        //     setAverageSessionGraph(avgSessionData);
        //   }
        // }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "100%",
          endingShape: "rounded",
          borderRadius: 5,
        },
      },
      dataLabels: { enabled: false },
      stroke: { show: true, width: 15, colors: ["transparent"] },
      xaxis: {
        categories: averageSessionGraph.labels,
         tickAmount: avgSessionToggleValue === "hours" ? 3 : 7,
         labels: { rotate: 0 },
      },
      yaxis: {
        tickAmount: 5,
        labels: { formatter: (val) => val },
      },
      fill: { opacity: 1 },
      tooltip: { y: { formatter: (val) => val } },
    },
  };

  const countryGraph = {
    data: chartData,
    options: {
      sizeAxis: { minValue: 0, maxValue: 100 },
      region: region,
      datalessRegionColor: "#e7f0ff",
      colorAxis: { colors: ["#e7f0ff", "#1976d2"] },
      legend: false,
    },
  };

  const UnPaidChart = {
    series: [
      {
        name: "Paid",
        data: [100, 550, 300, 350, 200, 100, 300],
      },
    ],
    options: {
      chart: {
        type: "area",
        width: "100%",
        height: "100%",
        toolbar: {
          show: false,
        },
      },
      colors: ["#FDA403"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 1,
        curve: "smooth",
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "vertical",
          shadeIntensity: 0,
          opacityFrom: 0.7,
          opacityTo: 0.2,
        },
      },
      legend: {
        show: false,
      },
      xaxis: {
        show: false,
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        show: false,
      },
      grid: {
        show: false,
      },
    },
  };

  const cardStyle = {
    boxShadow: "none",
    border: "1px solid rgb(193, 228, 254)",
  };
  if(isLoading) return (<>Loading...</>)
  return (
    <section id={style.analytics}>
      <div className="head-of-head writer-top">
        <div className="table-name">Traffic Overview</div>
        <div className="fixed-right">
          <Grid item xs={8}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <DateRangePicker
                  onChange={handleDateRangePicker}
                  sDate={dateRange[0].startDate}
                  eDate={dateRange[0].endDate}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      <p className="p-top-writer">
        Track your site's traffic trends and get to know your visitors.{" "}
        <span className="learn-more"> Learn More</span>
      </p>
      <Container id={style.analytics}>
        <Grid container spacing={4}>
          <Grid item xs={8}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Card sx={cardStyle}>
                  <CardContent>
                    <h5 className={style.customerViewsHeadTitle}>
                      Site Session
                    </h5>
                  </CardContent>
                  <Divider />
                  <CardContent>
                    <div className="count-graph">
                      <div>
                        <span className={style.title}>Count</span>
                        <h5 className={style.count}>{siteSession}</h5>
                      </div>
                      <div>
                        <ReactApexChart
                          options={PaidChart.options}
                          series={PaidChart.series}
                          type="area"
                          width={"90%"}
                          height={"60%"}
                        />
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card sx={cardStyle}>
                  <CardContent>
                    <h5 className={style.customerViewsHeadTitle}>
                      Unique Visitors
                    </h5>
                  </CardContent>
                  <Divider />
                  <CardContent>
                    <div className="count-graph">
                      <div>
                        <span className={style.title}>Count</span>
                        <h5 className={style.count}>{uniqueVisitor}</h5>
                      </div>
                      <div>
                        <ReactApexChart
                          options={UnPaidChart.options}
                          series={UnPaidChart.series}
                          type="area"
                          width={"90%"}
                          height={"60%"}
                        />
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card sx={cardStyle}>
                  <CardContent>
                    <h5 className={style.customerViewsHeadTitle}>
                      Sessions Over Time
                    </h5>
                  </CardContent>
                  <Divider />
                  <CardContent>
                    <div className={style.graphCardDiv}>
                      <ReactApexChart
                        options={SessionOverTimeGraph.options}
                        series={SessionOverTimeGraph.series}
                        type="area"
                        width={"100%"}
                        height={"100%"}
                      />
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card sx={cardStyle}>
                  <CardContent>
                    <h5 className={style.customerViewsHeadTitle}>
                      Session by sources
                    </h5>
                  </CardContent>
                  <Divider />
                  <CardContent>
                    <div className={style.graphCardAuto}>
                      <div className={style.progressbarItems}>
                        {sourcesSession.map((item, i) => (
                          <div className={style.items} key={i}>
                            <div className={style.content}>
                              <label className={style.title}>
                                {item.title}
                              </label>
                              <div className={style.count}>
                                <span className={style.percentage}>
                                  {item.percentage}%
                                </span>
                                <Divider
                                  orientation="vertical"
                                  variant="middle"
                                  flexItem
                                />
                                <span className={style.values}>
                                  {item.count}
                                </span>
                              </div>
                            </div>
                            <LinearProgress
                              className={style.linearProgressBar}
                              value={item.percentage}
                              color="primary"
                              variant="determinate"
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card sx={cardStyle}>
                  <CardContent>
                    <h5 className={style.customerViewsHeadTitle}>
                      Session By Country
                    </h5>
                  </CardContent>
                  <Divider />
                  <CardContent className="px-4">
                    <div
                      className={`d-flex ${
                        !chartData.length > 1
                          ? "align-items-center"
                          : "align-items-start"
                      } gap-4 mb-3 ${style.graphCardAuto}`}
                    >
                      <div style={{ width: "70%" }}>
                        <Chart
                          chartType="GeoChart"
                          width="100%"
                          height="300px"
                          data={countryGraph.data}
                          options={countryGraph.options}
                          chartEvents={[
                            {
                              eventName: "select",
                              callback: handleCountryGraph,
                            },
                          ]}
                        />
                      </div>
                      <div style={{ width: "30%" }}>
                        {!chartData.length > 1 ? (
                          <>
                            {/* You can add a loading or no-data message here */}
                          </>
                        ) : (
                          <>
                            <div
                              className={`${style.graphCardAuto} ${style.smallBar}`}
                            >
                              <div className={style.progressbarItems}>
                                {chartData.length > 1 &&
                                  chartData.map((item, i) => {
                                    if (i > 0) {
                                      return (
                                        <div className={style.items} key={i}>
                                          <div className={style.content}>
                                            <label className={style.title}>
                                              {item[0]}
                                            </label>
                                            <div className={style.count}>
                                              <span className={style.values}>
                                                {item[1]}
                                              </span>
                                            </div>
                                          </div>
                                          <LinearProgress
                                            className={style.linearProgressBar}
                                            value={100}
                                            color="primary"
                                            variant="determinate"
                                          />
                                        </div>
                                      );
                                    }
                                  })}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>


          <Grid item xs={4}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Card sx={cardStyle}>
                  <CardContent>
                    <h5 className={style.customerViewsHeadTitle}>
                      New vs returning visitors
                    </h5>
                  </CardContent>
                  <Divider />
                  <CardContent>
                    <div className={style.graphCardAuto}>
                      <ReactApexChart
                        options={ReturnVisitorGraph.options}
                        series={ReturnVisitorGraph.series}
                        type="donut"
                        width={"100%"}
                        height={"200px"}
                      />
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card sx={cardStyle}>
                  <CardContent>
                    <h5 className={style.customerViewsHeadTitle}>
                      Sessions by device
                    </h5>
                  </CardContent>
                  <Divider />
                  <CardContent>
                    <div className={style.graphCardAuto}>
                      <ReactApexChart
                        options={SessionDeviceGraph.options}
                        series={SessionDeviceGraph.series}
                        type="donut"
                        width={"100%"}
                        height={"200px"}
                      />
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              
              {/* Error */}
              <Grid item xs={12}>
                <Card sx={cardStyle}>
                  <CardContent
                    sx={{ display: "flex", alignItems: "center", gap: "1rem" }}
                  >
                    <h5 className={style.customerViewsHeadTitle}>
                      Avg Session
                    </h5>
                    <select
                      value={avgSessionToggleValue}
                      onChange={handleAvgSessionToggle}
                      style={{
                        border: "none",
                        fontSize: "14px",
                        color: "var(--primary-color)",
                      }}
                    >
                      <option value="days">Days</option>
                      <option value="hours">Hours</option>
                    </select>
                  </CardContent>
                  <Divider />
                  <CardContent>
                    {avgSessionToggleValue === "hours" && (
                      <Tabs
                        onChange={handleDaysHours}
                        value={avgToggleVal}
                        style={{ minHeight: "30px" }}
                      >
                        {averageSession.map((item, i) => {
                          const label = item.title.substring(0, 3);
                          return (
                            <Tab
                              className={style.smallTab}
                              label={label}
                              value={i}
                            />
                          );
                        })}
                      </Tabs>
                    )}
                    <div className={style.graphCardAuto}>
                      <ReactApexChart
                        options={AvgSessionGraph.options}
                        series={AvgSessionGraph.series}
                        type="bar"
                        width={"100%"}
                        height={"250px"}
                      />
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              {/* Error */}

              <Grid item xs={12}>
                <Card sx={cardStyle}>
                <CardContent
                    sx={{ display: "flex", alignItems: "center", gap: "1rem" }}
                  >
                    <h5 className={style.customerViewsHeadTitle}>
                      Suggested for you
                    </h5>
                  </CardContent>
                  <Divider />
                  <CardContent sx={{padding:'0 !important'}}>
                    <div className="mo-sales-div" style={{marginBottom:'0',border:'none'}}>
                      <div className="suggested-div">
                        
                        <div
                          className="sugg"
                          onClick={() => {
                            navigate("/analytics/realtime-overview");
                          }}
                        >
                          <div className="sugg-head">
                            <p className="sugg-p">Realtime overview</p>
                            <IoIosArrowForward />
                          </div>
                          <p className="sugg-p-small">
                            Find out where your visitors are based, and how many
                            of them are returning.
                          </p>
                        </div>
                        <div
                          className="sugg"
                          onClick={() => {
                            navigate("/analytics/behavior-overview");
                          }}
                        >
                          <div className="sugg-head">
                            <p className="sugg-p">Behavior overview</p>
                            <IoIosArrowForward />
                          </div>
                          <p className="sugg-p-small">
                            See how visitors interact with your site's pages.
                          </p>
                        </div>
                        <div
                          className="sugg"
                          onClick={() => {
                            navigate("/analytics/story-overview");
                          }}
                        >
                          <div className="sugg-head">
                            <p className="sugg-p">Story overview</p>
                            <IoIosArrowForward />
                          </div>
                          <p className="sugg-p-small">
                            Find out where your visitors are based, and how many
                            of them are returning.
                          </p>
                        </div>
                        <div
                          className="sugg"
                          style={{ borderBottom: "none" }}
                          onClick={() => {
                            navigate("/analytics/marketing-overview");
                          }}
                        >
                          <div className="sugg-head">
                            <p className="sugg-p">Marketing overview</p>
                            <IoIosArrowForward />
                          </div>
                          <p className="sugg-p-small">
                            Track key sales metrics and get insights on your
                            customers.
                          </p>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default Traffic;
