import React from "react";

const Flags = ({ data, selectedFlags, setSelectedFlags }) => {
  const handleChecked = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setSelectedFlags((prev) => [...prev, value]);
    } else {
      const filteredFlags = selectedFlags.filter((id) => id !== value);
      setSelectedFlags(filteredFlags);
    }
  };

  return (
    <div className="flex-cat">
      <div className="cat-div-video">
        <p className="categories-p">
          Assign story to one or more subcategories for readers to find them.
        </p>
        <div className="assign-cat">
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                {data.length > 0 &&
                  data.map((item, i) => {
                    const isSectionPresent =
                      item.section && item.section.length > 0;
                    return (
                      <div className="cat-list" key={`cat-${i}`}>
                        {isSectionPresent && (
                          <>
                            <label className="cat-title">{item.name}</label>
                            {item.section.map((sec, secIndex) => (
                              <>
                                {sec.storiesType === 1 && (
                                  <div
                                    className="flex-cat-div"
                                    key={`section-${secIndex}`}
                                  >
                                    <div className="flex-checkbox-p">
                                      <input
                                        type="checkbox"
                                        className="checkbox-cat"
                                        name="section"
                                        value={sec._id}
                                        checked={selectedFlags.includes(
                                          sec._id
                                        )}
                                        onChange={(e) => handleChecked(e)}
                                      />
                                      <p className="categories-p-check">
                                        {sec.name}
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </>
                            ))}
                          </>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Flags;
