import { IoSettingsOutline, IoFlagOutline } from "react-icons/io5";
import { CiSearch } from "react-icons/ci";
import { LuTags } from "react-icons/lu";
import { BiCategory } from "react-icons/bi";
import { HiPlus } from "react-icons/hi2";
import { Color } from "@tiptap/extension-color";
import { FocusClasses as Focus } from "@tiptap/extension-focus";
import Heading from "@tiptap/extension-heading";
import Highlight from "@tiptap/extension-highlight";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Indent from "./extension/Indent/Indent";
import LineHeight from "./extension/LineHeight/LineHeight";
import ImageBlock from "./extension/Image/ImageBlock";
import ImageUpload from "./extension/Image/ImageUpload";
import VideoBlock from "./extension/Video/VideoBlock";
import VideoUpload from "./extension/Video/VideoUpload";
import Embed from "./extension/Embed/Embed";
import TrailingNode from "./extension/TrailingNode/TrailingNode";
import Divider from "./extension/Divider/Divider";
import FontSize from "./extension/FontSize/FontSize";
import Link from "./extension/Link/Link";
import { LuLayoutTemplate } from "react-icons/lu";

export const extensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
  Indent.configure({
    types: ["paragraph", "heading", "listItem"],
  }),
  Highlight.configure({ multicolor: true }),
  TextAlign.configure({
    types: ["heading", "paragraph"],
  }),
  Heading,
  TrailingNode,
  Underline,
  LineHeight,
  ImageUpload,
  ImageBlock,
  VideoUpload,
  VideoBlock,
  Embed,
  Divider,
  FontSize,
  Link,
  Focus,
];

export const menu = [
  {
    name: "Add",
    svg: <HiPlus />,
    title: "Add",
  },
  {
    name: "Categories",
    svg: <BiCategory />,
    title: "Categories",
  },
  {
    name: "Flags",
    svg: <IoFlagOutline />,
    title: "Flags",
  },
  {
    name: "Tags",
    svg: <LuTags />,
    title: "Tags",
  },
  {
    name: "Settings",
    svg: <IoSettingsOutline />,
    title: "Settings",
  },
  {
    name: "Templates",
    svg: <LuLayoutTemplate />,
    title: "Templates",
  },
  {
    name: "SEO",
    svg: <CiSearch />,
    title: "SEO Settings",
  },
];
