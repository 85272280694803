import Headers from './Headers';
import { ProcessAPI, Const } from '../utils/Constants';

// Get List
export const getListStories = async (body) => {
    const res = await fetch(Const.Link + "api/article/list", new Headers("POST",body));
    return ProcessAPI(res);
}

// Get All List
export const getAllListStories = async (body) => {
    const res = await fetch(Const.Link + "api/article/all-list", new Headers("POST",body));
    return ProcessAPI(res);
}

// Get Non sub category articles 
export const getNonSubcategoryStories = async (body) => {
    const res = await fetch(Const.Link + "api/article/non-subcat-article", new Headers("POST",body));
    return ProcessAPI(res);
}
export const checkHighlightedArticle = async(body) =>{
    const res = await fetch(Const.Link + `api/article/find-highlighted-article`, new Headers("POST",body));
    return ProcessAPI(res);
}
// Add Sub Category Article
export const addSubcategoryStories = async (id, body) => {
    const res = await fetch(Const.Link + "api/article/add-subcat-article/"+ id, new Headers("PUT",body));
    return ProcessAPI(res);
}

// Add Sub Category Article
export const deleteSubcategoryStories = async (id, body) => {
    const res = await fetch(Const.Link + "api/article/delete-subcat-article/"+ id, new Headers("PUT",body));
    return ProcessAPI(res);
}

// Get Trash List
export const getTrashListStories = async (body) => {
    const res = await fetch(Const.Link + "api/article/trash-list", new Headers("POST",body));
    return ProcessAPI(res);
}

// Get List
export const getHomeListStories = async (body) => {
    const res = await fetch(Const.Link + "api/article/home-list", new Headers("GET"));
    return ProcessAPI(res);
}

// Create 
export const createStories = async (body) => {
    const res = await fetch(Const.Link + "api/article/save", new Headers("POST", body, true));
    return res.json();
}


// Duplicate
export const duplicateStories = async (body) => {
    const res = await fetch(Const.Link + "api/article/create-duplicate", new Headers("POST",body));
    return ProcessAPI(res);
}

// Export
export const exportStories = async (body) => {
    const res = await fetch(Const.Link + "api/article/export", new Headers("POST",body));
    return res;
}

// Update 
export const updateStories = async (id, body) => {
    const res = await fetch(Const.Link + "api/article/update/" + id, new Headers("PATCH", body, true));
    return res.json();
}

// Edit
export const editStories = async (id) => {
    const res = await fetch(Const.Link + "api/article/edit/"+ id, new Headers("GET"));
    return ProcessAPI(res);
}

// UpdateStatus
export const updateStatus = async (id,body) => {
    const res = await fetch(Const.Link + "api/article/update-status/"+ id, new Headers("PUT",body));
    return ProcessAPI(res);
}

// UpdateSchedule
export const updateSchedule = async (id,body) => {
    const res = await fetch(Const.Link + "api/article/update-scheduled/"+ id, new Headers("PUT",body));
    return ProcessAPI(res);
}

// Delete 
export const DeleteStories = async (id) => {
    const res = await fetch(Const.Link + "api/article/delete/" + id, new Headers("PATCH"));
    return ProcessAPI(res);
}

// Parmanent Delete 
export const parmanentDeleteStories = async (id) => {
    const res = await fetch(Const.Link + "api/article/parmanent-delete/" + id, new Headers("DELETE"));
    return ProcessAPI(res);
}