import React,{useState} from "react";
import OutsideClickHandler from "react-outside-click-handler";
import style from "../../styles/common/DeletePopup.module.css";
import DropChips from "../../components/common/DropChips";
import { CiSearch } from "react-icons/ci";
import { RxCross2 } from "react-icons/rx";

const DeletePopup = ({ setOpenRecipient,openRecipient, handleCheckboxChange,removeContact,selectedContacts,setSelectedContacts }) => {
  
  const label = [
    {
      name: "All",
      number: 1190,
    },
    {
      name: "Members",
      number: 563,
    },
    {
      name: "Subscribed",
      number: 509,
    },
    {
      name: "Contacted Me",
      number: 216,
    },
    {
      name: "Customers",
      number: 166,
    },
    {
      name: "Join the community",
      number: 156,
    },
    {
      name: "Never subscribed to SMS",
      number: 62,
    },
    {
      name: "Lucky Draw!",
      number: 41,
    },
    {
      name: "Contacts Us from",
      number: 14,
    },
  ];
  const contact = [
    {
        name:"Rohit Tiwari",
        img:"https://mantisdashboard.io/assets/avatar-6-Cv0iCdgR.png",
        email:"hello@zerrorstudios.com"
    },
    {
        name:"Megan",
        img:"https://mantisdashboard.io/assets/avatar-6-Cv0iCdgR.png",
        email:"hello@zerrorstudios.com"
    },
    {
        name:"Kat Jones",
        img:"https://mantisdashboard.io/assets/avatar-6-Cv0iCdgR.png",
        email:"hello@zerrorstudios.com"
    },
    {
        name:"Anubhav Gupta",
        img:"https://mantisdashboard.io/assets/avatar-6-Cv0iCdgR.png",
        email:"hello@zerrorstudios.com"
    },
    {
        name:"Vikas Gupta",
        img:"https://mantisdashboard.io/assets/avatar-6-Cv0iCdgR.png",
        email:"hello@zerrorstudios.com"
    },
    {
        name:"Anand Vishwakarma",
        img:"https://mantisdashboard.io/assets/avatar-6-Cv0iCdgR.png",
        email:"hello@zerrorstudios.com"
    },
    {
        name:"Zerror Studios",
        img:"https://mantisdashboard.io/assets/avatar-6-Cv0iCdgR.png",
        email:"hello@zerrorstudios.com"
    },
    {
        name:"Virat Kohli",
        img:"https://mantisdashboard.io/assets/avatar-6-Cv0iCdgR.png",
        email:"hello@zerrorstudios.com"
    },
  ]



  return (
    <div className={style.modal}>
      {/* <Toaster/> */}
      <OutsideClickHandler
        onOutsideClick={() => {
          setOpenRecipient(null);
        }}
      >
        <div className={style.card}>
          
            <RxCross2 className="cross-addrex" onClick={()=>{setOpenRecipient(null)}}/>
          <p className="label-email">Recipients</p>
          <DropChips contact={selectedContacts} removeContact={removeContact} setOpenRecipient={setOpenRecipient} openRecipient={openRecipient}  />
          <div className="add-rec-div">
            <div className="addreci-head-div">
              <p>Labels</p>
            </div>
            <div>
              {label.map((el, i) => (
                <div className="addrec-single-div" key={i}>
                  <input type="checkbox" checked={selectedContacts.includes(el.name)}
                    onChange={() => handleCheckboxChange(el.name)} ></input>
                  <p>
                    {el.name} ({el.number})
                  </p>
                </div>
              ))}
            </div>

            <div className="addreci-head-div flex-head-addrex">
              <p>Contacts</p>
              <div className="search-input-logo-div">
                <CiSearch/>
              <input className="addrec-contact-input" placeholder="Search"></input>
              </div>
            </div>
            <div>
              {contact.map((el, i) => (
                <div className="addrec-single-div" key={`contact-${i}`}>
                  <input type="checkbox" checked={selectedContacts.includes(el.name)}
                    onChange={() => handleCheckboxChange(el.name)}></input>
                  <img src={el.img} className="addrec-email-img"></img>
                  <p>
                    {el.name}
                  </p>
                  <p className="email-p-addres">{el.email}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default DeletePopup;
