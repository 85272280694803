import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa6";
import "../../styles/writers/Writers.css";
import TableTags from "../../components/tags/TableTags";
import AddTagsPopup from "../../components/tags/AddTagsPopup";
import EditTagsPopup from "../../components/tags/EditTagsPopup";
import DeleteTagsPopup from "../../components/tags/DeleteTagsPopup";
import { Const } from "../../utils/Constants";
import { useNavigate } from "react-router-dom";
import SEO from "../../components/common/SEO";
import {
  getListTag,
  createTag,
  deleteTags,
  updateTag,
  tagEdit,
} from "../../api/Tags";
const Tag = () => {
  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [meta, setMeta] = useState({});
  const [open, setOpen] = useState({ right: false });
  const [deleteTag, setDeleteTag] = useState(null);
  const [row, setRow] = useState([]);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState({});
  const [offset, setOffset] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);
  const [selection, setSelection] = useState({
    select_bulk: false,
    selected: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [rowLoading, setRowLoading] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [input, setInput] = useState({});
  const [editTag, setEditTag] = useState(null);

  const handleMetaData = (e) => {
    const { name, value } = e.target;
    if (name === "slug") {
      setInput((prev) => ({ ...prev, [name]: value }));
    } else {
      setMeta((prev) => ({ ...prev, [name]: value }));
    }
  };
  const toggleDrawer = (anchor, newOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen({ ...open, [anchor]: newOpen });
  };

  useEffect(() => {
    document
      .getElementsByClassName("admin-right")[0]
      .addEventListener("scroll", () => {
        if (
          document.getElementsByClassName("main-div")[0] &&
          document.getElementsByClassName("admin-right")[0].scrollTop >
            document.getElementsByClassName("main-div")[0].offsetTop - 150
        ) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      });
    // const element = document.getElementsByClassName("table-of")[0];
    // if (orders.length > 0) {
    //   element.addEventListener("scroll", handleLoadMore);
    // }

    setRowLoading(true);
    const timeout = setTimeout(() => {
      fetchData();
    }, 500);
    return () => {
      // element.removeEventListener("scroll", handleLoadMore);
      clearTimeout(timeout);
    };
  }, [filter, offset, isUpdated]);

  const fetchData = async () => {
    const payload = {
      search: JSON.stringify(filter?.search) || "",
      limit: Const.Limit,
      offset: offset,
    };
    try {
      const res = await getListTag(payload);
      if (res) {
        if (offset > 0) {
          setCount(res.count);
          setRow((prevData) => [...prevData, ...res.data]);
          return;
        }
        setCount(res.count);
        setRow(res.data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
      setRowLoading(false);
    }
  };

  const handleLoadMore = () => {
    const element = document.getElementsByClassName("table-of")[0];
    if (
      element.scrollHeight - element.scrollTop === element.clientHeight &&
      row.length < count
    ) {
      setRowLoading(true);
      setOffset((prevOffset) => prevOffset + Const.Limit);
      return;
    }
  };

  const handleSearch = (event) => {
    const { name, value } = event.target;
    const updatedFilter = { ...filter, [name]: value };
    if (!value) {
      delete updatedFilter[name];
    }
    setOffset(0);
    setFilter(updatedFilter);
  };

  const handleSelection = (event) => {
    const { id, checked } = event.target;
    if (id === "selection" || id === "selection-toggle") {
      setSelection({ ...selection, select_bulk: checked, selected: [] });
    } else {
      if (selection.select_bulk) {
        if (checked) {
          const filterSelected = selection.selected.filter((select) => {
            return select !== id;
          });
          setSelection({ ...selection, selected: filterSelected });
        } else {
          setSelection({ ...selection, selected: [...selection.selected, id] });
        }
      } else {
        if (checked) {
          setSelection({ ...selection, selected: [...selection.selected, id] });
        } else {
          const filterSelected = selection.selected.filter((select) => {
            return select !== id;
          });
          setSelection({ ...selection, selected: filterSelected });
        }
      }
    }
  };

  const handleFilter = (event) => {
    const { name, value, type, checked } = event.target;
    if (name === "status" || name === "inventory") {
      if (value === "all") {
        const { [name]: removedFilter, ...rest } = filter;
        setFilter(rest);
      } else {
        setFilter((prevFilter) => ({
          ...prevFilter,
          [name]: JSON.parse(value),
        }));
      }
    } else {
      setFilter((prevFilter) => ({ ...prevFilter, [name]: value }));
    }
  };

  const handleSave = async () => {
    try {
      const res = await createTag(input);
      if (res.data) {
        setRow((prev) => [...prev, res.data]);
      }
    } catch (e) {
      console.error("Error while saving tag", e);
    } finally {
      setOpenModel(false);
    }
  };

  const handleDelete = async () => {
    try {
      const res = await deleteTags(deleteTag);
      if (res) {
        const tags = row.filter((tag) => tag._id !== deleteTag);
        setRow(tags);
        setCount((prev) => prev - 1);
      }
    } catch (e) {
      console.error("Error while deleting tag", e);
    } finally {
      setDeleteTag(null);
    }
  };

  const handleUpdate = async () => {
    try {
      const res = await updateTag(editTag, input);
      if (res.data) {
        setRow((prev) =>
          prev.map((tag) =>
            tag._id === editTag ? { ...tag, ...res.data } : tag
          )
        );
      }
    } catch (e) {
      console.error("Error while updating tag", e);
    } finally {
      setEditTag(null);
      setOpenModel(false);
    }
  };

  const columns = ["Tag name", "URL slug", "Stories","SEO"];
  return (
    <>
      <div className="head-of-head writer-top">
        <div className="table-name">Tags ({count})</div>
        <div className="fixed-right">
          {/* <MoreActionbtn/> */}
          <button
            className="btn-primary"
            onClick={() => {
              navigate('/tags/add-tag')
            }}
          >
            <FaPlus className="plus-logo" /> Create Tag
          </button>
        </div>
      </div>
      <p className="p-top-writer">
        Create and manage tags to help readers discover related content in your
        stories.
      </p>
      <TableTags
        count={count}
        columns={columns}
        rows={row}
        selection={selection}
        filter={filter}
        rowLoading={rowLoading}
        handleSelection={handleSelection}
        handleSearch={handleSearch}
        handleFilter={handleFilter}
        setDeleteTag={setDeleteTag}
        setEditTag={setEditTag}
        setInput={setInput}
        setOpenModel={setOpenModel}
        setOffset={setOffset}
        offset={offset}
        setRowLoading={setRowLoading}
      />

      {/* {openModel && (
        <EditTagsPopup
          setEditTag={setEditTag}
          setOpenModel={setOpenModel}
          editTag={editTag}
          input={input}
          setInput={setInput}
          handleUpdate={handleUpdate}
          handleSave={handleSave}
          toggleDrawer={toggleDrawer}
        />
      )} */}
      {deleteTag && (
        <DeleteTagsPopup
          setDeleteTag={setDeleteTag}
          handleDelete={handleDelete}
        />
      )}

      {/* <SEO
        toggleDrawer={toggleDrawer}
        open={open}
        setOpen={setOpen}
        meta={meta}
        slug={input?.slug ?? ""}
        selectedKeywords={selectedKeywords}
        setSelectedKeywords={setSelectedKeywords}
        handleMetaData={handleMetaData}
      /> */}
    </>
  );
};

export default Tag;
