import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import style from "../../styles/common/DeletePopup.module.css";

const DeletePopup = ({
  handleDelete,
  setDeleteTag,
}) => {
  return (
    <div className={style.modal}>
      {/* <Toaster/> */}
      <OutsideClickHandler
        onOutsideClick={() => {
          setDeleteTag(null);
        }}
      >
        <div className={style.card}>
          <div className={style.cardBody}>
            <div className={style.imageSec}>
              <img
                src="https://img.freepik.com/free-vector/recycling-concept-illustration_114360-5872.jpg"
                className="img-pop"
              />
            </div>
            <div className={style.contentSec}>
              <h4>Delete this item</h4>
              <p className={style.subheading}>
                Once deleted, you won’t be able to recover these items.
              </p>
              <div className={style.box}>
                <span className={style.heading}>
                  Do you want to hide item without deleting them?
                </span>
                <p>
                  You can hide item from your online store by turning off their
                  visibility in the actions menu.
                </p>
              </div>
            </div>
          </div>
          <div className={style.footer}>
            <button
              className={style.btnDangerOutline}
              onClick={() => {
                setDeleteTag(null);
              }}
            >
              Cancel
            </button>
            <button className={style.btnDanger} onClick={handleDelete}>Delete</button>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default DeletePopup;
