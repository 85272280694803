import { NodeViewWrapper } from "@tiptap/react";
import { useEffect, useCallback, useRef } from "react";

export const ImageBlockView = (props) => {
  const { editor, getPos, node } = props;
  const imageWrapperRef = useRef(null);
  const { src } = node.attrs;

  const parentDiv = document.querySelector(".node-imageBlock")
    ? document.querySelector(".node-imageBlock")
    : null;
  useEffect(() => {
    const updateMaxWidth = () => {
      if (parentDiv) {
        if (parentDiv.querySelector(".original-view")) {
          parentDiv.style.maxWidth = "100rem";
        } else {
          parentDiv.style.maxWidth = "";
        }
      }
    };

    updateMaxWidth();

    const observer = new MutationObserver(() => {
      updateMaxWidth();
    });

    if (parentDiv) {
      observer.observe(parentDiv, {
        childList: true,
        subtree: true,
        attributes: true,
        attributeFilter: ["class"],
      });
    }

    return () => observer.disconnect();
  }, [parentDiv]);

  let wrapperClassName = "";
  if (node.attrs.align === "left") {
    wrapperClassName = "ml-0";
  } else if (node.attrs.align === "right") {
    wrapperClassName = "ml-auto";
  } else if (node.attrs.align === "center") {
    wrapperClassName = "mx-auto";
  } else {
    wrapperClassName = "ml-auto mr-auto";
  }

  const onClick = useCallback(() => {
    editor.commands.setNodeSelection(getPos());
  }, [getPos, editor.commands]);

  return (
    <NodeViewWrapper>
      <div
        className={`${wrapperClassName} ${
          node.attrs.width === "100vw" ? "original-view" : ""
        }`}
        style={{
          width:
            node.attrs.width === "100vw"
              ? `calc(100vw - 115px)`
              : node.attrs.width,
        }}
      >
        <div
          className="image-wrapper"
          contentEditable={false}
          ref={imageWrapperRef}
        >
          <img className="block" src={src} alt="Image" onClick={onClick} />
        </div>
      </div>
    </NodeViewWrapper>
  );
};

export default ImageBlockView;
