import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import * as React from "react";
import "./styles/global.css";
import "./styles/loader.css";
import "./styles/invoice/dashboard.css";
import SignIn from "./pages/SignIn";
import AdminRoute from "./routes/AdminRoute";
import AddEditStories from "./pages/stories/AddEditStories";
import { QueryClient, QueryClientProvider } from "react-query";
import AdminProtectedRoute from "./controllers/AdminProtectedRoute";
import Dnd from "./components/Dnd";

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: "/signin",
    element: <SignIn />,
  },
  {
    path: "/trial",
    element: <Dnd />,
  },
  {
    path: "/stories/add-stories",
    element: (
      <AdminProtectedRoute>
        <Toaster />
        <AddEditStories />
      </AdminProtectedRoute>
    ),
  },
  {
    path: "/stories/edit-stories/:id",
    element: (
      <AdminProtectedRoute>
        <Toaster />
        <AddEditStories />
      </AdminProtectedRoute>
    ),
  },
  {
    path: "*",
    element: <AdminRoute />,
  },
]);

function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </>
  );
}

export default App;
