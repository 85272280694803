import React from "react";
import "../../styles/marketing/emailMarketing.css";
import "../../styles/admin/mail/mail.css";
import Temp1 from "../../assets/temp1.jpg";
import Temp2 from "../../assets/temp2.jpg";
import Temp3 from "../../assets/temp3.jpg";
import Temp4 from "../../assets/temp4.jpg";
import Temp5 from "../../assets/temp5.png";
import AddRecipient from "../../components/marketing/AddRecipient"
import { useState } from "react";
import DropChips from "../../components/common/DropChips";
import ImageDrop from "../../components/stories/setting/ImageDrop"

const EmailMain = () => {
  const temps = [
    {
      name: "Introduce new products",
      img: Temp1,
    },
    {
      name: "Promote services",
      img: Temp2,
    },
    {
      name: "Recommend gift cards",
      img: Temp3,
    },
    {
      name: "Prompt clients to rebook",
      img: Temp4,
    },
    {
      name: "Start a promotion",
      img: Temp5,
    },
  ];
  const [images, setImages] = useState(null);

  const [openRecipient, setOpenRecipient] = useState(false)
  const [selectedContacts, setSelectedContacts] = useState(["All"]);

  const handleCheckboxChange = (contactName) => {
    setSelectedContacts((prevSelectedContacts) =>
      prevSelectedContacts.includes(contactName)
        ? prevSelectedContacts.filter((name) => name !== contactName)
        : [...prevSelectedContacts, contactName]
    );
  };

  const removeContact = (contactName) => {
    setSelectedContacts((prevSelectedContacts) =>
      prevSelectedContacts.filter((name) => name !== contactName)
    );
  };


  return (
    <>
      <div className="head-of-head writer-top">
        <div className="table-name">Email Marketing</div>
        <div className="fixed-right">
          {/* <MoreActionbtn/> */}
          {/* <button
            className="btn-primary"
          >
            Create Categories <FaPlus className="plus-logo" />
          </button> */}
        </div>
      </div>
      <p className="p-top-writer">
        Engage your audience, promote your site and build your brand with email
        campaigns.
      </p>

      <div className="temp-cards">
        {temps.map((el, i) => (
          <div className="temp-card" key={i}>
            <div className="hover-btn-email">
              <button className="btn-primary">Use Template</button>
              <p className="card-copy">Preview</p>
            </div>
            <div className="email-img-div">
              <img src={el.img} className="email-img"></img>
            </div>
            <p className="card-p">{el.name}</p>
          </div>
        ))}
      </div>

      <div className="mail-main">
        <div className="mail-body">
          <div className="mb-top">
            <h2>Details</h2>
          </div>
          <p className="label-email">Recipients</p>
          {/* <div className="reci-div" onClick={()=>{setOpenRecipient(true)}}></div> */}
          
          <div className="flex-drop-chip">
          <DropChips
            contact={selectedContacts}
            removeContact={removeContact}
            setOpenRecipient={setOpenRecipient}
            />
                  <button className="btn-primary" onClick={()=>{setOpenRecipient(true)}}>Select</button>
            </div>

          <div className="mb-body">
            <div className="input-group-mail">
              <label htmlFor="">Email Subject</label>
              <input type="text" name="subject" />
            </div>
            
            <div className="input-group-mail">
              <label htmlFor="">Heading</label>
              <input type="text" name="subject" />
            </div>
            <div className="input-group-mail">
              <label htmlFor="">Sub-heading</label>
              <input type="text" name="subject" />
            </div>
            <div className="btn-email-div">
              <div className="input-group-mail left-btn-email">
                <label htmlFor="">Button Text</label>
                <input type="text" name="subject" />
              </div>
              <div className="input-group-mail right-btn-email">
                <label htmlFor="">Button Link</label>
                <input type="text" name="subject" />
              </div>
            </div>
            <div className="input-group-mail">
              <label htmlFor="">Custom Message</label>
              <textarea type="text" name="customMessage" />
            </div>
            <div className="input-group-mail" style={{margin:'20px 0px 10px 0px'}}>
              <label htmlFor="">Image</label>
            </div>
                <ImageDrop selectedFiles={images} setSelectedFiles={setImages}/>
          </div>
          <div className="email-btn">
            <button className="btn-primary">Send</button>
            <button className="btn-primary-outline">Cancel</button>
          </div>
        </div>

        <div className="mail-body-2">
          <div className="mb-top">
            <h2>Preview</h2>
          </div>

          <div className="mb-2-body-w">
            <div className="mb-2-body-w-inside">
              <img src={Temp1} alt="" className="preview-img" />
              {/* <div className="p-sub"><h2>Input</h2></div> */}
              {/* <div className="preview-mail-msg">
                <h2>Your message will preview here</h2>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      
      {openRecipient && 
      <AddRecipient setOpenRecipient={setOpenRecipient} handleCheckboxChange={handleCheckboxChange} removeContact={removeContact} selectedContacts={selectedContacts} setSelectedContacts={setSelectedContacts} openRecipient={openRecipient} />
      }

    </>
  );
};

export default EmailMain;
