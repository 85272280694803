import React, { useMemo, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import "../../styles/categories/modal.css";
import { RiFindReplaceLine } from "react-icons/ri";
import { GoPlus } from "react-icons/go";
import { useDropzone } from "react-dropzone";
import { MdDeleteOutline } from "react-icons/md";
import { Tooltip } from "react-tooltip";

const Modal = ({
  selectedFiles,
  src,
  setSelectedFiles,
  handleDelete,
  thumbnailUrl,
  setThumbnailUrl,
}) => {
  const [videoUrl, setVideoUrl] = useState(src);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setSelectedFiles(acceptedFiles);

    if (file.type.startsWith("video/")) {
      setVideoUrl(URL.createObjectURL(file));
      generateVideoThumbnail(file).then((thumbnailUrl) => {
        setThumbnailUrl(thumbnailUrl);
      });
    }
  };

  const generateVideoThumbnail = (file) => {
    return new Promise((resolve) => {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(file);
      video.load();
      video.onloadeddata = () => {
        video.currentTime = 1; // Seek to 1 second
      };
      video.onseeked = () => {
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas
          .getContext("2d")
          .drawImage(video, 0, 0, canvas.width, canvas.height);
        resolve(canvas.toDataURL());
      };
    });
  };

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    isDragActive,
    open: openDropzone,
  } = useDropzone({
    accept: { "video/*": [] },
    maxFiles: 1,
    onDrop,
  });

  const replaceFile = () => {
    setSelectedFiles([]);
    setVideoUrl(null);
    setThumbnailUrl(null);
    openDropzone();
  };

  const removeFile = () => {
    setSelectedFiles([]);
    setVideoUrl(null);
    setThumbnailUrl(null);
    handleDelete();
  };

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    minWidth: "192px",
    height: "380px",
    borderWidth: 2,
    borderRadius: 5,
    borderColor: "#3b82f6",
    backgroundColor: "#f4f7ff",
    color: "#bdbdbd",
    outline: "none",
    cursor: "pointer",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: "fc6e00",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <>
      {videoUrl || (selectedFiles && selectedFiles.length > 0) ? (
        <div className="video-main">
          {/* <div className="cat-file-abs">
            <div className="cat-file-inps">
              <Tooltip id="toggle-tooltip" style={{ maxWidth: "300px" }} />
              <div
                className="cat-file-inp"
                onClick={replaceFile}
                data-tooltip-id="toggle-tooltip"
                data-tooltip-content="Replace"
              >
                <RiFindReplaceLine />
              </div>
              <div
                className="cat-file-inp"
                onClick={removeFile}
                data-tooltip-id="toggle-tooltip"
                data-tooltip-content="Delete"
              >
                <MdDeleteOutline />
              </div>
            </div>
          </div> */}
          <div className="video-container">
            <video controls src={videoUrl} />
          </div>
        </div>
      ) : (
        <div {...getRootProps({ style })}>
          <div className="feature-image-inner">
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the video here ...</p>
            ) : (
              <GoPlus className="feature-logo" />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
