import React, { useState, useEffect } from "react";
import {
  MdKeyboardArrowRight,
  MdKeyboardArrowDown,
  MdArrowForwardIos,
} from "react-icons/md";
import { BsThreeDots } from "react-icons/bs";
import "../../styles/admin/analytics/FullReport.css";
import { IoInformationCircleOutline } from "react-icons/io5";
import { CiSaveDown2 } from "react-icons/ci";
import Profilebtn2 from "../../components/analytics/DownloadBtn";
import { SlEnvolope } from "react-icons/sl";
import Tabs from "../../components/analytics/Tabs"

const FullReport = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    document
      .getElementsByClassName("admin-right")[0]
      .addEventListener("scroll", () => {
        if (
          document.getElementsByClassName("main-div")[0] &&
          document.getElementsByClassName("admin-right")[0].scrollTop >
            document.getElementsByClassName("main-div")[0].offsetTop - 150
        ) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      });

    return () => {};
  }, []);

  return (
    <>
      {/* <div className="head-of-head">
        <div className="pre-head">
          All Reports {<MdArrowForwardIos style={{ color: "#b6c1cd" }} />}{" "}
          Traffic over Time
        </div>
      </div> */}

      <div className="report-top">
        <div className="report-head-div">
          <div className="report-head">
            <p className="report-name">Traffic over Time</p>
            <div className="save-logo">
              <CiSaveDown2 className="save-svg" />
            </div>
          </div>
          <div className="report-btns">
            <div className="dot-logo-div">
              <BsThreeDots className="dot-logo" />
            </div>
            <div>
              <button className="subs-btn">
                <SlEnvolope className="envolope" /> Subscribe
              </button>
            </div>
            <Profilebtn2 />
          </div>
        </div>
        <div>
          <p className="sub-head-report">
            Learn which days or months get the most site visits.{" "}
            <span className="color-blue">Show report definitions</span>
          </p>
          <p className="time-report">
            Time period: May 7 - Today <IoInformationCircleOutline className="info-logo-report"/>
          </p>
        </div>
      </div>
      <Tabs/>
    </>
  );
};

export default FullReport;
