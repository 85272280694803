import React, { useState } from "react";
import MultipleSelectDropdown from "../../fields/MultipleSelectDropdown";
import ChipDropdown from "../../fields/ChipDropdown";
import Toggle from "../../fields/Toggle";
import ImageDrop from "./ImageDrop";

const General = ({
  writer,
  input,
  images,
  setImages,
  contributor,
  openFocalModal,
  setOpenFocalModal,
  setContributor,
  handleInputChange,
}) => {
  const [status, setStatus] = useState(false);
  const handlestatus = () => {
    setStatus(!status);
  };

  const handlePreviewModal = (e) => {
    const { checked } = e.target;
    setOpenFocalModal(checked);
  };

  return (
    <div className="general-main-div">
      <div className="featured-img-div">
        <div className="featured-img-top">
          <div className="feature-logo justify-between w-full">
            <p className="general-p-small">Featured image</p>
            <div className="basic-bottom">
              <Toggle
                value={openFocalModal === null ? true : openFocalModal}
                handleInputChange={handlePreviewModal}
              />
              <p className="general-p-small">Focal Point</p>
            </div>
          </div>
        </div>
        <ImageDrop selectedFiles={images} setSelectedFiles={setImages} />
      </div>
      <div className="publish-date-div">
        <div className="featured-img-top">
          <div className="feature-logo">
            <p className="general-p-small">Image caption</p>
          </div>
        </div>
        <input
          type="text"
          className="general-input"
          name="caption"
          onChange={handleInputChange}
          value={input && input.caption ? input.caption : ""}
          placeholder="Add a image caption here"
        />
      </div>
      <div className="publish-date-div">
        <div className="featured-img-top">
          <div className="feature-logo">
            <p className="general-p-small">Image courtesy</p>
          </div>
        </div>
        <input
          type="text"
          className="general-input"
          name="courtesy"
          onChange={handleInputChange}
          value={input && input.courtesy ? input.courtesy : ""}
          placeholder="Add a image courtesy here"
        />
      </div>
      <div className="publish-date-div">
        <div className="featured-img-top">
          <div className="feature-logo">
            <p className="general-p-small">Alt name</p>
          </div>
        </div>
        <input
          type="text"
          className="general-input"
          name="altName"
          onChange={handleInputChange}
          value={input && input.altName ? input.altName : ""}
          placeholder="e.g. A cat sleeping on a white blanket"
        />
      </div>
      <div className="publish-date-div">
        <div className="featured-img-top">
          <div className="feature-logo">
            <p className="general-p-small">Timestamp</p>
          </div>
        </div>
        <input
          type="number"
          className="general-input"
          name="time"
          onChange={handleInputChange}
          value={input && input.time ? input.time : ""}
          placeholder="Add a read time"
        />
      </div>

      {/* <div className="publish-date-div">
        <div className="feature-logo" style={{justifyContent:'space-between'}}>
          <p className="general-p-small">Flag this Story</p>
          <Toggle status={status} handleInputChange={handlestatus}/>
        </div>
        {status && 
        <p className="flag-p">Note: Flagged stories appear at the top of their respective categories. Only one story per category can be flagged at a time. If you flag a different story within the same category, the new story will take precedence, and the previously flagged story will be automatically unflagged.</p>
        }
      </div> */}

      {/* <div className="publish-date-div">
        <div className="feature-logo">
          <p className="general-p-small">Publish date</p>
        </div>
        <Date
          name={"publishData"}
          value={input?.publishData ?? ""}
          handleDate={handleDate}
        />
      </div> */}

      <div className="publish-date-div">
        <div className="feature-logo">
          <p className="general-p-small">Authors</p>
        </div>
        <MultipleSelectDropdown
          data={writer}
          name={"writer"}
          placeholder="Select Author"
          selected={input?.writer ?? []}
          handleOnChange={handleInputChange}
        />
      </div>
      <div className="publish-date-div">
        <div className="feature-logo">
          <p className="general-p-small">Add Contributor</p>
        </div>
        <ChipDropdown
          placeholder="Contributors are temporary authors for a limited number of stories, without Author’s profile page"
          selectedOptions={contributor}
          setSelectedOptions={setContributor}
        />
      </div>

      <div className="publish-date-div">
        <div className="featured-img-top">
          <div className="feature-logo">
            <p className="general-p-small">Excerpt</p>
          </div>
          <p className="general-p-small">0/140</p>
        </div>
        <textarea
          className="general-textarea"
          name="excerpt"
          onChange={handleInputChange}
          value={input && input.excerpt ? input.excerpt : ""}
          placeholder="e.g. This quick and easy pasta dish is great for busy weeknight family meals"
        />
      </div>

      <div className="publish-date-div">
        <div className="featured-img-top">
          <div className="feature-logo">
            <p className="general-p-small">Content Duplication Note</p>
          </div>
        </div>
        <input
          type="text"
          className="general-input"
          name="duplicationNote"
          onChange={handleInputChange}
          value={input && input.duplicationNote ? input.duplicationNote : ""}
          placeholder="e.g. this article was originally published on XYZ"
        />
      </div>

      {/* <div className="publish-date-div">
        <div className="featured-img-top">
          <div className="feature-logo">
            <p className="general-p-small">Related posts</p>
          </div>
          <p className="general-p-small">0/3</p>
        </div>
        <div className="general-related">
          <div className="flex-general">
            <GoPlus className="general-plus-logo2" />
            <p className="choposts">Choose Posts</p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default General;
