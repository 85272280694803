import React from 'react';
import '../../styles/construct.css';

const ConstructPage = () => {
  return (
    <div className="construction-container">
      <div className="construction-message">
        <h1>Under Development</h1>
        <p>We're working on something awesome! Please check back later.</p>
      </div>
      <div className="construction-animation"></div>
    </div>
  );
}

export default ConstructPage;