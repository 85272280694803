import React from "react";
import { Routes, Route } from "react-router-dom";
import Traffic from "../pages/analytics/Traffic";
import RealTime from "../pages/analytics/RealTime";
// import Behaviour from "../pages/analytics/Behaviour";
// import Story from "../pages/analytics/Story";
import Marketing from "../pages/analytics/MarketingOverview";
import Stories from "../pages/analytics/StoryOverview";
import Behavior from "../pages/analytics/BehaviorOverview";
import FullReport from "../pages/analytics/FullReport";
import ConstructPage from "../components/common/Construct";

const AnalyticsRoute = () => {
  return (
    <Routes>
      <Route path="/traffic-overview" element={<Traffic />} />
      <Route path="/realtime-overview" element={<RealTime />} />
      {/* <Route path="/behaviour-overview" element={<Behaviour />} /> */}
      {/* <Route path="/story-overview" element={<Story />} /> */}
       <Route path="/marketing-overview" element={<Marketing />} />
       <Route path="/story-overview" element={<Stories />} />
       <Route path="/behavior-overview" element={<Behavior />} />
      <Route path="/full-report/:id" element={<FullReport />} />
      <Route path="/*" element={<ConstructPage />} />
    </Routes>
  );
};

export default AnalyticsRoute;