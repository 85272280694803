import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import style from "../../styles/common/DeletePopup.module.css";
import DateTimePicker from "./DateTimePicker";

const DeletePopup = ({
  setOpenDatePicker,
  schedule,
  setSchedule,
  handleOnlyScheduled,
  handleCancle

}) => {
  return (
    <div className={style.modal}>
      {/* <OutsideClickHandler
        onOutsideClick={() => {
          setOpenDatePicker(null);
        }}
      > */}
        <div className={style.card}>
          <DateTimePicker schedule={schedule} setSchedule={setSchedule} setOpenDatePicker={setOpenDatePicker}/>
          <div className={style.footer} style={{margin:'0'}}>
            <button
              className={style.btnDangerOutlineblue}
              onClick={handleCancle}
            >
              Cancel
            </button>
            <button className={style.btnDangerblue} onClick={() => {
                handleOnlyScheduled()
                setOpenDatePicker(false);
              }}>Save</button>
          </div>
        </div>
    </div>
  );
};

export default DeletePopup;
