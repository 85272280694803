import React from "react";
import { Routes, Route } from "react-router-dom";
import ConstructPage from "../components/common/Construct";
// import ChatMain from "../pages/admin/marketing/ChatMain";
import EmailMain from "../pages/marketing/EmailMain";
import WhatsAppMain from "../pages/marketing/WhatsAppMain";
import SmsMain from "../pages/marketing/SmsMain";

const MarketingRoute = () => {
  return (
    <Routes>
      {/* <Route path="/sms-marketing" element={<ChatMain />} /> */}
      <Route path="/email-marketing" element={<EmailMain />} />
      <Route path="/whatsapp-marketing" element={<WhatsAppMain />} />
      <Route path="/sms-marketing" element={<SmsMain />} />
      <Route path="/*" element={<ConstructPage />} />
    </Routes>
  );
};

export default MarketingRoute;