import { ReactNodeViewRenderer } from "@tiptap/react";
import { mergeAttributes } from "@tiptap/core";

import VideoBlockView from "./components/VideoBlockView";
import { Node } from "@tiptap/core";

export const VideoBlock = Node.create({
  name: "videoBlock",

  group: "block",

  defining: true,

  isolating: true,

  addAttributes() {
    return {
      src: {
        default: "",
        parseHTML: (element) => element.getAttribute("src"),
        renderHTML: (attributes) => ({
          src: attributes.src,
        }),
      },
      width: {
        default: "100%",
        parseHTML: (element) => element.getAttribute("data-width"),
        renderHTML: (attributes) => ({
          "data-width": attributes.width,
        }),
      },
      align: {
        default: "center",
        parseHTML: (element) => element.getAttribute("data-align"),
        renderHTML: (attributes) => ({
          "data-align": attributes.align,
        }),
      },
      caption: {
        default: "",
        parseHTML: (element) => element.getAttribute("data-caption"),
        renderHTML: (attributes) => ({
          "data-caption": attributes.caption,
        }),
      },
      courtesy: {
        default: "",
        parseHTML: (element) => element.getAttribute("data-courtesy"),
        renderHTML: (attributes) => ({
          "data-courtesy": attributes.courtesy,
        }),
      },
      alt: {
        default: "",
        parseHTML: (element) => element.getAttribute("data-alt"),
        renderHTML: (attributes) => ({
          "data-alt": attributes.alt,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'video[src]:not([src^="data:"])',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "video",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
    ];
  },

  addCommands() {
    return {
      setVideoBlock:
        (attrs) =>
        ({ commands }) => {
          return commands.insertContent({
            type: "videoBlock",
            attrs: { src: attrs.src },
          });
        },

      setVideoBlockAt:
        (attrs) =>
        ({ commands }) => {
          return commands.insertContentAt(attrs.pos, {
            type: "videoBlock",
            attrs: { src: attrs.src },
          });
        },

      setVideoBlockAlign:
        (align) =>
        ({ commands }) =>
          commands.updateAttributes("videoBlock", { align }),

      setVideoBlockWidth:
        (width) =>
        ({ commands }) =>
          commands.updateAttributes("videoBlock", {
            width:
              width === 0 ? "100vw" : `${Math.max(0, Math.min(100, width))}%`,
          }),
      setVideoBlockCaption:
        (caption) =>
        ({ commands }) =>
          commands.updateAttributes("videoBlock", { caption }),

      setVideoBlockCourtesy:
        (courtesy) =>
        ({ commands }) =>
          commands.updateAttributes("videoBlock", { courtesy }),

      setVideoBlockAlt:
        (alt) =>
        ({ commands }) =>
          commands.updateAttributes("videoBlock", { alt }),
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(VideoBlockView); // Ensure you have a corresponding VideoBlockView component
  },
});

export default VideoBlock;
