import React, { useState } from "react";
import { GoPlus } from "react-icons/go";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Divider,
  LinearProgress,
  Tab,
  Tabs,
} from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { IoIosArrowDown } from "react-icons/io";
import Google from "../../assets/svg/google.svg"
import { AiOutlinePlus } from "react-icons/ai";
import { Chart } from "react-google-charts";
import dayjs from "dayjs";
import DateRangePicker from "../../components/fields/DateRangePicker";
import "../../styles/analytics/MarketingOverview.css";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { FaArrowUpLong } from "react-icons/fa6";
import { FcGoogle } from "react-icons/fc";
import { CiGlobe } from "react-icons/ci";
import { FaArrowDownLong } from "react-icons/fa6";
import MoSearchTable from "../../components/analytics/MoSearchTable";
import Progress from "../../components/analytics/Progress"

const MarketingOverview = () => {
  const [dateRange, setDateRange] = useState([
    {
      startDate: dayjs()
        .subtract(30, "days")
        .startOf("day")
        .format("YYYY-MM-DD"),
      endDate: dayjs().endOf("day").format("YYYY-MM-DD"),
    },
  ]);
  const handleDateRangePicker = (start, end) => {
    const date = [
      {
        startDate: start,
        endDate: end,
      },
    ];
    setDateRange(date);
  };

  const PaidChart = {
    series: [
      {
        name: "Paid",
        data: [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 300, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0,
        ],
      },
    ],
    options: {
      chart: {
        type: "area",
        width: "100%",
        height: "200%",
        toolbar: {
          show: false,
        },
      },
      colors: ["#65B741"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        curve: "smooth",
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "vertical",
          shadeIntensity: 0,
          opacityFrom: 0.7,
          opacityTo: 0.2,
        },
      },
      legend: {
        show: true,
      },
      xaxis: {
        type: "datetime",
        categories: [
          "2023-06-04",
          "2023-06-05",
          "2023-06-06",
          "2023-06-07",
          "2023-06-08",
          "2023-06-09",
          "2023-06-10",
          "2023-06-11",
          "2023-06-12",
          "2023-06-13",
          "2023-06-14",
          "2023-06-15",
          "2023-06-16",
          "2023-06-17",
          "2023-06-18",
          "2023-06-19",
          "2023-06-20",
          "2023-06-21",
          "2023-06-22",
          "2023-06-23",
          "2023-06-24",
          "2023-06-25",
          "2023-06-26",
          "2023-06-27",
          "2023-06-28",
          "2023-06-29",
          "2023-06-30",
          "2023-07-01",
          "2023-07-02",
        ],
        labels: {
          show: true,
          format: "MMM dd", // Format the date labels as "Jun 04", "Jun 05", etc.
        },
        axisBorder: {
          show: true,
        },
      },
      yaxis: {
        labels: {
          show: true,
          formatter: function (value) {
            return value.toFixed(0); // Ensures the labels are integers
          },
        },
        min: 0,
        max: 300,
        tickAmount: 3, // This creates 4 intervals: 0, 100, 200, 300
      },
      grid: {
        show: true,
      },
    },
  };
  const column = ["Query", "Impressions", "Clicks", "CTR", "Average position"];
  const row = [
    {
      query: "sensei store",
      impressions: 182,
      impressionschange: 30,
      impressionsstatus: true,
      clicks: 42,
      clickschange: 20,
      clicksstatus: false,
      ctr: "23.1%",
      ctrchange: 0.2,
      ctrstatus: false,
      average: 2.2,
      averagechange: 0.1,
      averagestatus: true,
    },
    {
      query: "the sensei store",
      impressions: 46,
      impressionschange: 16,
      impressionsstatus: true,
      clicks: 35,
      clickschange: 17,
      clicksstatus: true,
      ctr: "76.1%",
      ctrchange: 0.2,
      ctrstatus: true,
      average: "1.0",
      averagechange: null,
      averagestatus: true,
    },
    {
      query: "anime keychain",
      impressions: 18,
      impressionschange: 5,
      impressionsstatus: true,
      clicks: 16,
      clickschange: 10,
      clicksstatus: true,
      ctr: "88.9%",
      ctrchange: 0.4,
      ctrstatus: true,
      average: 9.2,
      averagechange: 1.5,
      averagestatus: false,
    },
    {
      query: "merch sensei",
      impressions: 828,
      impressionschange: 159,
      impressionsstatus: true,
      clicks: 14,
      clickschange: 11,
      clicksstatus: false,
      ctr: "1.7%",
      ctrchange: null,
      ctrstatus: false,
      average: 2.8,
      averagechange: 0.5,
      averagestatus: true,
    },
    {
      query: "sensei store",
      impressions: 182,
      impressionschange: 30,
      impressionsstatus: true,
      clicks: 42,
      clickschange: 20,
      clicksstatus: false,
      ctr: "23.1%",
      ctrchange: 0.2,
      ctrstatus: false,
      average: 2.2,
      averagechange: 0.1,
      averagestatus: true,
    },
    {
      query: "the sensei store",
      impressions: 46,
      impressionschange: 16,
      impressionsstatus: true,
      clicks: 35,
      clickschange: 17,
      clicksstatus: true,
      ctr: "76.1%",
      ctrchange: 0.2,
      ctrstatus: true,
      average: "1.0",
      averagechange: null,
      averagestatus: true,
    },
    {
      query: "anime keychain",
      impressions: 18,
      impressionschange: 5,
      impressionsstatus: true,
      clicks: 16,
      clickschange: 10,
      clicksstatus: true,
      ctr: "88.9%",
      ctrchange: 0.4,
      ctrstatus: true,
      average: 9.2,
      averagechange: 1.5,
      averagestatus: false,
    },
    {
      query: "merch sensei",
      impressions: 828,
      impressionschange: 159,
      impressionsstatus: true,
      clicks: 14,
      clickschange: 11,
      clicksstatus: false,
      ctr: "1.7%",
      ctrchange: null,
      ctrstatus: false,
      average: 2.8,
      averagechange: 0.5,
      averagestatus: true,
    },
  ];

  return (
    <>
      <div className="head-of-head writer-top">
        <div className="table-name">Marketing Overview</div>
        <div className="fixed-right">
          <Grid item xs={8}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <DateRangePicker
                  onChange={handleDateRangePicker}
                  sDate={dateRange[0].startDate}
                  eDate={dateRange[0].endDate}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      <p className="p-top-writer">
        Track, analyze and optimize the performance of all your marketing
        activities. <span className="learn-more"> Learn More</span>
      </p>

      <div className="mo-sales-div">
        <div className="mo-sales-top">
          <div className="mo-sales-left">
            <div className="sales-arrow">
              <p className="mo-head-main">Sales</p>
              <IoIosArrowDown className="mo-head-main" />
            </div>
            <div>
              <p className="mo-head-main2">by traffic category</p>
            </div>
          </div>
        </div>
        <div className="mo-sales-control">
          <div className="mo-control">
            <div className="check-name">
              <input type="checkbox" defaultChecked></input>
              <p className="mo-p-control">Organic social : ₹ 399</p>
            </div>
            {/* <p className="price">₹ 399</p> */}
            
          </div>
          <div className="mo-control">
            <div className="check-name">
              <input type="checkbox"></input>
              <p className="mo-p-control">Direct : ₹ 374</p>
            </div>
            
          </div>
          <div className="mo-control">
            <div className="check-name">
              <input type="checkbox"></input>
              <p className="mo-p-control">Organic search : ₹ 0</p>
            </div>
            {/* <p className="price">₹ 0</p> */}
            
          </div>
          <div className="mo-control">
            <div className="check-name">
              <input type="checkbox"></input>
              <p className="mo-p-control">Email marketing : ₹ 0</p>
            </div>
            
          </div>
          <div className="mo-control">
            <div className="check-name">
              <input type="checkbox"></input>
              <p className="mo-p-control">Paid social : ₹ 0</p>
            </div>
            
          </div>
        </div>
        <div style={{paddingBottom:'15px'}}>
          <ReactApexChart
            options={PaidChart.options}
            series={PaidChart.series}
            type="area"
            width={"100%"}
            height={"200%"}
          />
        </div>
      </div>

      <div className="mo-sales-div">
        <div className="mo-sales-top">
          <div className="mo-sales-left">
            <div>
              <p className="mo-head-main2">Organic search</p>
            </div>
          </div>
          <div className="mo-sales-right">
          <img src={Google}></img>
          </div>
        </div>

        <div className="mo-sales-control borderbottom">
          <div className="mo-control">
            <div className="check-name">
              <p>Sales : ₹ 399</p>
            </div>
          </div>
          <div className="mo-control">
            <div className="check-name">
              <p>Sessions : ₹ 374</p>
            </div>
            <div className="platform-arrow-rise">
              <FaArrowUpLong />
              <p>18%</p>
            </div>
          </div>
          <div className="mo-control">
            <div className="check-name">
              <p>Impressions : 13,543</p>
            </div>
            {/* <p className="price">₹ 0</p> */}
            <div className="platform-arrow-rise">
              <FaArrowUpLong />
              <p>1,443</p>
            </div>
          </div>
          <div className="mo-control">
            <div className="check-name">
              <p>Clicks : 277</p>
            </div>
            <div className="platform-arrow-down">
              <FaArrowDownLong />
              <p>22</p>
            </div>
          </div>
          <div className="mo-control">
            <div className="check-name">
              <p>CTR : 2.0%</p>
            </div>
            <div className="platform-arrow-neutral">
              <p>0</p>
            </div>
          </div>
        </div>
        <MoSearchTable columns={column} rows={row} />
      </div>

      <div className="mo-sales-div">
        <div className="mo-sales-top">
          <div className="mo-sales-left">
            <div>
              <p className="mo-head-main2">Paid ad campaigns</p>
            </div>
          </div>
          <div className="mo-sales-right">
          </div>
        </div>

        <div className="mo-ad-paid-control">
          <div className="mo-search-filter mo-search-active">All platform</div>
          <div className="mo-search-filter ">Google</div>
          <div className="mo-search-filter ">Other</div>
          <div className="mo-search-filter ">Facebook</div>
          <div className="mo-search-filter ">Instagram</div>
        </div>

        <div className="paid-ad-detail-div">
          <div className="left-detail-paid-ad">
            <div className="left-detail-top">
              <p className="paid-ad-p-big">Sessions : 674</p>
            </div>
            <p className="paid-ad-p-small">
              Data from <span className="learnmore">tracked campaigns</span>
            </p>
          </div>
          <div className="paid-ad-verti"></div>
          <div className="right-detail-paid-ad">
            <div className="left-detail-top">
              <p className="paid-ad-p-big">Amount spent : ₹0</p>
            </div>
            <p className="paid-ad-p-small">
              Data from your{" "}
              <span className="learnmore">connected platforms</span>
            </p>
          </div>
        </div>

        <div className="paid-ad-main-div">
          <div className="left-paid-ad-main">
            <div className="mo-sales-left">
              <div className="sales-arrow">
                <p className="mo-head-main">Sessions</p>
                <IoIosArrowDown className="mo-head-main" />
              </div>
              <div>
                <p className="mo-head-main2">over time</p>
              </div>
            </div>

            <div className="paid-ad-by-main">
              <div className="flex-indicator">
                <div className="indicator-paid"></div>
                <div className="paid-ad-by">
                  <p className="paid-ad-p">Google : ₹ 674</p>
                </div>
              </div>
              <div className="flex-indicator">
                <div className="indicator-paid2"></div>
                <div className="paid-ad-by">
                  <p className="paid-ad-p">Other : ₹ 0</p>
                </div>
              </div>
            </div>
            <ReactApexChart
              options={PaidChart.options}
              series={PaidChart.series}
              type="area"
              width={"100%"}
              height={"80%"}
            />
          </div>
          <div className="right-paid-ad-main">
            <p>Top campaigns by <span className="learnmore">sessions <IoIosArrowDown/></span> </p>
            <div className="top-campaign-paid-ad">
              <FcGoogle className="googlelogo"/>
              <p className="price">₹ 674</p>
            </div>
            <Progress value={100}/>

            <div className="top-campaign-paid-ad">
              <CiGlobe className="googlelogo"/>
              <p className="price">₹ 0</p>
            </div>
            <Progress value={0}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketingOverview;
