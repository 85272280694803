import React, { useState } from "react";
import Autocomplete from "@mui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const StyledTextField = styled(TextField)(({ theme }) => ({
  padding: "0 !important",
  "& .MuiOutlinedInput-root": {
    padding: "10px !important",
  },
  "& .MuiOutlinedInput-input": {
    padding: "0px!important",
  },
  "& .MuiAutocomplete-input": {
    padding: "0px",
  },
  "& .MuiInputBase-input::placeholder": {
    fontSize: "14px",
  },
}));

export default function ChipDropdown({
  placeholder,
  selectedOptions,
  setSelectedOptions,
}) {
  const handleOnChange = (event, value) => {
    setSelectedOptions(value);
  };

  return (
    <div>
      <Autocomplete
        multiple
        freeSolo
        id="tags-outlined"
        options={selectedOptions}
        defaultValue={selectedOptions}
        onChange={handleOnChange}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            variant="outlined"
            placeholder={placeholder ?? "Select or type..."}
          />
        )}
      />
    </div>
  );
}
