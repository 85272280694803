import React, {useState} from "react";

const Add = ({setInput}) => {

    const [active, setActive] = useState(0);
  return (
    <>
      <div className="ad-blocks-cont">
        <div className="temp-parent">
          <div className="adb-name">Template 1</div>
          <div className={active==0 ? "temp1-main temp-active":"temp1-main"} onClick={()=>{setActive(0); setInput((prev)=>({...prev, template:0}))}}>
            <img src="/Asset 4.png" style={{transform:"rotate(180deg)"}}></img>
          </div>
        </div>
        <div className="temp-parent">
          <div className="adb-name">Template 2</div>
          <div className={active==1 ? "temp1-main temp-active":"temp1-main"} onClick={()=>{setActive(1); setInput((prev)=>({...prev, template:1}))}}>
          <img src="/Asset 4.png" ></img>
          </div>
        </div>
        <div className="temp-parent">
          <div className="adb-name">Template 3</div>
          <div className={active==2 ? "temp1-main temp-active":"temp1-main"} onClick={()=>{setActive(2); setInput((prev)=>({...prev, template:2}))}}>
          <img src="/Asset 3.svg" ></img>
          </div>
        </div>
      </div>
    </>
  );
};

export default Add;
