import React, { useState } from "react";
import * as Yup from "yup";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { adminSignIn } from "../api/Authentication";
import { Const } from "../utils/Constants";
import "../styles/login.css";

const SignIn = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [load, setLoad] = useState(false);

  // Form Validator
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Eamil is required")
      .email("Please enter valid email id"),
    password: Yup.string().required("Password is required"),
  });

  const formOptions = { resolver: yupResolver(validationSchema), mode: "onSubmit" };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = async (e) => {
    try {
      setLoad(true);
      const payload = {
        email: email,
        password: password,
      };
      const res = await adminSignIn(payload);
      if (res.token) {
        localStorage.setItem(Const.Token, res.token);
        localStorage.setItem(Const.LoggedIn, Const.STrue);
        localStorage.setItem(Const.Session, JSON.stringify(res.session));
        localStorage.setItem("tabs", "tabs-0");
        navigate("/home");
      }
    } catch (error) {
      setLoad(false);
      console.error(error);
    }
  };

  return (
    <>
      <div className="login-cont">
        <div className="login-left-cont">
          <div className="left-one">
            <img
              src="./login.jpg"
              alt=""
            />
          </div>
        </div>
        <div className="login-right-cont">
          <div className="left-two">
            <form className="login-inner" onSubmit={handleSubmit(onSubmit)}>
              <div className="login-t">Sign in</div>
              <div className="inp-rel">
                <input
                  {...register("email")}
                  typeof="email"
                  className="login-inp"
                  value={email}
                  name="email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  placeholder="Email"
                />
                {errors && errors.email && (
                  <div className="error">{errors.email.message}</div>
                )}
              </div>
              <div className="pass-cont inp-rel">
                <div
                  className="eye-cont flex-all"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                </div>
                <input
                  {...register("password")}
                  type={showPassword ? "text" : "password"}
                  className="login-inp"
                  value={password}
                  name="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  placeholder="Password"
                />
                {errors && errors.password && (
                  <div className="error">{errors.password.message}</div>
                )}
              </div>
              <button
                className="login-btn flex-all"
                type="submit"
                disabled={load}
              >
                {load ? <div className="login-load"></div> : "Login"}
              </button>
              <div className="not-up">
                Developed by{" "}
                <Link to={"https://www.zerrorstudios.com/"} target="_blank">
                  <span>RPSG Media</span>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
