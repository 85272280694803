import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { GoPlus } from "react-icons/go";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const SelectDropdown = ({ data, name, selected, handleOnChange,setAddAuthor }) => {
  return (
    <FormControl sx={{}} size="small" className="general-dropdown" style={{margin:'10px 0'}}>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        name={name}
        value={selected ?? ""}
        onChange={handleOnChange}
        className="general-dropdown2"
      >
        {data.map((item, i) => (
          <MenuItem
            value={item.code}
            className="menuitem-drop"
            key={`option-${i}`}
          >
            <div className="general-drop-img-name">
              <p>{item.status}</p>
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectDropdown;
