import React from "react";
import "../../styles/admin/Footer.css";
import { useNavigate } from "react-router-dom";


const Footer = () => {
  return (
    <>
      <div className="footer">
        <div>
            <p className="footer-p">Copyright &#169; 2024 | Version 1.01.000</p>
        </div>
        <div>
            <a href="https://www.zerrorstudios.com/" target="_blank" rel="noopener noreferrer"><p className="footer-p">Developed by <span className="zerror">RPSG Media</span></p></a>
        </div>
      </div>
    </>
  );
};

export default Footer;
