import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa6";
import "../../styles/writers/Writers.css";
import TableCategory from "../../components/categories/TableCategory";
import DeletePopup from "../../components/common/DeletePopup";
import { Const } from "../../utils/Constants";
import { useNavigate } from "react-router-dom";
import {
  getListCategory,
  categoryDelete,
  updateSequenceCategory,
  duplicateCategory,
  exportCategory,
} from "../../api/Category";

const defaultcat = [
  {
    name: "All Post",
    isLocked: true,
  },
];

const columns = ["Category Details", "Sub-Categories", "Stories"];

const Category = () => {
  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);
  const [deleteCategory, setDeleteCategory] = useState(null);
  const [row, setRow] = useState([]);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState({});
  const [offset, setOffset] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);
  const [selection, setSelection] = useState({
    select_bulk: false,
    selected: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [rowLoading, setRowLoading] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState({
    export: false,
    duplicate: false,
  });

  useEffect(() => {
    document
      .getElementsByClassName("admin-right")[0]
      .addEventListener("scroll", () => {
        if (
          document.getElementsByClassName("main-div")[0] &&
          document.getElementsByClassName("admin-right")[0].scrollTop >
            document.getElementsByClassName("main-div")[0].offsetTop - 150
        ) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      });
    // const element = document.getElementsByClassName("table-of")[0];
    // if (orders.length > 0) {
    //   element.addEventListener("scroll", handleLoadMore);
    // }

    setRowLoading(true);
    const timeout = setTimeout(() => {
      fetchData();
    }, 500);
    return () => {
      // element.removeEventListener("scroll", handleLoadMore);
      clearTimeout(timeout);
    };
  }, [filter?.search, offset, isUpdated]);

  const fetchData = async () => {
    const payload = {
      search: JSON.stringify(filter?.search) || "",
      limit: Const.Limit,
      offset: offset,
    };

    try {
      const res = await getListCategory(payload);
      if (res) {
        if (offset > 0) {
          setCount((prev) => prev + res.count);
          setRow((prevData) => [...prevData, ...res.data]);
          return;
        }

        setCount(res.count);
        setRow(res.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setRowLoading(false);
    }
  };

  const handleLoadMore = () => {
    const element = document.getElementsByClassName("table-of")[0];
    if (
      element.scrollHeight - element.scrollTop === element.clientHeight &&
      row.length < count
    ) {
      setRowLoading(true);
      setOffset((prevOffset) => prevOffset + Const.Limit);
      return;
    }
  };

  const handleSearch = (event) => {
    const { name, value } = event.target;
    const updatedFilter = { ...filter, [name]: value };
    if (!value) {
      delete updatedFilter[name];
    }
    setOffset(0);
    setFilter(updatedFilter);
  };

  const handleSelection = (event) => {
    const { id, checked } = event.target;
    if (id === "selection" || id === "selection-toggle") {
      setSelection({ ...selection, select_bulk: checked, selected: [] });
    } else {
      if (selection.select_bulk) {
        if (checked) {
          const filterSelected = selection.selected.filter((select) => {
            return select !== id;
          });
          setSelection({ ...selection, selected: filterSelected });
        } else {
          setSelection({ ...selection, selected: [...selection.selected, id] });
        }
      } else {
        if (checked) {
          setSelection({ ...selection, selected: [...selection.selected, id] });
        } else {
          const filterSelected = selection.selected.filter((select) => {
            return select !== id;
          });
          setSelection({ ...selection, selected: filterSelected });
        }
      }
    }
  };

  const handleFilter = (event) => {
    const { name, value, type, checked } = event.target;
    if (name === "status") {
      if (value === "all") {
        const { [name]: removedFilter, ...rest } = filter;
        setFilter(rest);
      } else {
        setFilter((prevFilter) => ({
          ...prevFilter,
          [name]: JSON.parse(value),
        }));
      }
    } else if (name === "category") {
      if (checked) {
        setFilter((prevFilter) => ({
          ...prevFilter,
          [name]: [...(prevFilter?.[name] || []), value],
        }));
      } else {
        setFilter((prevFilter) => ({
          ...prevFilter,
          [name]: prevFilter[name].filter((item) => item !== value),
        }));
      }
    } else {
      setFilter((prevFilter) => ({ ...prevFilter, [name]: value }));
    }
  };

  const handleDelete = async () => {
    try {
      const res = await categoryDelete(deleteCategory);
      if (res) {
        const filterData = row.filter(
          (category) => category._id !== deleteCategory
        );
        setRow(filterData);
        setCount((prev) => prev - 1);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDeleteCategory(null);
    }
  };

  const handleEdit = (id) => {
    navigate(`/categories/edit-category/${id}`);
  };

  const handleDragAndDrop = async (newRow) => {
    try {
      const payload = { data: newRow };
      const res = await updateSequenceCategory(payload);
      if (res) {
        console.log("Sequence updated");
      }
    } catch (e) {
      console.error("Error while updating sequence tag", e);
    }
  };

  const handleDuplicate = async () => {
    setIsBtnLoading((prev) => ({ ...prev, duplicate: true }));
    try {
      const payload = {
        select_bulk: selection.select_bulk,
        selected: selection.selected,
      };
      await duplicateCategory(payload);
      setIsUpdated((prev) => !prev);
    } catch (error) {
      console.error(error);
    } finally {
      setIsBtnLoading((prev) => ({ ...prev, duplicate: false }));
    }
  };

  const handleExport = async () => {
    setIsBtnLoading((prev) => ({ ...prev, export: true }));
    try {
      const payload = {
        select_bulk: selection.select_bulk,
        selected: selection.selected,
      };
      const res = await exportCategory(payload);
      if (!res.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await res.blob();

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "category.csv");
      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    } finally {
      setIsBtnLoading((prev) => ({ ...prev, export: false }));
    }
  };

  return (
    <>
      <div className="head-of-head writer-top">
        <div className="table-name">Categories ({count})</div>
        <div className="fixed-right">
          {/* <MoreActionbtn/> */}
          {/* <button
            className="btn-primary"
            onClick={() => {
              navigate("/categories/add-category");
            }}
          >
            <FaPlus className="plus-logo" /> Create Category
          </button> */}
        </div>
      </div>
      <p className="p-top-writer">
        Group posts by topic to help readers and search engines find your
        content.
      </p>
      <TableCategory
        count={count}
        columns={columns}
        rows={row}
        selection={selection}
        filter={filter}
        defaultcat={defaultcat}
        setIsUpdated={setIsUpdated}
        rowLoading={rowLoading}
        isBtnLoading={isBtnLoading}
        handleSelection={handleSelection}
        handleSearch={handleSearch}
        handleDuplicate={handleDuplicate}
        handleExport={handleExport}
        handleFilter={handleFilter}
        handleDragAndDrop={handleDragAndDrop}
        setDeleteCategory={setDeleteCategory}
        handleEdit={handleEdit}
      />

      {/* {deleteCategory && (
        <DeletePopup
          setDelete={setDeleteCategory}
          handleDelete={handleDelete}
        />
      )} */}
    </>
  );
};

export default Category;
