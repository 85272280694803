import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa6";
import { MdArrowForwardIos } from "react-icons/md";
import "../../styles/writers/Writers.css";
import TableShortStories from "../../components/stories/TableShortStory";
import { Const } from "../../utils/Constants";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getListVideos,
  deleteVideos,
  duplicateVideos,
  exportVideos,
  updateStatus
} from "../../api/VideoStories";
import { getTag } from "../../api/Tags";
import CreateVideoBtn from "../../components/stories/addVideoStory/createVideobtn"
import { getListWriter } from "../../api/Writers";
import Tabs from "../../components/stories/Tabs";
import DeletePopup from "../../components/common/StoryDeletePopup";
import RestorePopup from "../../components/common/RestorePopUp";
import { FaRegTrashCan } from "react-icons/fa6";

const VideoStories = () => {
  const columns = ["Shorts Details", "Category", "Tags", "Status"];
  const navigate = useNavigate();
  const location = useLocation();

  const [scrolled, setScrolled] = useState(false);
  const [deleteStories, setDeleteStories] = useState(null);
  const [row, setRow] = useState([]);
  const [tag, setTag] = useState({});
  const [author, setAuthor] = useState({});
  const [count, setCount] = useState({
    allCount: 0,
    publishedCount: 0,
    unpublishedCount: 0,
    draftCount: 0,
    trashCount: 0,
    scheduledCount: 0,
  });
  const [filter, setFilter] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [offset, setOffset] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);
  const [selection, setSelection] = useState({
    select_bulk: false,
    selected: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isbtnLoading, setIsBtnLoading] = useState(false);
  const [isbtnLoadingExport, setIsBtnLoadingExport] = useState(false);
  const [rowLoading, setRowLoading] = useState(false);
  const [tabSelected, setTabSelected] = useState("all");
  const [selectedTags, setSelectedTags] = useState([]);
  const [restoreStory, setRestoreStory] = useState(null);
  const [selectedAuthors, setSelectedAuthors] = useState([]);

  useEffect(() => {
    const data = location?.state?.data ?? [];
    if (data && data.length > 0) {
      const index = data.length - 1;
      setBreadcrumbs(data);
      setFilter((prevFilter) => ({
        ...prevFilter,
        category: [...(prevFilter?.category || []), data[index].id],
      }));
    }
  }, []);

  useEffect(() => {
    document
      .getElementsByClassName("admin-right")[0]
      .addEventListener("scroll", () => {
        if (
          document.getElementsByClassName("main-div")[0] &&
          document.getElementsByClassName("admin-right")[0].scrollTop >
            document.getElementsByClassName("main-div")[0].offsetTop - 150
        ) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      });
    // const element = document.getElementsByClassName("table-of")[0];
    // if (orders.length > 0) {
    //   element.addEventListener("scroll", handleLoadMore);
    // }

    setRowLoading(true);
    const timeout = setTimeout(() => {
      fetchData();
    }, 500);
    return () => {
      // element.removeEventListener("scroll", handleLoadMore);
      clearTimeout(timeout);
    };
  }, [filter?.search, offset, isUpdated]);

  useEffect(() => {
    fetchTag();
    fetchAuthor();
  }, []);

  const fetchTag = async () => {
    try {
      const res = await getTag();
      if (res) {
        setTag(res.data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchAuthor = async () => {
    const payload = {
      search: "",
      limit: Const.Limit,
      offset: 0,
    };
    try {
      const res = await getListWriter(payload);
      if (res) {
        setAuthor(res.data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchData = async () => {
    const payload = {
      filter:
        filter && Object.keys(filter).length > 0
          ? filter
          : location.state?.data && location.state?.data.length > 0
          ? {
              category: [
                location.state?.data[location.state?.data.length - 1]?.id,
              ],
            }
          : {},
      limit: Const.Limit,
      offset: offset,
    };
    try {
      const res = await getListVideos(payload);
      if (res) {
        const {
          allCount,
          publishedCount,
          unpublishedCount,
          draftCount,
          scheduledCount,
          trashCount,
        } = res;
        if (offset > 0) {
          setCount((prevCount) => ({
            ...prevCount,
            allCount: prevCount[allCount] + allCount,
            publishedCount: prevCount[publishedCount] + publishedCount,
            unpublishedCount: prevCount[unpublishedCount] + unpublishedCount,
            draftCount: prevCount[draftCount] + draftCount,
            trashCount: prevCount[trashCount] + trashCount,
            scheduledCount: prevCount[scheduledCount] + scheduledCount,
          }));

          setRow((prevData) => [...prevData, ...res.data]);
          return;
        }
        setCount({
          allCount: allCount,
          publishedCount: publishedCount,
          unpublishedCount: unpublishedCount,
          draftCount: draftCount,
          trashCount: trashCount,
          scheduledCount: scheduledCount,
        });
        setRow(res.data);
      }
    } catch (e) {
      console.error("Error while fatching stories list", e);
    } finally {
      setIsLoading(false);
      setRowLoading(false);
    }
  };

  const handleSearch = (event) => {
    const { name, value } = event.target;
    const updatedFilter = { ...filter, [name]: value };
    if (!value) {
      delete updatedFilter[name];
    }
    setOffset(0);
    setFilter(updatedFilter);
  };

  // const editItem = (id) => {
  //   navigate(`/admin/edit-product/?id=${id}`);
  // };

  const handleSelection = (event) => {
    const { id, checked } = event.target;
    if (id === "selection" || id === "selection-toggle") {
      setSelection({ ...selection, select_bulk: checked, selected: [] });
    } else {
      if (selection.select_bulk) {
        if (checked) {
          const filterSelected = selection.selected.filter((select) => {
            return select !== id;
          });
          setSelection({ ...selection, selected: filterSelected });
        } else {
          setSelection({ ...selection, selected: [...selection.selected, id] });
        }
      } else {
        if (checked) {
          setSelection({ ...selection, selected: [...selection.selected, id] });
        } else {
          const filterSelected = selection.selected.filter((select) => {
            return select !== id;
          });
          setSelection({ ...selection, selected: filterSelected });
        }
      }
    }
  };

  const handleFilter = (event) => {
    const { name, value, type, checked } = event.target;
    if (name === "status") {
      if (value === "all") {
        const { [name]: removedFilter, ...rest } = filter;
        setFilter(rest);
      } else {
        setFilter((prevFilter) => ({
          ...prevFilter,
          [name]: JSON.parse(value),
        }));
      }
    } else if (name === "category") {
      if (checked) {
        setFilter((prevFilter) => ({
          ...prevFilter,
          [name]: [...(prevFilter?.[name] || []), value],
        }));
      } else {
        setFilter((prevFilter) => ({
          ...prevFilter,
          [name]: prevFilter[name].filter((item) => item !== value),
        }));
      }
    } else {
      setFilter((prevFilter) => ({ ...prevFilter, [name]: value }));
    }
  };

  const handleDelete = async () => {
    try {
      const res = await deleteVideos(deleteStories);
      if (res) {
        setIsUpdated((prev) => !prev);
      }
      if (tabSelected != "all") {
        if (res) {
          const writer = row.filter((tag) => tag._id !== deleteStories);
          setRow(writer);
        }
      }
    } catch (e) {
      console.error("Error while deleting writer", e);
    } finally {
      setDeleteStories(null);
    }
  };

  const sendDataToChild = (isEdit, id) => {
    const getLeftClass = document.querySelector(".admin-left");
    const getRightClass = document.querySelector(".admin-right");
    getLeftClass.classList.add("admin-nav-disable");
    getRightClass.classList.add("admin-right-w100");
    setTimeout(() => {
      getRightClass.classList.add("admin-right-op0");
    }, 350);

    const dataToSend = { prev: "/stories" };
    setTimeout(() => {
      if (isEdit) {
        navigate(`/stories/edit-stories/${id}`, { state: dataToSend });
        return;
      }
      navigate("/stories/add-stories", { state: dataToSend });
    }, 650);
  };

  const handleDublicate = async () => {
    setIsBtnLoading(true);
    try {
      const payload = {
        select_bulk: selection.select_bulk,
        selected: selection.selected,
      };
      await duplicateVideos(payload);
      setIsUpdated((prev) => !prev);
    } catch (e) {
      console.error(e);
    } finally {
      setIsBtnLoading(false);
    }
  };

  const handleExport = async () => {
    setIsBtnLoadingExport(true);
    try {
      const payload = {
        select_bulk: selection.select_bulk,
        selected: selection.selected,
      };
      const res = await exportVideos(payload);
      if (!res.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await res.blob();

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "products.csv");
      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link);
    } catch (e) {
      console.error(e);
    } finally {
      setIsBtnLoadingExport(false);
    }
  };

  const handleRestore = async () => {
    try {
      const payload = { status: 0 };
      await updateStatus(restoreStory, payload);
      setIsUpdated((prev) => !prev);
    } catch (e) {
      console.error(e);
    } finally {
      setRestoreStory(null);
    }
  };

  return (
    <>
      <div className="head-of-head writer-top">
        <div className="table-name">
          {breadcrumbs.length > 0 ? (
            <>
              <span
                className="menu-nav"
                onClick={() => navigate("/categories")}
              >
                Categories
              </span>{" "}
              {breadcrumbs.map((breadcrumb, index) => {
                if (breadcrumbs.length - 1 === 0 || index > 0) {
                  return (
                    <>
                      <MdArrowForwardIos className="menu-nav-arrow" />{" "}
                      <span className="active-p-head">{`${breadcrumb.name} (${row.length})`}</span>{" "}
                    </>
                  );
                } else {
                  return (
                    <>
                      <MdArrowForwardIos className="menu-nav-arrow" />{" "}
                      <span
                        className="menu-nav"
                        onClick={() =>
                          navigate("/subcategories", {
                            state: {
                              data: [breadcrumbs[0]],
                            },
                          })
                        }
                      >
                        {breadcrumb.name}
                      </span>{" "}
                    </>
                  );
                }
              })}
            </>
          ) : (
            <>Shorts Stories</>
          )}
        </div>
        <div className="fixed-right">
          {breadcrumbs.length == 0 && (
            <button
              className="btn-primary-outline"
              onClick={() => navigate("/stories/video-trash-stories")}
            >
              <FaRegTrashCan className="plus-logo" /> Trash
            </button>
          )}
          
          <button
            className="btn-primary"
            onClick={() => {
                navigate("/stories/video-stories/add-story", {
                  state: { data: { mode: true , isShort:true, isHome:false} },
                });
              }}
          >
            <FaPlus className="plus-logo" /> Create Shorts
          </button>
        </div>
      </div>
      <p className="p-top-writer">
        Create, customize and manage your stories.{" "}
        <span className="learn-more">Learn More</span>
      </p>

      <TableShortStories
        count={count}
        columns={columns}
        setFilter={setFilter}
        setTabSelected={setTabSelected}
        rows={row}
        selection={selection}
        tag={tag}
        author={author}
        filter={filter}
        setRestoreStory={setRestoreStory}
        rowLoading={rowLoading}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        setSelectedAuthors={setSelectedAuthors}
        selectedAuthors={selectedAuthors}
        tabSelected={tabSelected}
        setIsBtnLoading={setIsBtnLoading}
        isbtnLoading={isbtnLoading}
        isbtnLoadingExport={isbtnLoadingExport}
        handleEdit={sendDataToChild}
        handleDublicate={handleDublicate}
        handleExport={handleExport}
        setDeleteStories={setDeleteStories}
        setIsUpdated={setIsUpdated}
        handleSelection={handleSelection}
        handleSearch={handleSearch}
        handleFilter={handleFilter}
      />
      {deleteStories && (
        <DeletePopup setDelete={setDeleteStories} handleDelete={handleDelete} />
      )}
      {restoreStory && (
        <RestorePopup
          setDelete={setRestoreStory}
          handleDelete={handleRestore}
        />
      )}
    </>
  );
};

export default VideoStories;
