import React, { useState } from "react";
import Autocomplete from "@mui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const StyledTextField = styled(TextField)(({ theme }) => ({
    padding: '0 !important',
    "& .MuiOutlinedInput-root": {
      padding: '0 !important',
    },
    "& .MuiOutlinedInput-input": {
      padding: '0px',
    },
    "& .MuiAutocomplete-input": {
      padding: '0px',
    },
  }));

export default function Chips({
  option = [],
  selectedOptions,
  handleOnChange,
  handleSave,
}) {
  
  const options = Array.isArray(option) ? option.map((item) => {
    return { id: item._id, label: item.name };
  }) : [];

  return (
    <div>
      <Autocomplete
        multiple
        freeSolo
        id="tags-outlined"
        sx={{border:'none !important'}}
        options={options}
        value={options.filter((option) => selectedOptions.includes(option.id))}
        getOptionLabel={(options) => options.label}
        onChange={handleOnChange}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            variant="outlined"
            onKeyDown={handleSave}
          />
        )}
      />
    </div>
  );
}
