import React, { useState } from "react";
import "../../../styles/stories/settings.css";
import Tooltip from "@mui/material/Tooltip";
import { IoFlagOutline, IoFlagSharp } from "react-icons/io5";

const Categories = ({
  data,
  register,
  errors,
  selectedSubCategory,
  setSelectedSubCategory,
}) => {
  const maxLimit = 35;
  const [flagged, setFlagged] = useState([]);

  const handleChecked = (e, id) => {
    const { checked, value } = e.target;
    if (checked) {
      setSelectedSubCategory(value);
    } else {
      setSelectedSubCategory(null);
      // setFlagged((prev) => prev.filter((item) => item !== id));
    }
  };

  // const handleFlag = (id) => {
  //   setFlagged((prev) =>
  //     prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
  //   );

  //   if (!selectedSubCategory.includes(id)) {
  //     setSelectedSubCategory((prev) => [...prev, id]);
  //   }
  // };

  return (
    <div className="flex-cat">
      <div className="cat-div-video">
        <p className="categories-p">
          Assign story to one or more subcategories for readers to find them.
        </p>
        <div className="assign-cat">
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                {data.length > 0 &&
                  data.map((cat, i) => {
                    return (
                      <div className="cat-list" key={`cat-${i}`}>
                        <label className="cat-title">{cat.name}</label>
                        {cat.subcategory.length > 0 &&
                          cat.subcategory.map((subcat, subcatIndex) => (
                            <div
                              className="flex-cat-div"
                              key={`subcat-${subcatIndex}`}
                            >
                              <div className="flex-checkbox-p">
                                <input
                                  {...register("category")}
                                  type="checkbox"
                                  className="checkbox-cat"
                                  name="category"
                                  value={subcat._id}
                                  checked={selectedSubCategory === subcat._id}
                                  onChange={(e) => {
                                    handleChecked(e);
                                  }}
                                />
                                <p className="categories-p-check">
                                  {subcat.name}
                                </p>
                              </div>
                              {/* {flagged.includes(subcat._id) ? (
                                  <Tooltip
                                    title="Flag this story"
                                    placement="top"
                                  >
                                    <IoFlagSharp
                                      className="flagsvg"
                                      style={{ visibility: "visible" }}
                                      onClick={() => handleFlag(subcat._id)}
                                    />
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    title="Flag this story"
                                    placement="top"
                                  >
                                    <IoFlagOutline
                                      className="flagsvg"
                                      onClick={() => handleFlag(subcat._id)}
                                    />
                                  </Tooltip>
                                )} */}
                            </div>
                          ))}
                      </div>
                    );
                  })}
              </div>
            </div>
            {errors && errors.category && (
              <div className="error-div">
                <div className="error">{errors.category.message}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
