import React, { useRef } from "react";
import { EditorContent } from "@tiptap/react";
import "../../styles/editor/blockeditor.css";
import InlineToolbar from "./InlineToolbar";
import ImageBlockMenu from "../../helpers/extension/Image/components/ImageBlockMenu";
import VideoBlockMenu from "../../helpers/extension/Video/components/VideoBlockMenu";
import EmbedMenu from "../../helpers/extension/Embed/components/EmbedBlockMenu";
import LinkMenu from "../../helpers/extension/Link/components/LinkMenu";

const BlockEditor = ({ editor, menuContainerRef, setOpen, showLink, setShowLink }) => {
  return (
    <>
      <InlineToolbar setOpen={setOpen} editor={editor} />
      <EditorContent editor={editor} />
      <ImageBlockMenu editor={editor} appendTo={menuContainerRef} />
      <VideoBlockMenu editor={editor} appendTo={menuContainerRef} />
      <EmbedMenu editor={editor} appendTo={menuContainerRef} />
      <LinkMenu
        editor={editor}
        appendTo={menuContainerRef}
        showLink={showLink}
        setShowLink={setShowLink}
      />
    </>
  );
};

export default BlockEditor;
