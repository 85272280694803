import React from "react";
import { IoIosArrowForward, IoIosSearch } from "react-icons/io";
import { FiDownload } from "react-icons/fi";
import { CiLock } from "react-icons/ci";
import { FaSort } from "react-icons/fa";
import style from "../../styles/common/TableFlag.module.css";
import { useNavigate } from "react-router-dom";

const TableFlag = ({
  count,
  columns,
  rows,
  filter,
  selection,
  rowLoading,
  handleExport,
  handleSelection,
  handleSearch,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className={style.tableCard}>
        <div className={style.tableFilterSec}>
          {selection.select_bulk || selection.selected.length > 0 ? (
            <>
              <div className={style.bulkSec}>
                <div className="d-flex align-items-center gap-3">
                  <span className={style.bulkcount}>
                    {`${
                      selection.select_bulk
                        ? count - selection.selected.length
                        : selection.selected.length
                    } of ${count} selected`}
                  </span>
                </div>
                <div className={style.verticalDivider}></div>
                <div className="d-flex align-items-center gap-3">
                  <button
                    className="btn-primary-outline-table"
                    onClick={handleExport}
                  >
                    <FiDownload /> Export
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={style.leftSec}>
                <div className={style.searchInv}>
                  <IoIosSearch />
                  <input
                    placeholder="Search"
                    className={style.inputInv}
                    name="search"
                    value={filter?.search ?? ""}
                    onChange={handleSearch}
                  />
                </div>
              </div>
              <div className={style.rightSec}></div>
            </>
          )}
        </div>
        <table className={`${style.table} ${style.customers}`}>
          <thead>
            <tr>
              <th className={style.checktr}>
                {rows.some((item) => item.isLocked) ? (
                  <>
                    <CiLock className={style.locksvg} />
                  </>
                ) : (
                  <>
                    <input
                      type="checkbox"
                      className={style.formCheckbox}
                      id="selection"
                      checked={selection.select_bulk}
                      onChange={handleSelection}
                    />
                  </>
                )}
              </th>
              {columns?.map((items) => {
                return (
                  <>
                    <th>
                      <span role="button">
                        {items} <FaSort className={style.sortIcon} />
                      </span>
                    </th>
                  </>
                );
              })}
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="table-of">
            {rows.length > 0 ? (
              <>
                {rows.map((items, i) => {
                  return (
                    <tr>
                      <td className={style.checktr}>
                        {items.isLocked ? (
                          <CiLock className={style.locksvg} />
                        ) : (
                          <input
                            type="checkbox"
                            className={style.formCheckbox}
                            id={items._id}
                            checked={
                              selection.select_bulk || selection.select_bulk
                                ? !selection.selected.includes(items._id)
                                  ? true
                                  : false
                                : selection.selected.includes(items._id)
                                ? true
                                : false
                            }
                            onChange={handleSelection}
                          />
                        )}
                      </td>
                      <td>
                        <div
                          className={`${style.featureBox} align-items-center`}
                        >
                          <div className={style.tableContent}>
                            <p>{items.name}</p>
                            <p className={style.urlslug}>
                              URL Slug:{" "}
                              {items && items.slug ? `${items.slug}` : ""}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <>
                          <ul className={style.ulsubcat}>
                            {items.section.length > 0
                              ? items.section.map((sec) => {
                                  return (
                                    <li className={style.lisubcat}>
                                      <div
                                        key={sec._id}
                                        style={{ cursor: "pointer" }}
                                        className={style.catnamecatpage}
                                        onClick={() => {
                                          navigate("/flag/stories", {
                                            state: {
                                              data: [
                                                {
                                                  id: sec._id,
                                                  name: sec.name,
                                                },
                                              ],
                                              type: 2,
                                              id: sec._id,
                                            },
                                          });
                                        }}
                                      >
                                        <p>{sec.name}</p>{" "}
                                        <IoIosArrowForward
                                          className={style.catnamearrow}
                                        />
                                      </div>
                                    </li>
                                  );
                                })
                              : ""}
                          </ul>
                        </>
                      </td>
                      <td>
                        <>
                          <ul className={style.ulsubcat}>
                            {items.section.length > 0
                              ? items.section.map((sec) => {
                                  return (
                                    <li className={style.lisubcatcount}>
                                      <div
                                        key={sec._id}
                                        style={{ cursor: "pointer" }}
                                        className={style.catcountcatpage}
                                      >
                                        <p>{`${
                                          sec?.postCount ?? 0
                                        } stories`}</p>
                                      </div>
                                    </li>
                                  );
                                })
                              : ""}
                          </ul>
                        </>
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <>
                {rowLoading ? (
                  <>
                    <div className="text-center">Loading...</div>
                  </>
                ) : (
                  <>
                    <div className="text-center">Tag Not Found</div>
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TableFlag;
