import React, { useEffect, useState } from "react";
import { IoIosSearch } from "react-icons/io";
import { MdContentCopy, MdOutlineDragIndicator } from "react-icons/md";
import { FaSort } from "react-icons/fa";
import { CiImageOn, CiLock } from "react-icons/ci";
import Visidrop from "./VisiDrop";
import style from "../../styles/common/TableCategories.module.css";
import { useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";

const Table = ({
  count,
  columns,
  rows,
  selection,
  filter,
  handleDragAndDrop,
  setIsUpdated,
  rowLoading,
  isBtnLoading,
  handleSelection,
  handleSearch,
  handleDuplicate,
  handleExport,
  setDeleteCategory,
  handleEdit,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className={style.tableCard}>
        <div className={style.tableFilterSec}>
          {selection.select_bulk || selection.selected.length > 0 ? (
            <>
              <div className={style.bulkSec}>
                <div className="d-flex align-items-center gap-3">
                  <CiLock className={style.locksvg} />
                </div>
                <div className={style.verticalDivider}></div>
              </div>
            </>
          ) : (
            <>
              <div className={style.leftSec}>
                <div className={style.searchInv}>
                  <IoIosSearch />
                  <input
                    placeholder="Search"
                    className={style.inputInv}
                    name="search"
                    value={filter?.search ?? ""}
                    onChange={handleSearch}
                  />
                </div>
              </div>
              <div className={style.rightSec}></div>
            </>
          )}
        </div>
        <table className={`${style.table} ${style.customers}`}>
          <thead>
            <tr>
              <th className={style.checktr}>
                <CiLock className={style.locksvg} />
              </th>
              {columns?.map((items) => {
                return (
                  <th key={items}>
                    <span role="button">
                      {items} <FaSort className={style.sortIcon} />
                    </span>
                  </th>
                );
              })}
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="table-of" id="ul">
            {rows.length > 0 ? (
              <>
                {rows.map((item, i) => {
                  return (
                    <>
                      <tr key={item._id}>
                        <td className={style.checktr}>
                          <CiLock className={style.locksvg} />
                        </td>
                        <td>
                          <div
                            className={`${style.featureBox} align-items-center`}
                          >
                            <div className={style.storiesimg}>
                              <div className={style.storiesimgcircle}>
                                {item && item.imgsrc ? (
                                  <img
                                    className={style.storiesimg}
                                    src={item.imgsrc}
                                    alt="Cover Image"
                                  />
                                ) : (
                                  <div className={style.storiesimg}>
                                    <div className={style.storiesimgcircle}>
                                      <CiImageOn
                                        className={style.storiesimglogo}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className={style.tableContent}>
                              <p>{item.name}</p>
                              <p className={style.urlslug}>
                                URL Slug:{" "}
                                {item && item.slug ? `${item.slug}` : ""}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <>
                            <ul className={style.ulsubcat}>
                              {item.submenus.length > 0
                                ? item.submenus.map((submenu) => {
                                    return (
                                      <li className={style.lisubcat}>
                                        <div
                                          key={submenu._id}
                                          style={{ cursor: "pointer" }}
                                          className={style.catnamecatpage}
                                          onClick={() => {
                                            if (item.type === 1) {
                                              navigate("/categories/stories", {
                                                state: {
                                                  data: [
                                                    {
                                                      id: submenu._id,
                                                      name: submenu.name,
                                                      type: item.type,
                                                    },
                                                  ],
                                                  id: submenu._id,
                                                  type: item.type,
                                                  storiesType: null,
                                                },
                                              });
                                            } else if (item.type === 2) {
                                              navigate("/categories/stories", {
                                                state: {
                                                  data: [
                                                    {
                                                      id: submenu._id,
                                                      name: submenu.name,
                                                      type: item.type,
                                                    },
                                                  ],
                                                  id: submenu._id,
                                                  type: item.type,
                                                  storiesType: null,
                                                },
                                              });
                                            } else {
                                              navigate("/categories/stories", {
                                                state: {
                                                  data: [
                                                    {
                                                      id: submenu._id,
                                                      name: submenu.name,
                                                      type: submenu.type,
                                                    },
                                                  ],
                                                  id: submenu.isLocked
                                                    ? item.submenus.map(
                                                        (sub) => sub._id
                                                      )
                                                    : [submenu._id],
                                                  type: submenu.type,
                                                  storiesType: null,
                                                },
                                              });
                                            }
                                          }}
                                        >
                                          <p>{submenu.name}</p>{" "}
                                          <IoIosArrowForward
                                            className={style.catnamearrow}
                                          />
                                        </div>
                                      </li>
                                    );
                                  })
                                : ""}
                            </ul>
                          </>
                        </td>
                        <td>
                          <ul className={style.ulsubcat}>
                            {item.submenus.length > 0
                              ? item.submenus.map((submenu) => {
                                  return (
                                    <li className={style.lisubcatcount}>
                                      <div
                                        key={submenu._id}
                                        style={{ cursor: "pointer" }}
                                        className={style.catcountcatpage}
                                      >
                                        <p>{`${
                                          submenu?.postCount ?? 0
                                        } stories`}</p>
                                      </div>
                                    </li>
                                  );
                                })
                              : ""}
                          </ul>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </>
            ) : (
              <>
                {rowLoading ? (
                  <div className="text-center">Loading...</div>
                ) : (
                  <div className="text-center">Category Not Found</div>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Table;
