import React from "react";
import { Routes, Route } from "react-router-dom";
import Customer from "../pages/forms-submissions/ContactsForms";
import ContactFormSubmissions from "../pages/forms-submissions/ContactFormSubmissions"
// import NewsLetter from "../pages/customers/NewsLetter";
// import Preview from "../pages/customers/Preview";
import ConstructPage from "../components/common/Construct";

const CustomerRoute = () => {
  return (
    <Routes>
      <Route path="/contacts" element={<Customer />} />
      <Route path="/contact-form-submissions" element={<ContactFormSubmissions />} />
      {/* <Route path="/newsletter-signups" element={<NewsLetter />} /> */}
      {/* <Route path="/preview/:id" element={<Preview />} /> */}
      <Route path="/*" element={<ConstructPage />} />
    </Routes>
  );
};

export default CustomerRoute;
