import React from "react";
import { Routes, Route } from "react-router-dom";
import ConstructPage from "../components/common/Construct";

const MonetizationRoute = () => {
  return (
    <Routes>
      <Route path="/" element={"Monetization"} />
      <Route path="/*" element={<ConstructPage />} />
    </Routes>
  );
};

export default MonetizationRoute;
