import React, { useEffect, useState, useRef } from "react";
import { IoIosSearch } from "react-icons/io";
import {
  FiEye,
  FiEyeOff,
  FiEdit,
  FiTrash2,
  FiPlus,
  FiDownload,
  FiX,
  FiChevronDown,
  FiFilter,
} from "react-icons/fi";
import {
  LiaShippingFastSolid,
  LiaRupeeSignSolid,
  LiaRibbonSolid,
} from "react-icons/lia";
import { FaSort } from "react-icons/fa";
import { RiApps2Line } from "react-icons/ri";
import style from "../../styles/common/Table.module.css";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Drawer,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Slider,
  Divider,
  Menu,
  MenuItem,
  Button,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { dateFormate, timeFormate } from "../../utils/Util";
import { getData } from "../../api/admin/Customers";
import Exportbtn from "../../components/forms-and-submission/Exportbtn"

const Table = ({
  count,
  columns,
  rows,
  filter,
  selection,
  rowLoading,
  setId,
  setModal,
  setIsUpdated,
  handleExport,
  handleSelection,
  handleSearch,
  handleFilter,
  setIsDelete,
}) => {
  const navigate = useNavigate();

  const [open, setOpen] = useState({ right: false });
  const [data, setData] = useState({ country: [], states: [], city: [] });
  const abortControllerRef = useRef(null);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const toggleDrawer = (anchor, newOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen({ ...open, [anchor]: newOpen });
  };

  const getFilterData = async (value) => {
    const payload = { type: value };
    const key = value.split(".")[1];

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const newAbortController = new AbortController();
    abortControllerRef.current = newAbortController;

    try {
      const res = await getData(payload, newAbortController.signal);
      if (res) {
        setData((prev) => ({ ...prev, [key]: res[key] }));
      }
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error(error);
      }
    }
  };

  return (
    <>
      <div className={style.tableCard}>
        <div className={style.tableFilterSec}>
          {selection.select_bulk || selection.selected.length > 0 ? (
            <>
              <div className={style.bulkSec}>
                <div className="d-flex align-items-center gap-3">
                  <span className={style.bulkcount}>
                    {`${
                      selection.select_bulk
                        ? count - selection.selected.length
                        : selection.selected.length
                    } of ${count} selected`}
                  </span>
                </div>
                <div className={style.verticalDivider}></div>
                <div className="d-flex align-items-center gap-3">
                  <button
                    className="btn-primary-outline-table"
                    onClick={handleExport}
                  >
                    <FiDownload /> Export
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={style.leftSec}>
                <div className={style.searchInv}>
                  <IoIosSearch />
                  <input
                    placeholder="Search"
                    className={style.inputInv}
                    name="search"
                    value={filter?.search ?? ""}
                    onChange={handleSearch}
                  />
                </div>
              </div>
              <div className={style.rightSec}>
                <React.Fragment key={"right"}>
                  <Exportbtn/>
                  <button
                    className="btn-primary-outline-table"
                    onClick={toggleDrawer("right", true)}
                  >
                    <FiFilter /> Filter
                  </button>
                  {/* Filter Drawer */}
                  <Drawer
                    anchor={"right"}
                    open={open["right"]}
                    onClose={toggleDrawer("right", false)}
                  >
                    <Box sx={{ width: 400 }} role="presentation">
                      <div className={style.filterDrawer}>
                        <div className={style.headSec}>
                          <h2 className={style.heading}>
                            Filter your contacts
                          </h2>
                          <FiX
                            className={style.close}
                            onClick={toggleDrawer("right", false)}
                          />
                        </div>
                        <div className={style.mainSec}>
                          <Accordion className={style.accordion} disableGutters>
                            <AccordionSummary
                              expandIcon={<FiChevronDown />}
                              className={style.accordionBtn}
                              aria-controls="panel1-content"
                              id="panel1-header"
                              onClick={(e) => {
                                e.preventDefault();
                                getFilterData("user.country");
                              }}
                            >
                              Country
                            </AccordionSummary>
                            <AccordionDetails>
                              <select
                                name="country"
                                value={filter?.country ?? ""}
                                onChange={(e) => handleFilter(e)}
                              >
                                <option value={""}>Select</option>
                                {data.country.map((item) => {
                                  return <option value={item}>{item}</option>;
                                })}
                              </select>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion className={style.accordion} disableGutters>
                            <AccordionSummary
                              expandIcon={<FiChevronDown />}
                              className={style.accordionBtn}
                              aria-controls="panel2-content"
                              id="panel2-header"
                              onClick={(e) => {
                                e.preventDefault();
                                getFilterData("user.states");
                              }}
                            >
                              States
                            </AccordionSummary>
                            <AccordionDetails>
                              <select
                                name="states"
                                value={filter?.states ?? ""}
                                onChange={(e) => handleFilter(e)}
                              >
                                <option value={""}>Select</option>
                                {data.states.map((item) => {
                                  return <option value={item}>{item}</option>;
                                })}
                              </select>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion className={style.accordion} disableGutters>
                            <AccordionSummary
                              expandIcon={<FiChevronDown />}
                              className={style.accordionBtn}
                              aria-controls="panel3-content"
                              id="panel3-header"
                              onClick={(e) => {
                                e.preventDefault();
                                getFilterData("user.city");
                              }}
                            >
                              City
                            </AccordionSummary>
                            <AccordionDetails>
                              <select
                                name="city"
                                value={filter?.city ?? ""}
                                onChange={(e) => handleFilter(e)}
                              >
                                <option value={""}>Select</option>
                                {data.city.map((item) => {
                                  return <option value={item}>{item}</option>;
                                })}
                              </select>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion className={style.accordion} disableGutters>
                            <AccordionSummary
                              expandIcon={<FiChevronDown />}
                              className={style.accordionBtn}
                              aria-controls="panel4-content"
                              id="panel4-header"
                            >
                              Email subscriber status
                            </AccordionSummary>
                            <AccordionDetails>
                              <select
                                name="emailSubscribedStatus"
                                value={filter?.emailSubscribedStatus ?? ""}
                                onChange={(e) => handleFilter(e)}
                              >
                                <option value={""}>Select</option>
                                <option value={0}> Is unsubscribed</option>
                                <option value={1}> Is subscribed</option>
                                <option value={2}> Is never subscribed</option>
                              </select>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion className={style.accordion} disableGutters>
                            <AccordionSummary
                              expandIcon={<FiChevronDown />}
                              className={style.accordionBtn}
                              aria-controls="panel5-content"
                              id="panel5-header"
                            >
                              Birth Month
                            </AccordionSummary>
                            <AccordionDetails>
                              <select
                                name="birthMonth"
                                value={filter?.birthMonth ?? ""}
                                onChange={(e) => handleFilter(e)}
                              >
                                <option value={""}>Select</option>
                                <option value={1}>January</option>
                                <option value={2}>February</option>
                                <option value={3}>March</option>
                                <option value={4}>April</option>
                                <option value={5}>May</option>
                                <option value={6}>June</option>
                                <option value={7}>July</option>
                                <option value={8}>August</option>
                                <option value={9}>September</option>
                                <option value={10}>October</option>
                                <option value={11}>November</option>
                                <option value={12}>December</option>
                              </select>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion className={style.accordion} disableGutters>
                            <AccordionSummary
                              expandIcon={<FiChevronDown />}
                              className={style.accordionBtn}
                              aria-controls="panel6-content"
                              id="panel6-header"
                            >
                              Status
                            </AccordionSummary>
                            <AccordionDetails>
                              <select
                                name="status"
                                value={filter?.status ?? ""}
                                onChange={(e) => handleFilter(e)}
                              >
                                <option value={""}>Select</option>
                                <option value={0}>Inactive</option>
                                <option value={1}>Active</option>
                                <option value={2}>Blocked</option>
                              </select>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion className={style.accordion} disableGutters>
                            <AccordionSummary
                              expandIcon={<FiChevronDown />}
                              className={style.accordionBtn}
                              aria-controls="panel7-content"
                              id="panel7-header"
                            >
                              Age
                            </AccordionSummary>
                            <AccordionDetails>
                              <Slider
                                name="age"
                                getAriaLabel={() => "Age"}
                                value={[
                                  filter?.age?.min ?? 0,
                                  filter?.age?.max ?? 100,
                                ]}
                                min={0}
                                max={100}
                                onChange={(e) => handleFilter(e)}
                                valueLabelDisplay="auto"
                              />
                            </AccordionDetails>
                          </Accordion>
                        </div>
                        {/* <div className={style.footerSec}>
                          <button
                            className="btn-primary-outline"
                            onClick={toggleDrawer("right", false)}
                          >
                            Cancle
                          </button>
                          <button
                            className="btn-primary"
                            onClick={() => {
                              setIsUpdated((prev) => !prev);
                            }}
                          >
                            Save
                          </button>
                        </div> */}
                      </div>
                    </Box>
                  </Drawer>
                </React.Fragment>
              </div>
            </>
          )}
        </div>
        <table className={`${style.table} ${style.customers}`}>
          <thead>
            <tr>
              <th className={style.checktr}>
                <input
                  type="checkbox"
                  className={style.formCheckbox}
                  id="selection"
                  checked={selection.select_bulk}
                  onChange={handleSelection}
                />
              </th>
              {columns?.map((items) => {
                return (
                  <>
                    <th>
                      <span role="button">
                        {items} <FaSort className={style.sortIcon} />
                      </span>
                    </th>
                  </>
                );
              })}
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="table-of">
            {rows.length > 0 ? (
              <>
                {rows.map((items, i) => {
                  return (
                    <tr>
                      <td className={style.checktr}>
                        <input
                          type="checkbox"
                          className={style.formCheckbox}
                          id={items._id}
                          checked={
                            selection.select_bulk || selection.select_bulk
                              ? !selection.selected.includes(items._id)
                                ? true
                                : false
                              : selection.selected.includes(items._id)
                              ? true
                              : false
                          }
                          onChange={handleSelection}
                        />
                      </td>
                      <td>
                        <div
                          className={`${style.featureBox} align-items-center`}
                        >
                          <div
                            className={style.tableImg}
                            style={{ borderRadius: "50%" }}
                          >
                            <img src={items.profileImg} fill alt={items.name} />
                          </div>
                          <div className={style.tableContent}>
                            <label className={style.tableTitle}>
                              {items.name}
                            </label>
                            <span className={style.tdSubHead}>Site Member</span>
                          </div>
                          
                        </div>
                      </td>
                      <td>
                        {items && items.email ? items.email : ""}
                        <span className={style.tdSubHead}>
                          {items && items.emailSubscribedStatus === 0
                            ? "Unsubscribed"
                            : items.emailSubscribedStatus === 1
                            ? "Subscribed"
                            : "Never subscribed"}
                        </span>
                      </td>
                      <td>{items && items.phoneno ? items.phoneno : ""}</td>
                      <td>{items && items.createdAt ? <>{dateFormate(items.createdAt)}  <span className="time-table">{timeFormate(items.createdAt)}</span></>  : ""}</td>
                      <td>
                        <div className={style.actions}>
                          <button
                            className={style.items}
                            onClick={() => {
                              navigate(`/customers-and-leads/preview/${items._id}`);
                            }}
                          >
                            <FiEye />
                          </button>
                          <button
                            className={style.items}
                            onClick={() => {
                              setId(i);
                              setModal(true);
                            }}
                          >
                            <FiEdit />
                          </button>
                          <button
                            className={style.items}
                            onClick={() => {
                              setId(i);
                              setIsDelete(true);
                            }}
                          >
                            <FiTrash2 />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <>
                {rowLoading ? (
                  <>
                    <div className="text-center">Loading...</div>
                  </>
                ) : (
                  <>
                    <div className="text-center">Customer Not Found</div>
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Table;
