import Headers from './Headers';
import { ProcessAPI, Const } from '../utils/Constants';

// Get List
export const getListTag = async (body) => {
    const res = await fetch(Const.Link + `api/tag/list?search=${body.search}&limit=${body.limit}&offset=${body.offset}`, new Headers("GET"));
    return ProcessAPI(res);
}


// Create 
export const createTag = async (body) => {
    const res = await fetch(Const.Link + "api/tag/save", new Headers("POST", body));
    return ProcessAPI(res);
}

// Update 
export const updateTag = async (id, body) => {
    const res = await fetch(Const.Link + "api/tag/update/" + id, new Headers("PATCH", body));
    return ProcessAPI(res);
}

// Edit
export const tagEdit = async (id) => {
    const res = await fetch(Const.Link + "api/tag/edit/"+ id, new Headers("GET"));
    return ProcessAPI(res);
}
export const getTag = async () => {
    const res = await fetch(Const.Link + "api/tag/get-tag", new Headers("GET"));
    return ProcessAPI(res);
}

// Delete 
export const deleteTags = async (id) => {
    const res = await fetch(Const.Link + "api/tag/delete/" + id, new Headers("PATCH"));
    return ProcessAPI(res);
}

// Parmanent Delete 
export const parmanentDeleteTag = async (id) => {
    const res = await fetch(Const.Link + "api/tag/parmanent-delete/" + id, new Headers("DELETE"));
    return ProcessAPI(res);
}