import React from "react";

const SizeMedium = () => {
  return (
    <svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 0H0V1H14V0ZM14 13H0V14H14V13ZM1.5 3H12.5C13.3 3 14 3.7 14 4.5V9.5C14 10.3 13.3 11 12.5 11H1.5C0.7 11 0 10.3 0 9.5V4.5C0 3.7 0.7 3 1.5 3ZM12.5 10C12.8 10 13 9.8 13 9.5V4.5C13 4.2 12.8 4 12.5 4H1.5C1.2 4 1 4.2 1 4.5V9.5C1 9.8 1.2 10 1.5 10H12.5Z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

export default SizeMedium;
