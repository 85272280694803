import React from "react";

const Divider = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={36}
        height={36}
        viewBox="0 0 36 36"
      >
        <g fill="none" fillRule="evenodd">
          <g fill="#162D3D">
            <g>
              <g>
                <g>
                  <path
                    d="M30 25.5V27H4.5v-1.5H30zm-12.75-9c1.243 0 2.25 1.007 2.25 2.25S18.493 21 17.25 21 15 19.993 15 18.75s1.007-2.25 2.25-2.25zM12 18v1.5H4.5V18H12zm18 0v1.5h-7.5V18H30zm-12.75 0c-.414 0-.75.336-.75.75s.336.75.75.75.75-.336.75-.75-.336-.75-.75-.75zM30 10.5V12H4.5v-1.5H30z"
                    transform="translate(-823 -408) translate(536 204) translate(256 186) translate(31 18)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};

export default Divider;
