import React, { useState } from "react";
import { GoPlus } from "react-icons/go";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Divider,
  LinearProgress,
  Tab,
  Tabs,
} from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { AiOutlinePlus } from "react-icons/ai";
import { Chart } from "react-google-charts";
import dayjs from "dayjs";
import DateRangePicker from "../../components/fields/DateRangePicker";
import "../../styles/analytics/MarketingOverview.css";
import "../../styles/analytics/StoryOverview.css";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { FaArrowUpLong } from "react-icons/fa6";
import { FcGoogle } from "react-icons/fc";
import { CiGlobe } from "react-icons/ci";
import { FaArrowDownLong } from "react-icons/fa6";
import MoSearchTable from "../../components/analytics/MoSearchTable";
import Progress from "../../components/analytics/Progress";
import Tabstory from "../../components/analytics/Tabs";
import Tabstory2 from "../../components/analytics/Tabs2";
import HeatMap from "../../components/analytics/HeatMap";
import SoTable from "../../components/analytics/SoTable";
import SoTable2 from "../../components/analytics/SoTable2";
import { IoIosArrowForward } from "react-icons/io";

const MarketingOverview = () => {
  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState([
    {
      startDate: dayjs()
        .subtract(30, "days")
        .startOf("day")
        .format("YYYY-MM-DD"),
      endDate: dayjs().endOf("day").format("YYYY-MM-DD"),
    },
  ]);
  const handleDateRangePicker = (start, end) => {
    const date = [
      {
        startDate: start,
        endDate: end,
      },
    ];
    setDateRange(date);
  };

  const PaidChart = {
    series: [
      {
        name: "Paid",
        data: [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 300, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0,
        ],
      },
    ],
    options: {
      chart: {
        type: "area",
        width: "100%",
        height: "200%",
        toolbar: {
          show: false,
        },
      },
      colors: ["#65B741"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        curve: "smooth",
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "vertical",
          shadeIntensity: 0,
          opacityFrom: 0.7,
          opacityTo: 0.2,
        },
      },
      legend: {
        show: true,
      },
      xaxis: {
        type: "datetime",
        categories: [
          "2023-06-04",
          "2023-06-05",
          "2023-06-06",
          "2023-06-07",
          "2023-06-08",
          "2023-06-09",
          "2023-06-10",
          "2023-06-11",
          "2023-06-12",
          "2023-06-13",
          "2023-06-14",
          "2023-06-15",
          "2023-06-16",
          "2023-06-17",
          "2023-06-18",
          "2023-06-19",
          "2023-06-20",
          "2023-06-21",
          "2023-06-22",
          "2023-06-23",
          "2023-06-24",
          "2023-06-25",
          "2023-06-26",
          "2023-06-27",
          "2023-06-28",
          "2023-06-29",
          "2023-06-30",
          "2023-07-01",
          "2023-07-02",
        ],
        labels: {
          show: true,
          format: "MMM dd", // Format the date labels as "Jun 04", "Jun 05", etc.
        },
        axisBorder: {
          show: true,
        },
      },
      yaxis: {
        labels: {
          show: true,
          formatter: function (value) {
            return value.toFixed(0); // Ensures the labels are integers
          },
        },
        min: 0,
        max: 300,
        tickAmount: 3, // This creates 4 intervals: 0, 100, 200, 300
      },
      grid: {
        show: true,
      },
    },
  };
  const column = ["Category name", "Views", "Visitors", "Engagement"];
  const column2 = ["Publish name", "Impressions", "Clicks", "CTR"];

  return (
    <>
      <div className="head-of-head writer-top">
        <div className="table-name">Story Overview</div>
        <div className="fixed-right">
          <Grid item xs={8}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <DateRangePicker
                  onChange={handleDateRangePicker}
                  sDate={dateRange[0].startDate}
                  eDate={dateRange[0].endDate}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      <p className="p-top-writer">
        Track and analyze how your story is performing.
      </p>

      <div className="story-top-main">
        <div className="mo-sales-div left-story-top">
          <Tabstory />
          <div>
            <ReactApexChart
              options={PaidChart.options}
              series={PaidChart.series}
              type="area"
              width={"100%"}
              height={"225%"}
            />
          </div>
        </div>
        <div className="mo-sales-div right-story-top">
          <div className="post-views-so">Post views by time of day</div>
          <HeatMap />
        </div>
      </div>

      <div className="mo-sales-div">
        <Tabstory2 />
        <SoTable columns={column} />
      </div>

      <div className="story-top-main">
        <div className="mo-sales-div left-story-top">
          
          <SoTable2 columns={column2} />
        </div>

        <div className=" right-story-top">
          
          <div className="mo-sales-div">
            <div className="post-views-so">Suggested for you</div>
            <div className="suggested-div">
              <div className="sugg" onClick={()=>{navigate("/analytics/traffic-overview")}}>
                <div className="sugg-head">
                  <p className="sugg-p">Traffic overview</p>
                  <IoIosArrowForward />
                </div>
                <p className="sugg-p-small">Find out where your visitors are based, and how many of them are returning.</p>
              </div>
              <div className="sugg" onClick={()=>{navigate("/analytics/realtime-overview")}}>
                <div className="sugg-head">
                  <p className="sugg-p">Realtime overview</p>
                  <IoIosArrowForward />
                </div>
                <p className="sugg-p-small">Find out where your visitors are based, and how many of them are returning.</p>
              </div>
              <div className="sugg" onClick={()=>{navigate("/analytics/behavior-overview")}}>
                <div className="sugg-head">
                  <p className="sugg-p">Behavior overview</p>
                  <IoIosArrowForward />
                </div>
                <p className="sugg-p-small">See how visitors interact with your site's pages.</p>
              </div>
              <div className="sugg" style={{borderBottom:"none"}} onClick={()=>{navigate("/analytics/marketing-overview")}}>
                <div className="sugg-head">
                  <p className="sugg-p">Marketing overview</p>
                  <IoIosArrowForward />
                </div>
                <p className="sugg-p-small">Track key sales metrics and get insights on your customers.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketingOverview;
