import React from "react";

const TextColor = ({ color }) => {
  return (
    <svg
      viewBox="0 0 12 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      color={color ?? "#000"}
    >
      <g
        id="Specs-for-devs"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Icons"
          transform="translate(-178.000000, -127.000000)"
          fill="#000000"
        >
          <g
            id="Icons-/-Formatting-/-Text-color-/-All-Copy-11"
            transform="translate(178.000000, 127.000000)"
          >
            <path
              d="M3.8398,6 L5.8398,1 L6.1598,1 L8.1598,6 L3.8398,6 Z M9.7598,10 L10.8398,10 L6.8398,0 L5.1598,0 L1.1598,10 L2.2398,10 L3.4408,7 L8.5598,7 L9.7598,10 Z"
              id="Fill-2"
            ></path>
            <polygon
              fill="currentColor"
              id="Fill-3"
              points="0 15 12 15 12 12 0 12"
            ></polygon>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TextColor;
