import React, { useEffect, useState, useRef, useCallback } from "react";
import { IoIosSearch } from "react-icons/io";
import { FiEdit, FiTrash2, FiDownload } from "react-icons/fi";
import { FaSort } from "react-icons/fa";
import style from "../../styles/common/TableTags.module.css";
import { useNavigate } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import { getData } from "../../api/admin/Customers";
import debounce from "lodash.debounce";
import { Const } from "../../utils/Constants";

const Table = ({
  count,
  columns,
  rows,
  filter,
  selection,
  rowLoading,
  handleExport,
  handleSelection,
  handleSearch,
  handleFilter,
  setDeleteTag,
  setEditTag,
  setOpenModel,
  setOffset,
  offset,
  setRowLoading
}) => {
  const navigate = useNavigate();

  const [open, setOpen] = useState({ right: false });
  const [data, setData] = useState({ country: [], states: [], city: [] });
  const abortControllerRef = useRef(null);
  const tableRef = useRef(null);
  const [isEnd, setIsEnd] = useState(false);

  const handleScroll = useCallback(
    debounce(() => {
      const table = tableRef.current;
      if (!table || rowLoading) return;
      const { scrollTop, scrollHeight, clientHeight } = table;
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        // console.log(rows.length,count, offset,"count")
        if (rows.length >= count) {
          // console.log("STOP")
          setIsEnd(true);
          return;
        }

        setRowLoading(true);
        setOffset((prevOffset) => prevOffset + Const.Limit);
      }
    }, 200),
    [rows, count, rowLoading, setOffset, setRowLoading, setIsEnd]
  );

  useEffect(() => {
    const table = tableRef.current;
    if (table) {
      table.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (table) {
        table.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const toggleDrawer = (anchor, newOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen({ ...open, [anchor]: newOpen });
  };

  const getFilterData = async (value) => {
    const payload = { type: value };
    const key = value.split(".")[1];

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const newAbortController = new AbortController();
    abortControllerRef.current = newAbortController;

    try {
      const res = await getData(payload, newAbortController.signal);
      if (res) {
        setData((prev) => ({ ...prev, [key]: res[key] }));
      }
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error(error);
      }
    }
  };

  return (
    <>
      <div className={style.tableCard}>
        <div className={style.tableFilterSec}>
          {selection.select_bulk || selection.selected.length > 0 ? (
            <>
              <div className={style.bulkSec}>
                <div className="d-flex align-items-center gap-3">
                  <span className={style.bulkcount}>
                    {`${
                      selection.select_bulk
                        ? count - selection.selected.length
                        : selection.selected.length
                    } of ${count} selected`}
                  </span>
                </div>
                <div className={style.verticalDivider}></div>
                <div className="d-flex align-items-center gap-3">
                  <button
                    className="btn-primary-outline-table"
                    onClick={handleExport}
                  >
                    <FiDownload /> Export
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={style.leftSec}>
                <div className={style.searchInv}>
                  <IoIosSearch />
                  <input
                    placeholder="Search"
                    className={style.inputInv}
                    name="search"
                    value={filter?.search ?? ""}
                    onChange={handleSearch}
                  />
                </div>
              </div>
              <div className={style.rightSec}></div>
            </>
          )}
        </div>
        <div
          ref={tableRef} // Attach ref to track the table's scroll position
          style={{ height: "100%", overflowY: "auto" }}
        >
        <table className={`${style.table} ${style.customers}`}>
          <thead>
            <tr>
              <th className={style.checktr}>
                <input
                  type="checkbox"
                  className={style.formCheckbox}
                  id="selection"
                  checked={selection.select_bulk}
                  onChange={handleSelection}
                />
              </th>
              {columns?.map((items) => {
                return (
                  <>
                    <th>
                      <span role="button">
                        {items} <FaSort className={style.sortIcon} />
                      </span>
                    </th>
                  </>
                );
              })}
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="table-of">
            {rows.length > 0 ? (
              <>
                {rows.map((items, i) => {
                  let discountedPrice = 0;
                  const collections = items?.collections?.join(", ");
                  if (items.discountTypeRs === false) {
                    discountedPrice = (
                      items.priceperunit -
                      (items.priceperunit * items.discountperunit) / 100
                    ).toFixed(2);
                  } else if (items.discountTypeRs === true) {
                    discountedPrice = (
                      items.priceperunit - items.discountperunit
                    ).toFixed(2);
                  }
                  return (
                    <tr>
                      <td className={style.checktr}>
                        <input
                          type="checkbox"
                          className={style.formCheckbox}
                          id={items._id}
                          checked={
                            selection.select_bulk || selection.select_bulk
                              ? !selection.selected.includes(items._id)
                                ? true
                                : false
                              : selection.selected.includes(items._id)
                              ? true
                              : false
                          }
                          onChange={handleSelection}
                        />
                      </td>
                      <td>{items && items.name ? `${items.name}` : ""}</td>

                      <td>{items && items.slug ? `${items.slug}` : ""}</td>
                      <td>{items ? `${items.postCount} Stories` : ""}</td>
                      <td>
                        <div>
                          <LinearProgress
                            className={style.linearProgressBar}
                            value={50}
                            color="primary"
                            variant="determinate"
                          />
                          {/* <p>50%</p> */}
                        </div>
                      </td>
                      {/* <td>{items && items.postcount ? `${items.postcount} Posts`  : ""}</td> */}

                      <td>
                        <div className={style.actions}>
                          <button
                            className={style.items}
                            onClick={(e) => {
                              e.preventDefault();
                              // setEditTag(items._id);
                              navigate(`/tags/edit-tag/${items._id}`)
                            }}
                          >
                            <FiEdit />
                          </button>
                          <button
                            className={style.items}
                            onClick={() => {
                              setDeleteTag(items._id);
                            }}
                          >
                            <FiTrash2 />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <>
                {rowLoading ? (
                  <>
                    <div className="text-center">Loading...</div>
                  </>
                ) : (
                  <>
                    <div className="text-center">Tag Not Found</div>
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
        {rowLoading && !isEnd && <p>Loading....</p>}
        </div>
      </div>
    </>
  );
};

export default Table;
