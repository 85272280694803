import Headers from "../Headers";
import { ProcessAPI, Const } from "../../utils/Constants";

// Get Menu API
export const getMenu = async (body) => {
  const res = await fetch(Const.Link + "api/menu", new Headers("GET"));
  return ProcessAPI(res);
};

// Get Sub Menu API
export const getSubMenu = async (id) => {
  const res = await fetch(Const.Link + "api/submenu/" + id, new Headers("GET"));
  return ProcessAPI(res);
};

// Get Collection Menu API
export const getCollection = async (id) => {
  const res = await fetch(Const.Link + "api/submenu/getCategories?submenu=" + id, new Headers("GET"));
  return ProcessAPI(res);
};

// Get Collection Menu API
export const getNonCollection = async (id) => {
  const res = await fetch(Const.Link + "api/submenu/getNonCategories/?submenu=" + id, new Headers("GET"));
  return ProcessAPI(res);
};

// Create New Menu
export const insertMenuSave = async (body) => {
  const res = await fetch(Const.Link + "api/menu", { method: "POST", body: body });
  return ProcessAPI(res);
}

// Update Menu
export const updateMenuSave = async (body) => {
  const res = await fetch(Const.Link + "api/menu", { method: "PATCH", body: body });
  return ProcessAPI(res);
}

// Create New SubMenu
export const insertSubMenuSave = async (body) => {
  const res = await fetch(Const.Link + "api/submenu", { method: "POST", body: body });
  return ProcessAPI(res);
}

// Update Menu
export const updateSubMenuSave = async (body) => {
  const res = await fetch(Const.Link + "api/submenu", { method: "PATCH", body: body });
  return ProcessAPI(res);
}

// Update Submenu Categories or Collection Array
export const updateCollections = async (body) => {
  const res = await fetch(Const.Link + "api/submenu/updateCategories", new Headers("PATCH", body));
  return ProcessAPI(res);
}

// Delete Menu API
export const deleteMenu = async (id) => {
  const res = await fetch(Const.Link + "api/menu/" + id, new Headers("DELETE"));
  return ProcessAPI(res);
};

// Delete Menu API
export const deleteSubMenu = async (id) => {
  const res = await fetch(Const.Link + "api/submenu/" + id, new Headers("DELETE"));
  return ProcessAPI(res);
};

// Delete Menu API
export const deleteCategories = async (body) => {
  const res = await fetch(Const.Link + "api/categories/delete-category", new Headers("PATCH", body));
  return ProcessAPI(res);
};
