import React, { useEffect, useState, useRef } from "react";
import { FiCalendar } from "react-icons/fi";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateRangeCalendar as MuiDateRangePicker } from "@mui/x-date-pickers-pro/DateRangeCalendar";
import style from "../../styles/fields/DateRangePicker.module.css";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popper,
  Fade,
  Paper,
  TextField,
} from "@mui/material";

const intervals = {
  today: {
    title: "Today",
    startDate: new Date(),
    endDate: new Date(),
  },
  yesterday: {
    title: "Yesterday",
    startDate: dayjs().subtract(1, "day").toDate(),
    endDate: dayjs().subtract(1, "day").toDate(),
  },
  last7: {
    title: "Last 7 days",
    startDate: dayjs().subtract(7, "days").toDate(),
    endDate: new Date(),
  },
  last14: {
    title: "Last 14 days",
    startDate: dayjs().subtract(14, "days").toDate(),
    endDate: new Date(),
  },
  last30: {
    title: "Last 30 days",
    startDate: dayjs().subtract(30, "days").toDate(),
    endDate: new Date(),
  },
  last90: {
    title: "Last 90 days",
    startDate: dayjs().subtract(90, "days").toDate(),
    endDate: new Date(),
  },
  last365: {
    title: "Last 365 days",
    startDate: dayjs().subtract(365, "days").toDate(),
    endDate: new Date(),
  },
  thisMonth: {
    title: "This month",
    startDate: dayjs().startOf("month").toDate(),
    endDate: dayjs().endOf("month").toDate(),
  },
  thisYear: {
    title: "This year",
    startDate: dayjs().startOf("year").toDate(),
    endDate: dayjs().endOf("year").toDate(),
  },
  prevMonth: {
    title: "Previous month",
    startDate: dayjs().subtract(1, "month").startOf("month").toDate(),
    endDate: dayjs().subtract(1, "month").endOf("month").toDate(),
  },
  prevYear: {
    title: "Previous year",
    startDate: dayjs().subtract(1, "year").startOf("year").toDate(),
    endDate: dayjs().subtract(1, "year").endOf("year").toDate(),
  },
};

const DateRangePicker = ({
  sDate,
  eDate,
  id = "range-picker",
  getPlacement,
  onChange,
}) => {
  const [selectedStartDate, setSelectedStartDate] = useState(dayjs(sDate));
  const [selectedEndDate, setSelectedEndDate] = useState(dayjs(eDate));
  const [anchorEl, setAnchorEl] = useState(null);
  const [placement, setPlacement] = useState(getPlacement ?? "bottom-end");
  const [selectedInterval, setSelectedInterval] = useState(null);
  const [open, setOpen] = useState(false);

  const popperRef = useRef(null);

  const selectInterval = (key) => {
    const { startDate, endDate } = intervals[key];
    const parsedStartDate = dayjs(startDate);
    const parsedEndDate = dayjs(endDate);
    setSelectedStartDate(parsedStartDate);
    setSelectedEndDate(parsedEndDate);
    setSelectedInterval(key);
  };

  const formattedRange =
    selectedStartDate && selectedEndDate
      ? `${dayjs(selectedStartDate).format("DD MMM YYYY")} - ${dayjs(
          selectedEndDate
        ).format("DD MMM YYYY")}`
      : "Select Date";

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popperRef.current && !popperRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const foundInterval = Object.keys(intervals).find((key) => {
      const { startDate, endDate } = intervals[key];
      const parsedStartDate = dayjs(startDate);
      const parsedEndDate = dayjs(endDate);
      return (
        parsedStartDate.isSame(selectedStartDate, "day") &&
        parsedEndDate.isSame(selectedEndDate, "day")
      );
    });
    if (foundInterval) {
      setSelectedInterval(foundInterval);
    } else {
      setSelectedInterval(null);
    }
  }, [onChange]);

  return (
    <div className={style.daterangepicker}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className={style.inputGroup}>
          <input
            type="text"
            id={id}
            name="daterangepicker"
            value={formattedRange}
            readOnly
            onClick={(e) => {
              setOpen(true);
              setAnchorEl(e.currentTarget);
              setPlacement(placement);
            }}
          />
          <FiCalendar />
        </div>

        <Popper
          open={open}
          anchorEl={anchorEl}
          placement={placement}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper elevation={12} ref={popperRef}>
                <Box sx={{ display: "flex", width: "100%", height: "360px" }}>
                  <Box
                    sx={{
                      minWidth: "180px",
                      mr: 1,
                      pt: 2,
                      borderRight: "1px solid rgba(0,0,0,0.1)",
                      overflowY: "scroll",
                    }}
                  >
                    <List sx={{ p: 0 }}>
                      {Object.keys(intervals).map((key) => (
                        <ListItem
                          key={key}
                          disablePadding
                          selected={key === selectedInterval}
                          onClick={() => selectInterval(key)}
                        >
                          <ListItemButton
                            sx={{
                              backgroundColor:
                                key === selectedInterval
                                  ? "rgba(25, 118, 210, 0.12)"
                                  : "transparent",
                            }}
                          >
                            <ListItemText
                              primary={intervals[key].title}
                              sx={{
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                              primaryTypographyProps={{
                                component: "span",
                                sx: { fontSize: "15px", lineHeight: "1.2" },
                              }}
                            />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                  <Box sx={{ display: "flex", pt: 2 }}>
                    <MuiDateRangePicker
                      startText="Start Date"
                      endText="End Date"
                      value={[selectedStartDate, selectedEndDate]}
                      onChange={(newValue) => {
                        setSelectedStartDate(newValue[0]);
                        setSelectedEndDate(newValue[1]);
                      }}
                      renderInput={(startProps, endProps) => (
                        <>
                          <TextField {...startProps} />
                          <Box sx={{ mx: 2 }}> to </Box>
                          <TextField {...endProps} />
                        </>
                      )}
                      PopperProps={{
                        popperOptions: {
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, 5],
                              },
                            },
                          ],
                        },
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderTop: "1px solid rgba(0,0,0,0.1)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: "15px",
                    p: 2,
                  }}
                >
                  <button
                    className="btn-primary-outline"
                    style={{ width: "100px" }}
                    onClick={() => {
                      setOpen(false);
                      setSelectedInterval(4);
                      setSelectedStartDate(dayjs(sDate));
                      setSelectedEndDate(dayjs(eDate));
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn-primary"
                    style={{ width: "100px" }}
                    onClick={() => {
                      setOpen(false);
                      const formatedStartDate =
                        dayjs(selectedStartDate).format("YYYY-MM-DD");
                      const formatedEndDate =
                        dayjs(selectedEndDate).format("YYYY-MM-DD");
                      onChange(formatedStartDate, formatedEndDate);
                    }}
                  >
                    Apply
                  </button>
                </Box>
              </Paper>
            </Fade>
          )}
        </Popper>
      </LocalizationProvider>
    </div>
  );
};

export default DateRangePicker;
