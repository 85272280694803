import React from "react";

const List = () => {
  return (
    <svg
      width={36}
      height={36}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.75 25V26.5H11.75V25H31.75ZM31.75 17.5V19H11.75V17.5H31.75ZM31.75 10V11.5H11.75V10H31.75Z"
        fill="#162D3D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.321677 9.95649C0.226121 9.85904 0.226121 9.70165 0.321677 9.6042L0.845394 9.07328C0.891622 9.02636 0.95435 9 1.01976 9C1.08517 9 1.1479 9.02636 1.19413 9.07328L4.94148 12.8722L8.68759 9.07453C8.73382 9.02761 8.79655 9.00125 8.86196 9.00125C8.92737 9.00125 8.9901 9.02761 9.03633 9.07453L9.56004 9.6042C9.60632 9.65107 9.63232 9.71466 9.63232 9.78097C9.63232 9.84728 9.60632 9.91088 9.56004 9.95774L5.11646 14.4625C5.07023 14.5094 5.0075 14.5358 4.94209 14.5358C4.87668 14.5358 4.81395 14.5094 4.76772 14.4625L0.322909 9.95649H0.321677Z"
        fill="#162D3D"
      />
    </svg>
  );
};

export default List;
