import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { Const } from "../../utils/Constants";

const AntTabs = styled(Tabs)({
  overflow: "hidden",
  minHeight: "40px",
  WebkitOverflowScrolling: "touch",
  display: "flex",
  borderColor: "rgba(0, 0, 0, 0.2)",
  "& .MuiTabs-indicator": {
    position: "absolute",
    height: "2px",
    bottom: "2px",
    width: "100%",
    transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundColor: "#1890ff",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    minHeight: "30px",
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);

export default function BasicTabs({
  count,
  setFilter,
  setTabSelected,
  setIsUpdated,
}) {
  const [value, setValue] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === null) {
      setTabSelected("all");
    } else if (newValue == 1) {
      setTabSelected("published");
    } else if (newValue == 0) {
      setTabSelected("unpublished");
    } else if (newValue == 3) {
      setTabSelected("draft");
    } else if (newValue == 4) {
      setTabSelected("scheduled");
    } else if (newValue == 2) {
      setTabSelected("trash");
    } else {
      setTabSelected(null);
    }
    setFilter((prevFilter) => ({
      ...prevFilter,
      status: JSON.parse(newValue),
    }));
    setIsUpdated((prev) => !prev);
  };

  return (
    <>
      <div className="div-tab">
        <Box sx={{ width: "100%" }}>
          <AntTabs
            value={value}
            onChange={handleChange}
            aria-label="ant example"
          >
            <AntTab value={null} label={`All (${count?.allCount ?? 0})`} />
            <AntTab
              value={Const.Active}
              label={`Published (${count?.publishedCount ?? 0})`}
            />
            <AntTab
              value={Const.Inactive}
              label={`Unpublished (${count?.unpublishedCount ?? 0})`}
            />
            <AntTab
              value={Const.Draft}
              label={`Drafts (${count?.draftCount ?? 0})`}
            />
            <AntTab
              value={Const.Scheduled}
              label={`Scheduled (${count?.scheduledCount ?? 0})`}
            />
            {/* <AntTab value={Const.Trash} label={`Trash (${count?.trashCount ?? 0})`} /> */}
          </AntTabs>
        </Box>
      </div>
    </>
  );
}
