import Headers from './Headers';
import { ProcessAPI, Const } from '../utils/Constants';

// Get List
export const getListVideos = async (body) => {
    const res = await fetch(Const.Link + "api/video/list", new Headers("POST",body));
    return ProcessAPI(res);
}

// Get Trash List
export const getTrashListVideos = async (body) => {
    const res = await fetch(Const.Link + "api/video/trash-list", new Headers("POST",body));
    return ProcessAPI(res);
}

// Create 
export const createVideos = async (body) => {
    const res = await fetch(Const.Link + "api/video/save", new Headers("POST", body, true));
    return res.json();
}

// Duplicate
export const duplicateVideos = async (body) => {
    const res = await fetch(Const.Link + "api/video/create-duplicate", new Headers("POST",body));
    return ProcessAPI(res);
}

// Export
export const exportVideos = async (body) => {
    const res = await fetch(Const.Link + "api/video/export", new Headers("POST",body));
    return res;
}

// Update 
export const updateVideos = async (id, body) => {
    const res = await fetch(Const.Link + "api/video/update/" + id, new Headers("PATCH", body, true));
    return res.json();
}

// Edit
export const editVideos = async (id) => {
    const res = await fetch(Const.Link + "api/video/edit/"+ id, new Headers("GET"));
    return ProcessAPI(res);
}

// UpdateStatus
export const updateStatus = async (id,body) => {
    const res = await fetch(Const.Link + "api/video/update-status/"+ id, new Headers("PUT",body));
    return ProcessAPI(res);
}

// UpdateSchedule
export const updateSchedule = async (id,body) => {
    const res = await fetch(Const.Link + "api/video/update-scheduled/"+ id, new Headers("PUT",body));
    return ProcessAPI(res);
}

// Delete 
export const deleteVideos = async (id) => {
    const res = await fetch(Const.Link + "api/video/delete/" + id, new Headers("PATCH"));
    return ProcessAPI(res);
}

// Parmanent Delete 
export const parmanentDeleteVideos = async (id) => {
    const res = await fetch(Const.Link + "api/video/parmanent-delete/" + id, new Headers("DELETE"));
    return ProcessAPI(res);
}