import React from "react";

const SizeFullWidth = () => {
  return (
    <svg
      width={18}
      height={14}
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 0H2V1H16V0ZM16 13H2V14H16V13ZM1.5 3H16.5C17.3 3 18 3.7 18 4.5V9.5C18 10.3 17.3 11 16.5 11H1.5C0.7 11 0 10.3 0 9.5V4.5C0 3.7 0.7 3 1.5 3ZM16.5 10C16.8 10 17 9.8 17 9.5V4.5C17 4.2 16.8 4 16.5 4H1.5C1.2 4 1 4.2 1 4.5V9.5C1 9.8 1.2 10 1.5 10H16.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SizeFullWidth;
