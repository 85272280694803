import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import { useState, useEffect } from "react";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import General from "./General";
import Advanced from "./Advanced";
// import Categories from "./Categories";
// import Tags from "./Tags";
import "../../../styles/stories/settings.css";
const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#1890ff",
  },
  "& .MuiTabs-flexContainer": {
    justifyContent: "center",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
      width:'50%'
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",

    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
    "&.MuiTabs-flexContainer": {
      justifyContent: "space-between",
    },
  })
);

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Settings = ({
  writer,
  images,
  setImages,
  input,
  contributor,
  openFocalModal,
  setOpenFocalModal,
  setContributor,
  handleInputChange,
  handleDate,
  setWriter,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="div-tab">
        <Box sx={{ width: "100%" }}>
          <AntTabs
            value={value}
            onChange={handleChange}
            aria-label="ant example"
            sx={{
              borderColor: "rgba(0,0,0,0.2)",
            }}
          >
            <AntTab label="Basics" />
            <AntTab label="Advanced" />
          </AntTabs>
          <CustomTabPanel value={value} index={0}>
            <General
              writer={writer}
              images={images}
              setImages={setImages}
              input={input}
              contributor={contributor}
              openFocalModal={openFocalModal}
              setOpenFocalModal={setOpenFocalModal}
              setContributor={setContributor}
              handleInputChange={handleInputChange}
              handleDate={handleDate}
              setWriter={setWriter}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Advanced
              writer={writer}
              images={images}
              setImages={setImages}
              input={input}
              contributor={contributor}
              setContributor={setContributor}
              handleInputChange={handleInputChange}
              handleDate={handleDate}
              setWriter={setWriter}
            />
          </CustomTabPanel>
        </Box>
      </div>
    </>
  );
};

export default Settings;
