import React from "react";
import { IoMdClose } from "react-icons/io";

const TagsChips = ({ tag, selectedTags, handleTagClick, loadMore, tagCount }) => {

  return (
    <>
      {tag && (
        <div className="tag-filter-div">
          {tag.length > 0 ? (
            tag.map((el, i) => (
              <span
                key={el._id}
                className={`tag-filter-chip ${
                  selectedTags.includes(el._id) ? "tag-filter-active" : ""
                }`}
                onClick={() => handleTagClick(el._id)}
              >
                {el.name}
                {selectedTags.includes(el._id) ? <IoMdClose /> : ""}
              </span>
            ))
          ) : (
            <p>No tags available</p>
          )}
        </div>
      )}
      { tag.length<tagCount && (
        <div>
          <button className="btn-primary" onClick={loadMore}>
            {"Show More"}
          </button>
        </div>
      )}
    </>
  );
};

export default TagsChips;
