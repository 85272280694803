import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { MdArrowForwardIos } from "react-icons/md";
import "../../styles/stories/AddVideoStory.css";
import ImageVideo from "../../components/stories/addVideoStory/ImageVideo";
import VideoDetails from "../../components/stories/addVideoStory/VideoDetails";
import { getArticleCategoryList } from "../../api/Category";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { Const } from "../../utils/Constants";
import { generateSlugStories } from "../../utils/Util";
import { getListWriter } from "../../api/Writers";
import { getArticleFlagList } from "../../api/Flags";
import { yupResolver } from "@hookform/resolvers/yup";
import { createVideos, editVideos, updateVideos } from "../../api/VideoStories";
import SEOCategories from "../../components/categories/SEOCategories";
import Categories from "../../components/stories/addVideoStory/Categories";
import Flags from "../../components/stories/addVideoStory/Flags";
const AddVideo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [input, setInput] = useState({ status: 3 });
  const [isEdit, setIsEdit] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(null);
  const [videos, setVidoes] = useState([]);
  const [contributor, setContributor] = useState([]);
  const [open, setOpen] = useState({ right: false });
  const [videoPreviewImgs, setVideoPreviewImgs] = useState([]);
  const [images, setImages] = useState([]);
  const [product, setProduct] = useState({});
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [meta, setMeta] = useState({});
  const [writer, setWriter] = useState([]);
  const [flags, setFlags] = useState([]);
  const [selectedFlags, setSelectedFlags] = useState([]);
  const [customImg, setCustomImg] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState(null);
  const [category, setCategory] = useState({});
  const [mode, setMode] = useState(false);
  const [isShort, setIsShort] = useState(false)
  const [isHome, setIsHome] = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [isCanonical, setIsCanonical] = useState(false);
  const [errorForms, setErrorForms] = useState({
    name: "",
    price: 0,
    sku: "",
  });

  const getId = location.pathname.split("/")[4];

  const toggleDrawer = (anchor, newOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen({ ...open, [anchor]: newOpen });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    category: Yup.string().required("Category is required"),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const fetchData = async () => {
    try {
      if (location?.state?.data) {
        setMode(location?.state?.data?.mode);
        setIsShort(location?.state?.data?.isShort)
        setIsHome(location?.state?.data?.isHome)
      }
      const payload = {
        search: "",
        limit: 200,
        offset: 0,
      };

      const categoryRes = await getArticleCategoryList({ isVideo: null });
      if (categoryRes) setCategory(categoryRes.data);

      const flagRes = await getArticleFlagList();
      if (flagRes) setFlags(flagRes.data);

      const writerRes = await getListWriter(payload);
      if (writerRes) setWriter(writerRes.data);

      if (getId) {
        const res = await editVideos(getId);
        if (res) {
          setInput(res.data);
          setMeta({
            title: res.data.meta.title,
            description: res.data.meta.description,
            robots: res.data.meta.robots,
            ...(res.data.meta.author ? { author: res.data.meta.author } : {}),
            ...(res.data.meta.canonical
              ? { canonical: res.data.meta.canonical }
              : {}),
          });
          setSelectedKeywords(res.data.meta.keywords);
          setImages([res.data.coverImg]);
          setSelectedSubCategory(res.data.subcategory);
          setThumbnailUrl(res.data.coverImg);
          setMode(res.data.isYoutube);
        }
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const files = event.target.files;

    if (files.length > 0) {
      // Iterate through selected files
      const newFiles = Array.from(files).filter((file) => {
        // Check file size (in bytes)
        const fileSize = file.size;

        // 5 MB limit
        const maxSize = 5 * 1024 * 1024;

        // Check if the file size is within the limit
        return fileSize <= maxSize;
      });

      // Update the selected files state
      setImages([...newFiles, ...images]);

      // setInput({...input, images:[...newFiles, ...images]})
    }
  };

  const handleFilethumbnail = (event) => {
    const file = event.target.files[0];
    const files = event.target.files;
    if (file && file.type.startsWith("image/")) {
      const imageUrl = URL.createObjectURL(file);
      setThumbnailUrl(imageUrl);

      const newFiles = Array.from(files).filter((file) => {
        // Check file size (in bytes)
        const fileSize = file.size;

        // 5 MB limit
        const maxSize = 5 * 1024 * 1024;

        // Check if the file size is within the limit
        return fileSize <= maxSize;
      });
      setImages([...files]);
      setCustomImg(true);
      // setThumbnailUrl(null)
    }
  };

  const handleVideoFileChange = (event) => {
    // document.getElementsByTagName('input')[0].addEventListener('change', function(event) {
    var file = event.target.files[0];
    var fileReader = new FileReader();
    if (file.type.match("image")) {
      fileReader.onload = function () {
        var img = document.createElement("img");
        img.src = fileReader.result;
        document.getElementsByTagName("div")[0].appendChild(img);
      };
      fileReader.readAsDataURL(file);
    } else {
      fileReader.onload = function () {
        var blob = new Blob([fileReader.result], { type: file.type });
        var url = URL.createObjectURL(blob);
        var video = document.createElement("video");
        var timeupdate = function () {
          if (snapImage()) {
            video.removeEventListener("timeupdate", timeupdate);
            video.pause();
          }
        };
        video.addEventListener("loadeddata", function () {
          if (snapImage()) {
            video.removeEventListener("timeupdate", timeupdate);
          }
        });
        var snapImage = function () {
          var canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          canvas
            .getContext("2d")
            .drawImage(video, 0, 0, canvas.width, canvas.height);
          var image = canvas.toDataURL();
          var success = image.length > 100000;
          if (success) {
            var img = document.createElement("img");
            img.src = image;
            setVideoPreviewImgs([...videoPreviewImgs, image]);
            setProduct({
              ...product,
              videoPreviewImgs: [...videoPreviewImgs, image],
            });
            setInput({
              ...input,
              videoPreviewImgs: [...videoPreviewImgs, image],
            });
            URL.revokeObjectURL(url);
          }
          return success;
        };
        video.addEventListener("timeupdate", timeupdate);
        video.preload = "metadata";
        video.src = url;
        // console.log(url);
        setVidoes([...videos, url]);
        // Load video in Safari / IE11
        video.muted = true;
        video.playsInline = true;
        video.play();
      };
      fileReader.readAsArrayBuffer(file);
    }
    //   });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "writer") {
      setInput((prev) => {
        const writers = prev[name] || [];

        if (writers.includes(value)) {
          return { ...prev, [name]: writers.filter((item) => item !== value) };
        } else {
          return { ...prev, [name]: [...writers, value] };
        }
      });
    } else if (name === "title") {
      setInput((prev) => ({ ...prev, slug: generateSlugStories(value) }));
      setMeta((prev) => ({ ...prev, title: value }));
    }
    setInput((prev) => ({ ...prev, [name]: value }));
  };

  const handleToggleChange = (e) => {
    const { checked, name } = e.target;
  
    if (name === "isShorts") {
      setInput((prev) => ({ ...prev, [name]: checked }));
    }
  };
  

  const handleQuillChange = (value, name) => {
    setInput({ ...input, [name]: value });
  };

  function dataURLtoBlob(dataURL) {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  }

  function blobToFile(blob, fileName) {
    return new File([blob], fileName, { type: blob.type });
  }

  const handleSave = async () => {
    setIsBtnLoading(true);
    try {
      let payload = {
        ...input,
        content: input.content,
        isYoutube: mode == 1 ? true : false,
        contributor: contributor,
        subcategory: selectedSubCategory,
        section: selectedFlags,
        slug: `/${input?.slug}` ?? "",
        meta: { ...meta, author: input.author, keywords: selectedKeywords },
      };

      let file = {};
      if (!customImg && mode != 1) {
        const blob = dataURLtoBlob(thumbnailUrl);
        file = blobToFile(blob, "thumbnail.jpg");
      }

      const formData = new FormData();
      if (mode == 1) {
        if (customImg == true) {
          formData.append("coverImg", images[0]);
        } else {
          payload = { ...payload, coverImg: images[0] };
        }
      }
      formData.append("data", JSON.stringify(payload));
      if (thumbnailUrl && mode != 1) {
        if (!customImg) {
          formData.append("coverImg", file);
          if (videos) {
            formData.append("src", videos[0]);
          }
        } else {
          formData.append("coverImg", images[0]);
          if (videos) {
            formData.append("src", videos[0]);
          }
        }
      }

      if (getId) {
        await updateVideos(getId, formData);
      } else {
        await createVideos(formData);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsBtnLoading(false);
      navigate("/stories/video-stories");
    }
  };

  const handleMetaData = (e) => {
    const { name, value, checked } = e.target;
    if (name === "slug") {
      setInput((prev) => ({ ...prev, [name]: generateSlugStories(value) }));
    } else {
      setMeta((prev) => {
        const newMeta = { ...prev };
        if (value === undefined) {
          delete newMeta[name];
          return newMeta;
        }
        if (name === "robots") {
          newMeta[name] = checked ? "index,follow" : "noindex,nofollow";
          return newMeta;
        }
        newMeta[name] = value;
        return newMeta;
      });
    }
  };

  if (isLoading) return "Loading...";

  return (
    <>
      <div className="head-of-head writer-top">
        <div className="table-name">
          <span
            className="menu-nav"
            onClick={() => isHome ? navigate("/home") : isShort ? navigate("/stories/shorts"):navigate("/stories/video-stories")}
          >
            {isShort ? "Short Stories":"Video Stories"}
          </span>{" "}
          <MdArrowForwardIos style={{marginInline:'5px'}} className="menu-nav-arrow" />{" "}
          <span className="">
            {input?.title ? input?.title : "Untitled Story"}
          </span>{" "}
        </div>
        <div className="fixed-right">
          <button
            className="btn-primary-outline"
            onClick={() => isHome ? navigate("/home") : isShort ? navigate("/stories/shorts"):navigate("/stories/video-stories")}
          >
            Cancel
          </button>
          <button
            className="btn-primary"
            style={{ width: "80px" }}
            onClick={handleSave}
            // onClick={handleSubmit(isEdit ? handleUpdate : handleSave)}
          >
            {isBtnLoading ? <div className="loader"></div> : "Save"}
          </button>
        </div>
      </div>
      <p className="p-top-writer">
        Manage what info is shown on your video stories.
      </p>


      <div className="add-video-togglebtn">
        <div className="add-video-main-btndiv">
          <div onClick={()=>{setMode(true)}} className={mode ? "add-video-toggle-btn add-video-active":"add-video-toggle-btn curser-pointer"} style={{borderTopLeftRadius:'40px', borderBottomLeftRadius:'40px'}}>
            <span>Add from Youtube</span>
          </div>
          <div onClick={()=>{setMode(false)}} className={mode ? "add-video-toggle-btn curser-pointer":"add-video-toggle-btn add-video-active"} style={{borderTopRightRadius:'40px', borderBottomRightRadius:'40px'}}>
            <span>Add from this device</span>
          </div>
        </div>
      </div>
      <div className="aps-parent-div">
        <div className="aps-left">
          <ImageVideo
            productImages={[]}
            productVideos={input?.videoPreviewImgs || []}
            images={images}
            setImages={setImages}
            errorForms={errorForms}
            input={input}
            mode={mode}
            videos={videos}
            setVidoes={setVidoes}
            src={input.src}
            handleToggleChange={handleToggleChange}
            thumbnailUrl={thumbnailUrl}
            setThumbnailUrl={setThumbnailUrl}
            handleFileChange={handleFileChange}
            handleVideoFileChange={handleVideoFileChange}
            isShorts={isShort}
            handleInputChange={handleInputChange}
          />

          <VideoDetails
            input={input}
            name={input?.name}
            info={input?.excerpt}
            handleInputChange={handleInputChange}
            handleQuillChange={handleQuillChange}
            errorForms={errorForms}
            writer={writer}
            contributor={contributor}
            setContributor={setContributor}
          />

          <div className="aps-div">
            <div className="aps-title">Categories</div>
            <div className="aps-content-div">
              <Categories
                data={category}
                setCategory={setCategory}
                register={register}
                errors={errors}
                selectedSubCategory={selectedSubCategory}
                setSelectedSubCategory={setSelectedSubCategory}
              />
            </div>
          </div>
        </div>

        <div className="aps-right">
          {thumbnailUrl && (
            <div className="aps-div-top">
              <div className="aps-title">Thumbnail</div>
              <div className="aps-content-div">
                <img
                  src={thumbnailUrl}
                  alt="Video thumbnail"
                  className="video-thumbnail"
                />
                <div className="thumbnail-btn">
                  <div style={{ position: "relative" }}>
                    <button className="btn-primary replace-btn">Replace</button>
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={handleFilethumbnail}
                      className="file-thumbnail"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="aps-div-top">
            <SEOCategories
              handleMetaData={handleMetaData}
              meta={meta}
              input={input}
              isCanonical={isCanonical}
              setIsCanonical={setIsCanonical}
              selectedKeywords={selectedKeywords}
              setSelectedKeywords={setSelectedKeywords}
            />
          </div>
          <div className="aps-div-top">
            <div className="aps-title">Flags</div>
            <div className="aps-content-div">
              <Flags
                data={flags}
                selectedFlags={selectedFlags}
                setSelectedFlags={setSelectedFlags}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddVideo;
