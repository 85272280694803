import React, { useState } from "react";
import Autocomplete from "@mui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const StyledTextField = styled(TextField)(({ theme }) => ({
  height: "40px",
}));

export default function Tags({
  option,
  selectedOptions,
  handleOnChange,
  handleSave,
  handleInputChange
}) {
  const options = option.map((item) => {
    return { id: item._id, label: item.name };
  });

  return (
    <div>
      <Autocomplete
        multiple
        freeSolo
        disableClearable
        open={false}  // Disables dropdown
        id="tags-outlined"
        options={[]}  // Empty array to prevent showing dropdown options
        value={options.filter((option) => selectedOptions.includes(option.id))}
        getOptionLabel={(option) => option.label}
        onChange={handleOnChange}
        onInputChange={(event, newValue) => {
          // Custom logic for handling typed input
        }}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            variant="outlined"
            onChange={handleInputChange}
            onKeyDown={handleSave}  // Handle Enter press
          />
        )}
      />
    </div>
  );
}
