import React, { useState, useEffect } from "react";
import { formatDateAndTime } from "../../utils/Util";
import "../../styles/admin/home/Home.css";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import { getHomeListStories } from "../../api/Stories";
import { Const } from "../../utils/Constants";

const Home = () => {
  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document
      .getElementsByClassName("admin-right")[0]
      .addEventListener("scroll", () => {
        if (
          document.getElementsByClassName("main-div")[0] &&
          document.getElementsByClassName("admin-right")[0].scrollTop >
            document.getElementsByClassName("main-div")[0].offsetTop - 150
        ) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      });

    fetchData();
    return () => {
      // element.removeEventListener("scroll", handleLoadMore);
    };
  }, []);

  const fetchData = async () => {
    try {
      const res = await getHomeListStories();
      if (res.data.length > 0) {
        setData(res.data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const sendDataToChild = (isEdit, id) => {
    const getLeftClass = document.querySelector(".admin-left");
    const getRightClass = document.querySelector(".admin-right");
    getLeftClass.classList.add("admin-nav-disable");
    getRightClass.classList.add("admin-right-w100");
    setTimeout(() => {
      getRightClass.classList.add("admin-right-op0");
    }, 350);

    const dataToSend = { prev: "/home" };
    setTimeout(() => {
      if (isEdit) {
        navigate(`/stories/edit-stories/${id}`, { state: dataToSend });
        return;
      }
      navigate("/stories/add-stories", { state: dataToSend });
    }, 650);
  };

  return (
    <>
      <div className="top-box-home">
        <div className="left-top-box">
          <p className="top-home-p-main">Welcome to ESQUIRE Admin Dashboard</p>
          <p className="top-home-p">
            Your Central Hub for Comprehensive Management and Real-Time
            Insights, Developed by RPSG Media!
          </p>
        </div>
      </div>

      <div className="head-of-head">
        <div className="table-name" style={{ margin: "0" }}>
          <>Text Stories</>
        </div>
        <div className="fixed-right">
          <button
            className="btn-primary"
            onClick={() => sendDataToChild(false)}
          >
            <FaPlus className="plus-logo" /> Create Text Story
          </button>
        </div>
      </div>
      <div className="home-cards">
        {data.length > 0 &&
          data.map((item, i) => {
            let label = "";
            if (item.status === Const.Inactive) {
              label = "UNPUBLISHED";
            } else if (item.status === Const.Active) {
              label = "PUBLISHED";
            } else if (item.status === Const.Trash) {
              label = "TRASH";
            } else if (item.status === Const.Draft) {
              label = "DRAFT";
            } else if (item.status === Const.Scheduled) {
              label = "SCHEDULED";
            }
            return (
              <>
                <div className="home-card" key={`card-${i}`}>
                  <div className="hover-btn">
                    <button
                      className="btn-primary"
                      onClick={() => {
                        sendDataToChild(true, item._id);
                      }}
                    >
                      Edit Story
                    </button>
                    <a
                      href={
                        Const.ClientLink +
                        `${
                          item.status === 1
                            ? item.viewLink
                            : `/stories/${item.viewLink.split("/")[3]}`
                        }`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p className="card-copy">Preview</p>
                    </a>
                  </div>
                  <div className={`home-label sl-${item.status}`}>{label}</div>
                  <div className="card-img-div">
                    <img
                      src={item && item.coverImg ? item.coverImg : ""}
                      className="card-img"
                    />
                  </div>
                  <p className="card-p">
                    {item && item.title ? item.title : ""}
                  </p>
                  <div className="card-flex">
                    <p className="card-p-small">
                      {item && item.timestamp
                        ? formatDateAndTime(item.timestamp)
                        : ""}
                    </p>
                    {/* <div className="verti-line"></div> */}
                    <p className="card-p-small">|</p>
                    <p className="card-p-small">
                      {item && item.author ? item.author : ""}
                    </p>
                  </div>
                </div>
              </>
            );
          })}
      </div>

      <div className="head-of-head">
        <div className="table-name">
          <>Video Stories</>
        </div>
        <div className="fixed-right">
          <button
            className="btn-primary"
            onClick={() => {
              navigate("/stories/video-stories/add-story", {
                state: { data: { mode: true, isShort: false, isHome:true } },
              });
            }}
          >
            <FaPlus className="plus-logo" /> Create Video Story
          </button>
        </div>
      </div>
      <div className="home-cards">
        {data.length > 0 &&
          data.map((item, i) => {
            let label = "";
            if (item.status === Const.Inactive) {
              label = "UNPUBLISHED";
            } else if (item.status === Const.Active) {
              label = "PUBLISHED";
            } else if (item.status === Const.Trash) {
              label = "TRASH";
            } else if (item.status === Const.Draft) {
              label = "DRAFT";
            } else if (item.status === Const.Scheduled) {
              label = "SCHEDULED";
            }
            return (
              <>
                <div className="home-card" key={`card-${i}`}>
                  <div className="hover-btn">
                    <button
                      className="btn-primary"
                      onClick={() => {
                        navigate(`/stories/video-stories/edit-story/${item._id}`, {
                          state: { data: { mode: true, isShort: false, isHome:true } },
                        });
                      }}
                    >
                      Edit Story
                    </button>
                    <a
                      href={
                        Const.ClientLink +
                        `${
                          item.status === 1
                            ? item.viewLink
                            : `/stories/${item.viewLink.split("/")[3]}`
                        }`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p className="card-copy">Preview</p>
                    </a>
                  </div>
                  <div className={`home-label sl-${item.status}`}>{label}</div>
                  <div className="card-img-div">
                    <img
                      src={item && item.coverImg ? item.coverImg : ""}
                      className="card-img"
                    />
                  </div>
                  <p className="card-p">
                    {item && item.title ? item.title : ""}
                  </p>
                  <div className="card-flex">
                    <p className="card-p-small">
                      {item && item.timestamp
                        ? formatDateAndTime(item.timestamp)
                        : ""}
                    </p>
                    {/* <div className="verti-line"></div> */}
                    <p className="card-p-small">|</p>
                    <p className="card-p-small">
                      {item && item.author ? item.author : ""}
                    </p>
                  </div>
                </div>
              </>
            );
          })}
      </div>
      <div className="head-of-head">
        <div className="table-name">
          <>Shorts</>
        </div>
        <div className="fixed-right">
          <button
            className="btn-primary"
            onClick={() => {
              navigate("/stories/video-stories/add-story", {
                state: { data: { mode: true , isShort:true, isHome:true} },
              });
            }}
          >
            <FaPlus className="plus-logo" /> Create Shorts
          </button>
        </div>
      </div>
      <div className="home-cards">
        {data.length > 0 &&
          data.map((item, i) => {
            let label = "";
            if (item.status === Const.Inactive) {
              label = "UNPUBLISHED";
            } else if (item.status === Const.Active) {
              label = "PUBLISHED";
            } else if (item.status === Const.Trash) {
              label = "TRASH";
            } else if (item.status === Const.Draft) {
              label = "DRAFT";
            } else if (item.status === Const.Scheduled) {
              label = "SCHEDULED";
            }
            return (
              <>
                <div className="home-card-short" key={`card-${i}`}>
                  <div className="hover-btn">
                    <button
                      className="btn-primary"
                      onClick={() => {
                        navigate(`/stories/video-stories/edit-story/${item._id}`, {
                          state: { data: { mode: true, isShort: true, isHome:true } },
                        });
                      }}
                    >
                      Edit Story
                    </button>
                    <a
                      href={
                        Const.ClientLink +
                        `${
                          item.status === 1
                            ? item.viewLink
                            : `/stories/${item.viewLink.split("/")[3]}`
                        }`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p className="card-copy">Preview</p>
                    </a>
                  </div>
                  <div className={`home-label sl-${item.status}`}>{label}</div>
                  <div className="card-img-div-short">
                    <img
                      src={item && item.coverImg ? item.coverImg : ""}
                      className="card-img"
                    />
                  </div>
                  <p className="card-p">
                    {item && item.title ? item.title : ""}
                  </p>
                  <div className="card-flex">
                    <p className="card-p-small">
                      {item && item.timestamp
                        ? formatDateAndTime(item.timestamp)
                        : ""}
                    </p>
                    {/* <div className="verti-line"></div> */}
                    <p className="card-p-small">|</p>
                    <p className="card-p-small">
                      {item && item.author ? item.author : ""}
                    </p>
                  </div>
                </div>
              </>
            );
          })}
      </div>
    </>
  );
};

export default Home;
