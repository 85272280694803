export const getEmbedTypeAndUrl = (url) => {
  const youtubeRegex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

  const instagramRegex =
    /(?:https?:\/\/)?(?:www\.)?instagram\.com\/(?:p|tv|reel)\/([A-Za-z0-9_-]+)/;

  const twitterRegex =
    /(?:https?:\/\/)?(?:www\.)?(?:twitter|x)\.com\/(?:\w+)\/status\/([0-9]+)/;

  // Facebook post or video URLs
  const facebookPostOrVideoRegex =
    /(?:https?:\/\/)?(?:www\.)?facebook\.com\/(?:[^\/\n\s]+\/posts\/|(?:video\.php\?v=|watch\/))([0-9]+)/;

  if (youtubeRegex.test(url)) {
    const match = url.match(youtubeRegex);
    return {
      type: "youtube",
      embedUrl: `https://www.youtube.com/embed/${match[1]}`,
      embedFrame: `<iframe width="100%" height="auto" src="https://www.youtube.com/embed/${match[1]}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
      aspectRatio: "56.25%", // 16:9 aspect ratio
    };
  } else if (instagramRegex.test(url)) {
    const match = url.match(instagramRegex);
    return {
      type: "instagram",
      embedUrl: `https://www.instagram.com/p/${match[1]}/embed`,
      embedFrame: `<iframe width="100%" height="auto" src="https://www.instagram.com/p/${match[1]}/embed" frameborder="0" scrolling="no" allowtransparency="true"></iframe>`,
      aspectRatio: "177.78%", // 9:16 aspect ratio
    };

    // Handling Twitter/X embeds
  } else if (twitterRegex.test(url)) {
    const match = url.match(twitterRegex);
    return {
      type: "twitter",
      embedUrl: `https://twitframe.com/show?url=${url}`,
      embedFrame: `<iframe width="100%" height="auto" src="https://twitframe.com/show?url=${url}" frameborder="0" allowtransparency="true"></iframe>`,
      aspectRatio: "100%", // 16:9 aspect ratio
    };

    // Handling Facebook post or video embeds
  } else if (facebookPostOrVideoRegex.test(url)) {
    const match = url.match(facebookPostOrVideoRegex);
    return {
      type: "facebook",
      embedUrl: `https://www.facebook.com/plugins/post.php?href=${url}`,
      embedFrame: `<iframe width="100%" height="auto" src="https://www.facebook.com/plugins/post.php?href=${url}" frameborder="0" allow="encrypted-media" allowfullscreen></iframe>`,
      aspectRatio: "177.78%", // Assuming 16:9 for videos
    };
  }

  return { type: null, embedUrl: null, embedFrame: null, aspectRatio: null };
};
