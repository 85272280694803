import React, { useEffect } from "react";
import { IoAdd } from "react-icons/io5";
import { PiImagesThin, PiVideoCameraThin } from "react-icons/pi";
import Video from "./video";
import VideoDrop from "../../common/VideoDrop"
import ShortVideoDrop from "../../common/ShortVideoDrop"
import ToggleShorts from "../../../components/stories/addVideoStory/toggleShorts";

const ImagesVideos = ({
  productImages,
  productVideos,
  images,
  videos,
  src,
  handleFileChange,
  handleVideoFileChange,
  handleInputChange,
  errorForms,
  isShorts,
  input,
  mode,
  setImages,
  setVidoes,
  thumbnailUrl,
  setThumbnailUrl,
  handleToggleChange
}) => {

  console.log(isShorts,"short")
  // Function to extract YouTube video ID from the URL
  const getYouTubeEmbedUrl = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11
      ? `https://www.youtube.com/embed/${match[2]}`
      : null;
  };

  const extractYouTubeId = (url) => {
    const regex = /\/embed\/([^/?]+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  const videoEmbedUrl = getYouTubeEmbedUrl(input?.src ?? "");
  let videoId="";
  if(videoEmbedUrl){
    videoId = extractYouTubeId(videoEmbedUrl);
  }
  const getYouTubeThumbnailUrl = (videoId) => {
    return `https://img.youtube.com/vi/${videoId}/0.jpg`;
  };

  const fun = ()=>{
  if (videoId) {
    const thumbnailUrl = getYouTubeThumbnailUrl(videoId);
    setImages([thumbnailUrl]);
    setThumbnailUrl([thumbnailUrl])
  }
  }
  useEffect(() => {
    fun();
  }, [input?.src]);

  return (
    <div className="aps-div-top">
      <div className="aps-title flex-spacebtn">
        <p>Add Video</p>
        {/* <ToggleShorts status={isShorts} handleInputChange={handleToggleChange} /> */}
      </div>
      {mode ? (
        <div className="aps-content" style={{ display: "block" }}>
          <div className="aps-inp-div1">
            <label className="aps-label">Video Youtube Link*</label>
            {errorForms.src && (
              <label className="aps-label error_label">(Required)</label>
            )}
            <input
              placeholder="Enter YouTube Link"
              id="#aps1"
              value={input?.src ?? ""}
              className={errorForms.src ? "error-field" : ""}
              name="src"
              onChange={handleInputChange}
            />
          </div>
          {videoEmbedUrl && (
            <div className="video-embed" style={{ marginTop: "20px" }}>
              <iframe
                width="100%"
                height="315"
                src={videoEmbedUrl}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YouTube Video"
              ></iframe>
            </div>
          )}
        </div>
      ) : (
        <div className={isShorts ? "aps-content-short":"aps-content"}>
          {isShorts ? 
          <ShortVideoDrop selectedFiles={videos} src={src} setSelectedFiles={setVidoes} setThumbnailUrl={setThumbnailUrl} thumbnailUrl={thumbnailUrl}/>
          :
          <VideoDrop selectedFiles={videos} src={src} setSelectedFiles={setVidoes} setThumbnailUrl={setThumbnailUrl} thumbnailUrl={thumbnailUrl}/>
          }
        </div>
      )}
    </div>
  );
};

export default ImagesVideos;
