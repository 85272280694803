import React from "react";
import { Routes, Route } from "react-router-dom";
import AdminProtectedRoute from "../controllers/AdminProtectedRoute";
import { Toaster } from "react-hot-toast";
import Admin from "../components/layout/Admin";
import ConstructPage from "../components/common/Construct";

import HomeRoute from "./HomeRoute";
import AnalyticsRoute from "./AnalyticsRoute";
import CategoryRoute from "./CategoryRoute";
import FlagRoute from "./FlagRoute";
import CustomerRoute from "./CustomerRoute";
import CommentRoute from "./CommentRoute";
import TagRoute from "./TagRoute";
import WriterRoute from "./WriterRoute";
import MonetizationRoute from "./MonetizationRoute";
import InboxRoute from "./InboxRoute";
import SettingsRoute from "./SettingsRoute";
import MediaLib from "../pages/media-library/MediaLibrary";
import MarketingRoute from "./MarketingRoute";
import StoriesRoute from "./StoriesRoute";

const AdminRoute = () => {
  return (
    <AdminProtectedRoute>
      <Admin>
        <Toaster />
        <Routes>
          <Route path="/home/*" element={<HomeRoute />} />
          <Route path="/analytics/*" element={<AnalyticsRoute />} />
          <Route path="/authors/*" element={<WriterRoute />} />
          <Route path="/categories/*" element={<CategoryRoute />} />
          <Route path="/flag/*" element={<FlagRoute />} />
          {/* <Route path="/subcategories/*" element={<SubCategoryRoute />} /> */}
          <Route path="/forms-and-submissions/*" element={<CustomerRoute />} />
          <Route path="/inbox/*" element={<InboxRoute />} />
          <Route path="/settings/*" element={<SettingsRoute />} />
          <Route path="/marketing/*" element={<MarketingRoute />} />
          <Route path="/media-library" element={<MediaLib />} />
          <Route path="/stories/*" element={<StoriesRoute />} />
          <Route path="/comments/*" element={<CommentRoute />} />
          <Route path="/tags/*" element={<TagRoute />} />
          <Route path="/monetization/*" element={<MonetizationRoute />} />
          <Route path="*" element={<ConstructPage />} />
        </Routes>
      </Admin>
    </AdminProtectedRoute>
  );
};

export default AdminRoute;
