import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FiChevronDown } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import ListItemIcon from "@mui/material/ListItemIcon";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { CiExport } from "react-icons/ci";
import { CiImport } from "react-icons/ci";
import { BsPeople } from "react-icons/bs";

export default function AccountMenu() {
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const navigate = useNavigate();
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip>
          <div className="exportbtn" onClick={handleClick} aria-controls={open ? "account-menu" : undefined}
            aria-expanded={open ? "true" : undefined}>
            <CiExport/>
          </div>
        </Tooltip>
      </Box>
      <Menu
        
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            mt: 0.5,
            border: "1px solid rgba(0,0,0,0.2)",
            width: "300px",
            "& .MuiAvatar-root": {
              width: 40,
              border: "1px solid rgba(0,0,0,0.2)",
              height: 40,
              ml: -0.5,
              mr: 1,
              bgcolor:'#f2f9ff'
            },
            "&::hover":{
                bgcolor:'#f2f9ff'
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              border: "1px solid rgba(0,0,0,0.2)",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: -555,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        
        <MenuItem >
          <ListItemIcon>
          <CiExport style={{ fontSize: "18px", color:'black' }} />
          </ListItemIcon>
          <div>
          <p>Export</p>
          <p className="exportbtn-subhead">Export your physical products to a CSV file.</p>
          </div>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <CiImport style={{ fontSize: "18px", color:'black' }} />
          </ListItemIcon>
          <div>
          <p>Import</p>
          <p className="exportbtn-subhead">Import Multiple Product in your store</p>
          </div>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
