import React, { useState, useCallback } from "react";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { GoLink } from "react-icons/go";
import { BsQuote } from "react-icons/bs";
import { IoCodeSlashOutline, IoCloseOutline } from "react-icons/io5";
import { LiaAngleDownSolid } from "react-icons/lia";
import {
  AiOutlineBold,
  AiOutlineUnderline,
  AiOutlineOrderedList,
  AiOutlineUnorderedList,
  AiOutlineItalic,
  AiOutlineMenuFold,
  AiOutlineMenuUnfold,
} from "react-icons/ai";
import {
  TfiAlignCenter,
  TfiAlignJustify,
  TfiAlignLeft,
  TfiAlignRight,
} from "react-icons/tfi";
import { CiLineHeight } from "react-icons/ci";
import TextColor from "../../assets/svg/TextColor";
import TextHighlight from "../../assets/svg/TextHighlight";
import ColorPicker from "./ColorPicker";
import HighlightColorPicker from "./HighlightColorPicker";
import { useEditorState } from "@tiptap/react";
import LinkEditor from "../../helpers/extension/Link/components/LinkEditor";

const CustomToolbar = ({ editor, showLink, setShowLink, handleLinkMenu }) => {
  const [alignment, setAlignment] = useState("left");
  const [lineHeight, setLineHeight] = useState("1.5");
  const [textColorSelected, setTextColorSelected] = useState("#000");
  const [textHighlightColorSelected, setTextHighlightColorSelected] =
    useState("#fff");

  const [openTextColor, setOpenTextColor] = useState(null);
  const [openTextHighlight, setOpenTextHighlight] = useState(null);
  const [heading, setHeading] = useState(0);
  const [fontSize, setFontSize] = useState("16px");
  const open = Boolean(showLink);
  const linkMenuId = open ? "simple-popover" : undefined;

  const handleOpenTextColor = useCallback((event) => {
    setOpenTextColor(event.currentTarget);
  }, []);

  const handleOpenTextHighlight = useCallback((event) => {
    setOpenTextHighlight(event.currentTarget);
  }, []);

  const handleAlignmentChange = useCallback(
    (event) => {
      const value = event.target.value;
      if (value) {
        editor.chain().focus().setTextAlign(value).run();
        setAlignment(value);
      }
    },
    [editor]
  );

  const handleLineHeightChange = useCallback(
    (event) => {
      const value = event.target.value;
      if (value) {
        editor.chain().focus().setLineHeight(value).run();
        setLineHeight(value);
      }
    },
    [editor]
  );

  const handleHeading = useCallback(
    (event) => {
      const value = parseInt(event.target.value);

      let fontSize = "16px";

      switch (value) {
        case 2:
          fontSize = "48px";
          break;
        case 3:
          fontSize = "36px";
          break;
        case 4:
          fontSize = "30px";
          break;
        case 5:
          fontSize = "24px";
          break;
        case 6:
          fontSize = "18px";
          break;
        default:
          fontSize = "16px";
      }
      setFontSize(fontSize);
      if (value !== 0) {
        editor
          .chain()
          .focus()
          .toggleHeading({ level: value })
          .setFontSize(fontSize)
          .run();
      } else {
        editor.chain().focus().setParagraph().setFontSize(fontSize).run();
      }
      setHeading(value.toString());
    },
    [editor]
  );

  const handleFontSize = useCallback(
    (event) => {
      const value = event.target.value;
      if (value) {
        editor.chain().focus().setFontSize(value).run();
        setFontSize(value);
      }
    },
    [editor]
  );

  const { link, target, rel } = useEditorState({
    editor,
    selector: (ctx) => {
      const attrs = ctx.editor.getAttributes("link");
      return { link: attrs.href, target: attrs.target, rel: attrs.rel };
    },
  });

  if (!editor) {
    return null;
  }

  const relArray = rel ? rel.split(", ") : [];
  const initialNoReferrer = relArray.includes("noreferrer");
  const initialNoFollow = relArray.includes("nofollow");
  const initialSponsored = relArray.includes("sponsored");
  return (
    <>
      <div className="er-wrap-items">
        <button className="er-wrap-icon">
          <Select
            labelId="line-height-select-label"
            id="heading"
            value={heading}
            onChange={handleHeading}
            sx={{
              padding: "0px",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiSelect-icon": {
                display: "flex",
                alignItems: "center",
                width: "12px",
                height: "12px",
                right: "0px",
              },
              "& .MuiSelect-select": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0px",
                height: "unset",
                paddingRight: "15px!important",
                minHeight: "unset",
              },
            }}
            IconComponent={LiaAngleDownSolid}
            displayEmpty
          >
            <MenuItem value={2}>Heading 2</MenuItem>
            <MenuItem value={3}>Heading 3</MenuItem>
            <MenuItem value={4}>Heading 4</MenuItem>
            <MenuItem value={5}>Heading 5</MenuItem>
            <MenuItem value={6}>Heading 6</MenuItem>
            <MenuItem value={0}>Paragraph</MenuItem>
          </Select>
        </button>
      </div>
      <span className="er-divider"></span>
      <div className="er-wrap-items">
        <button className="er-wrap-icon">
          <Select
            labelId="font-size"
            id="font-size"
            value={fontSize}
            onChange={handleFontSize}
            sx={{
              padding: "0px",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiSelect-icon": {
                display: "flex",
                alignItems: "center",
                width: "12px",
                height: "12px",
                right: "0px",
              },
              "& .MuiSelect-select": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0px",
                height: "unset",
                paddingRight: "15px!important",
                minHeight: "unset",
              },
            }}
            IconComponent={LiaAngleDownSolid}
            displayEmpty
          >
            <MenuItem value="6px">6</MenuItem>
            <MenuItem value="8px">8</MenuItem>
            <MenuItem value="10px">10</MenuItem>
            <MenuItem value="12px">12</MenuItem>
            <MenuItem value="14px">14</MenuItem>
            <MenuItem value="16px">16</MenuItem>
            <MenuItem value="18px">18</MenuItem>
            <MenuItem value="20px">20</MenuItem>
            <MenuItem value="24px">24</MenuItem>
            <MenuItem value="30px">30</MenuItem>
            <MenuItem value="36px">36</MenuItem>
            <MenuItem value="40px">40</MenuItem>
            <MenuItem value="48px">48</MenuItem>
            <MenuItem value="64px">64</MenuItem>
            <MenuItem value="72px">72</MenuItem>
          </Select>
        </button>
      </div>
      <span className="er-divider"></span>
      <div className="er-wrap-items toolbar">
        <button
          className={`er-wrap-icon ${editor.isActive("bold") ? "active" : ""}`}
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
        >
          <AiOutlineBold />
        </button>
        <button
          className={`er-wrap-icon ${
            editor.isActive("italic") ? "active" : ""
          }`}
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
        >
          <AiOutlineItalic />
        </button>
        <button
          className={`er-wrap-icon ${
            editor.isActive("underline") ? "active" : ""
          }`}
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          disabled={!editor.can().chain().focus().toggleUnderline().run()}
        >
          <AiOutlineUnderline />
        </button>
        <button
          className={`er-wrap-icon ${
            editor.isActive("textStyle", { color: textColorSelected })
              ? "active"
              : ""
          }`}
          onClick={handleOpenTextColor}
        >
          <TextColor color={textColorSelected} />
        </button>
        <ColorPicker
          editor={editor}
          open={Boolean(openTextColor)}
          type="foreColor"
          setClose={() => setOpenTextColor(null)}
          selected={textColorSelected}
          setSelected={setTextColorSelected}
        />
        <button className="er-wrap-icon" onClick={handleOpenTextHighlight}>
          <TextHighlight color={textHighlightColorSelected} />
        </button>
        <HighlightColorPicker
          editor={editor}
          open={Boolean(openTextHighlight)}
          type="hiliteColor"
          setClose={() => setOpenTextHighlight(null)}
          selected={textHighlightColorSelected}
          setSelected={setTextHighlightColorSelected}
        />
      </div>
      <span className="er-divider"></span>
      <div className="er-wrap-items">
        <button
          className={`er-wrap-icon ${
            editor.isActive("blockquote") ? "active" : ""
          }`}
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
        >
          <BsQuote />
        </button>
        <button
          className={`er-wrap-icon ${
            editor.isActive("codeBlock") ? "active" : ""
          }`}
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        >
          <IoCodeSlashOutline />
        </button>
      </div>
      <span className="er-divider"></span>
      <div className="er-wrap-items">
        <button
          className={`er-wrap-icon ${
            editor.isActive("orderedList") ? "active" : ""
          }`}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
        >
          <AiOutlineOrderedList />
        </button>
        <button
          className={`er-wrap-icon ${
            editor.isActive("bulletList") ? "active" : ""
          }`}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
        >
          <AiOutlineUnorderedList />
        </button>
      </div>
      <span className="er-divider"></span>
      <div className="er-wrap-items">
        <button className="er-wrap-icon">
          <Select
            labelId="alignment-select-label"
            id="alignment-select"
            value={alignment}
            onChange={handleAlignmentChange}
            sx={{
              padding: "0px",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiSelect-icon": {
                display: "flex",
                alignItems: "center",
                width: "12px",
                height: "12px",
                right: "0px",
              },
              "& .MuiSelect-select": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0px",
                height: "unset",
                paddingRight: "15px!important",
                minHeight: "unset",
              },
            }}
            IconComponent={LiaAngleDownSolid}
          >
            <MenuItem value="left">
              <TfiAlignLeft />
            </MenuItem>
            <MenuItem value="center">
              <TfiAlignCenter />
            </MenuItem>
            <MenuItem value="right">
              <TfiAlignRight />
            </MenuItem>
            <MenuItem value="justify">
              <TfiAlignJustify />
            </MenuItem>
          </Select>
        </button>
      </div>
      <span className="er-divider"></span>
      <div className="er-wrap-items">
        <button className="er-wrap-icon">
          <Select
            labelId="line-height-select-label"
            id="line-height-select"
            value={lineHeight}
            onChange={handleLineHeightChange}
            sx={{
              padding: "0px",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiSelect-icon": {
                display: "flex",
                alignItems: "center",
                width: "12px",
                height: "12px",
                right: "0px",
              },
              "& .MuiSelect-select": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0px",
                height: "unset",
                paddingRight: "15px!important",
                minHeight: "unset",
              },
            }}
            IconComponent={LiaAngleDownSolid}
            displayEmpty
            renderValue={(selected) => (selected ? <CiLineHeight /> : selected)}
          >
            <MenuItem value="1rem">1</MenuItem>
            <MenuItem value="1.5rem">1.5</MenuItem>
            <MenuItem value="2rem">2</MenuItem>
            <MenuItem value="2.5rem">2.5</MenuItem>
            <MenuItem value="3rem">3</MenuItem>
            <MenuItem>Spacing options</MenuItem>
          </Select>
        </button>
      </div>
      <span className="er-divider"></span>
      <div className="er-wrap-items">
        <button
          className="er-wrap-icon"
          onClick={() => editor.chain().focus().outdent().run()}
        >
          <AiOutlineMenuFold />
        </button>
        <button
          className="er-wrap-icon"
          onClick={() => editor.chain().focus().indent().run()}
        >
          <AiOutlineMenuUnfold />
        </button>
      </div>
      <span className="er-divider"></span>
      <div className="er-wrap-items">
        <button
          aria-describedby={linkMenuId}
          className="er-wrap-icon"
          onClick={handleLinkMenu}
        >
          <GoLink />
        </button>
        <LinkEditor
          initialUrl={link ? link : ""}
          initialOpen={target === "_blank" ? true : false}
          initialNoReferrer={initialNoReferrer}
          initialNoFollow={initialNoFollow}
          initialSponsored={initialSponsored}
          editor={editor}
          linkMenuId={linkMenuId}
          open={open}
          showLink={showLink}
          setShowLink={setShowLink}
        />
      </div>
    </>
  );
};

export default CustomToolbar;
