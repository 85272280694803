import React, { useState } from "react";
import { IoIosSearch } from "react-icons/io";
import { FaArrowUpLong } from "react-icons/fa6";
import { FaArrowDownLong } from "react-icons/fa6";
import Google from "../../assets/svg/google.svg"
import {
  FiEye,
  FiEdit,
  FiTrash2,
  FiPlus,
  FiDownload,
  FiX,
  FiChevronDown,
  FiFilter,
} from "react-icons/fi";
import { FaSort } from "react-icons/fa";
import style from "../../styles/common/OrganicSearchTable.module.css";
import {
  Accordion,
  AccordionActions,
  AccordionSummary,
  AccordionDetails,
  Box,
  Drawer,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Slider,
} from "@mui/material";
import Popup from "../admin/products/Popup";
import { useNavigate } from "react-router-dom";
import FieldSearchText from "../fields/FieldSearchText";

const Table = ({ columns, rows, editItem, setdeleteProduct }) => {
  const navigate = useNavigate();
  const [openModel, setOpenModel] = useState(false);
  const [open, setOpen] = useState({ right: false });
  const [statusValue, setStatusValue] = useState(0);
  const [value, setValue] = useState([20, 37]);

  const toggleDrawer = (anchor, newOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen({ ...open, [anchor]: newOpen });
  };

  const handleSelectChange = ({ target: { name, value } }) => {
    setStatusValue(value);
  };

  const valuetext = (value) => {
    return `₹ ${value}`;
  };
  const handlePopupModel = () => {
    setOpenModel(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const status = [
    { id: 0, name: "Inactive" },
    { id: 1, name: "Active" },
  ];
  return (
    <>
      <div
        className={style.tableCard}
        style={{ border: "none", borderRadius: "0" }}
      >
        <div className={style.tableFilterSec}>
          <div className={style.leftSec}>
            <div className="mo-search-filter mo-search-active">
              <p>Top search queries</p>
            </div>
            <div className="mo-search-filter">
              <p>Search queries by growth</p>
            </div>
            <div className="mo-search-filter">
              <p>Top pages in search results</p>
            </div>
          </div>
          {/* <div className={style.rightSec}>
            <img src={Google}></img>
          </div> */}
        </div>
        <table className={style.table}>
          <thead>
            <tr>
              
              {columns?.map((items) => {
                return (
                  <>
                    <th className="searchOrganictd">
                      <span role="button">
                        {items} <FaSort className={style.sortIcon} />
                      </span>
                    </th>
                  </>
                );
              })}
              {/* <th>Action</th> */}
            </tr>
          </thead>
          <tbody>
            {rows &&
              rows.map((items, i) => {
                const collections = items?.collections?.join(", ");
                return (
                  <tr>
                    

                    <td className="searchOrganictd">
                      <label className={style.tableTitle}>{items.query}</label>
                    </td>

                    <td className="searchOrganictd">
                      <div>
                        {items && items.impressions}
                        {items.impressionsstatus ? <div className="platform-arrow-rise"> {items.impressions && <FaArrowUpLong/>} {items.impressionschange}</div>:<div className="platform-arrow-down"> {items.impressions && <FaArrowDownLong/>} {items.impressionschange}</div>}
                      </div>
                    </td>

                    <td className="searchOrganictd">
                      <div>
                        {items && items.clicks}
                        {items.clicksstatus? <div className="platform-arrow-rise"> {items.clickschange && <FaArrowUpLong/>} {items.clickschange}</div>:<div className="platform-arrow-down"> {items.clickschange && <FaArrowDownLong/>} {items.clickschange}</div>}
                      </div>
                    </td>
                    <td className="searchOrganictd">
                      <div>
                        {items && items.ctr}
                        {items.ctrstatus? <div className="platform-arrow-rise"> {items.ctrchange && <FaArrowUpLong/>} {items.ctrchange}</div>:<div className="platform-arrow-down"> {items.ctrchange && <FaArrowUpLong/>} {items.ctrchange}</div>}
                      </div>
                    </td>

                    <td className="searchOrganictd">
                      <div>
                        {items && items.average}
                        {items.averagestatus? <div className="platform-arrow-rise"> {items.averagechange && <FaArrowUpLong/>} {items.averagechange}</div>:<div className="platform-arrow-down"> {items.averagechange && <FaArrowUpLong/>} {items.averagechange}</div>}
                      </div>
                    </td>

                    {/* <td className="searchOrganictd">
                      <div className={style.actions}>
                        <button className={style.items}>
                          <FiEye />
                        </button>
                        <button
                          className={style.items}
                          onClick={() => editItem(items._id)}
                        >
                          <FiEdit />
                        </button>
                        <button
                          className={style.items}
                          onClick={() => setd className="searchOrganictd"eleteProduct(items)}
                        >
                          <FiTrash2 />
                        </button>
                      </div>
                    </td className="searchOrganictd"> */}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Table;
