import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';

export default function DateTimePickerComponent({ schedule, setSchedule }) {
  const handleDateChange = (newDate) => {
    setSchedule((prevState) => ({
      ...prevState,
      date: newDate,
      // Reset time if date is changed
      time: newDate.isSame(dayjs(), 'day') ? prevState.time : null,
    }));
  };

  const handleTimeChange = (newTime) => {
    setSchedule((prevState) => ({
      ...prevState,
      time: newTime,
    }));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box display="flex" alignItems="center">
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          orientation="landscape"
          value={schedule.date}
          onChange={handleDateChange}
          renderInput={(params) => <TextField {...params} />}
          minDate={dayjs()} // Restricting past dates
        />
        <TimePicker
          value={schedule.time}
          onChange={handleTimeChange}
          renderInput={(params) => <TextField {...params} />}
          minTime={schedule.date?.isSame(dayjs(), 'day') ? dayjs() : null} // Restricting past times
        />
      </Box>
    </LocalizationProvider>
  );
}
