import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import ReactApexChart from 'react-apexcharts';

// Assuming generateData is defined elsewhere
const generateData = (count, yrange) => {
  let series = [];
  for (let i = 0; i < count; i++) {
    let x = (i + 1).toString();
    let y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
    series.push({ x, y });
  }
  return series;
};

const ApexChart = () => {
  const [series] = useState([
    {
      name: '12 AM',
      data: generateData(7, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: '2 AM',
      data: generateData(7, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: '4 AM',
      data: generateData(7, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: '6 AM',
      data: generateData(7, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: '8 AM',
      data: generateData(7, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: '10 AM',
      data: generateData(7, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: '12 PM',
      data: generateData(7, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: '2 PM',
      data: generateData(7, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: '4 PM',
      data: generateData(7, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: '6 PM',
      data: generateData(7, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: '8 PM',
      data: generateData(7, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: '10 PM',
      data: generateData(7, {
        min: 0,
        max: 90,
      }),
    },
  ]);

  const [options] = useState({
    chart: {
      height: 350,
      type: 'heatmap',
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#008FFB'],
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={options} series={series} type="heatmap" height={350} />
      </div>
    </div>
  );
};

export default ApexChart;
