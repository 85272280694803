import React, { useMemo, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import "../../../styles/categories/modal.css";
import { RiFindReplaceLine } from "react-icons/ri";
import { GoPlus } from "react-icons/go";
import { useDropzone } from "react-dropzone";
import { MdDeleteOutline } from "react-icons/md";
import { insertMenuSave, insertSubMenuSave } from "../../../api/admin/Menu";
import { Tooltip } from "react-tooltip";

const Modal = ({ selectedFiles, setSelectedFiles }) => {
  const onDrop = (acceptedFiles) => {
    setSelectedFiles(acceptedFiles);
  };

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    isDragActive,
    open: openDropzone,
  } = useDropzone({
    accept: { "image/*": [] },
    maxFiles: 1,
    onDrop,
  });

  const replaceFile = () => {
    setSelectedFiles([]);
    openDropzone();
  };

  const removeFile = () => {
    setSelectedFiles([]);
  };

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    height: "180px",
    borderWidth: 2,
    borderRadius: 5,
    borderColor: "#3b82f6",
    backgroundColor: "#f4f7ff",
    color: "#bdbdbd",
    outline: "none",
    cursor: "pointer",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: "fc6e00",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <>
      {selectedFiles && selectedFiles.length > 0 ? (
        <div className="cat-img-file">
          <div className="cat-file-abs">
            <div className="cat-file-inps">
              <Tooltip id="toggle-tooltip" style={{ maxWidth: "300px" }} />
              <div
                className="cat-file-inp"
                onClick={replaceFile}
                data-tooltip-id="toggle-tooltip"
                data-tooltip-content="Replace"
              >
                <RiFindReplaceLine />
              </div>
              <div
                className="cat-file-inp"
                onClick={removeFile}
                data-tooltip-id="toggle-tooltip"
                data-tooltip-content="Delete"
              >
                <MdDeleteOutline />
              </div>
            </div>
          </div>
          <img
            src={typeof selectedFiles === "string" ? selectedFiles : URL.createObjectURL(selectedFiles[0])}
            alt="Selected file"
          />
        </div>
      ) : (
        <div {...getRootProps({ style })}>
          <div className="feature-image-inner">
            <input {...getInputProps()} />
            {isDragActive ? <p>Drop the files here ...</p> : <GoPlus className="feature-logo" />}
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
