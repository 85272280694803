import React, { useState } from "react";
import toast from 'react-hot-toast'

function Settings() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [errors, setErrors] = useState({ old: "", new: "", confirm: "" })

  let regex = () => {
    let valid = newPassword.includes("!") || newPassword.includes("(") || newPassword.includes("?") || newPassword.includes("=") || newPassword.includes(".") || newPassword.includes("*") || newPassword.includes("[") || newPassword.includes(" ") || newPassword.includes(`"`) || newPassword.includes("]") || newPassword.includes(")") || newPassword.includes("$") || newPassword.includes("@") || newPassword.includes("&")
    return valid;
  }
  let validate = () => {
    let formErrors = {};
    if (!oldPassword) {
      formErrors.old = "This field is required"
    }
    if (!newPassword) {
      formErrors.new = "This field is required"
    }
    else if (newPassword.length < 8) {
      formErrors.new = "Password must be at least 8 characters"
    }
    else if (newPassword.search[/a-z/i] < 1) {
      formErrors.new = "Password must contain a lower case letter";
    }
    else if (newPassword.search[/A-Z/i] < 1) {
      formErrors.new = "Password must contain an uppser case letter";
    }
    else if (newPassword.search[/0-9/] < 1) {
      formErrors.new = "Password must contain a number";
    }
    else if (!regex()) {
      formErrors.new = "Password must contain a special character";
    }
    if (!confirmNewPassword) {
      formErrors.confirm = "This field is required"
    }
    else if (newPassword != confirmNewPassword) {
      formErrors.confirm = "Confirm New Password should be same as your new password"
    }
    return formErrors;
  }
  let change = async () => {
    let formErrors = validate();
    if (Object.keys(formErrors).length == 0) {

      setErrors({ token: "", old: "", new: "", confirm: "" })
      let res = await fetch(`/api/changePassword`, {
        method: 'PATCH',
        body: JSON.stringify({ id:"", oldPassword, newPassword, role:"admin" }),
        headers: {
          'Content-type': 'application/json',
        },
      })
      let data = await res.json();
      console.log(data,"success data");
      let { error } = data;
      if (error) {
        toast.error(error || "some error occured",)
        return;
      }
      toast.success("Password changed successfully");
      setOldPassword("")
      setNewPassword("")
      setConfirmNewPassword("")

    }
    else {
      setErrors(formErrors)
    }
  }

  return (
    <div>
      <div className="head-of-head writer-top">
        <div className="table-name">Settings</div>
      </div>
      <p className="p-top-writer">
      View and update your account details, profile and more.
      </p>

      <div className="settings-main">
        <div className="sm-top">
          <h2>Change password</h2>
        </div>
        <div className="sm-body">
          <div className="sm-body-left">
            <div className="sm-form-group">
              <label>Old Password</label>
              <input placeholder="Enter Old Password" name="oldPassword" onChange={(e) => { setOldPassword(e.target.value) }} value={oldPassword} />
              {errors.old && <div className="error">{errors.old}</div>}

            </div>
            <div className="sm-form-group">
              <label>New Password</label>
              <input placeholder="Enter New Password" name="newPassword" onChange={(e) => { setNewPassword(e.target.value) }} value={newPassword} />
              {errors.new && <div className="error">{errors.new}</div>}
            </div>
            <div className="sm-form-group">
              <label>Confirm Password</label>
              <input placeholder="Confirm New Password" name="confirmNewPassword" onChange={(e) => { setConfirmNewPassword(e.target.value) }} value={confirmNewPassword} />
              {errors.confirm && <div className="error">{errors.confirm}</div>}

            </div>
          </div>
          <div className="sm-body-right">
            <h2>New password must contain:</h2>
            <p>- At least 8 characters</p>
            <p>- At least 1 lower letter (a-z)</p>
            <p>- At least 1 uppercase letter (A-Z)</p>
            <p>- At least 1 number (0-9)</p>
            <p>- At least 1 special characters</p>
          </div>
        </div>
        <div className="password-btn">
          <button className="btn-primary" >Change</button>
          <button className="btn-primary-outline" onClick={() => { setOldPassword(""); setNewPassword(""); setConfirmNewPassword(""); }}>Cancel</button>
        </div>
      </div>
    </div>
  );
}

export default Settings;
