import React, { useEffect, useState, useRef, useCallback } from "react";
import { IoIosSearch } from "react-icons/io";
import { FiEdit, FiTrash2, FiDownload } from "react-icons/fi";
import { MdContentCopy } from "react-icons/md";
import { FaSort } from "react-icons/fa";
import style from "../../styles/common/TableAuthors.module.css";
import { CiExport } from "react-icons/ci";
import { BiExport } from "react-icons/bi";
import { Const } from "../../utils/Constants";
import { useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";

const Table = ({
  count,
  columns,
  rows,
  filter,
  selection,
  rowLoading,
  isBtnLoading,
  handleDuplicate,
  handleExport,
  handleSelection,
  handleSearch,
  handleFilter,
  setIsDelete,
  handleEdit,
  setDeleteWriter,
  setOffset,
  offset,
  setRowLoading
}) => {
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [isEnd, setIsEnd] = useState(false);

  const handleScroll = useCallback(
    debounce(() => {
      const table = tableRef.current;
      if (!table || rowLoading) return;
      const { scrollTop, scrollHeight, clientHeight } = table;
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        // console.log(rows.length,count, offset,"count")
        if (rows.length >= count) {
          // console.log("STOP")
          setIsEnd(true);
          return;
        }

        setRowLoading(true);
        setOffset((prevOffset) => prevOffset + Const.Limit);
      }
    }, 200),
    [rows, count, rowLoading, setOffset, setRowLoading, setIsEnd]
  );

  useEffect(() => {
    const table = tableRef.current;
    if (table) {
      table.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (table) {
        table.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  return (
    <>
      <div className={style.tableCard}>
        <div className={style.tableFilterSec}>
          {selection.select_bulk || selection.selected.length > 0 ? (
            <>
              <div className={style.bulkSec}>
                <span className={style.bulkcount}>
                  {`${
                    selection.select_bulk
                      ? count - selection.selected.length
                      : selection.selected.length
                  } of ${count} selected`}
                </span>
                <div className={style.verticalDivider}></div>
                <div className={style.hidBtnGroup}>
                  <button
                    className="btn-primary-outline-table"
                    disabled={isBtnLoading.duplicate}
                    onClick={handleExport}
                  >
                    {isBtnLoading.export ? (
                      <div className="loader"></div>
                    ) : (
                      <>
                       <BiExport /> Export
                      </>
                    )}
                  </button>
                  <button
                    className="btn-primary-outline-table"
                    onClick={handleDuplicate}
                    disabled={isBtnLoading.duplicate}
                  >
                    {isBtnLoading.duplicate ? (
                      <div className="loader"></div>
                    ) : (
                      <>
                        <MdContentCopy /> Duplicate
                      </>
                    )}
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={style.leftSec}>
                <div className={style.searchInv}>
                  <IoIosSearch />
                  <input
                    placeholder="Search"
                    className={style.inputInv}
                    name="search"
                    value={filter?.search ?? ""}
                    onChange={handleSearch}
                  />
                </div>
              </div>
              <div className={style.rightSec}></div>
            </>
          )}
        </div>
        <div
          ref={tableRef} // Attach ref to track the table's scroll position
          style={{ height: "100%", overflowY: "auto" }}
        >
        <table className={`${style.table} ${style.customers}`}>
          <thead>
            <tr>
              <th className={style.checktr}>
                <input
                  type="checkbox"
                  className={style.formCheckbox}
                  id="selection"
                  checked={selection.select_bulk}
                  onChange={handleSelection}
                />
              </th>
              {columns?.map((items) => {
                return (
                  <>
                    <th>
                      <span role="button">
                        {items} <FaSort className={style.sortIcon} />
                      </span>
                    </th>
                  </>
                );
              })}
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="table-of">
            {rows && rows.length > 0 ? (
              <>
                {rows.map((items, i) => {
                  return (
                    <tr>
                      <td className={style.checktr}>
                        <input
                          type="checkbox"
                          className={style.formCheckbox}
                          id={items._id}
                          checked={
                            selection.select_bulk || selection.select_bulk
                              ? !selection.selected.includes(items._id)
                                ? true
                                : false
                              : selection.selected.includes(items._id)
                              ? true
                              : false
                          }
                          onChange={handleSelection}
                        />
                      </td>
                      <td>
                        <div
                          className={`${style.featureBox} align-items-center`}
                        >
                          <div
                            className={style.tableImg}
                            style={{ borderRadius: "50%" }}
                          >
                            <img
                              src={
                                items && items.imgsrc
                                  ? items.imgsrc
                                  : "https://mantisdashboard.io/assets/avatar-6-Cv0iCdgR.png"
                              }
                              fill
                              alt={items.name}
                            />
                          </div>
                          <div className={style.tableContent}>
                            <label className={style.tableTitle}>
                              {items.name}
                            </label>
                            <span className={style.tdSubHead}>Site Member</span>
                          </div>
                        </div>
                      </td>

                      {/* <td>{items && items.role ? `${items.role}` : "Admin"}</td> */}
                      <td>{items ? `${items.postCount} Stories` : ""}</td>
                      {/* <td>{items && items.postcount ? `${items.postcount} Posts`  : ""}</td> */}

                      <td>
                        <div className={style.actions}>
                          <button
                            className={style.items}
                            onClick={() => {
                              handleEdit(items._id);
                            }}
                          >
                            <FiEdit />
                          </button>
                          <button
                            className={style.items}
                            onClick={() => {
                              setDeleteWriter(items._id);
                            }}
                          >
                            <FiTrash2 />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <>
                {rowLoading ? (
                  <>
                    <div className="text-center">Loading...</div>
                  </>
                ) : (
                  <>
                    <div className="text-center">Writers Not Found</div>
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
        {rowLoading && !isEnd && <p>Loading....</p>}
        </div>
      </div>
    </>
  );
};

export default Table;
