import React from "react";
import "../../styles/marketing/emailMarketing.css";
import "../../styles/marketing/whatsappMarketing.css";
import "../../styles/marketing/smsMarketing.css";
import "../../styles/admin/mail/mail.css";
import AddRecipient from "../../components/marketing/AddRecipient";
import { useState } from "react";
import DropChips from "../../components/common/DropChips";
import { RiArrowLeftSLine } from "react-icons/ri";
import { IoVideocamOutline } from "react-icons/io5";
import { IoCallOutline } from "react-icons/io5";
import { GoPlus } from "react-icons/go";
import { BsCamera } from "react-icons/bs";
import { IoMicOutline } from "react-icons/io5";
import { PiSticker } from "react-icons/pi";
import { IoCheckmarkDone } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";

const EmailMain = () => {
  const [openRecipient, setOpenRecipient] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState(["All"]);

  const handleCheckboxChange = (contactName) => {
    setSelectedContacts((prevSelectedContacts) =>
      prevSelectedContacts.includes(contactName)
        ? prevSelectedContacts.filter((name) => name !== contactName)
        : [...prevSelectedContacts, contactName]
    );
  };

  const removeContact = (contactName) => {
    setSelectedContacts((prevSelectedContacts) =>
      prevSelectedContacts.filter((name) => name !== contactName)
    );
  };

  const [customMessage, setCustomMessage] = useState("")
  return (
    <>
      <div className="head-of-head writer-top">
        <div className="table-name">SMS Marketing</div>
      </div>
      <p className="p-top-writer">
        Customize templates and publish across social media platforms.
      </p>

      <div className="mail-main">
        <div className="whatsapp-body">
          <div className="mb-top">
            <h2>Details</h2>
          </div>
          <p className="label-email">Recipients</p>
          {/* <div className="reci-div" onClick={()=>{setOpenRecipient(true)}}></div> */}
          <div className="flex-drop-chip">
          <DropChips
            contact={selectedContacts}
            removeContact={removeContact}
            setOpenRecipient={setOpenRecipient}
            />
                  <button className="btn-primary" onClick={()=>{setOpenRecipient(true)}}>Select</button>
            </div>

          <div className="mb-body mb-89">
            <div className="input-group-mail">
              <label htmlFor="">Greeting</label>
              <input type="text" name="subject" />
            </div>
            
            <div className="input-group-mail">
              <label htmlFor="">Custom Message</label>
              <textarea type="text" name="customMessage" onChange={(e)=>{setCustomMessage(e.target.value)}} />
            </div>
          </div>
          <div className="email-btn">
            <button className="btn-primary">Send</button>
            <button className="btn-primary-outline">Cancel</button>
          </div>
        </div>

        <div className="whatsapp-body2">
          <div className="mb-top">
            <h2>Preview</h2>
          </div>

          <div className="mb-2-body-w mobile">
            <div className="sms-head">
              <RiArrowLeftSLine className="sms-back" />
              <div className="sms-head-left">
                <img
                  className="dp"
                  src="https://mantisdashboard.io/assets/avatar-6-Cv0iCdgR.png"
                ></img>
                <div className="user-logo-div-sms">
                  <p className="username-sms">{selectedContacts.length==1 ? selectedContacts[0]:"XYZ"}</p>
                  <IoIosArrowForward className="sms-logos-small"/>
                </div>
              </div>
            </div>

            <div className="sms-area">
              <div className="time-sms">
                <p className="sms-p-small">SMS</p>
                <p className="sms-p-small">Today, 9:33PM</p>
              </div>
              <div className="sms">
                <p className="main-mess">{customMessage ? customMessage:"Untitled Message"}</p>
              </div>
            </div>

            <div className="sms-control">
              <div className="plus-div">
              <GoPlus className="sms-logos"/>
                </div>
              <div className="sms-input-div">
              <input className="sms-input" placeholder="SMS"></input>
              <IoMicOutline className="sms-logos"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      {openRecipient && (
        <AddRecipient
          setOpenRecipient={setOpenRecipient}
          handleCheckboxChange={handleCheckboxChange}
          removeContact={removeContact}
          selectedContacts={selectedContacts}
          setSelectedContacts={setSelectedContacts}
          openRecipient={openRecipient}
        />
      )}
    </>
  );
};

export default EmailMain;
